import React, { Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';

import { cloneDeep, reverse } from 'lodash';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import EnpsDemo from './EnpsDemo';
import OrgHealth from './OrgHealth';
import DeepDiveBoard from './DeepDiveBoard';
import colors from '../../../../sass/colors';

function DeepDive({
    filters,
    selectedDemographic,
    ieHeatmap,
    ieHeatmapFetched,
    getEmpExpFactor,
    sortIeHeatmap,
    defaultSettings,
    apiParams,
    getHeatMapPractice,
    heatMapPracticeFetched,
    heatMapPractice,
    callHeatMap,
    empExpHeatmaps,
    empExpOhiHeatmaps,
    getOhiScoreForEmp,
    crossTabDemo,
    setCrossTabDemo,
    selectedTab,
    setSelectedTab,
    getOrgHealthData,
    orgHealth,
    singleQuestion,
    orgHealthFetched,
    enpsFetched,
    apiLoadingCount,
    errorInfo,
    addOnTab,
    newDeepDiv,
    newDeepDive,
    newDeepDiving,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    setSelectedDemographic,
    selectedDemographics,
    appliedBenchmark,
    footnoteList,
}) {
    const {
        demographic = '',
        lang: langTxt = '',
        report_type: surveyType = '',
        quartileColors = [],
        apply_employee_exp_benchmark = false,
    } = defaultSettings;
    const { filters: filterParams } = apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const { DEFAULT_STATE, ORGHEALTH_DEMO } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const {
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        EE_OHI_PERCENT_FAVORABLE,
        EE_OHI_PERCENTILE,
        BENCHMARK_HEAD,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
        BENCHMARK_NA,
        BOTTOM_DECILE,
        NO_BENCHMARK,
    } = SITE_TEXT;
    const legends = reverse(cloneDeep(IND_EXP_SCORE_CLASSIFICATION));
    const {
        fe_ee_deep_dive_footnote,
        com_ee_neg_enps_pc_fv,
        fe_ee_deep_dive_percentile,
        com_ee_neg_enps_percentile,
        ppt_ent_ex_org_health_analysis_footnote,
    } = footnoteList || {};
    const DEEP_DIVE_FOOTNOTES =
        surveyType === 'percent favorable'
            ? [fe_ee_deep_dive_footnote, com_ee_neg_enps_pc_fv]
            : [fe_ee_deep_dive_percentile, com_ee_neg_enps_percentile];
    const ORGHEALTH_FOOTNOTE = [...DEEP_DIVE_FOOTNOTES, ppt_ent_ex_org_health_analysis_footnote];
    const FOOTNOTES = crossTabDemo === ORGHEALTH_DEMO ? ORGHEALTH_FOOTNOTE : DEEP_DIVE_FOOTNOTES;

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
        columns: 10000,
    };

    const getPage = () => {
        const defaultProps = {
            filters,
            selectedDemographic,
            ieHeatmap,
            ieHeatmapFetched,
            sortIeHeatmap,
            defaultSettings,
            apiParams,
            getHeatMapPractice,
            heatMapPracticeFetched,
            heatMapPractice,
            callHeatMap,
            selectedTab,
        };
        switch (crossTabDemo) {
            case DEFAULT_STATE:
                return (
                    <DeepDiveBoard
                        {...defaultProps}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        selectedDemographics={selectedDemographics}
                        setSelectedDemographic={setSelectedDemographic}
                        crossTabDemo={crossTabDemo}
                        setSelectedTab={setSelectedTab}
                        setCrossTabDemo={setCrossTabDemo}
                        activeResetFilter={activeResetFilter}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
            case ORGHEALTH_DEMO:
                return (
                    <OrgHealth
                        orgHealth={orgHealth}
                        defaultSettings={defaultSettings}
                        singleQuestion={singleQuestion}
                        getOrgHealthData={getOrgHealthData}
                        filterParams={filterParams}
                        orgHealthFetched={orgHealthFetched}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        selectedDemographics={selectedDemographics}
                        setSelectedDemographic={setSelectedDemographic}
                        crossTabDemo={crossTabDemo}
                        setSelectedTab={setSelectedTab}
                        setCrossTabDemo={setCrossTabDemo}
                        activeResetFilter={activeResetFilter}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
            default:
                return (
                    <EnpsDemo
                        filters={filters}
                        getEmpExpFactor={getEmpExpFactor}
                        selectedDemographic={selectedDemographic}
                        apiParams={apiParams}
                        defaultSettings={defaultSettings}
                        crossTabDemo={crossTabDemo}
                        empExpHeatmaps={empExpHeatmaps}
                        empExpOhiHeatmaps={empExpOhiHeatmaps}
                        getOhiScoreForEmp={getOhiScoreForEmp}
                        addOns={addOns}
                        enpsFetched={enpsFetched}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        selectedDemographics={selectedDemographics}
                        setSelectedDemographic={setSelectedDemographic}
                        setSelectedTab={setSelectedTab}
                        setCrossTabDemo={setCrossTabDemo}
                        activeResetFilter={activeResetFilter}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
        }
    };

    return (
        <Fragment>
            <div>
                {getPage()}
                <div
                    className={c(
                        { orgHealthBoard: crossTabDemo === ORGHEALTH_DEMO },
                        'demographicBoard clearfix clearPadding'
                    )}
                >
                    {!apiLoadingCount && !errorInfo && (
                        <Fragment>
                            {apply_employee_exp_benchmark ? (
                                <div className="note">
                                    <b>{NOTE}:</b>
                                    {FOOTNOTES.map((txt, i) => {
                                        return <div>{`${i + 1}. ${txt}`}</div>;
                                    })}
                                </div>
                            ) : (
                                <div className="note">
                                    <b>{NOTE}:</b>
                                    {addOnTab !== 3 && surveyType === 'percent favorable' ? (
                                        <div>1. {EE_PERCENT_FAVORABLE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {addOnTab !== 3 && surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                                    {addOnTab === 3 && surveyType === 'percent favorable' && newDeepDive ? (
                                        <div>1. {EE_OHI_PERCENT_FAVORABLE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {addOnTab === 3 && surveyType === 'percentile' && newDeepDive ? (
                                        <div>1. {EE_OHI_PERCENTILE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {addOnTab === 3 && newDeepDiving && surveyType === 'percent favorable' ? (
                                        <div>1. {EE_PERCENT_FAVORABLE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {addOnTab === 3 && newDeepDiving && surveyType === 'percentile' ? (
                                        <div>1. {EE_PERCENTILE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {addOnTab === 3 && newDeepDiv && surveyType === 'percent favorable' ? (
                                        <div>1. {EE_PERCENT_FAVORABLE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {addOnTab === 3 && newDeepDiv && surveyType === 'percentile' ? (
                                        <div>1. {EE_PERCENTILE}</div>
                                    ) : (
                                        ''
                                    )}
                                    {IND_EXP_QUESTION_NOTE[0]}
                                    <br />
                                    {IND_EXP_QUESTION_NOTE[1]}
                                    <br />
                                    {addOnTab === 3 && crossTabDemo === ORGHEALTH_DEMO ? IND_EXP_QUESTION_NOTE[2] : ''}
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                {apply_employee_exp_benchmark ? (
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === BOTTOM_DECILE;
                                const BenchmarkTitle = title === NO_BENCHMARK;
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                ) : (
                    <Fragment>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                            {legends.map(({ title, positiveBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                            {legends.map(({ titleNeg: title, negativeBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                )}
            </ul>
        </Fragment>
    );
}

DeepDive.propTypes = {
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    empExpHeatmaps: PropTypes.object.isRequired,
    empExpOhiHeatmaps: PropTypes.object.isRequired,
    getOhiScoreForEmp: PropTypes.func.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
    setCrossTabDemo: PropTypes.func.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    getEmpExpFactor: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    getOrgHealthData: PropTypes.func.isRequired,
    orgHealth: PropTypes.object.isRequired,
    singleQuestion: PropTypes.object.isRequired,
    orgHealthFetched: PropTypes.bool.isRequired,
    enpsFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.number.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    newDeepDiving: PropTypes.bool.isRequired,
    newDeepDiv: PropTypes.bool.isRequired,
    newDeepDive: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    selectedDemographics: PropTypes.string.isRequired,
    appliedBenchmark: PropTypes.func.isRequired,
    footnoteList: PropTypes.array.isRequired,
};

export default DeepDive;
