import { cloneDeep } from 'lodash';
import colors from '../sass/colors';
import { RESURVEY_PARAMS } from '../containers/Reports/constants';
import { getStaticText, OHI4_IND_EXP, THOMSON_OHI_ID, IND_EXP_SCORE_CLASSIFICATION } from './constants';
import { DEMOGRAPHIC_SCORE_COLOR } from '../containers/Inclusion/constants';

const { SITE_TEXT } = getStaticText();
const {
    OPTION_ONE,
    NOT_ALLOWED,
    NUMBER_CAP_TEXT,
    COMPANY_HEAD,
    SIGNIFICANCE,
    SIGNIFICANCES,
    SIGNIFICANCE_V2,
} = SITE_TEXT;

export const base64ToArrayBuffer = base64 => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
};

export const getFirstLetter = text => {
    if (!text) return '';
    return text[0];
};

export const getPptParamsForLegend = benchmark => {
    switch (benchmark) {
        case '1002:11':
            return [
                {
                    name: 'Legend_Default',
                    is_delete: true,
                },
                {
                    name: 'Legend_OHI',
                    is_delete: true,
                },
                {
                    name: 'Legend_Refreshed',
                    is_delete: false,
                },
            ];
        case '-1:-1':
            return [
                {
                    name: 'Legend_Default',
                    is_delete: false,
                },
                {
                    name: 'Legend_OHI',
                    is_delete: true,
                },
                {
                    name: 'Legend_Refreshed',
                    is_delete: true,
                },
            ];
        case '1001:10':
            return [
                {
                    name: 'Legend_Default',
                    is_delete: true,
                },
                {
                    name: 'Legend_OHI',
                    is_delete: false,
                },
                {
                    name: 'Legend_Refreshed',
                    is_delete: true,
                },
            ];
        default:
            return [
                {
                    name: 'Legend_Default',
                    is_delete: true,
                },
                {
                    name: 'Legend_OHI',
                    is_delete: false,
                },
                {
                    name: 'Legend_Refreshed',
                    is_delete: true,
                },
            ];
    }
};

export const removeUnderscore = text => text.replace(/_/g, ' ');

export const getPptValue = (quartileColors, valueToUse, valueKey = '', addTextColor = true, addBorderColor = false) => {
    const { display_name = '', score = '', score_percentile = '', quartile = '' } = valueToUse;
    const { background = colors.$white, color: text_color = colors.$black, border: border_color = colors.$white } =
        quartileColors[quartile] || {};
    return [
        {
            name: `${valueKey}_bg`,
            bgcolor: background,
            border_color: quartile === '0' || addBorderColor ? border_color : '',
        },
        {
            name: `${valueKey}_text`,
            value: display_name,
            text_color: addTextColor ? text_color : '',
        },
        {
            name: `${valueKey}_value`,
            value: score_percentile || score,
            text_color,
        },
    ];
};

export const getExcludeGapString = (defaultExGap, reportExGap) => {
    if (!reportExGap) return defaultExGap;
    return reportExGap;
};

export const scoreToDisplay = (value, metaScoreArr, isPulse) => {
    if (isPulse && value === 0) return '-';
    if (value !== 0 || !metaScoreArr || !isPulse) return value;
    const allZero = metaScoreArr
        .map(({ score: top_score, bottom_2_score, neutral_score }) => [top_score, bottom_2_score, neutral_score])
        .flat()
        .every(elem => elem === 0);
    return allZero ? '-' : value;
};

export const gapToDisplay = (value, scoreValue, isPulse) => {
    if (scoreValue === NOT_ALLOWED) return NOT_ALLOWED;
    if (value === 0 && scoreValue === 0 && isPulse) return NOT_ALLOWED;
    if (value !== 0 || !isPulse) return value;
    return value;
};

export const getPptValueForStyles = (quartileColors, valueToUse, valueKey = '', isIngClient = false) => {
    const { display_name = '', score = '', score_percentile = '', quartile = '' } = valueToUse;
    const { color: text_color = colors.$black } = quartileColors[quartile] || {};
    return [
        {
            name: `${valueKey}_bg`,
            style: quartile !== '' && parseInt(quartile, 10) !== 999 ? quartile : 5,
        },
        {
            name: `${valueKey}_text`,
            value: display_name,
            text_color: isIngClient ? '#000000' : text_color,
        },
        {
            name: `${valueKey}_value`,
            value: score_percentile || score,
            text_color,
        },
    ];
};

export const getMetaValues = (mainObj = {}) => {
    const { score = 0, nuetral_score = 0, bottom2_score = 0 } = mainObj;
    return [
        {
            index: 0,
            value: `${score}`,
            row_index: 1,
            column_index: 0,
        },
        {
            series: 1,
            index: 0,
            value: `-${nuetral_score}`,
            label: `${nuetral_score}`,
            row_index: 1,
            column_index: 1,
        },
        {
            series: 3,
            index: 0,
            value: `-${bottom2_score}`,
            label: `${bottom2_score}`,
            row_index: 1,
            column_index: 2,
        },
    ];
};

export const getOutputFilter = (ArrItem1 = [], demographicListData) => {
    let filter_applied = [];
    const filterText = [];
    if (ArrItem1.length > 0) {
        for (let i = 0; i < ArrItem1.length; i += 1) {
            filter_applied = ArrItem1[i].split(':');
            const parentKey = filter_applied[0];
            const childKeyArr = filter_applied[1].split(',');
            const parentObj = demographicListData.filter(({ code }, index) => {
                if (code === parentKey) return demographicListData[index];
                return null;
            });
            const { code: parCode = '', label: parent = '', options: childArr = [] } = parentObj[0] || {};
            const children = {};
            for (let j = 0; j < childKeyArr.length; j += 1) {
                const child = childArr
                    .filter(({ code }) => code === childKeyArr[j])
                    .map(item => {
                        return { childKey: item.label, parentK: parent, parentCode: parCode, childCode: item.code };
                    });

                // const { code = '' } = child[0] || {};
                children[j] = childKeyArr[j];
                filterText.push(child[0]);
            }
        }
    }
    return filterText;
};

export const getFiltersAppliedText = (appliedFilters = [], demographicListData) => {
    let filter_applied = [];
    let filterText = '';
    if (appliedFilters.length > 0) {
        for (let i = 0; i < appliedFilters.length; i += 1) {
            filter_applied = appliedFilters[i].split(':');
            const parentKey = filter_applied[0];
            const childKeyArr = filter_applied[1].split(',');
            const parentObj = demographicListData.filter(({ code }, index) => {
                if (code === parentKey) return demographicListData[index];
                return null;
            });
            const { title: parent = '', options: childArr = [] } = parentObj[0] || {};
            let children = '';
            for (let j = 0; j < childKeyArr.length; j += 1) {
                const child = childArr.filter(({ code }, index) => {
                    if (code === childKeyArr[j]) return childArr[index].label;
                    return null;
                });
                const { label = '' } = child[0] || {};
                if (j !== childKeyArr.length - 1) {
                    children += label + ', ';
                } else {
                    children += label;
                }
            }
            filterText += i === 0 ? `${parent} : ${children}` : `, ${parent} : ${children}`;
        }
    }
    return filterText;
};

export const getToolDraggedItems = (data = [], itemsToCheck = []) => {
    if (!itemsToCheck.length || !Object.keys(data).length) return [];
    const practicesToDisplay = [];
    Object.keys(data).forEach(innerContent => {
        const valueInRecord = data[innerContent];
        const { children = {} } = valueInRecord;
        if (itemsToCheck.indexOf(innerContent) !== -1) {
            practicesToDisplay.push({ ...valueInRecord, dataKey: innerContent });
        }
        Object.keys(children).forEach(dataKey => {
            if (itemsToCheck.indexOf(dataKey) !== -1) {
                practicesToDisplay.push({ ...children[dataKey], dataKey });
            }
        });
    });
    return practicesToDisplay;
};

export const addZeroToNUmber = (num = 0) => {
    if (num >= 100) {
        return num;
    }
    return ('0' + num).slice(-2);
};

export const getBenchmarkString = (benchmarksArray = []) => {
    if (!benchmarksArray.length) {
        return '';
    }
    const { code: benCode = '', selectedOption = '' } = benchmarksArray.filter(({ selectedOption: op }) => op)[0] || {};
    return `${selectedOption}:${benCode}`;
};

export const getFilterArray = (demographics = [], preString = '', endString = '') => {
    if (!demographics.length) {
        return [];
    }
    const filters = [];
    demographics.forEach(({ options = [], code: parentCode = '' }) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { code }) => [...pre, code], []);
        if (optionsCode.length) {
            filters.push(`${preString}${parentCode}:${optionsCode.join(',')}${endString}`);
        }
    });
    return filters;
};

export const getBulkFilterArray = (demographics = [], preString = '', endString = '') => {
    if (!demographics.length) {
        return [];
    }
    const filters = [];
    demographics.forEach(({ options = [], code: parentCode = '' }) => {
        options
            .filter(({ isSelected }) => isSelected)
            .forEach(({ code, label }) =>
                filters.push({
                    filterOption: `${preString}${parentCode}:${code}${endString}`,
                    label,
                })
            );
    });
    return filters;
};

export const getResurveyFilterArray = (demographics = [], mappedDemos = {}) => {
    if (!demographics.length) {
        return [];
    }
    const filters = [];
    demographics.forEach(({ options = [], code: parentCode = '' }) => {
        const { previous_demographic: parentPre = '', options: preOptions = [] } = mappedDemos[parentCode] || {};
        options
            .filter(({ isSelected }) => isSelected)
            .forEach(({ code, label }) => {
                const { previous_demographic = '' } = preOptions.find(({ code: preCode }) => preCode === code) || {};
                filters.push({
                    filterOption: [`${parentCode}:${code}||`, `||${parentPre}:${previous_demographic}`],
                    label,
                });
            });
    });
    return filters;
};

export const getBenchmarksLabel = (benchmarks = []) => {
    if (!benchmarks.length) {
        return '';
    }
    const { label: mainLabel = '', selectedOption = '', options = [] } =
        benchmarks.filter(({ selectedOption: op }) => op)[0] || {};
    if (selectedOption) {
        const { label: optionsLabel } = options.find(({ code }) => code === selectedOption) || {};
        return `${mainLabel}:${optionsLabel}`;
    }
    return '';
};

export const getInclusionBenchmarksLabel = (benchmarks = []) => {
    if (!benchmarks.length) {
        return '';
    }
    const { label: mainLabel = '', selectedOption = '' } = benchmarks.filter(({ selectedOption: op }) => op)[0] || {};
    if (selectedOption) {
        return `${mainLabel}`;
    }
    return '';
};

export const getDemographicsLabel = (demographics = []) => {
    if (!demographics.length) {
        return [];
    }
    const filters = [];
    demographics.forEach(({ options = [], label: parentLabel = '' }) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { label }) => [...pre, label], []);
        if (optionsCode.length) {
            filters.push(`${parentLabel}:${optionsCode.join(',')}`);
        }
    });
    return filters;
};

export const getEkgOptions = (demographics = [], allSelected = false) => {
    if (!demographics.length) {
        return [];
    }
    const filters = [];
    demographics.forEach(({ options = [], code: parentCode = '' }) => {
        if (allSelected) {
            filters.push(`${parentCode}`);
        } else {
            const optionsCode = options.filter(({ isSelected }) => isSelected);
            if (optionsCode.length) {
                filters.push(`${parentCode}`);
            }
        }
    });
    return filters.join(',');
};

export const getFilterInsights = (demographics = [], preString = '', endString = '') => {
    if (!demographics.length) {
        return [];
    }
    const filters = [];
    demographics.forEach(({ options = [], code: parentCode = '' }) => {
        const arr = [];
        options
            .filter(({ isSelected }) => isSelected)
            .forEach(({ code, optionNo }) => {
                if (optionNo === OPTION_ONE) {
                    arr[0] = code;
                } else {
                    arr[1] = code;
                }
            });
        if (arr.length) {
            filters.push(`${preString}${parentCode},${arr.join(',')}${endString}`);
        }
    });
    return filters.length ? filters[0] : '';
};

export const updateFilterParams = (demographics = [], benchmarksArray = []) => {
    const benchmarks = getBenchmarkString(benchmarksArray);
    const filters = getFilterArray(demographics);
    return { benchmarks, filters };
};

export const getSubFromMain = (mainNumber = 0, midString = '', endString = '', checkForEmpty = true) => {
    if (!mainNumber && checkForEmpty) return '';
    const midStringInUse = mainNumber > 1 ? `${midString}s` : midString;
    return `${mainNumber} ${midStringInUse} ${endString}`;
};

export const getFilterSummary = (demographicArr = [], type = '') => {
    const filterArr = [];
    demographicArr.forEach(({ options = [], label: parentLabel = '' }) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { label }) => [...pre, label], []);
        if (optionsCode.length) {
            filterArr.push(`${parentLabel} [${optionsCode.join(' , ')}]`);
        }
    });
    let str = '';
    if (type === 'BuFilter') {
        str = filterArr.length > 0 ? filterArr.join(' ; ') : '';
    } else {
        str = filterArr.length > 0 ? `Report Filter: ${filterArr.join(' ; ')}` : '';
    }
    return str;
};

export const getFilterSummaryForBu = (demographicArr = [], filterOp) => {
    const filterArr = [];
    const filterOption = filterOp.replaceAll('|', '');
    demographicArr.forEach((_item, i) => {
        demographicArr[i].forEach(({ options = [], label: parentLabel = '' }) => {
            const optionsCode = options
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { label }) => [...pre, label], []);
            if (optionsCode.length) {
                if (i === demographicArr.length - 1) {
                    const { label = '' } = options.filter(({ code }) => filterOption.split(':')[1] === code)[0] || {};
                    filterArr.push(`${parentLabel} [${label}]`);
                } else {
                    filterArr.push(`${parentLabel} [${optionsCode.join(' , ')}]`);
                }
            }
        });
        const str = filterArr.length > 0 ? filterArr.join(' ; ') : '';
        return str;
    });
    return filterArr.length > 0 ? filterArr.join(' ; ') : '';
};

export const getFilterBUSummary = (reportFilter, comparisonFilter) => {
    const rpFilter = getFilterSummary(reportFilter, 'BuFilter');
    const cpFilter = getFilterSummary(comparisonFilter, 'BuFilter');
    return `Report Filter  (n = {0}) : ${rpFilter} #### Comparison Filter (n = {1}) : ${cpFilter}`;
};

export const comparisonFilterArr = (reportFilter, comparisonFilter = []) => {
    const filtersArray = [];
    reportFilter.forEach(({ options = [], code: parentCode = '' }, indexInDemo) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { code }) => [...pre, code], []);
        const { options: buOptions = [] } = comparisonFilter[indexInDemo] || {};
        const buOptionsCode = buOptions
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { code }) => [...pre, code], []);
        const preStr = optionsCode.length ? `${parentCode}:${optionsCode.join(',')}` : '';
        const endStr = buOptionsCode.length ? `${parentCode}:${buOptionsCode.join(',')}` : '';
        if (preStr || endStr) {
            filtersArray.push(`${preStr}||${endStr}`);
        }
    });
    return filtersArray;
};

export const checkCompareArrLength = (newDemoArr = []) =>
    newDemoArr.filter(({ options: demoOpt = [] }) => (demoOpt.filter(({ isSelected }) => isSelected) || []).length)
        .length;

export const checkChildrenArrLength = (newDemoArr = [], skipIndex = false, checkValue = 1) => {
    const arr = [];
    // eslint-disable-next-line no-unused-vars
    const checkChildren = newDemoArr.filter(({ options: demoOpt = [] }) => {
        const check = (demoOpt.filter(({ isSelected }) => isSelected) || []).length;
        arr.push(check);
        return demoOpt;
    });
    const filterArr = arr.filter(val => val !== 0);
    let condition = false;
    if (skipIndex && filterArr.length === 0) {
        condition = true;
    } else {
        condition = filterArr.length ? !filterArr.includes(checkValue) : false;
    }
    return condition;
};

export const checkForNodata = numberToCheck => {
    return numberToCheck === -2 ? Number.NEGATIVE_INFINITY : numberToCheck;
};

export const sortArray = (arr = [], key = '', sortType = 1, inCompletedRes = false) => {
    if (!arr.length) return [];
    return [...arr].sort((a, b) => {
        const aKey = inCompletedRes ? checkForNodata(a.total) - a.completed : a[key];
        const bKey = inCompletedRes ? checkForNodata(b.total) - b.completed : b[key];
        if (aKey < bKey) {
            return -1 * sortType;
        }
        if (aKey > bKey) {
            return sortType;
        }
        return 0;
    });
};

export const sortArrayOnNumber = (arr = [], key = '', sortType = 1) => {
    if (!arr.length) return [];
    return [...arr].sort((a, b) => {
        const aKey = parseInt(a[key].split('.')[0], 10);
        const bKey = parseInt(b[key].split('.')[0], 10);
        if (aKey < bKey) {
            return -1 * sortType;
        }
        if (aKey > bKey) {
            return sortType;
        }
        return 0;
    });
};

export const getBenchmarkName = (benchmarksArray = []) => {
    if (!benchmarksArray.length) {
        return '';
    }
    const { options = [], selectedOption = '' } = benchmarksArray.filter(({ selectedOption: op }) => op)[0] || {};
    const { label = '' } = options.filter(({ code }) => code === selectedOption)[0] || {};
    return label;
};

export const getDemographicPageCount = (demoArr = [], selectedCode = '', index = 0, chunkCount = 9, size) => {
    if (!demoArr.length) {
        return '';
    }
    const { options = [] } = demoArr.filter(({ code }) => code === selectedCode)[0] || {};
    const label =
        options.length > 9 ? `(Page ${Math.floor(index / chunkCount) + 1} of ${Math.ceil(size / chunkCount)})` : '';
    return label;
};

export const getDemographicName = (demoArr = [], selectedCode = '') => {
    if (!demoArr.length) {
        return '';
    }
    const { label = '', title = '' } = demoArr.filter(({ code }) => code === selectedCode)[0] || {};
    return title || label;
};

export const resurveyData = (additionalSettings, currentOhid, report_name, compareReports, filters, currentYear) => {
    const benchmarksArray = [];
    const filtersArray = [];
    const exBenchmarksArr = [];
    const ohids = [];
    const resurveyYears = [currentYear];
    additionalSettings.forEach(({ surveyType }) => {
        ohids.push(surveyType.split('+')[0]);
        resurveyYears.push(surveyType.split('+')[2]);
    });
    const combinedOhid = `${currentOhid},${ohids.join(',')}`;
    const surveyNames = additionalSettings.map(({ reportName }) => reportName);
    const survey_names = `${report_name}$#$${surveyNames.join('$#$')}`;
    const compare_ids = [];
    const comparison_names_arr = [];
    compareReports.forEach(({ comparisonName, from, to }) => {
        compare_ids.push(`${from.split('-')[0]}-${to.split('-')[0]}`);
        comparison_names_arr.push(comparisonName);
    });
    const comparison_names_string = comparison_names_arr.join('$#');
    const yearArr = additionalSettings.map(({ surveyType }) => surveyType.split('+')[2]);
    yearArr.unshift(currentYear);
    ohids.unshift(currentOhid);
    const filtersData = [];

    filters.forEach(({ benchmarks: newB = [], demographics: newD = [], ex_benchmarks: newExB = [] }, indexToUse) => {
        const benchMarkYear = yearArr[indexToUse];
        let preString = '';
        let endString = '';
        const prevSurveyCount = filters.length - 1;
        for (let i = 0; i < prevSurveyCount - indexToUse; i += 1) {
            endString += '||';
        }
        for (let i = prevSurveyCount; i > prevSurveyCount - indexToUse; i -= 1) {
            preString += '||';
        }
        const filtersObtained = getFilterArray(newD, preString, endString);
        filtersObtained.forEach(result => {
            filtersArray.push(result);
        });
        benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
        if (newExB.length) {
            exBenchmarksArr.push(`${getBenchmarkString(newExB)}@${benchMarkYear}`);
        }
        filtersData.push(getFilterSummary(newD, 'BuFilter'));
    });

    const FilterSummaryValue =
        filtersData.filter(data => !data).length === filtersData.length
            ? ''
            : filtersData.map((data, indexF) => `${ohids[indexF]}[${data}]`).join(' ; ');

    return {
        comparison_names: comparison_names_string,
        ohid: combinedOhid,
        survey_names,
        compare_ids,
        benchmarks: benchmarksArray.join(','),
        filters: filtersArray,
        benchmark_str: benchmarksArray.join(','),
        ex_benchmark_str: exBenchmarksArr.length ? exBenchmarksArr.join(',') : '',
        Resurvey_years: resurveyYears.filter(value => typeof value === 'string').join('$'),
        FilterSummary: FilterSummaryValue ? `Filter: ${FilterSummaryValue}` : '',
    };
};

export const getResurveyFilters = (filters, additionalSettings, currentOhid, currentYear) => {
    const filtersArray = [];
    const filtersData = [];
    const ohids = [currentOhid];
    const resurveyYears = [currentYear];
    additionalSettings.forEach(({ surveyType }) => {
        ohids.push(surveyType.split('+')[0]);
        resurveyYears.push(surveyType.split('+')[2]);
    });
    filters.forEach((newD, indexToUse) => {
        let preString = '';
        let endString = '';
        const prevSurveyCount = filters.length - 1;
        for (let i = 0; i < prevSurveyCount - indexToUse; i += 1) {
            endString += '||';
        }
        for (let i = prevSurveyCount; i > prevSurveyCount - indexToUse; i -= 1) {
            preString += '||';
        }
        const filtersObtained = getFilterArray(newD, preString, endString);
        filtersObtained.forEach(result => {
            filtersArray.push(result);
        });
        filtersData.push(getFilterSummary(newD, 'BuFilter'));
    });
    const FilterSummaryValue =
        filtersData.filter(data => !data).length === filtersData.length
            ? ''
            : filtersData.map((data, indexF) => `${ohids[indexF]}[${data}]`).join(' ; ');
    return { filtersArray, FilterSummaryValue };
};

export const heatmapDemoObj = (heatmapDemo, unmappedDemo) => {
    const unmappedDemoObj = Object.fromEntries(unmappedDemo);
    const heatmapobj = Object.fromEntries(heatmapDemo);
    Object.keys(heatmapobj).forEach(key => {
        if (unmappedDemoObj[key]) {
            delete heatmapobj[key];
        }
    });
    return heatmapobj;
};

export const manipulateReportData = (reportApiParams, ReportType, params) => {
    const {
        compareFilterOption,
        filterOption,
        heatMapOption,
        selectAll,
        heatmap_demographic,
        includeOhi4Demo,
        ohi4_incDemographics,
        options,
        demographicFilters,
        comparisonBubble,
        additionalSettings,
        compare_pos,
        compareReports,
        ohid,
        filters,
        priorityPractice,
        comparison_names,
        practices,
        compareFilterArr,
        SURVEYSENT_RR,
        gap_on,
        bubbleSort,
        practice_options,
        outcomePerception,
        ekgOptions,
        outcomeDemographic,
        deep_drive_options,
        inclusionReportType,
        threshold,
        exclude_demographic,
        demographic_breakdown_summary,
        exclude_inclusion_gap,
        withBenchmark,
        non_profit_report_full,
        overall_inclusion_added,
        quartile_movement,
        employee_experience_demographic,
        response_rate_demographic,
        customDeltaValue,
        eeCompBubble,
        eeCustomDeltaValue,
        ...rest
    } = reportApiParams;
    let obj = {};
    const heatMap = Object.fromEntries(heatmap_demographic);
    const heatMapKeys = Object.keys(heatMap);
    let exclude_from_gap = [];
    if (exclude_inclusion_gap) {
        exclude_from_gap = exclude_inclusion_gap.includes(',')
            ? exclude_inclusion_gap.split(',')
            : [exclude_inclusion_gap];
    }
    if (ReportType === '0') {
        const { surversentRR, pss_module, engagement, ohi4_incModules, cohesionDemographics } = params;
        const individualExpAdded = ohi4_incModules.some(({ IsSelected, Name }) => Name === OHI4_IND_EXP && IsSelected);
        obj = {
            ...rest,
            ohid,
            filters,
            options: {
                ...options,
                SURVEYSENT_RR: surversentRR,
                pss_module,
                engagement,
                non_profit_report_full,
            },
            heatmap_demographic: heatMap,
            threshold,
            response_rate_demographic: Object.fromEntries(response_rate_demographic),
            employee_experience_demographic: Object.fromEntries(employee_experience_demographic),
            cohesion_demographic: getFilterArray(cohesionDemographics),
            ohi4_incModules: includeOhi4Demo ? ohi4_incModules : [],
            ohi4_incDemographics: includeOhi4Demo && individualExpAdded ? Object.fromEntries(ohi4_incDemographics) : {},
        };
    } else if (ReportType === '1') {
        const {
            demoGraphicState,
            buDemographics,
            surversentRR,
            pss_module,
            engagement,
            isIngClient,
            cohesionDemographics,
            ohi4_incModules,
            TRResDemoState,
        } = params;
        const individualExpAdded = ohi4_incModules.some(({ IsSelected, Name }) => Name === OHI4_IND_EXP && IsSelected);
        const resFiltersTR = TRResDemoState.length ? { resFiltersTR: getFilterArray(TRResDemoState) } : {};
        const { year } = reportApiParams;
        const { filtersArray, FilterSummaryValue } = getResurveyFilters(
            [demoGraphicState, buDemographics],
            additionalSettings,
            ohid,
            year
        );
        obj = {
            ...rest,
            comparison_names,
            compare_pos,
            ohid: `${ohid},${ohid}`,
            options: {
                ...options,
                SURVEYSENT_RR: surversentRR,
                pss_module,
                engagement,
                FilterSummary: FilterSummaryValue,
            },
            filters: isIngClient ? filtersArray : comparisonFilterArr(demoGraphicState, buDemographics),
            bu_Filters: comparisonFilterArr(demoGraphicState, buDemographics),
            heatmap_demographic: heatMap,
            cohesion_demographic: getFilterArray(cohesionDemographics),
            response_rate_demographic: Object.fromEntries(response_rate_demographic),
            employee_experience_demographic: Object.fromEntries(employee_experience_demographic),
            threshold,
            ohi4_incModules: includeOhi4Demo ? ohi4_incModules : [],
            ohi4_incDemographics: includeOhi4Demo && individualExpAdded ? Object.fromEntries(ohi4_incDemographics) : {},
            ...resFiltersTR,
        };
    } else if (ReportType === '3') {
        const {
            lang,
            year,
            report_name,
            sort_by,
            export_type,
            report_id,
            report_type,
            others,
            bm_filters,
            practicePptVersion,
            outcomePptVersion,
            includeDelta,
            options: { BENCHMARK_NAME, FilterSummary: IngBuFilterSummary, BU_FilterSummary, EX_BENCHMARK_NAME },
            bu_comparison_names,
            bu_reportname,
            unmapped_demos,
            build_your_report,
            ee_unmapped_demos,
            practice_rank_score_flag,
            sort_by_ee,
            sort_by_rr,
            ex_benchmarks,
        } = reportApiParams;
        const {
            surversentRR,
            currentYear,
            surveyCompleted,
            surveySent,
            filters: filterArr,
            allFilters,
            pss_module,
            engagement,
            buDemographics,
            ohi4_incModules,
            cohesionDemographics,
        } = params;
        const { Resurvey_years, survey_names, FilterSummary, ...data } = resurveyData(
            additionalSettings,
            ohid,
            report_name,
            compareReports,
            filterArr,
            year
        );
        const isThomsonRpt = ohid === THOMSON_OHI_ID;
        const { filtersArray, FilterSummaryValue } = getResurveyFilters(allFilters, additionalSettings, ohid, year);
        const heatmapToUse = isThomsonRpt ? heatMap : heatmapDemoObj(heatmap_demographic, unmapped_demos);
        const individualExpAdded = ohi4_incModules.some(({ IsSelected, Name }) => Name === OHI4_IND_EXP && IsSelected);
        obj = {
            ...RESURVEY_PARAMS,
            ...data,
            practicePptVersion,
            outcomePptVersion,
            comparison_heatmap: !isThomsonRpt,
            bu_comparison_names,
            bu_reportname,
            lang,
            bm_filters,
            bu_Filters: comparisonFilterArr(allFilters[0], buDemographics),
            year,
            threshold,
            report_name,
            export_type,
            BENCHMARK_NAME,
            sort_by,
            sort_by_ee,
            sort_by_rr,
            report_id,
            report_type,
            PRIORITY_PRAC_ORDER: priorityPractice,
            heatmap_demographic: heatmapToUse,
            build_your_report,
            practice_rank_score_flag,
            ex_benchmarks,
            EX_BENCHMARK_NAME,
            options: {
                ...RESURVEY_PARAMS.options,
                BU_FilterSummary,
                IngBuFilterSummary,
                Resurvey_years,
                survey_names,
                SURVEYSENT_RR: surversentRR,
                Resurvey_SigVsQuartile: comparisonBubble,
                ee_Resurvey_SigVsQuartile: eeCompBubble,
                reportLanguage: `'${lang}'`,
                Resurvey_Stats: `${currentYear}$${surveySent}$${surveyCompleted}`,
                pss_module,
                engagement,
                FilterSummary: FilterSummaryValue,
                includeDelta,
                unmapped_demos: JSON.stringify(Object.fromEntries(unmapped_demos)),
                resurvey_custom_delta: customDeltaValue,
                ee_resurvey_custom_delta: eeCustomDeltaValue,
                ee_unmapped_demos: JSON.stringify(Object.fromEntries(ee_unmapped_demos)),
            },
            ohi4_incModules: includeOhi4Demo ? ohi4_incModules : [],
            ohi4_incDemographics: includeOhi4Demo && individualExpAdded ? Object.fromEntries(ohi4_incDemographics) : {},
            cohesion_demographic: getFilterArray(cohesionDemographics),
            response_rate_demographic: Object.fromEntries(response_rate_demographic),
            employee_experience_demographic: Object.fromEntries(employee_experience_demographic),
            others,
            filters: filtersArray,
        };
    } else if (ReportType === '4') {
        const { surveyCompleted, surveySent, inclusion_threshold = 10 } = params;
        const { benchmarks = '', benchmark_sub_version_id = 2 } = reportApiParams;
        const s_detail = `survey_detail: ${surveySent},${surveyCompleted},${(surveyCompleted / surveySent) * 100}`;
        const heat_map_score_on = heatMapKeys.length ? `heat_map_score_on: ${heatMapKeys.join(',')}` : '';
        const bubble_chart = gap_on ? `bubble_chart_options: ${gap_on}` : '';
        const p_option = practice_options ? `practice_options: ${practice_options}` : '';
        const deep_opt = deep_drive_options ? `deep_drive_options: ${deep_drive_options}` : '';
        const o_perception = outcomePerception ? `outcome_perceptions: ${outcomePerception}` : '';
        const ekg_opt = ekgOptions ? `ekg_options: ${ekgOptions}` : '';
        const finalFilterArr = filters
            .concat(bubble_chart)
            .concat(deep_opt)
            .concat(ekg_opt)
            .concat(p_option)
            .concat(o_perception)
            .concat(heat_map_score_on)
            .concat(s_detail);
        obj = {
            ...rest,
            ohid,
            inclusion_payload: {
                exclude_from_gap,
                with_benchmark: withBenchmark,
                pid: '',
                benchmarks,
                demographic_breakdown_summary,
                overall_inclusion_added,
                quartile_movement,
            },
            inclusion_threshold,
            filters: finalFilterArr.filter(el => el !== ''),
            options: {
                ...options,
            },
            heatmap_demographic: heatMap,
            practice_options,
            threshold,
            benchmark_sub_version_id,
        };
    } else if (ReportType === '6') {
        const {
            surveyCompleted,
            surveySent,
            inclusion_threshold = 10,
            resurveyFilters = [],
            previousSurveySent = 1,
            previousFilters = [],
            comparisonPrevSurveySent = 1,
        } = params;
        const s_detail = `survey_detail: ${surveySent},${surveyCompleted},${(surveyCompleted / surveySent) * 100}`;
        const heat_map_score_on = heatMapKeys.length ? `heat_map_score_on: ${heatMapKeys.join(',')}` : '';
        const finalFilterArr = filters.concat(heat_map_score_on).concat(s_detail);
        const { surveyType = '', reportName = '' } = additionalSettings[0] || {};
        const surveyTypeSplit = surveyType.split('+');
        const { benchmarks = '', benchmark_sub_version_id = 2 } = reportApiParams;
        const additionCompSetting = additionalSettings[1] || {};
        const { surveyType: surveyCompType = '', reportName: reportComparisonName = '' } = additionCompSetting || {};
        const surveyCompTypeSplit = surveyCompType.split('+');
        const objComparison = {
            ohid: surveyCompTypeSplit[0],
            year: surveyCompTypeSplit[2],
            filters: previousFilters,
            reportName: reportComparisonName,
            survey_sent: comparisonPrevSurveySent,
        };
        const comparisonObj = Object.keys(additionCompSetting).length > 0 ? objComparison : null;
        const resurveyCompData = {
            comparison_data: {
                ohid: surveyTypeSplit[0],
                year: surveyTypeSplit[2],
                filters: resurveyFilters,
                reportName,
                survey_sent: previousSurveySent,
                comparison_data_previous: comparisonObj,
            },
        };
        obj = {
            ...rest,
            ohid,
            inclusion_threshold,
            filters: finalFilterArr.filter(el => el !== ''),
            options: {
                ...options,
            },
            inclusion_payload: {
                exclude_from_gap,
                with_benchmark: benchmarks !== '-1:-1',
                pid: '',
                benchmarks,
                overall_inclusion_added,
                quartile_movement,
            },
            heatmap_demographic: heatMap,
            practice_options,
            threshold,
            benchmark_sub_version_id,
            ...resurveyCompData,
        };
    } else if (ReportType === '5') {
        const { benchmarks = '', benchmark_sub_version_id = 2, resource = 'inclusion' } = reportApiParams;
        const ekg_opt = ekgOptions ? `ekg_options: ${ekgOptions}` : '';
        const finalFilterArr = filters.concat(ekg_opt);
        obj = {
            ...rest,
            ohid,
            filters: finalFilterArr.filter(el => el !== ''),
            threshold,
            with_benchmark: withBenchmark,
            resource,
            inclusion_payload: {
                exclude_from_gap,
                with_benchmark: benchmarks !== '-1:-1',
                pid: '',
                benchmarks,
                benchmark_sub_version_id,
                quartile_movement,
            },
        };
    } else if (ReportType === '7') {
        obj = {
            ...rest,
            threshold,
            ohid,
            with_benchmark: withBenchmark,
            filters,
            heatmap_demographic: heatMap,
            inclusion_payload: {
                exclude_from_gap,
                with_benchmark: withBenchmark,
                pid: '',
                benchmarks: ['1001:1'],
                quartile_movement,
            },
        };
    } else if (ReportType === '8') {
        const { lang, year, report_name, export_type, report_id, report_type, resource } = reportApiParams;
        const { surversentRR, pss_module, engagement } = params;
        obj = {
            ...rest,
            ohid,
            filters,
            options: {
                ...options,
                SURVEYSENT_RR: surversentRR,
                pss_module,
                engagement,
            },
            heatmap_demographic: heatMap,
            threshold,
            year,
            lang,
            resource,
            export_type,
            report_name,
            report_id,
            report_type,
        };
    } else if (ReportType === '9') {
        // const { lang, year, report_name, export_type, report_id, resource } = reportApiParams;
        const {
            lang,
            year,
            report_name,
            export_type,
            report_id,
            resource,
            only_op_module_data,
            op_module_without_cr,
        } = reportApiParams;
        // const { surversentRR, pss_module, engagement } = params;
        obj = {
            ...rest,
            ohid,
            filters,
            options: {
                ...options,
                // SURVEYSENT_RR: surversentRR,
                // pss_module,
                // engagement,
            },
            heatmap_demographic: heatMap,
            threshold,
            year,
            lang,
            resource,
            export_type,
            report_name,
            report_id,
            report_type: 'op_model_module_excel',
            only_op_module_data,
            op_module_without_cr,
        };
    }
    return obj;
};

export const combineFilters = (benchState, demoGraphicState, exBenchState) => {
    const arr = [];
    benchState.forEach((val, index) => {
        const obj = {};
        obj.benchmarks = val.map(v => {
            v.isOpen = false;
            return v;
        });
        obj.demographics = demoGraphicState[index].map(v => {
            v.isOpen = false;
            return v;
        });
        if (exBenchState.length && exBenchState[index] && exBenchState[index].length) {
            obj.ex_benchmarks =
                exBenchState[index].map(v => {
                    v.isOpen = false;
                    return v;
                }) || [];
        }
        arr.push(obj);
    });
    return arr;
};

export const parseJwt = (token = '') => {
    if (!token) return {};
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const assignGraphColor = value => {
    /* eslint-disable prefer-destructuring */
    const { $cohesionGraph } = colors;
    let lineGraphColor = '';
    let textColor = '';
    if (value >= 0.9 && value < 1) {
        lineGraphColor = $cohesionGraph[1][0];
        textColor = $cohesionGraph[1][1];
    } else if (value >= 0.7 && value < 0.9) {
        lineGraphColor = $cohesionGraph[2][0];
        textColor = $cohesionGraph[2][1];
    } else if (value >= 0.5 && value < 0.7) {
        lineGraphColor = $cohesionGraph[3][0];
    } else if (value >= 0.3 && value < 0.5) {
        lineGraphColor = $cohesionGraph[4][0];
        textColor = $cohesionGraph[4][1];
    } else if (value >= -0.3 && value < 0.3) {
        lineGraphColor = $cohesionGraph[5][0];
    } else if (value === 1) {
        lineGraphColor = $cohesionGraph[6][0];
        textColor = $cohesionGraph[6][1];
    } else {
        lineGraphColor = $cohesionGraph[7][0];
        textColor = $cohesionGraph[7][1];
    }
    return { textColor, lineGraphColor };
};

export const assignGraphColorOhi4 = value => {
    /* eslint-disable prefer-destructuring */
    const { $cohesionGraphOhi4: $cohesionGraph } = colors;
    let lineGraphColor = '';
    let textColor = '';
    if (value >= 0.7 && value < 1) {
        lineGraphColor = $cohesionGraph[1][0];
        textColor = $cohesionGraph[1][1];
    } else if (value < 0.7 && value >= 0.5) {
        lineGraphColor = $cohesionGraph[2][0];
        textColor = $cohesionGraph[2][1];
    } else if (value < 0.5) {
        lineGraphColor = $cohesionGraph[3][0];
    } else {
        lineGraphColor = $cohesionGraph[4][0];
        textColor = $cohesionGraph[4][1];
    }
    return { textColor, lineGraphColor };
};

export const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

export const toFixed2 = num => {
    return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
};

export const assignGraphColorPractice = value => {
    /* eslint-disable prefer-destructuring */
    let lineGraphColor = '';
    let textColor = '';
    if (value > 95) {
        lineGraphColor = colors.$cohesionPractice[1][0];
        textColor = colors.$cohesionPractice[1][1];
    } else if (value >= 85 && value <= 95) {
        lineGraphColor = colors.$cohesionPractice[2][0];
        textColor = colors.$cohesionPractice[2][1];
    } else if (value >= 75 && value <= 84) {
        lineGraphColor = colors.$cohesionPractice[3][0];
    } else {
        lineGraphColor = colors.$cohesionPractice[4][0];
        textColor = colors.$cohesionPractice[4][1];
    }
    return { textColor, lineGraphColor };
};

export const assignGraphColorPracticeOhi4 = value => {
    /* eslint-disable prefer-destructuring */
    let lineGraphColor = '';
    let textColor = '';
    if (value > 95) {
        lineGraphColor = colors.$cohesionPracticeOhi4[1][0];
        textColor = colors.$cohesionPracticeOhi4[1][1];
    } else if (value >= 85 && value <= 95) {
        lineGraphColor = colors.$cohesionPracticeOhi4[2][0];
        textColor = colors.$cohesionPracticeOhi4[2][1];
    } else if (value >= 75 && value <= 84) {
        lineGraphColor = colors.$cohesionPracticeOhi4[3][0];
    } else {
        lineGraphColor = colors.$cohesionPracticeOhi4[4][0];
        textColor = colors.$cohesionPracticeOhi4[4][1];
    }
    return { textColor, lineGraphColor };
};

export const getData = (control, firstBenchmark, secondBenchmark, selectedOption, code) => {
    const bm_filters_arr = [];
    const footer_arr = [];
    const { code: right = '', selectedOption: rightOpt = '' } =
        firstBenchmark.filter(({ selectedOption: op }) => op)[0] || {};
    if (control === 'left') {
        bm_filters_arr.push(`${selectedOption}:${code}`);
        bm_filters_arr.push(`${rightOpt}:${right}`);
    } else {
        bm_filters_arr.push(`${rightOpt}:${right}`);
        bm_filters_arr.push(`${selectedOption}:${code}`);
    }
    const indexOne = firstBenchmark.findIndex(x => x.code === code);
    const indexTwo = firstBenchmark.findIndex(x => x.selectedOption === rightOpt);
    const { label: labelOne = '' } =
        secondBenchmark[indexOne].options.filter(op => op.code === selectedOption)[0] || {};
    const { label: labelTwo = '' } = secondBenchmark[indexTwo].options.filter(op => op.code === rightOpt)[0] || {};
    footer_arr.push(
        {
            bmcode: `${selectedOption}:${code}`,
            bmtext: labelOne,
        },
        {
            bmcode: `${rightOpt}:${right}`,
            bmtext: labelTwo,
        }
    );
    return {
        footer_arr,
        bm_filters_arr,
    };
};

// export const getInclusionStyle = (inclusionQuartile, quartile, benchmarkStyle = [], styles = []) => {
//     if (inclusionQuartile === -1 && quartile === -1) return benchmarkStyle[6];
//     if (inclusionQuartile > -1) {
//         return styles[inclusionQuartile];
//     }
//     const obj = benchmarkStyle.filter(
//         ({ quartile: quartileNo = 0 }) => parseInt(quartileNo, 10) === parseInt(quartile, 10)
//     )[0];
//     return obj;
// };

export const getInclusionStyle = (inclusionQuartile, quartile, benchmarkStyle = [], styles = []) => {
    const quart = parseInt(quartile, 10);
    const quartileNo = quart === 0 ? quart : quart - 1;
    if (inclusionQuartile === -1 && quartile === -1) return { bgcolor: '', text_color: '', border_color: '' };
    if (inclusionQuartile === 999 || quart === 999)
        return {
            bgcolor: '#ffffff',
            text_color: '#000000',
            border_color: '#ffffff',
        };
    if (inclusionQuartile > -1) {
        return styles[inclusionQuartile];
    }
    return benchmarkStyle[quartileNo];
};
export const getIncStyle = (quartile = '', styleArr) => {
    const styleArrToUse = styleArr.slice(1);
    const quartileNo = parseInt(quartile, 10);
    const { color, border, background, title } = styleArrToUse[quartileNo] || {};
    return {
        bgcolor: background || '#ffffff',
        text_color: color || '#ffffff',
        border_color: border || '#ffffff',
        title,
    };
};

export const changeColorArr = colorsArr => {
    const arr = colorsArr
        .map(({ background, color, border, title }) => {
            return {
                bgcolor: background || '#ffffff',
                text_color: color || '#ffffff',
                border_color: border || '#ffffff',
                style_for: 'score',
                title,
            };
        })
        .slice(0, 5);
    arr[0] = arr[1];
    return arr;
};

export const getIncBenchmarkStyles = colorsArr => {
    return (
        colorsArr
            .map(({ background, color, border, title }) => {
                return {
                    bgcolor: background || '#ffffff',
                    text_color: color || '#ffffff',
                    border_color: border || '#ffffff',
                    style_for: 'score',
                    title,
                };
            })
            .slice(1, 5) || []
    );
};

export const getPptStyles = (withBenchmark, styleColors, pptBenchmarkStyles, quartileColors) => {
    return !withBenchmark ? styleColors : [...changeColorArr(quartileColors), ...pptBenchmarkStyles.slice(5)];
};

export const getStyleForExcel = (quart, styleArr) => {
    const quartNo = parseInt(quart, 10);
    const quartile = quartNo === 0 ? quartNo : quartNo - 1;
    const obj = styleArr[quartile];
    return obj;
};

export const getStyleForGapExcel = (index, styleArr) => {
    if (isNaN(Number(index))) return null;
    return styleArr[index];
};

export const quartileTitle = (withBenchmark, title) => {
    if (!title) return '';
    if (withBenchmark) {
        const arr = title.split('_');
        const newTitle = arr[0][0].toUpperCase() + arr[0].slice(1) + ' ' + arr[1][0].toUpperCase() + arr[1].slice(1);
        return newTitle;
    }
    return title;
};

export const getFiltersText = (appliedFilters, demographicListData) => {
    let filter_applied = [];
    let filterText = '';
    if (appliedFilters.length > 0) {
        for (let i = 0; i < appliedFilters.length; i += 1) {
            filter_applied = appliedFilters[i].split(':');
            const parentKey = filter_applied[0];
            const childKeyArr = filter_applied[1].split(',');
            const parentObj = demographicListData.filter(({ code }, index) => {
                if (code === parentKey) return demographicListData[index];
                return null;
            });
            const parent = parentObj[0].label;
            const childArr = parentObj[0].options;
            let children = '';
            for (let j = 0; j < childKeyArr.length; j += 1) {
                const childLabel = childArr.filter(({ code }, index) => {
                    if (code === childKeyArr[j]) return childArr[index].label;
                    return null;
                });
                if (j !== childKeyArr.length - 1) {
                    children += childLabel[0].label + ', ';
                } else {
                    children += childLabel[0].label;
                }
            }
            filterText += parent + ' : ' + children + '\n';
        }
    }
    return filterText;
};

export const getBenchmarkColor = (quartile = '', scoreVal = '') => {
    const quart = parseInt(quartile, 10);
    if (quart > 20 || scoreVal === '-') {
        return 5;
    }
    if (quart < 0) {
        return 6;
    }
    return quart;
};

export const getIaBenchmarkLabel = label => {
    if (label === 'Standardized classification (traffic light colors)') {
        return 'STD (Traffic light)';
    }
    if (label === 'Global Inclusion benchmark (OHI colors)') {
        return 'IA (OHI colors)';
    }
    if (label === 'Global Inclusion benchmark (Refreshed colors)') {
        return 'IA (Refreshed)';
    }
    return label;
};

export const getColor = (number, scoreColorObj = [], demo = false, showIndex = false) => {
    let index = 0;
    if (number === '-') {
        index = 5;
    } else if (number >= 80) {
        index = 0;
    } else if (number >= 70 && number <= 79) {
        index = 1;
    } else if (number >= 60 && number <= 69) {
        index = 2;
    } else if (number >= 50 && number <= 59) {
        index = 3;
    } else if (number < 50 && number >= 0) {
        index = 4;
    }
    if (!number) {
        index = 18;
    }

    if (showIndex) {
        return index;
    }
    return demo ? DEMOGRAPHIC_SCORE_COLOR[index] : scoreColorObj[index];
};

export const getNegColor = (number, scoreColorObj = [], demo = false, showIndex = false) => {
    let index = 0;
    if (number === '-') {
        index = 5;
    } else if (number >= 40) {
        index = 0;
    } else if (number >= 30 && number <= 39) {
        index = 1;
    } else if (number >= 20 && number <= 29) {
        index = 2;
    } else if (number >= 10 && number <= 19) {
        index = 3;
    } else if (number < 10 && number >= 0) {
        index = 4;
    }
    if (!number) {
        index = 18;
    }

    if (showIndex) {
        return index;
    }
    return demo ? DEMOGRAPHIC_SCORE_COLOR[index] : scoreColorObj[index];
};

export const excludeFromGapText = (excludedDemos, parentArr) => {
    if (!excludedDemos) return '';
    const arr = excludedDemos.split(',');
    let text = '';
    arr.forEach(elem => {
        const parentObj = parentArr.filter(({ code }) => code === elem)[0];
        const { label = '' } = parentObj || {};
        if (label) {
            text += label;
        }
    });
    return text;
};

export const getRowData = (value, styleIndex, row_index, column_index, highLowColor) => {
    const rowObj = {
        row_index,
        column_index,
        value: isNaN(value) && value !== '-' ? ' ' : value,
        style: isNaN(value) && value !== '-' ? 6 : styleIndex,
    };
    if (value === 'a') {
        return {
            row_index,
            column_index,
            value: ' ',
            style: 6,
        };
    }
    if (value === '-') {
        return {
            row_index,
            column_index,
            value,
            style: 6,
        };
    }
    return highLowColor ? { ...rowObj, border_color: highLowColor } : rowObj;
};

export const getRowDataText = (value, styleIndex, row_index, column_index) => {
    if (value === 'a') {
        return {
            row_index,
            column_index,
            value,
            style: 7,
        };
    }
    return {
        row_index,
        column_index,
        value,
        style: styleIndex,
    };
};

export const getGapColor = (number, gapColorObj = {}, showIndex = false) => {
    let index = 0;
    if ((isNaN(number) && !showIndex) || (!number && number !== 0)) {
        return {
            bgcolor: '#FFFFFF',
            border_color: '#ffffff',
            text_color: '#000000',
        };
    }
    if (showIndex) {
        if (isNaN(number)) {
            return -7;
        }
    }
    index = gapColorObj.findIndex(({ to, from }) => number >= from && number <= to);
    return showIndex ? index : gapColorObj[index];
};

export const getDeltaColor = payload => {
    const { delta, type = '', quartMovement = '', noBg = false } = payload;
    let addToIndex = 0;
    const deltaToDisplay = quartMovement !== '' ? quartMovement : delta;
    if (quartMovement !== '') {
        if (deltaToDisplay > 0) {
            addToIndex = 5;
        } else if (deltaToDisplay < 0) {
            addToIndex = 2;
        } else if (deltaToDisplay === 0) {
            addToIndex = 5;
        }
    }
    if (delta === '') {
        return 3;
    }
    if (delta === '-' || noBg) {
        return -10;
    }
    if (deltaToDisplay > 0) {
        return type === 'gap' ? 2 : addToIndex;
    }
    if (deltaToDisplay < 0) {
        return type === 'gap' ? 0 : 2 + addToIndex;
    }
    return type === 'gap' ? 1 : 1 + addToIndex;
};

export const quartileDelta = (iaQuartile, quartile, resIaQuartile, resQuartile) => {
    const changeTopDecile = num => {
        if (num === 0) return 1;
        return num;
    };
    const incQuartile = changeTopDecile(parseInt(iaQuartile, 10));
    const quart = changeTopDecile(parseInt(quartile, 10));
    const resIncQuartile = changeTopDecile(parseInt(resIaQuartile, 10));
    const resQuart = changeTopDecile(parseInt(resQuartile, 10));
    const delta = quart > -1 ? resQuart - quart : resIncQuartile - incQuartile;
    if (delta > 20 || delta < -20) return -100;
    return delta;
};

export const deltaToDisplay = (current, prev) => {
    if (current === '-' || prev === '-') return '-';
    if ((!current && current !== 0) || (!prev && prev !== 0)) return '';
    const delta = current - prev;
    if (delta > 0) {
        return `+${delta}`;
    }
    return delta;
};

export const getCrossTabBg = number => {
    let index = 0;
    if (number >= 0 && number < 40) {
        index = 4;
    } else if (number >= 40 && number <= 70) {
        index = 3;
    } else if (number > 70 && number <= 90) {
        index = 2;
    } else if (number > 90) {
        index = 1;
    }
    return index;
};

export function toUpper(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => {
            return word[0].toUpperCase() + word.substr(1).toLowerCase();
        })
        .join(' ');
}

export const sortChildren = (child, sortKey = 'sort_order', asc = 1) => {
    return Object.keys(child)
        .sort((a, b) =>
            // eslint-disable-next-line no-nested-ternary
            child[a][sortKey] > child[b][sortKey] ? 1 * asc : child[b][sortKey] > child[a][sortKey] ? -1 * asc : 0
        )
        .map(key => {
            child[key].keyName = key;
            child[key].key = key;
            return child[key];
        });
};

export const getSortedChild = (children, chunk) => {
    if (children.length <= chunk) {
        return {
            valuesToUse: children,
            othersText: '-',
        };
    }
    const sortedVal = sortChildren(children, 'n_size', -1);
    const valuesToUse = [...sortedVal.slice(0, chunk)];
    let othersSize = 0;
    const othersInfo = [];
    sortedVal.slice(chunk).forEach(({ display_name, n_size }) => {
        othersSize += n_size;
        othersInfo.push(`${display_name} (${n_size})`);
    });
    valuesToUse.push({
        display_name: 'Others',
        n_size: othersSize,
    });
    return { valuesToUse, othersText: othersInfo.join(', ') };
};

export const getPptValues = (mainParent = {}) => {
    const arrayIterated = [];
    Object.keys(mainParent).forEach(name => {
        arrayIterated.push({ name, value: mainParent[name] });
    });
    return arrayIterated;
};

export const getTableMeta = (isPractice = false, top_position, height) => ({
    left_position: '5500',
    top_position,
    height,
    width: isPractice ? '12300' : '8400',
    font_size: 8,
    font_family: 'Arial',
    text_color: '000000',
    bgcolor: 'FFFFFF',
    border_color: isPractice ? '000000' : 'FFFFFF',
    border_width: '80',
    alignment: 'center',
    v_alignment: 'center',
    margin: isPractice ? [0, -1, 0, -1] : null,
});

export const getStylesForPpt = (quartileColors = []) => {
    const styleArr = [];
    quartileColors.forEach(({ background = '', border = '', color = '' }, index) => {
        if (!index) {
            styleArr.push({
                bgcolor: background,
                text_color: color,
                border_color: 'FFFFFF',
                gradient_stoplist: [
                    {
                        position: 0,
                        bgcolor: border,
                    },
                    {
                        position: 100000,
                        bgcolor: background,
                    },
                    {
                        position: 10000,
                        bgcolor: border,
                    },
                    {
                        position: 10000,
                        bgcolor: background,
                    },
                ],
            });
        } else {
            styleArr.push({
                border_color: 'FFFFFF',
                text_color: color,
                bgcolor: background,
            });
        }
    });
    return styleArr;
};

export const checkHighLowOverall = (score, overall, k) => {
    const overallScores = overall
        .slice(3)
        .filter(({ isDemo_999 }) => !isDemo_999)
        .map(({ score: scoreVal = '' }) => scoreVal);
    const min = Math.min(...overallScores);
    const max = Math.max(...overallScores);
    if (k === overallScores.length) {
        return '';
    }
    if (score === min) {
        return '#FCAE1E';
    }
    if (score === max) {
        return '#228C22';
    }
    return '';
};

export const getEngagementQuartile = score => {
    if (score >= 59) {
        return 0;
    }
    if (score >= 51 && score < 59) {
        return 1;
    }
    if (score >= 44 && score < 51) {
        return 2;
    }
    if (score >= 33 && score < 44) {
        return 3;
    }
    return 4;
};

export const manipulateHeatmapTitle = (title, lang = '1033') => {
    const { SITE_TEXT: siteText = {} } = getStaticText(lang);
    const { NUMBER_TEXT, OVERALL } = siteText;
    if (title === NUMBER_CAP_TEXT) {
        return NUMBER_TEXT;
    }
    if (title === COMPANY_HEAD) {
        return OVERALL;
        // return 'General';
    }
    // console.log("Overall", OVERALL);
    return title;
};

export const getEEBoxStyle = (val, isNegative) => {
    const index = getColor(val, [], false, true);
    const indexNeg = getNegColor(val, [], false, true);
    return IND_EXP_SCORE_CLASSIFICATION[isNegative ? indexNeg : index] || {};
};

export const replacePssKeys = (mappingObj, condition, replacements, prop) => {
    if (condition) {
        const updatedMapping = { ...mappingObj };
        updatedMapping[prop].forEach((item, index) => {
            if (replacements[item]) {
                updatedMapping[prop].splice(index, 1, ...replacements[item]);
            }
        });
        return updatedMapping;
    }
    return mappingObj;
};

export const keepReportOption = (buildObj, opToCheck) => {
    if (!buildObj) return true;
    return buildObj[opToCheck].keep;
};

export const resetSelect = targetBm => {
    const targetBenchIndexToUse = targetBm.findIndex(({ selectedOption }) => selectedOption);
    const { options = [] } = targetBm[targetBenchIndexToUse];
    const childIndexToUse = options.findIndex(({ selectedOption }) => selectedOption);
    const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
    targetBm[targetBenchIndexToUse].selectedOption = '';
    targetBm[targetBenchIndexToUse].options[selectedChildIndex].selectedOption = '';
};

export const setTargetSettingBm = (bmCode, targetBenchmarks) => {
    const targetBm = cloneDeep(targetBenchmarks);
    resetSelect(targetBm);
    const targetBmArr = bmCode.split(':');
    const defaultChildCode = targetBmArr[0];
    const targetBmDefaultCode = targetBmArr[1];
    const targetBenchIndexToUse = targetBm.findIndex(({ code }) => code === parseInt(targetBmDefaultCode, 10));
    const { options = [] } = targetBm[targetBenchIndexToUse];
    const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
    const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
    targetBm[targetBenchIndexToUse].selectedOption = targetBm[targetBenchIndexToUse].options[selectedChildIndex].code;
    return targetBm;
};

export const USNumFormat = num => {
    if (typeof num === 'number') {
        return num.toLocaleString('en-US');
    }
    return num;
};

export const validateInput = input => {
    const validInputPattern = /^[\w\s:]*$/;
    return validInputPattern.test(input);
};

export const matchFilterRegex = params => {
    if (validateInput(params)) {
        const keys = params.match(/\b\w+(?=\s*:\b)/g);
        return keys;
    }
    return '';
};

export const getRandomValues = () => {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);
    const num = Math.ceil(crypto.getRandomValues(array)[0] / 1000);
    return num;
};

export const getSignificanceArrayOhi = (surveyVersion, sigVersion) => {
    if (sigVersion === 'v2') return SIGNIFICANCE_V2;
    if (surveyVersion === '4' || surveyVersion === '3_2') return SIGNIFICANCES;
    return SIGNIFICANCE;
};
