import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Scorecard from './Scorecard';
import { getPptData } from '../../Diagnose/actions';
import {
    selectAllGapDataFetched,
    selectCompSurveySetting,
    selectFiltersApplied,
    selectPrevYear,
    selectResurveyAllGapData,
    selectResurveyAllGapDataFetched,
    selectResurveyData,
    selectResurveyDataFetched,
} from '../selector';
import { getMappingExcel, getInclusionDataResurvey, getAllGapData } from '../../Reports/actions';
import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    resurveyData: selectResurveyData(),
    resurveyDataFetched: selectResurveyDataFetched(),
    resurveyAllGapData: selectResurveyAllGapData(),
    resurveyAllGapDataFetched: selectResurveyAllGapDataFetched(),
    allGapDataFetched: selectAllGapDataFetched(),
    prevYear: selectPrevYear(),
    compSurveySetting: selectCompSurveySetting(),
});

const dispatchToProps = { getPptData, getMappingExcel, getInclusionDataResurvey, getAllGapData };

export default connect(stateToProps, dispatchToProps)(Scorecard);
