import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DemographicInclusion from './DemographicInclusion';
import { getGapData, updateGraphGapData, getResurveyGapData, getMappingExcel } from '../../Reports/actions';
import { getDemographics, getPptData } from '../../Diagnose/actions';
import {
    getAllGapInclusionData,
    setpageDataFetched,
    setResurveyDropDownValue,
    getResAllGapInclusionData,
} from '../actions';
import {
    selectGapDataFetched,
    selectGapData,
    selectGraphData,
    selectGraphDataFetched,
    selectReportApiCount,
} from '../../Reports/selector';
import { getDefaultSettings } from '../../Login/selector';
import {
    selectError,
    selectFiltersApplied,
    selectAllGapInclusionData,
    selectAllGapInclusionDataFetched,
    selectPagesLoaded,
    selectResurveyGapData,
    selectResurveyGapDataFetched,
    selectResurveyData,
    selectDemographicMappedData,
    selectAllGapDataFetched,
    selectAllGapInclusionDataLoading,
    selectDemoComparisonYear,
    selectResurveyDropdown,
    selectResurveyAllGapData,
    selectResurveyAllGapDataFetched,
    selectResAllGapInclusionDataFetched,
    selectResAllGapInclusionData,
    selectResAllGapInclusionDataLoading,
    selectCompSurveySetting,
} from '../selector';

const stateToProps = createStructuredSelector({
    gapDataFetched: selectGapDataFetched(),
    gapData: selectGapData(),
    activeFilter: selectFiltersApplied(),
    error: selectError(),
    defaultSettings: getDefaultSettings(),
    graphData: selectGraphData(),
    graphDataFetched: selectGraphDataFetched(),
    reportApiCount: selectReportApiCount(),
    allGapInclusionDataFetched: selectAllGapInclusionDataFetched(),
    allGapInclusionData: selectAllGapInclusionData(),
    pagesLoaded: selectPagesLoaded(),
    resurveyGapData: selectResurveyGapData(),
    resurveyGapDataFetched: selectResurveyGapDataFetched(),
    resurveyData: selectResurveyData(),
    demographicMappedData: selectDemographicMappedData(),
    prevYear: selectDemoComparisonYear(),
    allGapDataFetched: selectAllGapDataFetched(),
    allGapInclusionDataLoading: selectAllGapInclusionDataLoading(),
    resurveyDropdownGroup: selectResurveyDropdown(),
    resurveyAllGapData: selectResurveyAllGapData(),
    resurveyAllGapDataFetched: selectResurveyAllGapDataFetched(),
    resAllGapInclusionDataFetched: selectResAllGapInclusionDataFetched(),
    resAllGapInclusionData: selectResAllGapInclusionData(),
    resAllGapInclusionDataLoading: selectResAllGapInclusionDataLoading(),
    compSurveySetting: selectCompSurveySetting(),
});

const dispatchToProps = {
    getGapData,
    updateGraphGapData,
    getPptData,
    getDemographics,
    getAllGapInclusionData,
    setpageDataFetched,
    getResurveyGapData,
    getMappingExcel,
    setResurveyDropDownValue,
    getResAllGapInclusionData,
};

export default connect(stateToProps, dispatchToProps)(DemographicInclusion);
