import PropTypes from 'prop-types';
import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const DonutChart = ({ background, value, displayValue }) => {
    // Data for the chart
    const data = [
        { name: 'Category A', value: 100 - value },
        { name: 'Category B', value },
    ];

    return (
        <div style={{ textAlign: 'center', position: 'relative', width: '200px' }}>
            <PieChart width={100} height={100}>
                <defs>
                    <linearGradient id="gradientColor" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="0%" stopColor="#0088FE" />
                        <stop offset="100%" stopColor="#00C49F" />
                    </linearGradient>
                </defs>
                <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={20} // Inner radius to create the "donut" effect
                    outerRadius={32}
                    fill="#8884d8"
                    paddingAngle={0} // No gap between partitions
                    startAngle={90} // Start angle for better alignment
                    endAngle={450} // End angle for a full circle
                >
                    <Cell key="cell-1" fill="#efeeef" />
                    <Cell
                        key="cell-0"
                        fill={background}
                        stroke={background === '#ffffff' ? '#000000' : 'none'} // Add border to the first segment
                        strokeWidth={background === '#ffffff' ? 1 : 0}
                    />
                </Pie>
            </PieChart>
            {/* Percentage indicator in the middle */}
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '1.6vh',
                    color: '#333',
                }}
            >
                {displayValue}
            </div>
        </div>
    );
};
DonutChart.propTypes = {
    background: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    displayValue: PropTypes.string.isRequired,
};

export default DonutChart;
