import { fromJS } from 'immutable';
import {
    GET_REPORT,
    GET_RESURVEY_EXCEL,
    GET_DATA_CUBE,
    REPORT_API_PARAMS,
    UPDATE_REPORT_API_PARAMS,
    UPDATE_RESPONDENTS,
    UPDATE_COMMENTS,
    UPDATE_BU_FILTERS,
    UPDATE_PRACTICE_BU_FILTERS,
    UPDATE_BULK_FILTERS,
    UPDATE_BENCHMARK_DATA_FETCHED,
    UPDATE_DEMOGRAHIC_DATA_FETCHED,
    UPDATE_FILTER_INDEX,
    GET_PAST_REPORT,
    SET_CURRENT_TAB,
    GET_PPT_RAW_DATA,
    GET_INCLUSION_DATA,
    GET_GAP_DATA,
    GET_GRAPH_GAP_DATA,
    UPDATE_GRAPH_GAP_DATA,
    PENDING_REPORTS_STATUS,
    ADD_PENDING_REPORT,
    UPDATE_PREV_RESPONDENTS,
    SET_BULK_TOGGLE,
    GET_RESURVEY_MAPPING,
    UPDATE_RESURVEY_MAPPING_FETCHED,
    PUT_PAST_REPORT,
    SET_AVG_GAP_TOGGLE,
    UPDATE_TASK_ID,
    UPDATE_PAST_REPORTS,
    SAVE_PPT_ZIP,
    GET_MULTI_PPT_DATA,
    RESET_PENDING_REPORT,
    UPDATE_COMP_PREVIOUS_RESPONDENTS,
    GET_BULK_SCORE_DATA,
    RESET_ASSIGN_VALID,
    SET_ORG_LEVEL_TOGGLE,
    UPDATE_EX_BENCHMARK_DATA_FETCHED,
} from './constants';
import getStaticTextDiagnose from '../Diagnose/constants';
import { GET_DEFAULT_SETTINGS, REMOVE_SAVED_TOKEN } from '../Login/constants';
import { UPDATE_INCLUSION_FILTERS } from '../Inclusion/constants';

const staticTextDiagnose = getStaticTextDiagnose();
const { GET_BENCHMARKS, GET_DEMOGRAPHICS, UPDATE_FILTERS, GET_EX_BENCHMARKS } = staticTextDiagnose;

export const INITIAL_STATE = fromJS({
    count: 0,
    respondent: 1,
    previousRespondent: 1,
    comments: 1,
    error: '',
    reportData: {},
    reportApiParams: REPORT_API_PARAMS,
    buDemographics: [],
    ingBuDemographics: [],
    practiceDemographics: [],
    bulkDemographics: [],
    originalDemographics: [],
    resurveyDemographics: fromJS([]),
    resurveyDemographicDataFetched: false,
    benchDataFetched: false,
    demograhicDataFetched: true,
    exBenchDataFetched: false,
    filterIndex: 0,
    pastReport: {},
    rawPptData: {},
    currentTab: 0,
    inclusionData: {},
    gapData: {},
    gapDataFetched: false,
    graphData: {},
    mappedDemos: fromJS({}),
    graphDataFetched: false,
    showBulk: false,
    pendingReports: fromJS([]),
    showAvgGap: false,
    resAllGapInclusionDataFetched: false,
    resAllGapInclusionData: {},
    resAllGapInclusionDataLoading: false,
    taskId: '',
    showMultiProgress: false,
    previousCompRespondent: 1,
    scoreBulkData: {},
    scoreBulkDataFetched: false,
    orgLevelBu: '0',
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_GAP_DATA}_PENDING`:
        case `${GET_PAST_REPORT}_PENDING`:
        case `${GET_REPORT}_PENDING`:
        case `${GET_RESURVEY_EXCEL}_PENDING`:
        case `${GET_DATA_CUBE}_PENDING`:
        case `${GET_INCLUSION_DATA}_PENDING`:
        case `${PUT_PAST_REPORT}_PENDING`:
        case `${GET_PPT_RAW_DATA}_PENDING`:
            return state.set('error', '').update('count', count => count + 1);
        case `${GET_MULTI_PPT_DATA}_PENDING`: {
            return state.set('showMultiProgress', true);
        }
        case `${GET_DATA_CUBE}_SUCCESS`:
        case `${GET_REPORT}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('reportData', payload)
                .set('currentTab', 1);
        }
        case `${GET_RESURVEY_MAPPING}_PENDING`:
            return state
                .set('error', '')
                .set('resurveyDemographicDataFetched', false)
                .set('resurveyDemographics', fromJS([]))
                .set('mappedDemos', fromJS({}))
                .update('count', count => count + 1);
        case `${GET_DATA_CUBE}_FAILED`:
        case `${GET_RESURVEY_EXCEL}_FAILED`:
        case `${GET_REPORT}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.message);
        }
        case `${GET_BULK_SCORE_DATA}_PENDING`: {
            return state
                .set('error', '')
                .update('count', count => count + 1)
                .set('scoreBulkDataFetched', true);
        }
        case `${GET_RESURVEY_EXCEL}_SUCCESS`: {
            return state.update('count', count => count - 1);
        }
        case `${GET_PPT_RAW_DATA}_SUCCESS`: {
            return state.update('count', count => count - 1).set('rawPptData', payload);
        }
        case `${GET_PPT_RAW_DATA}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.message);
        }
        case `${GET_DEFAULT_SETTINGS}_SUCCESS`: {
            const { total_respondents = 1 } = payload;
            const { ohid, year } = state.get('reportApiParams') || {};
            const params = { ...REPORT_API_PARAMS, ohid, year };
            return state.set('respondent', total_respondents).set('reportApiParams', params);
        }
        case `${GET_PAST_REPORT}_SUCCESS`: {
            return state.update('count', count => count - 1).set('pastReport', payload);
        }
        case `${GET_PAST_REPORT}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.message);
        }
        case `${SAVE_PPT_ZIP}_SUCCESS`: {
            return state
                .set('showMultiProgress', false)
                .set('error', payload.error)
                .set('status', payload.status);
        }
        case `${SAVE_PPT_ZIP}_FAILED`: {
            return state.set('showMultiProgress', false);
        }
        case UPDATE_REPORT_API_PARAMS: {
            return state.set('reportApiParams', payload);
        }
        case UPDATE_BU_FILTERS: {
            return state.set('buDemographics', payload);
        }
        case UPDATE_PRACTICE_BU_FILTERS: {
            return state.set('practiceDemographics', payload);
        }
        case UPDATE_BULK_FILTERS: {
            if (payload.updateResurveyDemo) {
                return state.set('resurveyDemographics', payload.benchmarks);
            }
            return state.set('bulkDemographics', payload.benchmarks);
        }
        case SET_BULK_TOGGLE: {
            return state.set('showBulk', payload);
        }
        case SET_AVG_GAP_TOGGLE: {
            return state.set('showAvgGap', payload);
        }
        case `${GET_DEMOGRAPHICS}_SUCCESS`: {
            if (!payload.indexToUpdate) {
                return state
                    .set('bulkDemographics', payload.demographics)
                    .set('buDemographics', payload.demographics)
                    .set('ingBuDemographics', payload.demographics)
                    .set('practiceDemographics', payload.demographics)
                    .set('originalDemographics', payload.demographics)
                    .set('demograhicDataFetched', true);
            }
            return state.set('demograhicDataFetched', true);
        }
        case `${GET_BENCHMARKS}_SUCCESS`: {
            return state.set('benchDataFetched', true);
        }
        case `${GET_EX_BENCHMARKS}_SUCCESS`: {
            return state.set('exBenchDataFetched', true);
        }
        case UPDATE_BENCHMARK_DATA_FETCHED: {
            return state.set('benchDataFetched', false);
        }
        case UPDATE_DEMOGRAHIC_DATA_FETCHED: {
            return state.set('demograhicDataFetched', false);
        }
        case UPDATE_EX_BENCHMARK_DATA_FETCHED: {
            return state.set('exBenchDataFetched', false);
        }
        case UPDATE_FILTER_INDEX: {
            return state.set('filterIndex', payload);
        }
        case UPDATE_TASK_ID: {
            return state.set('taskId', payload);
        }
        case SET_CURRENT_TAB: {
            return state.set('currentTab', payload);
        }
        case `${GET_INCLUSION_DATA}_SUCCESS`: {
            return state.update('count', count => count - 1).set('inclusionData', payload);
        }
        case `${PENDING_REPORTS_STATUS}_SUCCESS`: {
            return state.set('pendingReports', fromJS(payload));
        }
        case ADD_PENDING_REPORT: {
            return state.updateIn(['pendingReports'], oldReports => oldReports.push(payload));
        }
        case RESET_PENDING_REPORT: {
            return state.set('pendingReports', fromJS([]));
        }
        case `${GET_INCLUSION_DATA}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.message);
        }
        case `${GET_GAP_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('gapData', payload)
                .set('gapDataFetched', true);
        }
        case `${GET_RESURVEY_MAPPING}_SUCCESS`: {
            const originalDemographics = state.get('originalDemographics');
            const mappedKeys = Object.keys(payload);
            const resurveyDemographics = originalDemographics.filter(({ code }) => mappedKeys.indexOf(code) !== -1);
            return state
                .update('count', count => count - 1)
                .set('resurveyDemographicDataFetched', true)
                .set('resurveyDemographics', fromJS(resurveyDemographics))
                .set('mappedDemos', fromJS(payload));
        }
        case `${GET_RESURVEY_MAPPING}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.message)
                .set('resurveyDemographicDataFetched', true)
                .set('resurveyDemographics', fromJS([]))
                .set('mappedDemos', fromJS({}));
        }
        case UPDATE_RESURVEY_MAPPING_FETCHED: {
            return state.set('resurveyDemographicDataFetched', false);
        }
        case `${GET_GRAPH_GAP_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('graphData', payload)
                .set('graphDataFetched', true);
        }
        case UPDATE_GRAPH_GAP_DATA: {
            return state.set('graphDataFetched', false);
        }
        case `${GET_GAP_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.message)
                .set('graphDataFetched', false)
                .set('gapDataFetched', true);
        }
        case UPDATE_FILTERS: {
            return state.set('gapDataFetched', false);
        }
        case UPDATE_INCLUSION_FILTERS: {
            return state.set('gapDataFetched', false);
        }
        case UPDATE_RESPONDENTS: {
            return state.set('respondent', payload);
        }
        case UPDATE_PREV_RESPONDENTS: {
            return state.set('previousRespondent', payload);
        }
        case UPDATE_COMMENTS: {
            return state.set('comments', payload);
        }
        case UPDATE_PAST_REPORTS: {
            return state.set('pastReport', payload);
        }
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        case `${PUT_PAST_REPORT}_SUCCESS`: {
            return state.update('count', count => count - 1);
        }
        case `${PUT_PAST_REPORT}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('status', payload.status);
        }

        case UPDATE_COMP_PREVIOUS_RESPONDENTS: {
            return state.set('previousCompRespondent', payload);
        }

        case `${GET_BULK_SCORE_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('scoreBulkData', payload)
                .set('scoreBulkDataFetched', false);
        }
        case `${GET_BULK_SCORE_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('scoreBulkData', payload)
                .set('scoreBulkDataFetched', false);
        }

        case `${RESET_ASSIGN_VALID}`: {
            return state.set('scoreBulkData', fromJS({}));
        }
        case SET_ORG_LEVEL_TOGGLE: {
            return state.set('orgLevelBu', payload);
        }
        default:
            return state;
    }
};

export default reducer;
