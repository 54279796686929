import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core';
import { getStaticText } from '../../utils/constants';
import './index.scss';

const Dropdown = ({ selectedDemographic, selected, setSelected, demographics, dropDownValue }) => {
    const isAllSelected = selectedDemographic.length > 0 && selected.length === selectedDemographic.length;
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const { CHOOSE_DEMOGRAPHICS } = SITE_TEXT;

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    const styles = {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
    };

    const handleChange = event => {
        const { value } = event.target;
        if (value[value.length - 1] === 'all') {
            setSelected(selected.length === selectedDemographic.length ? [] : selectedDemographic);
            return;
        }
        setSelected(value);
    };

    const getLabelName = optionCode => {
        const demo = demographics.filter(({ code }) => code === dropDownValue)[0] || [];
        const demoOptions = demo.length ? demo.options : demographics[0].options;
        const demoParent = demoOptions.find(({ code = '' }) => code === optionCode) || {};
        const { label = '' } = demoParent;
        return label;
    };

    return (
        <FormControl>
            {selected.length ? <span className="activeSort" /> : null}
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={() => CHOOSE_DEMOGRAPHICS}
                MenuProps={MenuProps}
                displayEmpty
            >
                <MenuItem value="" classes={{ root: 'sortOptions' }}>
                    <em>{CHOOSE_DEMOGRAPHICS}</em>
                </MenuItem>
                <MenuItem
                    value="all"
                    // classes={{
                    //     root: '',
                    // }}
                    style={styles}
                    classes={{ root: 'sortOptions' }}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={isAllSelected}
                            indeterminate={selected.length > 0 && selected.length < selectedDemographic.length}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </MenuItem>
                {selectedDemographic.map(code => (
                    <MenuItem key={code} value={code} style={styles} classes={{ root: 'sortOptions' }}>
                        <ListItemIcon>
                            <Checkbox checked={selected.indexOf(code) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={getLabelName(code)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

Dropdown.propTypes = {
    selected: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    selectedDemographic: PropTypes.array.isRequired,
    demographics: PropTypes.array.isRequired,
    dropDownValue: PropTypes.string.isRequired,
};

export default Dropdown;
