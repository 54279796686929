import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Select, MenuItem } from '@material-ui/core';
// import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
// import FilterSvg from '../assets/Filter.svg';
// import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import Questions from './QuestionsBoard';
// import BulletList from '../assets/BulletList.svg';
import IndExpScorecard from './ScorecardBoard';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import HeatmapBoard from './HeatmapBoard';
import DeepDiveBoard from './DeepDiveBoard';
// import Down from '../assets/Down.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import { getBenchmarkName, getBenchmarkString, USNumFormat } from '../../../utils/functions';
import WarningDialog from './WarningDialog';
import FilterBar from '../../../components/FilterBar';
import FlagpoleBoard from './FlagpoleBoard';

function IndExperience({
    defaultSettings,
    singleQuestion,
    singleQuestionFetched,
    getSingleQuestion,
    apiParams,
    apiLoadingCount,
    errorInfo,
    setSelectedDemographic,
    getIeHeatmap,
    selectedDemographics,
    filters,
    activeResetFilter,
    toggleFilterView,
    reportError,
    getReport,
    getRawPptData,
    getSinglePastReport,
    singlePastReport,
    appliedFiltersInfo,
    addOnTab,
    setAddOnTabs,
    setFilters,
    filterOpen,
    surveyToUse,
    leftBenchmarks,
    rightBenchmarks,
    showBmDialog,
    setBmDialog,
    footnoteListFetched,
    getExFootnoteData,
    getScoreData,
    scoreDataFetched,
}) {
    const {
        threshold = 10,
        demographic = '',
        report_id = '',
        checkme_mapping = {},
        report_name_or_client_name: rName = '',
        lang: defaultlang,
        ohid: LatamOhid = '',
        role: LatamRole = '',
        engagement = '',
        apply_employee_exp_benchmark = false,
        benchmark = [],
        benchmark_year: bmYear = '',
        report_type = '',
    } = defaultSettings;

    const { ohid = '', filters: filterParams = [], bm_filters, benchmarks, year, lang } = apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(defaultlang);
    const { IE_CROSSTAB_OPTIONS, ORGHEALTH_DEMO, PRACTICE_VAR } = staticTextDiagnose;
    const staticText = getStaticText(defaultlang);
    const { SINGLE_PAGE_EXPORT_PARAMS, NON_PROFIT_SURVEY, SITE_TEXT } = staticText;
    const { NUMBER_OF_SURVEYS, NUMBER_TEXT, YEAR } = SITE_TEXT;
    const DEFAULT_STATE = 'demo_OHIScoreCal';
    const NON_PROFIT_DEFAULT_STATE = 'demo_IENPS1';
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const defaultStateCode = isNonProfit ? NON_PROFIT_DEFAULT_STATE : DEFAULT_STATE;
    const [crossTabDemo, setCrossTabDemo] = useState(defaultStateCode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [dwnld, setdwnld] = useState(false);
    const [resurveyModal, setReSurveyModal] = useState(false);
    const { ex_benchmarks = [], benchmarks: bmFilter = [] } = filters[0] || {};
    const showOutcomeBm = addOnTab === 3 || addOnTab === 2;
    const { qbyq: singleQbyq = {} } = singleQuestion || {};

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };

    // const filterCheck = apiParams.filters || [];
    const RoleClient = LatamRole.toLowerCase();
    const LatamHide = !!(LatamOhid === 'OHI13648' && RoleClient === 'roles.client');
    const ex = {
        report_type,
        bm_filters,
        threshold,
        lang,
        ohid,
        benchmarks,
        filters: filterParams,
        year,
        ex_benchmarks: apply_employee_exp_benchmark ? getBenchmarkString(ex_benchmarks) || '1001:1' : '',
        ex_bm_filters: [
            getBenchmarkString(leftBenchmarks) || '1001:1',
            getBenchmarkString(rightBenchmarks) || '1001:1',
        ],
    };

    const callHeatMap = code => {
        getIeHeatmap({
            demographic: code,
            threshold,
            lang,
            page: 1,
            ohid,
            benchmarks,
            type: PRACTICE_VAR,
            filters: filterParams,
            year,
            ...addOns,
            columns: 99999,
            report_type,
            ex_benchmarks: apply_employee_exp_benchmark ? getBenchmarkString(ex_benchmarks) || '1001:1' : '',
        });
    };

    useEffect(() => {
        const { demographics = [] } = filters[0] || {};
        if (!singleQuestionFetched) {
            getSingleQuestion(ex);
        }
        if (demographics.length) {
            const { code = '' } = demographics[0] || {};
            const demo = selectedDemographics || code;
            setSelectedDemographic(demo);
        }

        // eslint-disable-next-line
    }, [singleQuestionFetched]);

    useEffect(() => {
        if (!footnoteListFetched) {
            getExFootnoteData({ ohid, lang });
        }
    }, [footnoteListFetched]);

    const newDeepDiving = IE_CROSSTAB_OPTIONS[3].code === crossTabDemo;
    const newDeepDiv = IE_CROSSTAB_OPTIONS[4].code === crossTabDemo;

    const newDeepDiveNote1 = IE_CROSSTAB_OPTIONS[0].code === crossTabDemo;
    const newDeepDiveNote2 = IE_CROSSTAB_OPTIONS[1].code === crossTabDemo;
    const newDeepDiveNote3 = IE_CROSSTAB_OPTIONS[2].code === crossTabDemo;

    const newDeepDive = newDeepDiveNote1 || newDeepDiveNote2 || newDeepDiveNote3;

    const getHeatmapDemographic = () => {
        const { demographics = [] } = filters[0] || {};
        const map = new Map();
        demographics.forEach(({ code, label }) => {
            if (selectedDemographics === code) {
                map.set(code, label);
            }
        });
        return Object.fromEntries(map);
    };

    const getMeta = () => {
        const deepDiveIndex = IE_CROSSTAB_OPTIONS.findIndex(({ code }) => code === crossTabDemo) || 0;
        const { employee_experience = [] } = checkme_mapping || {};
        const getCategoryCheckme = () => {
            const arr = employee_experience.slice(3, 6);
            switch (selectedTab) {
                case 0:
                    return arr[1];
                case 1:
                    return arr[0];
                case 2:
                    return arr[2];
                default:
                    return arr;
            }
        };
        const deepDiveCheckme =
            deepDiveIndex === 0 ? getCategoryCheckme() : employee_experience.slice(6)[deepDiveIndex - 1];
        const checkmeInUse = crossTabDemo === ORGHEALTH_DEMO ? 'Employee_Experience_with_OrgHealth' : deepDiveCheckme;
        switch (addOnTab) {
            case 0:
                return {
                    checkme: [employee_experience[0]],
                    name: 'Employee_Experience_Scorecard',
                };
            case 1:
                return {
                    checkme: [employee_experience[1]],
                    name: 'Employee_Experience_Questions',
                };
            case 2:
                return {
                    checkme: [employee_experience[2], 'Employee_Experience_eNPS_demographic'],
                    name: 'Employee_Experience_heatmap',
                };
            case 3:
                return {
                    checkme: [checkmeInUse],
                    name: 'Employee_Experience_Deep_Dive',
                };
            case 4:
                return {
                    checkme: ['EE_factors_key_peer_checkme'],
                    name: 'Employee_Experience_Flagpole',
                };
            default:
                return {};
        }
    };

    const pptDownload = () => {
        const { benchmarks: bm = [] } = filters[0];
        const label = getBenchmarkName(bm);
        const exLabel = getBenchmarkName(ex_benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label, EX_BENCHMARK_NAME: exLabel }),
            ...apiParams,
            report_id,
            report_name: rName,
            ...addOns,
            report_type,
            ex_benchmarks: apply_employee_exp_benchmark ? getBenchmarkString(ex_benchmarks) : '',
            ex_bm_filters: apply_employee_exp_benchmark
                ? [getBenchmarkString(leftBenchmarks), getBenchmarkString(rightBenchmarks)]
                : [],
            employee_experience_demographic: getHeatmapDemographic(),
            single_page_meta: getMeta(),
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const appliedBenchmark = () => {
        const { n_respondents = '', n_survey = '' } = singleQbyq || {};
        const exLabel = getBenchmarkName(ex_benchmarks);
        const label = getBenchmarkName(bmFilter);

        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    <div className="heading">EX Benchmark:</div>
                    {apply_employee_exp_benchmark ? (
                        <Fragment>
                            <div className="nsizeContent">
                                <div>{YEAR} : </div>
                                <div>{bmYear}</div>
                            </div>
                            <div className="nsizeContent">
                                <div>{NUMBER_TEXT} : </div>
                                <div>{USNumFormat(n_respondents)}</div>
                            </div>
                            <div className="nsizeContent">
                                <div>{NUMBER_OF_SURVEYS} :</div>
                                <div>{USNumFormat(n_survey)}</div>
                            </div>
                            <div className="filterContent">
                                <div className="filterHighlight filterKey filterLab">{exLabel}</div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="nsizeContent">
                            <div>Benchmark not available</div>
                        </div>
                    )}
                </div>
                {showOutcomeBm ? (
                    <div className="filterDemo">
                        <div className="heading">Outcomes & Practices Benchmark:</div>
                        <div className="nsizeContent">
                            <div>{YEAR} : </div>
                            <div>{bmYear}</div>
                        </div>
                        <div className="nsizeContent">
                            <div>{NUMBER_TEXT} : </div>
                            <div>{USNumFormat(surveyToUse[1])}</div>
                        </div>
                        <div className="nsizeContent">
                            <div>{NUMBER_OF_SURVEYS} :</div>
                            <div>{USNumFormat(surveyToUse[2])}</div>
                        </div>
                        <div className="filterContent">
                            <div className="filterHighlight filterKey filterLab">{label}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    const getPage = () => {
        switch (addOnTab) {
            case 0:
                return (
                    <IndExpScorecard
                        qbyqData={singleQbyq}
                        resurveyModal={resurveyModal}
                        setReSurveyModal={setReSurveyModal}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        activeResetFilter={activeResetFilter}
                        setFilters={setFilters}
                        setdwnld={setdwnld}
                        getReport={getReport}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
            case 1:
                return (
                    <Questions
                        defaultSettings={defaultSettings}
                        apiLoadingCount={apiLoadingCount}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        activeResetFilter={activeResetFilter}
                        setFilters={setFilters}
                        setdwnld={setdwnld}
                        getReport={getReport}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
            case 2:
                return (
                    <HeatmapBoard
                        callHeatMap={callHeatMap}
                        apiLoadingCount={apiLoadingCount}
                        resurveyModal={resurveyModal}
                        setReSurveyModal={setReSurveyModal}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        activeResetFilter={activeResetFilter}
                        setFilters={setFilters}
                        setdwnld={setdwnld}
                        getReport={getReport}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
            case 3:
                return (
                    <DeepDiveBoard
                        crossTabDemo={crossTabDemo}
                        setCrossTabDemo={setCrossTabDemo}
                        callHeatMap={callHeatMap}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        apiLoadingCount={apiLoadingCount}
                        newDeepDiving={newDeepDiving}
                        newDeepDiv={newDeepDiv}
                        newDeepDive={newDeepDive}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        pptDownload={pptDownload}
                        activeResetFilter={activeResetFilter}
                        selectedDemographics={selectedDemographics}
                        setSelectedDemographic={setSelectedDemographic}
                        setFilters={setFilters}
                        appliedBenchmark={appliedBenchmark}
                    />
                );
            case 4:
                return (
                    <FlagpoleBoard
                        toggleFilterView={toggleFilterView}
                        activeResetFilter={activeResetFilter}
                        appliedFiltersInfo={appliedFiltersInfo}
                        addOnTab={addOnTab}
                        setAddOnTabs={setAddOnTabs}
                        appliedBenchmark={appliedBenchmark}
                        pptDownload={pptDownload}
                    />
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
        const { benchmarks: bm = [] } = filters[0] || {};
        if (!scoreDataFetched && !apiLoadingCount && bm.length) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, apiParams, filters]);

    return (
        <Fragment>
            {getPage()}
            {errorInfo ? <ErrorToast message={errorInfo} /> : null}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    reportError={reportError}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    LatamHide={LatamHide}
                    defaultSettings={defaultSettings}
                />
            )}
            {filterOpen && (
                <FilterBar
                    activeResetFilter={activeResetFilter}
                    size={surveyToUse}
                    surveyRespondents={[surveyToUse[1]]}
                    surveys={[surveyToUse[2]]}
                    filters={filters}
                    closeFilters={toggleFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    isEEpage
                    showBenchmarks={showOutcomeBm}
                />
            )}
            {apply_employee_exp_benchmark && showBmDialog && <WarningDialog setView={setBmDialog} />}
        </Fragment>
    );
}

IndExperience.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    singleQuestion: PropTypes.array.isRequired,
    singleQuestionFetched: PropTypes.bool.isRequired,
    getSingleQuestion: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    getIeHeatmap: PropTypes.func.isRequired,
    selectedDemographics: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    errorInfo: PropTypes.number.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    filterOpen: PropTypes.bool.isRequired,
    surveyToUse: PropTypes.array.isRequired,
    showBmDialog: PropTypes.bool.isRequired,
    setBmDialog: PropTypes.func.isRequired,
    footnoteListFetched: PropTypes.bool.isRequired,
    getExFootnoteData: PropTypes.func.isRequired,
    leftBenchmarks: PropTypes.array.isRequired,
    rightBenchmarks: PropTypes.array.isRequired,
    getScoreData: PropTypes.func.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
};

export default IndExperience;
