import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoLarge from '../../Analytics/assets/DisclaimerWarning1.svg';

const WarningDialog = ({ setView }) => {
    const EX_DIALOG_TXT_1 =
        'The global benchmark is the default for Employee Experience, with select sub-benchmarks available.';
    const EX_DIALOG_TXT_2 =
        'Please note that Outcomes & Practices benchmarks and Employee Experience benchmarks must be selected separately in the "Filter" tab, as the same sub-benchmarks may not always be available for both. Where possible, we recommend using consistent benchmarks across both categories.';
    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'feedbackSuccessDialogRoot warningDialogRoot',
            }}
        >
            <section className="dataContent dataHead">
                <div className="infoLarge bmxInfoWarn">
                    <img src={InfoLarge} alt="" />
                </div>
                <h2>Disclaimer!</h2>
                {/* <div className="nRes">
                    {'Jira ticket ID : '} <b>{jira_number}</b>
                </div> */}
                <div className="para">
                    <div>{EX_DIALOG_TXT_1}</div>
                    <div> {EX_DIALOG_TXT_2} </div>
                </div>
            </section>

            <section className="buttonContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setView(false);
                        }}
                    >
                        Continue
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

WarningDialog.propTypes = {
    setView: PropTypes.func.isRequired,
};

export default WarningDialog;
