import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import DotLoader from 'react-spinners/DotLoader';

import { Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { getStaticText, IA_NON_COMPARABLE_PRACTICES } from '../../../utils/constants';
import {
    getGapColor,
    // quartileTitle,
    sortChildren,
    getInclusionStyle,
    gapToDisplay,
    scoreToDisplay,
    getFiltersAppliedText,
    deltaToDisplay,
    quartileDelta,
    getIncBenchmarkStyles,
} from '../../../utils/functions';
import OverallInclusion from '../assets/OverallInclusion.svg';
import Peers from '../assets/Peers.svg';
import Leaders from '../assets/Leaders.svg';
import Systems from '../assets/Systems.svg';
import './index.scss';
import Loader from '../../../components/Loader';
import { DEFAULT_STYLE } from '../constants';

function ScorecardAlt({
    inclusionqbyq,
    allGapData,
    demographics,
    filters,
    scoreColor,
    iaApiParams,
    resurveyData,
    resurveyFilters,
    resurveyDemographic,
    resurveyAllGapDataFetched,
    resurveyAllGapData,
    isPulse,
    benchmarkName,
    allGapDataFetched,
    benchmarkVersions,
    showGapToggle,
    showDelta,
    updateModal,
    setParentGap,
    isQuartileMovement,
    defaultSettings,
    renderFootnotes,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        DISCLAIMER,
        OUTCOME_SCORE_GAPS,
        PRACTICE_SCORE_GAPS,
        PEERS,
        LEADERS,
        SYSTEMS,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        NONE,
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        // CHANGE_CLASSIFICATION,
        // SIGNIFICANCE,
        QUARTILE_DIFFERENCE,
        VALUE_BASED_DIFFERENCE,
        GAP_BASED_DIFFERENCE,
        QUARTILE_MOVE_LEGEND_TXT_IA,
        GAP_LEGEND_TEXT,
        // NA_BG,
        // VALUES_BASED_LEGEND_TEXT,
    } = SITE_TEXT;
    const { quartileColors = [] } = defaultSettings;
    const { with_benchmark: withBenchmark = false } = iaApiParams;
    const [qbyqData, setQbyqData] = useState({});
    const [resQbyqData, setResQbyqData] = useState({});
    const { qbyq = {} } = inclusionqbyq;
    const { qbyq: resurveyQbyq = {} } = resurveyData;
    const { records: prevRecords = {}, size: prev_size = 0, inclusion_survey_version: surveyVRes = '' } =
        resurveyQbyq || {};
    const { level_scores: levels_score = {}, records = {}, size = 0, inclusion_survey_version: surveyV = '' } = qbyq;
    const { enterprise = {}, individual = {} } = levels_score;
    // const { enterprise: res_enterprise = {}, individual: res_individual = {} } = resurvey_level_score || {};
    const checkLength = Object.keys(qbyq).length;
    const { gap = {}, levels_gap = {} } = allGapData;
    const { gap: resurveyGap = {}, levels_gap: prev_levels_gap = {} } = resurveyAllGapData || {};
    const checkGapLength = Object.keys(gap).length;
    const [listStyle, setListStyle] = useState(-1);
    // const selectedBm = benchmarkVersions.filter(
    //     ({ BenchmarkPreCalculationSubVersionId }) => BenchmarkPreCalculationSubVersionId === bmVersionInUse
    // )[0];
    // const { BenchmarkPreCalculationSubVersionName: bmName = '', SurveyCount = '', RespondentCount = '' } =
    //     selectedBm || {};
    // const benchmarkText = `${NUMBER_CAP_TEXT} = ${RespondentCount} | ${NUMBER_CAP_SURVEY_TEXT} : ${SurveyCount} | ${bmName}`;
    const initialValue = {
        lowScore: 0,
        highScore: 100,
        lowGap: 0,
        highGap: 100,
        gapHighlight: {},
        styleFade: {},
    };
    const [classification, setClassification] = useState(initialValue);
    const [benchmarkColor, setBenchmarkColor] = useState('');
    const {
        score: scoreStyles = [],
        gap: gapStyles = [],
        styles: styleColors = [],
        deltaStyles = [],
        gapDeltaStyles = [],
    } = scoreColor;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const isDiffSurveyV = surveyV === '2.1' && surveyVRes !== '2.1';
    const getCounter = child => {
        const newObj = {};
        Object.keys(child).forEach(val => {
            const { level: subLevel = '' } = child[val];
            if (newObj[subLevel]) {
                newObj[subLevel] += 1;
            } else {
                newObj[subLevel] = 1;
            }
        });
        return Math.max(...Object.values(newObj));
    };

    const renderData = (data, setData) => {
        const obj = {};
        sortChildren(data).forEach(mainInfo => {
            const { level = '', children = {}, key } = mainInfo;
            if (level && level !== '-') {
                const objToAdd = {
                    ...mainInfo,
                    count: Object.keys(children).length === 1 ? 1 : getCounter(children),
                    children: sortChildren(children),
                    key,
                };
                if (obj[level]) {
                    obj[level].push(objToAdd);
                } else {
                    obj[level] = [objToAdd];
                }
            }
        });
        setData(obj);
    };

    useEffect(() => {
        if (!isEqual(records, qbyqData)) {
            renderData(records, setQbyqData);
        }
        if (resurveyAllGapDataFetched) {
            renderData(prevRecords, setResQbyqData);
        }
        // eslint-disable-next-line
    }, [levels_score, resurveyAllGapDataFetched]);

    const selectionView = (higherLimit, lowerLimit, type) => {
        const greyBg = { backgroundColor: 'rgb(235, 235, 235)' };
        if (type === 'score') {
            setClassification({
                ...initialValue,
                lowScore: lowerLimit,
                highScore: higherLimit,
                styleFade: greyBg,
            });
        } else {
            setBenchmarkColor('');
            setClassification({
                ...initialValue,
                lowGap: lowerLimit,
                highGap: higherLimit,
                gapHighlight: greyBg,
            });
        }
    };

    const setViewOption = (value, type) => {
        setClassification(initialValue);
        const { lowScore, highScore, lowGap, highGap } = classification;
        switch (value) {
            case scoreStyles[0].title: {
                if (!(lowScore === 80 && highScore === 100)) selectionView(100, 80, type);
                break;
            }
            case scoreStyles[1].title: {
                if (!(lowScore === 70 && highScore === 79)) selectionView(79, 70, type);
                break;
            }
            case scoreStyles[2].title: {
                if (!(lowScore === 60 && highScore === 69)) selectionView(69, 60, type);
                break;
            }
            case scoreStyles[3].title: {
                if (!(lowScore === 50 && highScore === 59)) selectionView(59, 50, type);
                break;
            }
            case scoreStyles[4].title: {
                if (!(lowScore === 0 && highScore === 50)) selectionView(50, 0, type);
                break;
            }
            case gapStyles[0].title: {
                if (!(lowGap === 0 && highGap === 10)) selectionView(10, 0, type);
                break;
            }
            case gapStyles[1].title: {
                if (!(lowGap === 10 && highGap === 15)) selectionView(15, 10, type);
                break;
            }
            case gapStyles[2].title: {
                if (!(lowGap === 15 && highGap === 100)) selectionView(100, 15, type);
                break;
            }
            default:
                setClassification(initialValue);
        }
    };

    const setModalData = (
        isLevel,
        parentKey,
        childKey = '',
        score = '',
        { gap: gapModal = '' },
        meta_scores = [],
        data = {}
    ) => {
        updateModal({ isLevel, parentKey, childKey, score, gap: gapModal, meta_scores, data });
    };

    const getDeltaIndex = val => {
        const i = val > 0 ? 0 : 1;
        const index = val === 0 ? 2 : i;
        return index;
    };

    const getDeltaElement = (value, type, quartileDel, noBg) => {
        const valueToUse = quartileDel !== -100 ? quartileDel : value;
        const styleArr = type === 'gap' ? gapDeltaStyles : deltaStyles;
        const isNoComparison = isQuartileMovement && quartileDel === -100;
        if (value === '-' || noBg || isNoComparison) {
            return (
                <div
                    className="delta quartileBased"
                    style={{
                        color: '#000000',
                        background: '#ffffff',
                        border: isNoComparison ? '1px solid #000' : 'none',
                    }}
                >
                    <div>{value}</div>
                </div>
            );
        }

        if (type === 'gap') {
            const index = getDeltaIndex(value);
            const { text_color } = styleArr[index];
            return (
                <div className="gap delta" style={{ color: text_color }}>
                    <div>
                        {value > 0 ? (
                            <div>
                                <span>{value}</span>
                                <span>
                                    <ArrowUpward />
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span>{value}</span>
                                {value < 0 ? (
                                    <span>
                                        <ArrowDownward />
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        const index = getDeltaIndex(valueToUse);
        const { bgcolor } = styleArr[index];

        return (
            <div className="delta quartileBased" style={{ background: bgcolor, color: '#ffffff' }}>
                <div>
                    <div>
                        <span>{value}</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderGap = (gapDelta, delta) => {
        if (showDelta) {
            if (!allGapDataFetched) {
                return (
                    <div className="gap delta">
                        <span>
                            <div className="spinLoader">
                                <img src={Loader} alt="" />
                            </div>
                        </span>
                    </div>
                );
            }
            return getDeltaElement(gapDelta, 'gap', delta === '-');
        }
        return null;
    };

    const getFadeStyle = (value, gapV, bgColor) => {
        const { lowScore, highScore, lowGap, highGap } = classification;
        const fadeStyle =
            !(value >= lowScore && value <= highScore) || !(Number(gapV) < highGap && Number(gapV) >= lowGap)
                ? { opacity: 0 }
                : null;
        const fadeStyleBm = !(bgColor === benchmarkColor) && benchmarkColor ? { opacity: 0 } : null;
        if (gapV !== '-' && value !== '-' && !benchmarkColor) {
            return null;
        }
        return withBenchmark && benchmarkColor ? fadeStyleBm : fadeStyle;
    };

    const renderValueInImage = (styleName, value, type, inclusion_quartile = -1, quartile = -1, demoLevel) => {
        let demographicParent = '';
        let demographicParentCode = '';
        const levelType = type === 'individual' ? individual : enterprise;
        const { gap: gapVal = '', avg_gap: avgGapVal = '', demographic: demoParent = '' } = levels_gap[type] || {};
        const { gap: resGapVal = '', avg_gap: resAvgGapVal = '' } = prev_levels_gap[type] || {};
        const {
            display_score: resScore = '',
            quartile: resQuartile = '',
            inclusionQuartile: resIaQuart = '',
        } = showDelta ? resurveyQbyq.level_scores[type] : {};
        const resGapV = !showGapToggle ? resGapVal : resAvgGapVal;
        const gapV = !showGapToggle ? gapVal : avgGapVal;

        const parent = demographics.filter(({ code }) => {
            return code === demoParent;
        })[0];
        if (parent) {
            const { label = '' } = parent;
            demographicParent = label;
            demographicParentCode = parent.code;
        }
        const delta = deltaToDisplay(value, resScore, value);
        const quartDelta = isQuartileMovement
            ? quartileDelta(inclusion_quartile, quartile, resIaQuart, resQuartile)
            : -100;
        const gapDelta = deltaToDisplay(gapV, resGapV, delta, isPulse);
        const { bgcolor: bgColor = '', text_color: textColor = '', border_color: borderColor = '' } = getInclusionStyle(
            inclusion_quartile,
            quartile,
            benchmarkStyles,
            styleColors
        );
        const { score } = levelType;
        const {
            bgcolor: gapBgColor = '',
            text_color: textBgColor = '',
            border_color: gapBorderColor = '',
        } = getGapColor(gapToDisplay(Number(gapV), score, isPulse), gapStyles);
        const fadeStyle = getFadeStyle(value, gapV, bgColor);
        const displayGap = gapToDisplay(gapV, score, isPulse);
        const displayScore = scoreToDisplay(value, [], isPulse);
        return (
            <div
                data-testid={`${type}Btn`}
                className={styleName}
                style={gapV !== '-' || value !== '-' ? fadeStyle : null}
                onClick={() => {
                    setParentGap(demographicParentCode);
                    setModalData(true, type, '', score, levels_gap[type], [], demoLevel);
                }}
            >
                {showDelta ? getDeltaElement(gapDelta, 'gap') : null}
                <div style={{ background: bgColor }} className={c({ smallText: displayScore === '-' }, 'circle')}>
                    <div style={{ color: textColor }}>{displayScore}</div>
                </div>
                {gapV !== '' ? (
                    <Tooltip title={demographicParent}>
                        <div
                            style={{ background: gapBgColor, border: `0.5 px solid ${gapBorderColor}` }}
                            className={c({ smallText: displayGap === '-' }, 'rectangle')}
                        >
                            <div style={{ color: textBgColor, border: `0.5 px solid ${borderColor}` }}>
                                {displayGap}
                            </div>
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip title="Gap Scores are Loading. Please wait.">
                        <div
                            style={{ background: gapBgColor, border: `0.5 px solid ${gapBorderColor}` }}
                            className="rectangle"
                        >
                            <div style={{ color: textBgColor, border: `0.5 px solid ${borderColor}` }}>
                                <DotLoader size={15} />
                            </div>
                        </div>
                    </Tooltip>
                )}
                {showDelta ? getDeltaElement(delta, 'score', quartDelta) : null}
            </div>
        );
    };

    const renderInnerSection = (data, styleValue = '', parentKey = '', resData) => {
        const {
            display_name = '',
            display_score: score = '',
            key = '',
            meta_scores,
            inclusion_quartile = -1,
            quartile = -1,
        } = data;
        const { display_score: resScore = '', inclusion_quartile: resIncQuart = -1, quartile: resQuart = -1 } =
            resData || {};
        let gapValue = '';
        let resurveyGapValue = '';
        let demographicParent = '';
        let demographicParentCode = '';
        let avgGap = '';
        if (parentKey) {
            const { children = {} } = gap[parentKey] || {};
            const { gap: gapV = '', demographic: demoParent = '' } = children[key] || {};
            const { avg_gap: AverageGap = '' } = children[key] || {};
            avgGap = AverageGap;
            gapValue = gapV;
            const parent = demographics.filter(({ code }) => {
                return code === demoParent;
            })[0];
            if (parent) {
                const { label = '' } = parent;
                demographicParent = label;
                demographicParentCode = parent.code;
            }
        } else {
            const { gap: gapV = '', demographic: demoParent = '' } = gap[key] || {};
            const { avg_gap: AverageGap = '' } = gap[key] || {};
            avgGap = AverageGap;
            gapValue = gapV;
            const parent = demographics.filter(({ code }) => {
                return code === demoParent;
            })[0];
            if (parent) {
                const { label = '' } = parent;
                demographicParent = label;
                demographicParentCode = parent.code;
            }
        }

        if (parentKey) {
            const { children = {} } = resurveyGap[parentKey] || {};
            const { gap: resurveyGapV = '', avg_gap: avgResurveyGapV } = children[key] || {};
            resurveyGapValue = showGapToggle ? avgResurveyGapV : resurveyGapV;
        } else {
            const { gap: resurveyGapV = '', avg_gap: avgResurveyGapV } = resurveyGap[key] || {};
            resurveyGapValue = showGapToggle ? avgResurveyGapV : resurveyGapV;
        }
        const quartDelta = isQuartileMovement
            ? quartileDelta(inclusion_quartile, quartile, resIncQuart, resQuart)
            : -100;
        const delta = deltaToDisplay(score, resScore);
        const displayGap = showGapToggle ? avgGap : gapValue;
        const displayScore = score;
        const displayAvgGap = avgGap;
        const gapDelta = deltaToDisplay(displayGap, resurveyGapValue, resScore, true);

        const { bgcolor: bgColor = '', text_color: textColor = '', border_color: borderColor = '' } =
            displayScore !== '-'
                ? getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, styleColors)
                : DEFAULT_STYLE;

        const { bgcolor: gapBgColor = '', text_color: textBgColor = '', border_color: gapBorderColor = '' } =
            displayGap !== '-' ? getGapColor(gapToDisplay(Number(gapValue), score, isPulse), gapStyles) : DEFAULT_STYLE;
        const fadeStyle = getFadeStyle(score, gapValue, bgColor);

        const { bgcolor: gapAvgBgColor = '', text_color: textAvgBgColor = '', border_color: gapAvgBorderColor = '' } =
            displayAvgGap !== '-' ? getGapColor(gapToDisplay(avgGap), gapStyles) : DEFAULT_STYLE;
        const noBg = IA_NON_COMPARABLE_PRACTICES.includes(key) && isDiffSurveyV && isQuartileMovement;
        return (
            <div
                data-testid={key}
                key={display_name}
                style={gapValue !== '-' && score !== '-' ? fadeStyle : null}
                className={c({ resurvey: showDelta })}
                onClick={() => {
                    setParentGap(demographicParentCode);
                    if (parentKey) setModalData(false, parentKey, key, score, { gap: gapValue }, meta_scores, data);
                }}
            >
                <div className="categoryHeading">
                    <div>{display_name}</div>
                </div>
                {showDelta ? getDeltaElement(delta, 'score', quartDelta, noBg) : null}
                {allGapDataFetched ? (
                    <Fragment>
                        {showGapToggle ? (
                            <div
                                style={{ background: gapAvgBgColor, border: `0.5px solid ${gapAvgBorderColor}` }}
                                className={c({ smallText: displayAvgGap === '-' }, 'rectangle', styleValue)}
                            >
                                <div style={{ color: textAvgBgColor }}>{displayAvgGap}</div>
                            </div>
                        ) : (
                            <Tooltip title={demographicParent}>
                                <div
                                    style={{ background: gapBgColor, border: `0.5px solid ${gapBorderColor}` }}
                                    className={c({ smallText: displayGap === '-' }, 'rectangle', styleValue)}
                                    onClick={() => {
                                        setParentGap(demographicParentCode);
                                        if (parentKey)
                                            setModalData(
                                                false,
                                                parentKey,
                                                key,
                                                score,
                                                { gap: gapValue },
                                                meta_scores,
                                                data
                                            );
                                    }}
                                >
                                    <div style={{ color: textBgColor }}>{displayGap}</div>
                                </div>
                            </Tooltip>
                        )}
                    </Fragment>
                ) : (
                    <Tooltip title="Gap Scores are Loading. Please wait.">
                        <div
                            style={{ background: gapBgColor, border: `0.5 px solid ${gapBorderColor}` }}
                            className="rectangle"
                        >
                            <div style={{ color: textBgColor, border: `0.5 px solid ${borderColor}` }}>
                                <div className="spinLoader">
                                    <img src={Loader} alt="" />
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )}

                <div
                    style={{ background: bgColor, border: `0.5px solid ${borderColor}` }}
                    className={c({ smallText: displayScore === '-' }, 'circle', styleValue)}
                >
                    <div style={{ color: textColor }}>{displayScore}</div>
                </div>
                {renderGap(gapDelta, delta)}
            </div>
        );
    };

    const renderSection = (data, index, minHeight, styleValue, resData) => {
        const { key = '', score = '', meta_scores = [] } = data;
        const { gap: gapV = '', avg_gap: avgGapV = '' } = gap[key] || {};
        return (
            <div
                key={index}
                style={{
                    minHeight,
                }}
                className="secBody parentHead"
                onClick={() =>
                    setModalData(false, key, '', score, { gap: !showGapToggle ? gapV : avgGapV }, meta_scores, data)
                }
            >
                {renderInnerSection(data, styleValue, '', resData)}
            </div>
        );
    };

    const renderQbyqUpperSection = (peerArr, children, resChildren, i, mKey) => {
        if (!showDelta) {
            return peerArr.map(val => {
                const { level = '' } = children[val];
                return level === 'Peer' ? renderInnerSection(children[val], '', mKey) : null;
            });
        }
        return peerArr.map(val => {
            const { level = '' } = children[val];
            return level === 'Peer' ? renderInnerSection(children[val], '', mKey, resChildren[val]) : null;
        });
    };

    const renderSectionBody = (children, mainKey, resData) => {
        return Object.keys(children).map(val => {
            const { level = '' } = children[val];
            if (level === 'Systems') {
                return renderInnerSection(children[val], '', mainKey, showDelta ? resData[val] : '');
            }
            return null;
        });
    };

    return (
        <div className="contentAlt">
            {Object.keys(qbyqData).length ? (
                <div className="box">
                    {['Individual'].map((key, index) => {
                        const qbyqUpperArr = qbyqData[key] || [];
                        let resQbyqUpperArr = {};
                        if (resurveyAllGapDataFetched && showDelta) {
                            resQbyqUpperArr = resQbyqData[key];
                        }
                        const { score = '', inclusion_quartile = -1, quartile = -1 } = individual;
                        return (
                            <div key={index} className="upperHalf">
                                <div>
                                    <div />
                                    <div>{OUTCOME_SCORE_GAPS}</div>
                                    <div>{PRACTICE_SCORE_GAPS}</div>
                                </div>

                                <div>
                                    <div className="leftSec">
                                        <div>
                                            {renderValueInImage(
                                                'shape upper',
                                                score,
                                                'individual',
                                                inclusion_quartile,
                                                quartile,
                                                individual
                                            )}
                                            <img src={OverallInclusion} alt="" />
                                            <div data-testid="indModalBtn" className="leftSecDummy" />
                                        </div>
                                        <div className="endSection">
                                            <div className="secHeader invisible-white" />
                                            {qbyqUpperArr.map((data, i) => {
                                                const { count = '' } = data;
                                                const resData = resQbyqUpperArr[i];
                                                const minHeight =
                                                    i === qbyqUpperArr.length - 1
                                                        ? count * 6.2 + 'vh'
                                                        : count * 6.5 + 'vh';
                                                return !showDelta
                                                    ? renderSection(data, i, minHeight, 'middle')
                                                    : renderSection(data, i, minHeight, 'middle', resData);
                                            })}
                                        </div>
                                        <div className="endSection">
                                            <div className="secHeader">
                                                <img src={Peers} alt="peers" />
                                                <span>{PEERS}</span>
                                            </div>
                                            {qbyqUpperArr.map(({ children = {}, count = '', key: mKey }, i) => {
                                                const { children: resChildren = {} } = resQbyqUpperArr[i] || {};
                                                const peerArr = Object.keys(children);
                                                return (
                                                    <div
                                                        style={{
                                                            minHeight: i === 0 ? 14.8 + 'vh' : count * 6.5 + 'vh',
                                                        }}
                                                        key={i}
                                                        className="secBody"
                                                    >
                                                        {renderQbyqUpperSection(
                                                            peerArr,
                                                            children,
                                                            resChildren,
                                                            i,
                                                            mKey
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="rightSec">
                                        <div className="endSection">
                                            <div className="secHeader">
                                                <img src={Leaders} alt="leaders" />
                                                <span>{LEADERS}</span>
                                            </div>
                                            {qbyqUpperArr.map(({ children, count, key: mainKey }, qbyqIndex) => {
                                                const { children: resData = {} } = resQbyqUpperArr[qbyqIndex] || {};
                                                const leaderArray = Object.keys(children).filter(
                                                    myVal => children[myVal].level === 'Leader'
                                                );
                                                const minHeight =
                                                    qbyqIndex === qbyqUpperArr.length - 1 ? '13vh' : count * 3 + 'vh';
                                                return leaderArray.length ? (
                                                    <div
                                                        key={qbyqIndex}
                                                        style={{ minHeight }}
                                                        className="secBody noLeftBorder"
                                                    >
                                                        {leaderArray.map(val => {
                                                            return renderInnerSection(
                                                                children[val],
                                                                '',
                                                                mainKey,
                                                                showDelta ? resData[val] : ''
                                                            );
                                                        })}
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {['Enterprise'].map((key, index) => {
                        const qbyqUpperArr = qbyqData[key];
                        let resQbyqUpperArr = {};
                        if (resurveyAllGapDataFetched && showDelta) {
                            resQbyqUpperArr = resQbyqData[key];
                        }
                        const { score = '', inclusion_quartile = -1, quartile = -1 } = enterprise;
                        return (
                            <div key={index} className="lowerHalf">
                                <div>
                                    <div className="leftSec">
                                        <div>
                                            {renderValueInImage(
                                                'shape lower',
                                                score,
                                                'enterprise',
                                                inclusion_quartile,
                                                quartile,
                                                enterprise
                                            )}
                                            <div data-testid="entModalBtn" className="leftSecDummy lower" />
                                        </div>
                                        <div className="endSection">
                                            <div className="secHeader invisible-white" />
                                            {qbyqUpperArr.map((data, qbyqUpperIndex) => {
                                                const { count } = data;
                                                const resData = resQbyqUpperArr[qbyqUpperIndex] || {};
                                                const minHeight =
                                                    qbyqUpperIndex === qbyqUpperArr.length - 1
                                                        ? 17.5 + 'vh'
                                                        : count * 5.6 + 'vh';
                                                return renderSection(
                                                    data,
                                                    qbyqUpperIndex,
                                                    minHeight,
                                                    'middle',
                                                    resData
                                                );
                                            })}
                                        </div>

                                        <div className="endSection">
                                            <div className="secHeader">
                                                <img src={Systems} alt="systems" />
                                                <span>{SYSTEMS}</span>
                                            </div>
                                            {qbyqUpperArr.map(({ children, count, key: mainKey }, i) => {
                                                const { children: resData = {} } = resQbyqUpperArr[i] || {};
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{ minHeight: count * 6 + 'vh' }}
                                                        className="secBody"
                                                    >
                                                        {renderSectionBody(children, mainKey, resData)}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="rightSec noBorder">
                                        <div className="endSection border">
                                            <div className="secHeader invisible" />
                                            {qbyqUpperArr.map(({ children, key: mainKey }, qbyqIndex) => {
                                                const { children: resData = {} } = resQbyqUpperArr[qbyqIndex] || {};
                                                const leaderArray = Object.keys(children).filter(
                                                    myVal => children[myVal].level === 'Leader'
                                                );
                                                const minHeight =
                                                    index === qbyqUpperArr.length - 1 ? '4vh' : 12.4 + 'vh';
                                                return leaderArray.length ? (
                                                    <div
                                                        key={qbyqIndex}
                                                        style={{ minHeight }}
                                                        className={c('secBody noLeftBorder', {
                                                            noTopBorder: qbyqIndex === 0,
                                                        })}
                                                    >
                                                        {leaderArray.map(val => {
                                                            return renderInnerSection(
                                                                children[val],
                                                                '',
                                                                mainKey,
                                                                showDelta ? resData[val] : ''
                                                            );
                                                        })}
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <div className="tile">
                {checkLength ? (
                    <div>
                        <div>
                            <ul className="classification">
                                <li>{SCORE_CLASSIFICATION_TEXT}</li>
                                {styleToUse
                                    .filter(
                                        ({ quartile, style_for }) =>
                                            quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                    )
                                    .map(({ title, bgcolor: background = '' }, index) => {
                                        const { styleFade } = classification;
                                        const styles = index === listStyle ? styleFade : {};
                                        return (
                                            <li
                                                data-testid={`score${index}`}
                                                key={title}
                                                style={styles}
                                                onClick={() => {
                                                    if (withBenchmark) {
                                                        if (benchmarkColor === background) {
                                                            setBenchmarkColor('');
                                                            setClassification(initialValue);
                                                        } else {
                                                            setBenchmarkColor(background);
                                                            setClassification({
                                                                ...initialValue,
                                                                styleFade: {
                                                                    backgroundColor: 'rgb(235, 235, 235)',
                                                                },
                                                            });
                                                            setListStyle(index);
                                                        }
                                                    } else {
                                                        setViewOption(title, 'score');
                                                        setListStyle(index);
                                                    }
                                                }}
                                            >
                                                <span className="circle" style={{ background }} />
                                                {title}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        <div>
                            <ul className="classification">
                                <li>{GAP_CLASSIFICATION_TEXT} </li>
                                {gapStyles.map(({ title, bgcolor: background }, index) => {
                                    const { gapHighlight } = classification;
                                    const styles = index === listStyle ? gapHighlight : {};
                                    return (
                                        <li
                                            data-testid={`gap${index}`}
                                            key={title}
                                            style={styles}
                                            onClick={() => {
                                                setViewOption(title, 'gap');
                                                setListStyle(index);
                                            }}
                                        >
                                            <span style={{ background }} />
                                            {title}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {showDelta ? (
                            <div>
                                <ul className="classification clearfix">
                                    <li>{isQuartileMovement ? QUARTILE_DIFFERENCE : VALUE_BASED_DIFFERENCE}</li>
                                    {deltaStyles.map(({ bgcolor: color, label }, index) => {
                                        const labelToUse = !isQuartileMovement
                                            ? label
                                            : QUARTILE_MOVE_LEGEND_TXT_IA[index];
                                        return (
                                            <li data-testid={`change${index}`} key={label}>
                                                <span style={{ background: color }} />
                                                {labelToUse}
                                            </li>
                                        );
                                    })}
                                    <li data-testid="change3">
                                        <span style={{ background: '#ffffff', border: '1px solid #000000' }} />
                                        No score comparison
                                    </li>
                                </ul>
                                <ul className="classification clearfix">
                                    <li>{GAP_BASED_DIFFERENCE}</li>
                                    {gapDeltaStyles.map(({ text_color: color }, index) => {
                                        const changeLabel = GAP_LEGEND_TEXT[index];
                                        return (
                                            <li data-testid={`gapchange${index}`} key={changeLabel}>
                                                <span style={{ background: color }} />
                                                {changeLabel}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
            {resurveyAllGapDataFetched && showDelta ? (
                <div className="filterInfoSection">
                    <div>
                        <b>{FILTERS_APPLIED + ' (current survey) : '}</b>
                        {getFiltersAppliedText(filters, demographics)
                            ? getFiltersAppliedText(filters, demographics)
                            : NONE}{' '}
                        | n = {size}
                    </div>
                    <div>
                        <b>{FILTERS_APPLIED + ' (comparison survey) : '}</b>
                        {getFiltersAppliedText(resurveyFilters, demographics)
                            ? getFiltersAppliedText(resurveyFilters, resurveyDemographic)
                            : NONE}{' '}
                        | n = {prev_size}
                    </div>
                    <div>
                        <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkVersions[0]}`}
                    </div>
                </div>
            ) : (
                <div className="filterInfoSection">
                    <div>
                        <b>{FILTERS_APPLIED + ' : '}</b>
                        {getFiltersAppliedText(filters, demographics)
                            ? getFiltersAppliedText(filters, demographics)
                            : NONE}{' '}
                        | n = {size}
                    </div>
                    <div>
                        <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkVersions[0]}`}
                    </div>
                </div>
            )}
            {checkLength && checkGapLength ? (
                <div className="disclaimer">
                    <div>{DISCLAIMER}</div>
                    {renderFootnotes()}
                </div>
            ) : null}
        </div>
    );
}

ScorecardAlt.defaultProps = {
    isPulse: false,
    benchmarkName: '',
    resurveyAllGapData: {},
    resurveyAllGapDataFetched: false,
    resurveyFilters: [],
    resurveyDemographic: [],
};

ScorecardAlt.propTypes = {
    demographics: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    allGapData: PropTypes.object.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    resurveyData: PropTypes.object.isRequired,
    resurveyAllGapDataFetched: PropTypes.bool,
    resurveyAllGapData: PropTypes.object,
    resurveyFilters: PropTypes.array,
    resurveyDemographic: PropTypes.array,
    benchmarkName: PropTypes.string,
    isPulse: PropTypes.bool,
    allGapDataFetched: PropTypes.bool.isRequired,
    benchmarkVersions: PropTypes.array.isRequired,
    showGapToggle: PropTypes.bool.isRequired,
    showDelta: PropTypes.bool.isRequired,
    updateModal: PropTypes.func.isRequired,
    setParentGap: PropTypes.func.isRequired,
    isQuartileMovement: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    renderFootnotes: PropTypes.func.isRequired,
};

export default ScorecardAlt;
