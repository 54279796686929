import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import getStaticTextDiagnose from '../../constants';
import colors from '../../../../sass/colors';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
// import BulletList from '../../assets/BulletList.svg';
import Down from '../../assets/Down.svg';
import { getStaticText } from '../../../../utils/constants';
import SelectMenu from '../../BenchmarkBoard/SelectMenu';

const FlagpoleBoard = ({
    scoreData,
    leftBenchmarks,
    rightBenchmarks,
    updateExApiParams,
    defaultSettings,
    appliedFiltersInfo,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    updateExLeftBenchmarks,
    updateExRightBenchmarks,
    appliedBenchmark,
    apiLoadingCount,
    footnoteList,
}) => {
    const { quartileColors = [], lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    // const { PERCENTILE } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { QUESTIONS } = staticTextDiagnose;
    const { $white } = colors;
    const { QUES_LEGEND_TYPE, SITE_TEXT } = staticText;
    const {
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        // RESURVEY_TEXT,
        SIGNIFICANCEv2,
        DIFF_HEAD,
        BENCHMARK_NA,
        BOTTOM_DECILE,
        BENCHMARK_HEAD,
        NO_BENCHMARK,
        BUSINESS_TEXT,
        NOTE,
    } = SITE_TEXT || {};
    const { qbyq = {}, bm_filters_result = [] } = scoreData;
    // const { ex_benchmarks = [] } = filters[0] || {};
    const benchOne = bm_filters_result[0] || {};
    const benchTwo = bm_filters_result[1] || benchOne;
    const { records: benchRecordOne = [] } = benchOne;
    const { records: benchRecordTwo = [] } = benchTwo;
    const { records = {}, size = '' } = qbyq;
    // eslint-disable-next-line no-unused-vars
    const { ohi_score = {}, ...dataToIterate } = records;
    const sigValToUse = SIGNIFICANCEv2;
    const { fe_ee_factor_pc_fv, fe_ee_enps_calc_short_pc_fv, com_ee_neg_enps_pc_fv, com_ee_flagpole_footnote } =
        footnoteList || {};
    const FOOTNOTES = [
        fe_ee_factor_pc_fv,
        fe_ee_enps_calc_short_pc_fv,
        com_ee_neg_enps_pc_fv,
        com_ee_flagpole_footnote,
    ];

    const getSelectedChild = (item, control) => {
        const { code = '', selectedOption = '' } = item;
        const bm_filters = [];
        if (control === 'left') {
            const { code: right = '', selectedOption: rightOpt = '' } =
                rightBenchmarks.filter(({ selectedOption: op }) => op)[0] || {};
            bm_filters.push(`${selectedOption}:${code}`);
            bm_filters.push(`${rightOpt}:${right}`);
        } else {
            const { code: left = '', selectedOption: leftOpt = '' } =
                leftBenchmarks.filter(({ selectedOption: op }) => op)[0] || {};
            bm_filters.push(`${leftOpt}:${left}`);
            bm_filters.push(`${selectedOption}:${code}`);
        }
        updateExApiParams({ ex_bm_filters: bm_filters });
    };
    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">
                                {EMPLYEE_EXPEIENCE} <sup>1</sup>
                            </div>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                            </div>
                        </div>
                        <ul className="navScoreList">
                            {/* <li
                            data-testid="setReSurveyModalBtn"
                            onClick={() => setReSurveyModal(true)}
                            className="fImage resurveyImg"
                        >
                            <img src={BulletList} alt="" />
                            <span className="upperHead">{RESURVEY_TEXT}</span>
                        </li> */}
                            {!errorInfo && (
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            )}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    {appliedFiltersInfo()}
                    {appliedBenchmark()}

                    <div className="optionWrapper">
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {/* {renderRightOptions()} */}
                    </div>
                    <div className="benchmarkBoard clearfix">
                        <section className="outcomeContent selectContent clearfix">
                            <div />
                            <div>{BUSINESS_TEXT}</div>
                            <SelectMenu
                                key="left"
                                control="left"
                                defaultSettings={defaultSettings}
                                benchmarks={leftBenchmarks}
                                getSelectedChild={getSelectedChild}
                                updateBenchmarks={updateExLeftBenchmarks}
                            />
                            <SelectMenu
                                key="right"
                                control="right"
                                defaultSettings={defaultSettings}
                                benchmarks={rightBenchmarks}
                                getSelectedChild={getSelectedChild}
                                updateBenchmarks={updateExRightBenchmarks}
                            />
                        </section>
                        {QUESTIONS.map(({ children }) => {
                            return children.map((key, index) => {
                                const dataSet = key;
                                const { display_name = '', quartile = '', score = '' } = dataToIterate[key] || {};
                                const recordOne =
                                    benchRecordOne.find(({ parent: pOne = '' }) => pOne === display_name) || {};
                                const recordTwo =
                                    benchRecordTwo.find(({ parent: pTwo = '' }) => pTwo === display_name) || {};
                                const { difference: recordOneDiff, significance: recordOneSign } =
                                    recordOne[dataSet] || {};
                                const { difference: recordTwoDiff, significance: recordTwoSign } =
                                    recordTwo[dataSet] || {};
                                const { background = $white, border: borderColor = $white } =
                                    quartileColors[quartile] || {};
                                const { bg: recordOneBg } = sigValToUse[recordOneSign] || {};
                                const { bg: recordTwoBg } = sigValToUse[recordTwoSign] || {};
                                const sup = QUES_LEGEND_TYPE[key] || '';
                                // if (isNaN(parseInt(score, 10))) return null;
                                return (
                                    <section className="outcomeContent clearfix" key={display_name + index}>
                                        <div className="ellipsisPro">
                                            {display_name}
                                            <sup>{sup}</sup>
                                        </div>
                                        <div>
                                            <div className={c('rank', { negative: score < 0 })}>
                                                <div
                                                    className="textBorder"
                                                    style={{
                                                        background,
                                                        borderColor,
                                                        width: `${Math.abs(score)}%`,
                                                    }}
                                                >
                                                    <div className="textRank">{score}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={c('rank', { negative: recordOneDiff < 0 })}>
                                                <div
                                                    className="textBorder"
                                                    style={{
                                                        background: recordOneBg,
                                                        width: `${Math.abs(recordOneDiff)}%`,
                                                    }}
                                                >
                                                    <div className="textRank">{recordOneDiff}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={c('rank', { negative: recordTwoDiff < 0 })}>
                                                <div
                                                    className="textBorder"
                                                    style={{
                                                        background: recordTwoBg,
                                                        width: `${Math.abs(recordTwoDiff)}%`,
                                                    }}
                                                >
                                                    <div className="textRank">{recordTwoDiff}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                );
                            });
                        })}
                        {/* {Object.keys(dataToIterate).map((dataSet, index) => {
                            // console.log(dataSet)
                            const { display_name = '', quartile = '', score = '' } = dataToIterate[dataSet];
                            const recordOne =
                                benchRecordOne.find(({ parent: pOne = '' }) => pOne === display_name) || {};
                            const recordTwo =
                                benchRecordTwo.find(({ parent: pTwo = '' }) => pTwo === display_name) || {};
                            const { difference: recordOneDiff, significance: recordOneSign } = recordOne[dataSet] || {};
                            const { difference: recordTwoDiff, significance: recordTwoSign } = recordTwo[dataSet] || {};
                            const { background = $white, border: borderColor = $white } =
                                quartileColors[quartile] || {};
                            const { bg: recordOneBg } = sigValToUse[recordOneSign] || {};
                            const { bg: recordTwoBg } = sigValToUse[recordTwoSign] || {};
                            return (
                                <section className="outcomeContent clearfix" key={display_name + index}>
                                    <div className="ellipsisPro">{display_name}</div>
                                    <div>
                                        <div className={c('rank', { negative: score < 0 })}>
                                            <div
                                                className="textBorder"
                                                style={{
                                                    background,
                                                    borderColor,
                                                    width: `${Math.abs(score)}%`,
                                                }}
                                            >
                                                <div className="textRank">{score}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={c('rank', { negative: recordOneDiff < 0 })}>
                                            <div
                                                className="textBorder"
                                                style={{
                                                    background: recordOneBg,
                                                    width: `${Math.abs(recordOneDiff)}%`,
                                                }}
                                            >
                                                <div className="textRank">{recordOneDiff}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={c('rank', { negative: recordTwoDiff < 0 })}>
                                            <div
                                                className="textBorder"
                                                style={{
                                                    background: recordTwoBg,
                                                    width: `${Math.abs(recordTwoDiff)}%`,
                                                }}
                                            >
                                                <div className="textRank">{recordTwoDiff}</div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            );
                        })} */}
                    </div>
                    {!apiLoadingCount && !errorInfo && (
                        <div className="note">
                            <b>{NOTE}:</b>
                            {FOOTNOTES.map((txt, i) => {
                                return <div>{`${i + 1}. ${txt}`}</div>;
                            })}
                        </div>
                    )}
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                <ul className="bottomList clearfix">
                    <li>{DIFF_HEAD} : </li>
                    {sigValToUse.map(({ title, bg: background }) => (
                        <li key={title}>
                            <span style={{ background }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{BENCHMARK_HEAD} : </li>
                    {quartileColors
                        .slice()
                        .reverse()
                        .map(({ title, background, border: borderColor }) => {
                            const bottomDecileVar = title === BOTTOM_DECILE;
                            const BenchmarkTitle = title === NO_BENCHMARK;
                            return (
                                <li
                                    key={title}
                                    style={{ color: background }}
                                    className={c({
                                        hide: bottomDecileVar || BenchmarkTitle,
                                    })}
                                >
                                    <span
                                        className="benchmarkTriangle"
                                        style={{
                                            background,
                                            borderWidth: '13px 13px 0 0',
                                            borderColor: bottomDecileVar
                                                ? `transparent ${borderColor} transparent transparent`
                                                : `${borderColor} transparent transparent transparent`,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <div style={{ color: 'black' }}>{title}</div>
                                </li>
                            );
                        })}
                    <li>
                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                        {BENCHMARK_NA}
                    </li>
                </ul>
            </ul>
        </Fragment>
    );
};

FlagpoleBoard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    // apiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    // scoreDataFetched: PropTypes.bool.isRequired,
    // activeFilter: PropTypes.bool.isRequired,
    leftBenchmarks: PropTypes.array.isRequired,
    rightBenchmarks: PropTypes.array.isRequired,
    // filters: PropTypes.array.isRequired,
    // getScoreData: PropTypes.func.isRequired,
    updateExApiParams: PropTypes.func.isRequired,
    // toggleFilterView: PropTypes.func.isRequired,
    updateExLeftBenchmarks: PropTypes.func.isRequired,
    updateExRightBenchmarks: PropTypes.func.isRequired,
    // getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    appliedBenchmark: PropTypes.func.isRequired,
    footnoteList: PropTypes.array.isRequired,
};

export default FlagpoleBoard;
