import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Dialog, IconButton, Select, MenuItem } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import c from 'classnames';
import InfoLarge from '../../containers/Analytics/assets/DisclaimerWarning1.svg';

import Loader from '../Loader';
import { getStaticText } from '../../utils/constants';
import './index.scss';
import Remove from './assets/Remove.svg';

function SelectModal({
    projects,
    projectsFetched,
    closeModal,
    selectProject,
    oldSelectedId,
    oldSelectedDemo,
    defaultSettings,
    heatMapResurvey,
    demographics,
    current_ohid,
    getMappedDemographics,
    apiLoadingCount,
    practiceRankModal,
    getComparisonYearSettings,
    compYrSettings,
    isExModule,
}) {
    const { lang, report_type = '', emp_exp_resurvey_settings = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SELECT_DEFAULT,
        RESURVEY_TEXT,
        SELECT_YEAR,
        DETAILED_BUTTON,
        COMPARE_SURVEY_BUTTON,
        VIEW_HEATMAP_BUTTON,
        NO_MAPPED_DEMO,
        SELECT_DEMOGRAPHIC,
        BUSINESS_YEAR,
        DEMOGRAPHIC_GROUP,
        EX_PERCENTILE_MSG,
        EX_QUARTILE_MSG,
    } = SITE_TEXT || {};
    const [currentSelect, updateSelect] = useState('');
    const [selectedDemographic, setSelectedDemographic] = useState('');
    const { mappedDemoFetched: isDataFetched = false } = projects[currentSelect] || {};
    const { compYrSettings: settings = {}, settingsFetched = false } = compYrSettings[currentSelect] || {};
    const { apply_employee_exp_benchmark = false } = settings || {};
    const exNoCompQuartile =
        isExModule &&
        !apply_employee_exp_benchmark &&
        emp_exp_resurvey_settings.toLowerCase() === 'quartile movement' &&
        currentSelect !== '' &&
        settingsFetched;
    const exNoCompPercentile =
        isExModule &&
        !apply_employee_exp_benchmark &&
        report_type === 'percentile' &&
        currentSelect !== '' &&
        settingsFetched;
    const exNoComp = exNoCompQuartile || exNoCompPercentile;

    const getReportDemographics = newIndex => {
        if (newIndex !== '') {
            updateSelect(newIndex);
            setSelectedDemographic('');
            const { ohid: previous_ohid, mappedDemoFetched = false } = projects[newIndex];
            if (!mappedDemoFetched) {
                getMappedDemographics({ current_ohid, previous_ohid }, newIndex);
            }
            if (!settingsFetched && isExModule) {
                getComparisonYearSettings({ ohid: previous_ohid, lang }, newIndex);
            }
        }
    };

    const demographicsInUse = useMemo(() => {
        if (!heatMapResurvey) return [];
        if (currentSelect !== '') {
            const { mappedDemos = [] } = projects[currentSelect];
            if (!mappedDemos.length) {
                return [];
            }
            return demographics.filter(({ code }) => mappedDemos.indexOf(code) !== -1);
        }
        return [];
        // eslint-disable-next-line
    }, [heatMapResurvey, projects, demographics, currentSelect]);

    useEffect(() => {
        updateSelect(oldSelectedId);
        setSelectedDemographic(oldSelectedDemo);
        // eslint-disable-next-line
    }, []);

    const active = heatMapResurvey ? currentSelect !== '' && selectedDemographic : currentSelect !== '';
    const activeEx = heatMapResurvey ? !exNoComp && selectedDemographic : !exNoComp;
    const activeButtons = isExModule ? activeEx : active;

    return (
        <Dialog
            open
            disableBackdropClick
            maxWidth="sm"
            classes={{
                root: 'selectDialogRoot',
            }}
        >
            {!projectsFetched || apiLoadingCount ? (
                <Loader position="absolute" showBackground background="transparent" />
            ) : null}
            <section className="headContent">
                <IconButton
                    aria-label="close"
                    classes={{
                        root: 'closeButton',
                    }}
                    onClick={() => closeModal(false)}
                >
                    <img src={Remove} alt="" />
                </IconButton>
                <h2>{RESURVEY_TEXT}</h2>
            </section>

            <section className={c('dataContent dataHead', { heatMapResurvey })}>
                <p>{SELECT_YEAR}</p>

                <p className="heading">{BUSINESS_YEAR}</p>
                <Select
                    value={currentSelect}
                    onChange={({ target }) => getReportDemographics(target.value)}
                    IconComponent={ExpandMore}
                    displayEmpty
                >
                    <MenuItem classes={{ root: 'selectOptions' }} value="">
                        {SELECT_DEFAULT}
                    </MenuItem>
                    {projects.map(({ ohid, year }, menuIndex) => {
                        if (heatMapResurvey && ohid === current_ohid) {
                            return null;
                        }
                        return (
                            <MenuItem key={`${ohid}${year}`} value={menuIndex} classes={{ root: 'selectOptions' }}>
                                {`${ohid} - ${year}`}
                            </MenuItem>
                        );
                    })}
                </Select>
                {heatMapResurvey && <p className="heading">{DEMOGRAPHIC_GROUP}</p>}
                {heatMapResurvey && (
                    <Select
                        IconComponent={ExpandMore}
                        value={selectedDemographic}
                        displayEmpty
                        disabled={exNoComp}
                        onChange={({ target: { value: code } }) => {
                            setSelectedDemographic(code);
                        }}
                    >
                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                            <em>{SELECT_DEMOGRAPHIC}</em>
                        </MenuItem>
                        {demographicsInUse.map(({ label, code }) => {
                            return (
                                <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
                {heatMapResurvey && isDataFetched && !demographicsInUse.length && (
                    <div className="noMapDemo">{NO_MAPPED_DEMO}</div>
                )}
                {exNoCompQuartile && (
                    <div className="noMapDemo exInfo">
                        <span>
                            <img src={InfoLarge} alt="warning" />
                        </span>
                        {EX_QUARTILE_MSG}
                    </div>
                )}
                {exNoCompPercentile && (
                    <div className="noMapDemo exInfo">
                        <span>
                            <img src={InfoLarge} alt="warning" />
                        </span>
                        {EX_PERCENTILE_MSG}
                    </div>
                )}
            </section>
            <section className={c('buttonContent clearfix', { activeButtons })}>
                {heatMapResurvey ? (
                    <div
                        data-testid="heatMapResurveyButton"
                        onClick={() => selectProject(currentSelect, selectedDemographic)}
                        className="compareBtn activeCompareBtn"
                    >
                        {VIEW_HEATMAP_BUTTON}
                    </div>
                ) : (
                    <Fragment>
                        {!practiceRankModal && (
                            <div
                                data-testid="detailButton"
                                className="detailedBtn aciveDetailBtn"
                                onClick={() => selectProject(currentSelect, 2)}
                            >
                                {DETAILED_BUTTON}
                            </div>
                        )}
                        <div
                            data-testid="compareButton"
                            className="compareBtn activeCompareBtn"
                            onClick={() => selectProject(currentSelect, 1)}
                        >
                            {COMPARE_SURVEY_BUTTON}
                        </div>
                    </Fragment>
                )}
            </section>
        </Dialog>
    );
}

SelectModal.defaultProps = {
    current_ohid: '',
    oldSelectedDemo: '',
    heatMapResurvey: false,
    getMappedDemographics: () => {},
    apiLoadingCount: 0,
    demographics: [],
    practiceRankModal: false,
    compYrSettings: {},
    isExModule: false,
    getComparisonYearSettings: () => {},
};

SelectModal.propTypes = {
    oldSelectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    projects: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectProject: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    oldSelectedDemo: PropTypes.string,
    demographics: PropTypes.array,
    current_ohid: PropTypes.string,
    heatMapResurvey: PropTypes.bool,
    getMappedDemographics: PropTypes.func,
    apiLoadingCount: PropTypes.number,
    practiceRankModal: PropTypes.bool,
    compYrSettings: PropTypes.object,
    isExModule: PropTypes.bool,
    getComparisonYearSettings: PropTypes.func,
};

export default SelectModal;
