import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    getDataAtGlanceFailed,
    getDataAtGlanceSuccess,
    getFilterDataFailed,
    getFilterDataSuccess,
    getScorecardFailed,
    getScorecardSuccess,
    getAnalyticsPerCategoryFailed,
    getAnalyticsPerCategorySuccess,
    getRankingFailed,
    getRankingSuccess,
    getFlagpoleSuccess,
    getFlagpoleFailed,
    getDifferentialSuccess,
    getDifferentialFailed,
    getFiltersMappingSuccess,
    getFiltersMappingFailed,
    getDefaultYearSuccess,
    getDefaultYearFailed,
    setOrgSizeData,
    getMedianSuccess,
    getMedianFailed,
    getDifferentialQuartileSuccess,
    getDifferentialQuartileFailed,
    showQuatileTabBtn,
    getDifferentialMedianSuccess,
    getDifferentialMedianFailed,
    showMedianTabBtn,
    getCustomBmListSuccess,
    getCustomBmListFailed,
    getCustomBmSurveyListSuccess,
    getCustomBmSurveyListFailed,
    saveCustomBmDraftSuccess,
    saveCustomBmDraftFailed,
    getCustomBmDetailSuccess,
    getCustomBmDetailFailed,
    deleteCustomBmSuccess,
    deleteCustomBmFailed,
    updateCustomBmSuccess,
    updateCustomBmFailed,
    checkNameExistSuccess,
    checkNameExistFailed,
    sendCustomBmRequestSuccess,
    sendCustomBmRequestFailed,
    getApprovalRequestsSuccess,
    getApprovalRequestsFailed,
    getRejectedRequestsSuccess,
    getRejectedRequestsFailed,
    approveRequestSuccess,
    approveRequestFailed,
    rejectRequestSuccess,
    rejectRequestFailed,
    getApprovedRequestsSuccess,
    getApprovedRequestsFailed,
    sendCustomBmRequest as sendCustomBmReqAction,
    duplicateCustomBmRequestSuccess,
    duplicateCustomBmRequestFailed,
    getPreValidationCheck,
    getRealtimeYearSuccess,
    getRealtimeYearFailed,
    getRealtimeAnalyticsSuccess,
    getRealtimeAnalyticsFailed,
    getRealtimeSurveyListSuccess,
    getRealtimeSurveyListFailed,
    getRealtimeApprovalSuccess,
    getRealtimeApprovalFailed,
    getRealtimeRejectSuccess,
    getRealtimeRejectFailed,
    showSecondPopup,
    // new
    hideSecondPopup,
    getRealtimeFiltersSuccess,
    getRealtimeFiltersFailed,
    refreshMetaRequestSuccess,
    refreshMetaRequestFailed,
    getAnalyticsPerSectorSuccess,
    getAnalyticsPerSectorFailed,
} from './actions';
import { getStaticTextAnalytics } from './constants';
import {
    getAnalyticsDataUrl,
    getFilterDataUrl,
    getScorecardUrl,
    getAnalyticsPerCategoryUrl,
    getFlagpoleUrl,
    getAnalyticsRankingUrl,
    getDifferentialUrl,
    getFilterMappingUrl,
    getDefaultYearUrl,
    getMedianUrl,
    getCustomBmListUrl,
    getCustomBmSurveyListUrl,
    saveCustomBmDraft,
    getCustomBmDetails,
    deleteCustomBenchmark,
    updateCustomBenchmark,
    checkNameExist,
    sendCustomBmRequest,
    approvalRequest,
    approveRequest,
    rejectRequest,
    duplicateCustomBm,
    checkValidation,
    getRealtimeYearUrl,
    getRealtimeAnalyticsUrl,
    getRealtimeSurveyListUrl,
    realtimeApproveRequestUrl,
    realtimeRejectUrl,
    getRealtimeFiltersUrl,
    putRefreshMetaUrl,
} from './apis';
import { getMaxRange, removeQuartileFromFilterParams } from './assets/functions';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    GET_FILTERS_DATA,
    GET_ANALYTICS_SCORECARD,
    GET_ANALYTICS_FLAGPOLE,
    GET_ANALYTICS_CATEGORY,
    GET_RANKING,
    GET_DIFFERENTIAL,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
    GET_MEDIAN,
    GET_DIFFERENTIAL_QUARTILE,
    GET_DIFFERENTIAL_MEDIAN,
    GET_CUSTOM_BM_LIST,
    GET_CUSTOM_BM_SURVEY_LIST,
    SAVE_CUSTOM_BM,
    GET_CUSTOM_BM_DETAIL,
    DELETE_CUSTOM_BM,
    UPDATE_CUSTOM_BM,
    CHECK_NAME_EXIST,
    SEND_CUSTOM_BM_REQUEST,
    GET_APPROVAL_REQUESTS,
    GET_REJECTED_REQUESTS,
    APPROVE_REQUEST,
    REJECT_REQUEST,
    GET_APPROVED_REQUESTS,
    SAVE_AND_PUBLISH,
    DUPLICATE_BM,
    GET_REALTIME_YEAR,
    GET_REALTIME_ANALYTICS,
    GET_REALTIME_SURVEY_LIST,
    GET_REALTIME_APPROVAL,
    GET_REALTIME_REJECT,
    GET_REALTIME_FILTERS,
    REFRESH_META_REQ,
    GET_ANALYTICS_SECTOR,
} = staticTextAnalytics;

// export function* filtersDataSaga({ payload }) {
//     try {
//         const { data = [] } = yield call(getFilterDataUrl, payload);
//         const sortedData = data.sort(({ display_order: a }, { display_order: b }) => a - b);
//         const year = `${payload}`;
//         if (sortedData.length) {
//             const filterYearIndex = sortedData.findIndex(({ filter_id }) => filter_id === 'year');
//             const filterYearIndex1 = sortedData.findIndex(({ filter_id }) => filter_id === 'survey_year');
//             const { filter_items = [] } = sortedData[filterYearIndex];
//             const { filter_items: filterItems = [] } = sortedData[filterYearIndex1];
//             const latestYrIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === payload);
//             if (year === '2025') {
//                 if (!filter_items.some(item => item.filter_item_id === 2025)) {
//                     filter_items.unshift({ filter_item_id: 2025, filter_item_name: 2025, allowSelect: true });
//                 }
//             } else {
//                 sortedData[filterYearIndex].filter_items = filter_items.filter(item => item.filter_item_id !== 2025);
//             }
//             filter_items.forEach((item, i) => {
//                 const { filter_item_name = '' } = item;
//                 if (parseInt(filter_item_name, 10) >= 2023) {
//                     sortedData[filterYearIndex].filter_items[i].allowSelect = true;
//                 }
//             });
//             if (year === '2025') {
//                 sortedData[filterYearIndex].isSelected = true;
//                 sortedData[filterYearIndex].isDefault = true;
//                 sortedData[filterYearIndex].filter_items[0].isSelected = true;
//             } else {
//                 sortedData[filterYearIndex].isSelected = true;
//                 sortedData[filterYearIndex].isDefault = true;
//                 sortedData[filterYearIndex].filter_items[latestYrIndex].isSelected = true;
//             }
//             const max_range = getMaxRange(sortedData) || '';
//             yield put(setOrgSizeData([0, max_range]));

//             filterItems.forEach((item, i) => {
//                 const { filter_item_name = '' } = item;
//                 const yearItem = parseInt(filter_item_name, 10);
//                 if (year === '2025')
//                     if (yearItem < 2025 && yearItem >= 2019) {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
//                     } else {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     }
//                 sortedData[filterYearIndex1].isSurveyDefault = true;

//                 if (year === '2024')
//                     if (yearItem < 2024 && yearItem >= 2019) {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
//                     } else {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     }
//                 sortedData[filterYearIndex1].isSurveyDefault = true;
//             });
//             // sortedData[filterYearIndex1].isSurveyDefault = true;
//         }
//         yield put(getFilterDataSuccess({ data: sortedData, year }));
//     } catch ({ response = {} }) {
//         const { data: errorData = {}, status = '' } = response;
//         const { message = {}, error = '' } = errorData;
//         yield put(getFilterDataFailed({ message, error, status }));
//     }
// }

// export function* filtersDataSaga({ payload }) {
//     try {
//         const { data = [] } = yield call(getFilterDataUrl, payload);
//         const sortedData = data.sort(({ display_order: a }, { display_order: b }) => a - b);
//         const { year, role } = payload;
//         if (sortedData.length) {
//             const filterYearIndex = sortedData.findIndex(({ filter_id }) => filter_id === 'year');
//             const filterYearIndex1 = sortedData.findIndex(({ filter_id }) => filter_id === 'survey_year');
//             const { filter_items = [] } = sortedData[filterYearIndex];
//             const { filter_items: filterItems = [] } = sortedData[filterYearIndex1];

//             if (role) {
//                 if (!filter_items.some(item => item.filter_item_id === 2026)) {
//                     filter_items.unshift({ filter_item_id: 2026, filter_item_name: 2026, allowSelect: true });
//                 }
//             }
//             const defaultYearIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === 2025);
//             if (defaultYearIndex !== -1) {
//                 sortedData[filterYearIndex].filter_items.forEach(item => (item.isSelected = false));
//                 sortedData[filterYearIndex].filter_items[defaultYearIndex].isSelected = true;
//                 sortedData[filterYearIndex].isSelected = true;
//                 sortedData[filterYearIndex].isDefault = true;
//             }

//             filter_items.forEach((item, i) => {
//                 const { filter_item_name = '' } = item;
//                 if (parseInt(filter_item_name, 10) >= 2025) {
//                     sortedData[filterYearIndex].filter_items[i].allowSelect = true;
//                 }
//             });

//             const max_range = getMaxRange(sortedData) || '';
//             yield put(setOrgSizeData([0, max_range]));

//             filterItems.forEach((item, i) => {
//                 const { filter_item_name = '' } = item;
//                 const yearItem = parseInt(filter_item_name, 10);
//                 if (year === 2024) {
//                     if (yearItem < 2024 && yearItem >= 2019) {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     } else {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     }
//                 }

//                 if (year === 2023) {
//                     if (yearItem < 2023 && yearItem >= 2019) {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     } else {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     }
//                 }

//                 if (year === 2025) {
//                     if (yearItem < 2025 && yearItem >= 2020) {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
//                     } else {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     }
//                 }
//                 if (year === 2026) {
//                     if (yearItem < 2026 && yearItem >= 2020) {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
//                     } else {
//                         sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
//                     }
//                 }
//                 sortedData[filterYearIndex1].isSurveyDefault = true;
//             });
//         }
//         yield put(getFilterDataSuccess({ data: sortedData, year }));
//     } catch ({ response = {} }) {
//         const { data: errorData = {}, status = '' } = response;
//         const { message = {}, error = '' } = errorData;
//         yield put(getFilterDataFailed({ message, error, status }));
//     }
// }

// new
export function* filtersDataSaga({ payload }) {
    try {
        const { data = [] } = yield call(getFilterDataUrl, payload);
        const sortedData = data.sort(({ display_order: a }, { display_order: b }) => a - b);
        const { year, role } = payload;
        if (sortedData.length) {
            const filterYearIndex = sortedData.findIndex(({ filter_id }) => filter_id === 'year');
            const filterYearIndex1 = sortedData.findIndex(({ filter_id }) => filter_id === 'survey_year');
            const { filter_items = [] } = sortedData[filterYearIndex];
            const { filter_items: filterItems = [] } = sortedData[filterYearIndex1];

            if (role) {
                if (!filter_items.some(item => item.filter_item_id === 2025)) {
                    filter_items.unshift({ filter_item_id: 2025, filter_item_name: 2025, allowSelect: true });
                }
            }
            const defaultYearIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === 2024);
            if (defaultYearIndex !== -1) {
                sortedData[filterYearIndex].filter_items.forEach(item => (item.isSelected = false));
                sortedData[filterYearIndex].filter_items[defaultYearIndex].isSelected = true;
                sortedData[filterYearIndex].isSelected = true;
                sortedData[filterYearIndex].isDefault = true;
            }

            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                if (parseInt(filter_item_name, 10) >= 2023) {
                    sortedData[filterYearIndex].filter_items[i].allowSelect = true;
                }
            });

            const max_range = getMaxRange(sortedData) || '';
            yield put(setOrgSizeData([0, max_range]));

            filterItems.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                const yearItem = parseInt(filter_item_name, 10);
                if (year === 2024) {
                    if (yearItem < 2024 && yearItem >= 2019) {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
                    } else {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
                    }
                }

                if (year === 2023) {
                    if (yearItem < 2023 && yearItem >= 2019) {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
                    } else {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
                    }
                }

                if (year === 2025) {
                    if (yearItem < 2025 && yearItem >= 2019) {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
                    } else {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
                    }
                }
                sortedData[filterYearIndex1].isSurveyDefault = true;
            });
        }
        yield put(getFilterDataSuccess({ data: sortedData, year }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFilterDataFailed({ message, error, status }));
    }
}

export function* realtimeFiltersDataSaga({ payload }) {
    try {
        const { data = [] } = yield call(getRealtimeFiltersUrl, payload);
        const sortedData = data.sort(({ display_order: a }, { display_order: b }) => a - b);
        const year = `${payload}`;
        if (sortedData.length) {
            const filterYearIndex = sortedData.findIndex(({ filter_id }) => filter_id === 'year');
            const filterYearIndex1 = sortedData.findIndex(({ filter_id }) => filter_id === 'survey_year');
            const { filter_items = [] } = sortedData[filterYearIndex];
            const { filter_items: filterItems = [] } = sortedData[filterYearIndex1];
            const latestYrIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === payload);
            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                if (parseInt(filter_item_name, 10) >= 2023) {
                    sortedData[filterYearIndex].filter_items[i].allowSelect = true;
                }
            });
            sortedData[filterYearIndex].isSelected = true;
            sortedData[filterYearIndex].isDefault = true;
            sortedData[filterYearIndex].filter_items[latestYrIndex].isSelected = true;
            const max_range = getMaxRange(sortedData) || '';
            yield put(setOrgSizeData([0, max_range]));

            filterItems.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                const yearItem = parseInt(filter_item_name, 10);
                if (year === '2025')
                    if (yearItem < 2025 && yearItem >= 2019) {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
                    } else {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
                    }
                sortedData[filterYearIndex1].isSurveyDefault = true;
            });

            // sortedData[filterYearIndex1].isSurveyDefault = true;
        }
        yield put(getRealtimeFiltersSuccess({ data: sortedData, year }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getRealtimeFiltersFailed({ message, error, status }));
    }
}

export function* filtersMappingSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFilterMappingUrl, payload);
        yield put(getFiltersMappingSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFiltersMappingFailed({ message, error, status }));
    }
}

export function* dataAtGlanceSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsDataUrl, payload);
        yield put(getDataAtGlanceSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDataAtGlanceFailed({ message, error, status }));
    }
}

export function* differentialDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    try {
        const { data = {} } = yield call(getDifferentialUrl, newPayload1);
        yield put(getDifferentialSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialFailed({ message, errors, status }));
    }
}

export function* quartileDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    yield put(showQuatileTabBtn(true));
    try {
        const { data = {} } = yield call(getDifferentialUrl, newPayload1);
        yield put(getDifferentialQuartileSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialQuartileFailed({ message, errors, status }));
    }
}

export function* medianDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    try {
        const { data = {} } = yield call(getMedianUrl, newPayload1);
        yield put(getMedianSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getMedianFailed({ message, errors, status }));
    }
}

export function* differentiateMedianDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    yield put(showMedianTabBtn(true));
    try {
        const { data = {} } = yield call(getMedianUrl, newPayload1);
        yield put(getDifferentialMedianSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialMedianFailed({ message, errors, status }));
    }
}

export function* rankingDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsRankingUrl, payload);
        yield put(getRankingSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getRankingFailed({ message, errors, status }));
    }
}

export function* analyticsPerCategorySaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsPerCategoryUrl, payload);
        const state = yield select();
        const analyticsData = state.get('analytics').toJS() || {};
        const { analyticsPerCategoryData = {} } = analyticsData || {};
        const { filter_id = '' } = payload || {};
        const newData = {
            ...analyticsPerCategoryData,
            [filter_id]: data,
        };
        yield put(getAnalyticsPerCategorySuccess(newData));
    } catch ({ response = {} }) {
        const state = yield select();
        const analyticsData = state.get('analytics').toJS() || {};
        const { analyticsPerCategoryData = {} } = analyticsData || {};
        const { filter_id = '' } = payload || {};
        const newData = {
            ...analyticsPerCategoryData,
            [filter_id]: {},
        };
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getAnalyticsPerCategoryFailed({ message, error, status, data: newData }));
    }
}

export function* analyticsPerSectorSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsPerCategoryUrl, payload);
        yield put(getAnalyticsPerSectorSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getAnalyticsPerSectorFailed({ message, error, status, errorData }));
    }
}

export function* flagpoleSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFlagpoleUrl, payload);
        yield put(getFlagpoleSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getFlagpoleFailed({ message, errors, status }));
    }
}

export function* scorecardSaga({ payload }) {
    try {
        const { data = {} } = yield call(getScorecardUrl, payload);
        yield put(getScorecardSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getScorecardFailed({ message, errors, status }));
    }
}

export function* defaultYearSaga({ payload }) {
    try {
        const { data } = yield call(getDefaultYearUrl, payload);
        const { default_year } = data;
        yield put(getDefaultYearSuccess(default_year));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDefaultYearFailed({ message, error, status }));
    }
}

export function* realTimeDefaultYearSaga({ payload }) {
    try {
        const { data } = yield call(getRealtimeYearUrl, payload);
        const { default_year } = data;
        yield put(getRealtimeYearSuccess(default_year));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getRealtimeYearFailed({ message, error, status }));
    }
}

export function* realTimeAnalyticsSaga({ payload }) {
    try {
        const { data } = yield call(getRealtimeAnalyticsUrl, payload);
        yield put(getRealtimeAnalyticsSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getRealtimeAnalyticsFailed({ message, error, status }));
    }
}

export function* realTimeSurveyListSaga({ payload }) {
    try {
        const { data } = yield call(getRealtimeSurveyListUrl, payload);
        yield put(getRealtimeSurveyListSuccess(data.data));
        yield put(hideSecondPopup());
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getRealtimeSurveyListFailed({ message, error, status }));
    }
}

export function* realtimeApprovalReqSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(realtimeApproveRequestUrl, payload);
        yield put(getRealtimeApprovalSuccess(data));
        yield put(showSecondPopup());
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getRealtimeApprovalFailed({ message, errors, status }));
    }
}
export function* realtimeRejectReqSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(realtimeRejectUrl, payload);
        yield put(getRealtimeRejectSuccess(data));
        yield put(showSecondPopup());
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getRealtimeRejectFailed({ message, errors, status }));
    }
}

export function* customBenchmarkListSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCustomBmListUrl, payload);
        yield put(getCustomBmListSuccess(data.data.records));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getCustomBmListFailed({ message, errors, status }));
    }
}

export function* customBmSurveyListSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCustomBmSurveyListUrl, payload);
        yield put(getCustomBmSurveyListSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getCustomBmSurveyListFailed({ message, errors, status }));
    }
}

export function* saveCustomBmSaga({ payload }) {
    try {
        const { data } = yield call(saveCustomBmDraft, payload);
        const { message = '' } = data || {};
        yield put(saveCustomBmDraftSuccess(message));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(saveCustomBmDraftFailed({ message, errors, status }));
    }
}

export function* customBenchmarkDetailsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCustomBmDetails, payload);
        yield put(getCustomBmDetailSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getCustomBmDetailFailed({ message, errors, status }));
    }
}

export function* deleteCustomBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(deleteCustomBenchmark, payload);
        yield put(deleteCustomBmSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(deleteCustomBmFailed({ message, errors, status }));
    }
}

export function* updateCustomBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(updateCustomBenchmark, payload);
        yield put(updateCustomBmSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(updateCustomBmFailed({ message, errors, status }));
    }
}

export function* checkNameExistSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(checkNameExist, payload);
        yield put(checkNameExistSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(checkNameExistFailed({ message, errors, status }));
    }
}

export function* sendCustomBmReqSaga({ payload = {} }) {
    try {
        const { id } = payload || {};
        const { data = {} } = yield call(checkValidation, id);
        const { success, data: validationData = {} } = data || {};
        if (success) {
            const { data: reqData = {} } = yield call(sendCustomBmRequest, payload);
            yield put(sendCustomBmRequestSuccess(reqData));
        } else {
            yield put(getPreValidationCheck(validationData));
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(sendCustomBmRequestFailed({ message, errors, status }));
    }
}

export function* bmApprovalReqSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(approvalRequest, payload);
        yield put(getApprovalRequestsSuccess(data.data.records));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getApprovalRequestsFailed({ message, errors, status }));
    }
}

export function* bmApprovalRejectedSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(approvalRequest, payload);
        yield put(getRejectedRequestsSuccess(data.data.records));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getRejectedRequestsFailed({ message, errors, status }));
    }
}

export function* bmPublishedSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(approvalRequest, payload);
        yield put(getApprovedRequestsSuccess(data.data.records));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getApprovedRequestsFailed({ message, errors, status }));
    }
}

export function* approveBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(approveRequest, payload);
        yield put(approveRequestSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(approveRequestFailed({ message, errors, status }));
    }
}

export function* rejectBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(rejectRequest, payload);
        yield put(rejectRequestSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(rejectRequestFailed({ message, errors, status }));
    }
}

export function* saveAndPublishBmReqSaga({ payload = {} }) {
    try {
        const { savePayload = {}, payload: payloadData = {}, id: inputId = '' } = payload || {};
        let data = {};
        if (!inputId) {
            const { data: saveDraftData = {} } = yield call(saveCustomBmDraft, savePayload);
            data = saveDraftData;
        } else {
            const { data: editDraftData = {} } = yield call(updateCustomBenchmark, {
                payload: savePayload,
                id: inputId,
            });
            data = editDraftData;
        }
        const { data: savedData = {} } = data || {};
        const { id = '' } = savedData || {};
        yield put(
            sendCustomBmReqAction({
                id,
                payload: payloadData,
            })
        );
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(sendCustomBmRequestFailed({ message, errors, status }));
    }
}

export function* duplicateBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(duplicateCustomBm, payload);
        yield put(duplicateCustomBmRequestSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(duplicateCustomBmRequestFailed({ message, errors, status }));
    }
}

export function* refreshMetaRequestSaga({ payload }) {
    try {
        const { data = {} } = yield call(putRefreshMetaUrl, payload);
        const { data: refreshData = {} } = data || {};
        const { success = [], failed = [] } = refreshData || {};
        // const dataToUse = success.length ? success : failed;
        yield put(refreshMetaRequestSuccess(refreshData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(refreshMetaRequestFailed({ message, errors, status }));
    }
}

const analyticsSagas = [
    takeLatest(`${GET_FILTERS_DATA}_PENDING`, filtersDataSaga),
    takeLatest(`${GET_FILTERS_MAPPING}_PENDING`, filtersMappingSaga),
    takeLatest(`${GET_DATA_AT_GLANCE}_PENDING`, dataAtGlanceSaga),
    takeLatest(`${GET_DIFFERENTIAL}_PENDING`, differentialDataSaga),
    takeLatest(`${GET_RANKING}_PENDING`, rankingDataSaga),
    takeLatest(`${GET_ANALYTICS_CATEGORY}_PENDING`, analyticsPerCategorySaga),
    takeLatest(`${GET_ANALYTICS_FLAGPOLE}_PENDING`, flagpoleSaga),
    takeLatest(`${GET_ANALYTICS_SCORECARD}_PENDING`, scorecardSaga),
    takeLatest(`${GET_DEFAULT_YEAR}_PENDING`, defaultYearSaga),
    takeLatest(`${GET_MEDIAN}_PENDING`, medianDataSaga),
    takeLatest(`${GET_DIFFERENTIAL_QUARTILE}_PENDING`, quartileDataSaga),
    takeLatest(`${GET_DIFFERENTIAL_MEDIAN}_PENDING`, differentiateMedianDataSaga),
    takeLatest(`${GET_CUSTOM_BM_LIST}_PENDING`, customBenchmarkListSaga),
    takeLatest(`${GET_CUSTOM_BM_SURVEY_LIST}_PENDING`, customBmSurveyListSaga),
    takeLatest(`${SAVE_CUSTOM_BM}_PENDING`, saveCustomBmSaga),
    takeLatest(`${GET_CUSTOM_BM_DETAIL}_PENDING`, customBenchmarkDetailsSaga),
    takeLatest(`${DELETE_CUSTOM_BM}_PENDING`, deleteCustomBmSaga),
    takeLatest(`${UPDATE_CUSTOM_BM}_PENDING`, updateCustomBmSaga),
    takeLatest(`${CHECK_NAME_EXIST}_PENDING`, checkNameExistSaga),
    takeLatest(`${SEND_CUSTOM_BM_REQUEST}_PENDING`, sendCustomBmReqSaga),
    takeLatest(`${GET_APPROVAL_REQUESTS}_PENDING`, bmApprovalReqSaga),
    takeLatest(`${GET_REJECTED_REQUESTS}_PENDING`, bmApprovalRejectedSaga),
    takeLatest(`${APPROVE_REQUEST}_PENDING`, approveBmSaga),
    takeLatest(`${REJECT_REQUEST}_PENDING`, rejectBmSaga),
    takeLatest(`${GET_APPROVED_REQUESTS}_PENDING`, bmPublishedSaga),
    takeLatest(SAVE_AND_PUBLISH, saveAndPublishBmReqSaga),
    takeLatest(`${DUPLICATE_BM}_PENDING`, duplicateBmSaga),
    takeLatest(`${GET_REALTIME_YEAR}_PENDING`, realTimeDefaultYearSaga),
    takeLatest(`${GET_REALTIME_ANALYTICS}_PENDING`, realTimeAnalyticsSaga),
    takeLatest(`${GET_REALTIME_SURVEY_LIST}_PENDING`, realTimeSurveyListSaga),
    takeLatest(`${GET_REALTIME_APPROVAL}_PENDING`, realtimeApprovalReqSaga),
    takeLatest(`${GET_REALTIME_REJECT}_PENDING`, realtimeRejectReqSaga),
    takeLatest(`${GET_REALTIME_FILTERS}_PENDING`, realtimeFiltersDataSaga),
    takeLatest(`${REFRESH_META_REQ}_PENDING`, refreshMetaRequestSaga),
    // new
    takeLatest(`${GET_ANALYTICS_SECTOR}_PENDING`, analyticsPerSectorSaga),
];

export default analyticsSagas;
