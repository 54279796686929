import colors from '../../sass/colors';
import CompanySurveyImg from '../../containers/Diagnose/assets/CompanyValuesIcon.svg';
import UpArrow from '../../containers/Diagnose/assets/arrow-up.svg';
import DownArrow from '../../containers/Diagnose/assets/arrow-down.svg';
import SquareMinus from '../../containers/Diagnose/assets/minus-square.svg';
import NoSig from '../../containers/Diagnose/assets/no_significance.svg';
import Pdf from '../../containers/Diagnose/assets/Pdf.svg';

export const NONE = 'Ninguno';
export const CUSTOM = 'Personalizado';
export const NONE_10 = 'Ninguno';

export const SITE_TEXT = {
    ERROR_400_MSG:
        'La página que está buscando no existe. Es posible que haya escrito mal la dirección o que la página haya cambiado de dirección.',
    LOADING: 'Cargando',
    BUBBLE_CHART: 'Gráficos de burbujas',
    HEALTH_NAV_LINKS: [
        'Resumen de resultados',
        'Perfil',
        'Experiencia del empleado',
        'Ranking de prácticas',
        'Recetas',
        'Demográfico',
        'Benchmark',
        'Cohesión',
        'Preguntas',
        'Analítica de texto',
        '',
    ],
    INCLUSION_NAV_LINKS: [
        'Descripción general',
        'Encuestados',
        'Resumen de resultados',
        'Demográfico',
        'Preguntas',
        'Ranking de prácticas',
        'Comparativo a través del tiempo de Salud',
    ],
    SURVEY_NAV_LINKS: ['Respuestas día a día', 'Respuestas en términos de demografía', 'Entre pestañas'],
    SETTINGS_NAV_LINKS: ['Personalización', 'Personalizar colores de cuartil'],
    LONG_TEXT: 'Solo 18 carácteres ',
    REPORT_NAV_LINKS: [
        'Seleccionar informe',
        'Configuración general',
        'Comparar informes',
        'Opciones demográficas',
        'Práctica prioritaria y burbujas de comparación',
        'Gráfico de bandera y clasificación de práctica',
        'Opciones de dimensiones',
        'Opciones de práctica',
    ],
    REPORT_NAV_LINKS_V4: [
        'Seleccionar informe',
        'Configuración general',
        'Comparar informes',
        'Opciones demográficas',
        'Burbujas de comparación',
        'Gráfico de bandera y clasificación de práctica',
        'Opciones de dimensiones',
        'Opciones de práctica',
    ],
    NPS_NAV_LINKS: ['NPS', 'NPS por demográfico'],
    ENGAGEMENT_NAV_LINKS: ['Compromiso', 'Compromiso por demográfico'],
    IND_EXP_LINKS: [
        'Resumen de resultados',
        'Preguntas',
        'Mapa de calor',
        'Análisis profundo',
        // 'Comparativo con otros benchmarks de referencia',
    ],
    ANALYTICS_NAV_LINKS: [
        'Introducción',
        'Descripción general',
        'Resumen de resultados',
        'Comparativo con otros benchmarks de referencia',
        'Ranking de prácticas',
        'Differentiator Analysis',
        'Custom Benchmark',
        // new
        'RealTime',
    ],

    ANALYTICS_NAV_LINKS_REALTIME: [
        'Introducción',
        'Descripción general',
        'Resumen de resultados',
        'Comparativo con otros benchmarks de referencia',
        'Ranking de prácticas',
        'Differentiator Analysis',
        'Custom Benchmark',
        'RealTime',
    ],
    ANALYTICS_RANKING_TABS: ['Ranking de prácticas', 'Análisis diferenciador'],
    DIFFERENTIATOR_ANALYSIS_TABS: ['Cuartil dividido', 'Mediana dividida'],
    QUARTILE_TAB: ['Cuartil dividido'],
    CUSTOM_BM_ANALYSIS_TABS: [
        { tabName: 'Requested Benchmark', index: 1, restrictedAccess: true },
        { tabName: 'Rejected Benchmark', index: 2, restrictedAccess: true },
        { tabName: 'Your Benchmark', index: 0 },
        { tabName: 'Benchmark library', index: 6 },
    ],
    ENGAGEMENT_HEADING: 'Compromiso',
    INCLUSION_OVERVIEW: 'Descripción general de Inclusión',
    ADD_ON: [{ label: 'Valores de la empresa', img: CompanySurveyImg, codeN: 0 }],
    ADD_ON_NP: [{ label: 'Text Comments', img: CompanySurveyImg, codeN: 0 }],
    ADD_ON_VIEWS: ['NPS', 'Compromiso'],
    ADD_ON_VIEWS_ING: [
        { label: 'NPS', codeN: 1, img: CompanySurveyImg },
        { label: 'Compromiso', codeN: 2, img: CompanySurveyImg },
    ],
    ADD_ON_VIEWS_TALANX: [{ label: 'Informe de BU personalizado', codeN: 3, img: CompanySurveyImg }],
    NET_PROMOTER_SCORE: 'Puntuación neta del promotor',
    NPS_HEADING: 'Puntaje neta del promotor por empleado',
    CUSTOM_BU_HEADING: 'Informe de BU personalizado',
    NET_PROMOTER_SCORE_DESC: '(Promotores - Detractores)',
    PROMOTERS: 'Promotores',
    PROMOTERS_DESC: 'answer (9 - 10)',
    PASSIVES: 'Pasivos',
    PASSIVES_DESC: 'answer (7 - 8)',
    DETRACTORS: 'Detractores',
    DETRACTORS_DESC: 'answer (0 - 6)',
    REPORTS_USER_PAGE: 'Página de usuarios de informes',
    ANALYTICS_LANDING_PAGE: 'Página de inicio de Analítica de OHI',
    INCLUSION_QUESTIONS_BOARD: 'Preguntas de Inclusión',
    INCLUSION_RESPONDENTS: 'Encuestados sobre Inclusión',
    EX_ENPS_HEADER: [
        'n',
        'Puntaje de OHI',
        'Promotores (9-10)',
        'Pasivamente satisfecho (7-8)',
        'Detractores (0-6)',
        'eNPS',
    ],
    NPS_NOTE: [
        '1. Preguntas respondidas en una escala de 11 puntos que va desde 0 = extremadamente improbable hasta 10 = extremadamente probable.',
        '2. El puntaje neta de promotores de empleados (eNPS) se calcula restando el % de detractores del % de promotores y puede ir de -100 a 100.',
    ],
    ENGAGEMENT_NOTE: [
        '1. Compromiso laboral: Un estado mental positivo y gratificante relacionado con el trabajo que se caracteriza por sentimientos de dedicación, absorción y felicidad derivados del trabajo.',
        '2. Compromiso organizacional: Un apego emocional a ING que se caracteriza por sentirse partícipe y disfrutar trabajando para la organización.',
    ],
    IND_EXP_QUESTION_NOTE: [
        '2. Las puntuaciones altas no son deseables, ya que indican el porcentaje de empleados que respondieron “De acuerdo” o “Muy de acuerdo” a factores negativos de experiencia individual.',
        '3. Los puntajes altos no son deseables, ya que indican el porcentaje de empleados que respondieron “De acuerdo” o “Muy de acuerdo” a factores negativos de experiencia individual.',
        '4. rxy es la correlación bivariada entre las respuestas individuales a los factores de Experiencia del empleado y los puntajes de OHI a nivel de empleado',
    ],
    OHI4_RESURVEY_NOTE: [
        '1. Los resultados estadísticamente significativos tuvieron valores p de <0,05, lo que indica una alta probabilidad de que haya ocurrido un cambio real. La significación estadística se basa en el número de encuestados y la magnitud de la diferencia de puntos',
        '2. Las preguntas de práctica subyacentes tuvieron cambios de 2022 a 2023; es decir, se agregaron o eliminaron preguntas en función de las investigaciones y algunas prácticas también cambiaron de nombre; por ejemplo, Conducta de los empleados antes se llamaba Estándares profesionales. En adelante, el puntaje de 2023 debe considerarse el nuevo Benchmark.',
        '3. Las prácticas correspondientes a conceptos completamente nuevos introducidos en el marco de OHI 4.0 no tienen puntaje disponible para el año anterior.',
        'Las prácticas correspondientes a conceptos completamente nuevos introducidos en el marco de OHI 4.0 no tienen puntaje disponible para el año anterior.',
    ],
    IND_QUESTIONS: [
        {
            title: 'Propósito',
            children: ['connection_to_meaning'],
        },
        {
            title: 'Desarrollo profesional',
            children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
        },
        {
            title: 'Bienestar',
            children: ['psychological_safety', 'energy', 'Agotamiento laboral'],
        },
        {
            title: 'Retención de talento',
            children: ['job_satisfaction', 'intent_to_leave', 'endorsing_organization_to_others'],
        },
    ],
    IND_QUESTIONS_QUES: [
        {
            title: 'Todos',
            children: [
                'connection_to_meaning',
                'professional_growth',
                'equitable_opportunity',
                'career_customization',
                'endorsing_organization_to_others',
                'job_satisfaction',
                'intent_to_leave',
                'psychological_safety',
                'energy',
                'burnout',
            ],
        },
        {
            title: 'Propósito',
            children: ['connection_to_meaning'],
        },
        {
            title: 'Desarrollo profesional',
            children: ['professional_growth', 'equitable_opportunity', 'career_customization'],
        },
        {
            title: 'Atracción y retención de talento',
            children: ['endorsing_organization_to_others', 'job_satisfaction', 'intent_to_leave'],
        },
        {
            title: 'Bienestar',
            children: ['psychological_safety', 'energy', 'burnout'],
        },
    ],
    TARGET_HEADER: ['Tercer cuartil', 'Segundo cuartil', 'Cuartil superior', 'decil superior'],
    IND_QUES_LEGEND: ['En desacuerdo', 'Neutral', 'De acuerdo', 'No lo sé'],
    NPS_SCORE: 'Puntaje de NPS',
    NPS_QUES: '¿Qué tan probable es que recomiende ser cliente de ING a sus familiares, amigos y colegas?',
    JOB_ENGAGEMENT: 'Compromiso laboral',
    ORG_ENGAGEMENT: 'Compromiso organizacional',
    PULSE: 'Percepciones',
    INCLUSION_SURVEY_RESPONDENTS: '78,351',
    INCLUSION_SURVEY_RESPONDENTS_COUNT: 45,
    DIFF_TEXT: 'Dif.',
    ORG_TITLE: 'Organización',
    INCLUSION_SURVEY_RESPONDENTS_V_TEXT: 'v1 March 2022',
    INCLUSION_BENCHMARK_TEXT: 'N= 78,351 | No. surveys: 45 | v1 March 2022',
    DEMO_INCLUSION_TABS: [
        'Mapa de calor de puntaje',
        'Mapa de calor de brechas',
        'Gráfico de pulso',
        'Mapa de calor de puntaje de la encuesta anterior',
        'Mapa de calor de brecha de la encuesta anterior',
    ],
    SCORECARD_TABS: ['Resumen de resultados', 'Resumen de resultados comparados a través del tiempo'],
    SCORECARD_GROUP_TEXTS: [
        '¿Están las personas alineadas con la visión, la estrategia, la cultura y los valores de la organización?',
        '¿Pueden los empleados cumplir sus funciones con las capacidades, procesos y nivel de motivación actuales?',
        '¿Cómo entiende, interactúa, responde y se adapta la organización a su situación y entorno externo?',
    ],
    REPORT_FILTER_NOTE:
        'Seleccione el grupo del que desea ver un análisis detallado (generalmente es una unidad de negocio o función)',
    COMPARISON_FILTER_NOTE:
        'Seleccione el grupo que será el grupo de referencia (generalmente es toda la organización)',
    REPORT_FILTER: 'Filtro de informe',
    COMPARISON_FILTER: 'Filtro de comparación',
    SCORECARD_GROUP_HEAD: ['Alineación', 'Ejecución', 'Renovación'],
    PRACTICE_CATEGORY_MAP: [
        {
            title: 'Propósito individual',
            children: ['Conexión con el significado'],
            children_ohi: [
                'N',
                'Propósito común',
                'Orientación al cliente',
                'Responsabilidad social',
                'Líderes inspiradores',
                'Incentivos financieros',
            ],
            children_ohi_slug: [
                'n',
                'common_purpose',
                'customer_orientation',
                'social_responsibility',
                'inspirational_leaders',
                'financial_incentives',
            ],
        },
        {
            title: 'Desarrollo profesional',
            children: [
                'Crecimiento profesional',
                'Oportunidad equitativa',
                'Personalización del desarrollo profesional',
            ],
            children_ohi: [
                'N',
                'Desarrollo de talento',
                'Oportunidades profesionales',
                'Asignación de talento',
                'Evaluación del desempeño de las personas',
                'Reconocimientos y Premios',
            ],
            children_ohi_slug: [
                'n',
                'talent_development',
                'career_opportunities',
                'talent_deployment',
                'people_performance_review',
                'rewards_recognition',
            ],
        },
        {
            title: 'Bienestar',
            children: ['Seguridad psicológica', 'Energía', 'Agotamiento laboral'],
            children_ohi: [
                'N',
                'Liderazgo de apoyo',
                'Inclusión y pertenencia',
                'Normas laborales saludables',
                'Apertura y confianza',
            ],
            children_ohi_slug: [
                'n',
                'supportive_leadership',
                'inclusion_belonging',
                'healthy_working_norms',
                'open_and_trusting',
            ],
        },
        {
            title: 'Atracción y retención de talento',
            children: ['Satisfacción laboral', 'Intención de irse', 'Respaldo a la organización ante otros'],
            children_ohi: ['N', 'Puntaje de OHI', 'Orientación externa', 'Dirección'],
        },
    ],
    PRACTICE_CATEGORY_MAP_3_2: [
        {
            title: 'Propósito individual',
            children: ['Conexión con el significado'],
            children_ohi: [
                'N',
                'Participación de los empleados',
                'Enfoque en el cliente',
                'Líderes inspiradores',
                'Incentivos financieros',
            ],
            children_ohi_slug: [
                'n',
                'employee_involvement',
                'customer_focus',
                'inspirational_leaders',
                'financial_incentives',
            ],
        },
        {
            title: 'Desarrollo profesional',
            children: [
                'Crecimiento profesional',
                'Oportunidad equitativa',
                'Personalización del desarrollo profesional',
            ],
            children_ohi: [
                'N',
                'Desarrollo de talento',
                'Oportunidades profesionales',
                'Evaluación del desempeño de las personas',
                'Reconocimientos y Premios',
            ],
            children_ohi_slug: [
                'n',
                'talent_development',
                'career_opportunities',
                'people_performance_review',
                'rewards_recognition',
            ],
        },
        {
            title: 'Bienestar',
            children: ['Seguridad psicológica', 'Energía', 'Agotamiento laboral'],
            children_ohi: ['N', 'Liderazgo de apoyo', 'Apertura y confianza'],
            children_ohi_slug: ['n', 'supportive_leadership', 'open_and_trusting'],
        },
        {
            title: 'Atracción y retención de talento',
            children: ['Satisfacción laboral', 'Intención de irse', 'Respaldo a la organización ante otros'],
            children_ohi: ['N', 'Puntaje de OHI', 'Orientación externa', 'Dirección'],
        },
    ],
    PROFILE_VIEW: 'Vista de Perfil',
    COMPARISON: 'Comparación',
    MAPPED: 'Mapeado',
    SESSION_EXPIRED: 'Su sesión ha caducado.',
    LOGIN_BTN: 'Haga clic aquí para iniciar sesión',
    LOGIN_BTN_TEXT: 'Iniciar',
    LOGIN_TEXT: 'Welcome to',
    LOGIN_REFRESH_TEXT: 'OHI por McKinsey',
    LOGIN_DIFF_USER: 'Iniciar sesión como otro usuario',
    SCORECARD: 'resumen de resultados',
    BU_HEAD: 'Informe de BU',
    DRAG_DROP: 'Arrastrar y soltar',
    OUTCOMES_TEXT: 'Dimensiones y Prácticas',
    COMPANY_HEAD: 'Company',
    QUESTION_HEAD: 'Preguntas',
    PROFILE_HEAD: 'Perfil',
    PRACTICE_HEAD: 'Ranking de prácticas',
    RECIPE_HEAD: 'Recetas',
    OUTCOME_SUBHEAD: 'preguntas de resultado',
    PRACTICE_SUBHEAD: 'Preguntas por práctica',
    PRC_QUESTIONS: 'Preguntas de práctica',
    QUESTIONS_LEGEND: ['En desacuerdo', 'Neutral', 'De acuerdo', 'No lo sé'],
    SINGLE_QUESTIONS_LEGEND_COLORS: ['#051c2c', '#00a9f4', '#1f40e6'],
    SINGLE_QUESTIONS_LEGEND: ['Detractors (0 - 6)', 'Pasivamente satisfecho (7 - 8)', 'Promoters (9 - 10)'],
    QUESTIONS_PRACTICE_LEGEND: ['Con poca frecuencia', 'A veces', 'Con frecuencia', 'No lo sé'],
    QUESTIONS_EX_LEGEND: ['Experiencia negativa', 'Neutral', 'Experiencia positiva', 'No lo sé'],
    SURVEY_LINKS: ['Respuestas día a día', 'Respuestas por demográficos', 'Entre pestañas'],
    RESPONSE_RATE_CUMULATIVE: 'Tasa de respuesta (acumulativa)',
    FILTERS: 'Filtros',
    FILTERS_MAIN: 'Filtros',
    FILTERS_APPLIED: 'Filtros aplicados',
    BENCHMARKS_APPLIED: 'Benchmarks aplicados',
    FILTER_FOR: 'Filtrar por',
    APPLY_FILTERS: 'Aplicar filtros',
    RESET_FILTERS: 'Restablecer filtros',
    VIEW_ALL: 'Ver todo',
    ALL_SELECTED: 'Toda la selección',
    SELECT_ONE: 'Seleccione un artículo',
    SELECT_DEMOGRAPHIC: 'Seleccionar grupo',
    SURVEY_TYPE: 'Tipo de encuesta',
    SWITCH_VIEW: 'Cambiar vista',
    SURVEY_ID: 'Id. de encuesta',
    SURVEY_PID: 'PID',
    REPORT_ID: 'Identificación de reporte',
    SURVEY_NAME: 'Nombre de la encuesta',
    TARGET_HEADING: 'Se utiliza el benchmark global o regional asociado para establecer objetivos asociados',
    TARGET_PARAGRAPH:
        'Los objetivos de práctica y dimensiones se establecen en comparación con el benchmark  global o regional. Se recomienda basarse en el benchmark a global como mejor práctica. Los benchmark regionales son útiles sólo cuando los factores locales afectan los resultados. Los sub-benchmarks son más volátiles, lo que dificulta determinar con precisión la proyección de aumentos de puntos necesarios para alcanzar los cuartiles posteriores año tras año.',
    TARGET_OUTCOME: 'La herramienta mostrará los objetivos de dimensiones y práctica:',
    TARGET_LIST_1:
        'Benchmark global si benchmarks a nivel de sector de McKinsey o benchmarks de especialidad/personalizados es seleccionado',
    TARGET_SUB_LIST: 'Benchmark de la región si el benchmark del país o el benchmark subregional es seleccionado”',
    CONTINUE: 'Continuar',
    COACH_TEXT: 'Coach/Creador',
    YR_TEXT: 'Año',
    ACTION_TEXT: 'Librería de acción',
    EXPLORE_PARAGRAPH: 'Panel interactivo para explorar y visualizar el OHI GSBD como nunca antes.',
    ACTION_PARAGRAPH:
        '¡Colección de iniciativas que puedes llevar a cabo con los clientes para generar impacto desde el primer día!',
    EXPLORER_TEXT: 'Benchmark por Explorer (BMX)',
    EXPLORE: 'Explore',
    EXPLORE_DASHBOARD: 'Explore OHI Dashboards',
    ACTIONS: 'Acción',
    ANALYTICS_TEXT: 'Choose Analytics Report',
    DONE: 'Listo',
    NONE: NONE_10,
    NO_ACCESS:
        'No tiene acceso a este informe para la Id. de OHI seleccionada; póngase en contacto con el servicio de asistencia técnica de OHI',
    TOTAL_RESPONDENTS: 'Total de encuestados',
    TOTAL_RES_TEXT: 'Total de encuestados (n) = ',
    TOTAL_RESURVEY_TEXT: 'Total de encuestados (n)',
    FREQUENCY_SCALE: 'Escala de frecuencia',
    FILTERS_APPLIED_TEXT: 'Según los filtros aplicados',
    ACROSS_DEMO: 'En todos los demográficos',
    ON_SURVEY: 'en la encuesta',
    YES: 'Sí',
    OPTION_ONE: 'Opción 1',
    OPTION_TWO: 'Opción 2',
    SAMPLE_SIZE: 'Tamaño de la muestra',
    N_SIZE: 'N',
    N_SIZE_TEXT: 'Tamaño N',
    N_SIZE_SMALL: 'n-size',
    N_SIZE_MAIN: 'Tamaño N',
    DIS_AGREE: 'En desacuerdo',
    NEUTRAL: 'Neutral',
    AGREE: 'De acuerdo',
    DEMOGRAPHIC: 'Demográfico',
    DEMOGRAPHICS: 'Demografía',
    HEALTH_DEMOGRAPHIC: 'Salud por demográfico',
    DEMOGRAPHICS_SMALL: 'demographics',
    SELECT_MIN_2: 'Puede seleccionar un mínimo de 2 hasta',
    OUT_OF: ' out of ',
    BENCHMARK_HEAD: 'Benchmark',
    NO_BENCHMARK: 'No benchmarks',
    BOTTOM_DECILE: 'Decil inferior',
    EXCLUDE_GAP_HEAD: 'Cálculo de puntaje de brecha de demográficos',
    EXCLUDE_GAP_SUB_HEAD:
        'Abajo puede seleccionar demográficos que desee excluir de los cálculos de brecha para todas las dimensiones y prácticas',
    FILTER_HOVER_TXT:
        'Ahora puede excluir grupos demográficos seleccionados de los cálculos de brechas más grandes y aplicar los nuevos benchmarks de IA',
    UNDER_DEVELOPMENT_1: 'Esta página está en desarrollo.',
    UNDER_DEVELOPMENT_2: 'Agradecemos su paciencia. Volveremos pronto.',
    DEMOGRAPHIC_SUMMARY_SUBHEADING: [
        'Elija hasta 8 grupos demográficos para ver una página de resumen de anillos demográficos',
        'Elija hasta 5 opciones para cada grupo, las opciones restantes se agruparán en otras en el anillo.',
    ],
    DEMOGRAPHIC_SUMMARY: 'Resumen de grupos demográficos',
    DIFF_HEAD: 'Diferencia',
    STATISTICAL_DIFFERENCE: 'Diferencia de Puntuación (con lógica de Significancia Estadística)',
    QUARTILE_DIFFERENCE: 'Diferencia de Puntuación (con lógica de Movimiento de Cuartil)',
    CUSTOM_DIFFERENCE: 'Diferencia de Puntuación (con lógica de Delta Customizada)',
    VALUE_BASED_DIFFERENCE: 'Diferencia de Puntuación (Basada en valor)',
    GAP_BASED_DIFFERENCE: 'Diferencia de brecha (basada en valor)',
    BY_TEXT: 'por',
    BUSINESS_TEXT: 'Nombre del negocio',
    CARLESS_TEXT: 'Todos los encuestados descuidados de OHI (CRs) están preexcluidos de todas las exportaciones',
    SEARCH: 'Buscar',
    TORNADO_LABEL: 'Convertir a gráfico de tornado',
    VS_LABEL: ' vs. ',
    LEGEND: 'Leyenda',
    HIGH_PRACTICES: 'Prácticas destacadas',
    RECIPE_TOP: 'Las 10 prácticas más enfatizadas de la receta',
    UNDER_EMPHASIZED: 'Énfasis insuficiente (ranking< -10)',
    COMPARE_TO_RECIPE: 'Comparable con la receta/énfasis excesivo',
    STRONG_TEXT: 'Fuerte',
    VERY_STRONG_TEXT: 'Muy fuerte',
    NO_ALIGNMENT: 'Sin alineación',
    OUTCOMES: 'Dimensiones',
    PRACTICES: 'Prácticas',
    LIST_BY_OUTCOMES: 'Lista por dimensión',
    LIST_BY_PRACTICES: 'Listar por práctica',
    PRACTICES_SELECT: 'Tipo de práctica',
    OUTCOME_SELECT: 'Dimensione',
    OUTCOME_TEXT: 'Dimensione',
    PRACTICE_TEXT: 'Práctica',
    PRACTICE_NAME_VIEW: 'Vista del nombre de la práctica',
    OVERVIEW_PRCT_TEXT: 'Descripción general de dimensión y práctica',
    HEALTH_SCORE: 'Puntaje de salud total',
    TOP: 'Superior',
    BOTTOM: 'Inferior',
    THREE: 'Tres',
    BY_SCR: 'Por Puntaje',
    VIEW_RES: 'Ver encuestados',
    VIEW_SCORE: 'Ver tabla de resultados',
    VIEW_PRC_RANKING: 'Ver ranking de prácticas',
    VIEW_PRC: 'Ver práctica',
    VIEW_OCT: 'Ver dimensión',
    VIEW_DEMOGRAPHICS: 'Ver demográficos',
    VIEW_BY: 'Ver por',
    INVITED_TEXT: 'Invitado a encuesta',
    COMPLETE_RES_TEXT: 'Respuestas completas',
    INCOMPLETE_RES_TEXT: 'Respuestas incompletas',
    DEMO_SURVEY_TOOLTIP:
        'Las tasas de participación solo son visibles para los grupos demográficos con al menos 5 respuestas.',
    TOTAL_RES: 'Núm. de encuestados',
    NUMBER_OF_RESPONDENTS_N: 'Número de encuestados (n)',
    RES_HEAD: 'Respuestas',
    COMPLETE: 'Completo',
    INCOMPLETE: 'Incompleto',
    OVERALL: 'General',
    TOTAL: 'Total',
    DAILY_RES_TEXT: 'Respuestas diarias',
    DEMO_RES_TEXT: 'Respuestas basadas en',
    PEOPLE_RES_TEXT: '% de personas que respondieron a la encuesta',
    PEOPLE_NUM_RES_TEXT: 'Response Rate (cumulative)',
    RES_TO_TEXT: 'Respondieron la encuesta',
    COMMENTS_TEXT: 'Número de comentarios',
    RESURVEY_TEXT: 'Comparativo a través del tiempo de Salud',
    HEATMAP: 'Mapa de calor',
    HEALTH_BU_DETAILED_BOARD: 'Salud por BU detallada',
    HEALTH_BU_BOARD: 'Tablero de Salud de BU',
    HEALTH_RESURVEY_DETAIL_BOARD: 'Tablero detallado comparativo a través del tiempo de Salud',
    HEALTH_RESURVEY_BOARD: 'Tablero comparativo a través del tiempo de Salud',
    HEALTH_SCORECARD: 'Resumen de resultados de Salud',
    RESURVEY_INACTIVE_TEXT:
        'No tiene acceso a datos de encuestas anteriores. Póngase en contacto con su equipo de asistencia técnica para utilizar esta función.',
    COMPARE_BU: 'Comparación de BU',
    SELECT_DEFAULT: 'Seleccionar año',
    SELECT_YEAR: 'Seleccione el año comercial para comparar',
    BUSINESS_YEAR: 'Año comercial',
    DEMOGRAPHIC_GROUP: 'Grupo demográfico',
    SELECT_YEAR_INCLUSION:
        'Seleccione el año para elegir los datos de qué año desea comparar con los datos de esta encuesta. Los resultados estarán disponibles en las pestañas individuales. ',
    CLOSE_RESURVEY_TEXT: 'Cerrar pestañas comparativas a través del tiempo',
    CONFIRMATION_TEXT:
        '¿Está seguro de que desea cerrar la vista comparativa? Esto cerrará las pestañas que están mostrando datos de la otra encuesta.',
    CONFIRMATION_BTN: 'Sí, cerrar',
    DETAILED_BUTTON: 'Ver informe detallado',
    COMPARE_SURVEY_BUTTON: 'Ver comparación en la página',
    VIEW_HEATMAP_BUTTON: 'Ver mapa de calor comparativo',
    NO_MAPPED_DEMO:
        'No existe mapeo para el año comercial seleccionado. Cargue mapas demográficos antes de generar mapas de calor comparativos',
    EX_PERCENTILE_MSG: 'Percentile score comparisons are not available for surveys conducted before 2025',
    EX_QUARTILE_MSG:
        'Quartile shift logic is not available for surveys conducted before 2025. Please use statistical significance or custom delta for resurvey comparisons instead.',
    BULK_RESURVEY_ERROR: 'Para exportaciones masivas, seleccione solo dos encuestas.',
    BULK_RESURVEY_COMP_ERROR: 'Para mapas de calor comparativos, seleccione solo dos encuestas.',
    COMPANY_RESULTS: 'Este año',
    VIEW_PRACTICE: 'Ver perfil de práctica',
    VIEW_MORE: 'Ver más',
    VIEW_LESS: 'Ver menos',
    BENCHMARK: 'Benchmark',
    ORG_DEMOGRAPHIC: 'Demográficos de la organización',
    BU_DEMOGRAPHIC: 'Demográficos de los BU',
    COMPANY_RESULTS_TEXT: 'Resultados',
    BU_RESULTS: 'Resultados por BU',
    COMPANY_RESULTS_DIFF: 'Diferencia b/n ',
    COMPANY_RESULTS_DIFF_TEXT: 'resultados y',
    ADD_YEAR: 'Agregar otro año para comparar',
    ADD_ACTION: 'Agregar nueva acción',
    CREATE_ACTION: 'Crear nueva acción de OHI',
    ACTION_SUBMITTED: '¡Enviado correctamente!',
    ACTION_SUBMITTED_TEXT: 'Los nuevos detalles de acción de OHI se enviaron correctamente.',
    OK_TEXT: '¡Bien!',
    REPORT_TEXT: 'Informe',
    REPORT_VIEW: 'Ver informe',
    REPORTS: 'Informes',
    REPORTS_LIST: 'Lista de informes',
    HOME: 'Pantalla de inicio',
    LOG_OUT: 'Cerrar sesión',
    USER_MNGT: 'Gestión de usuarios',
    USER_ID: 'Id. de usuario',
    NAME: 'Nombre',
    EMAIL: 'Dirección de correo electrónico',
    ROLE: 'Función',
    SEARCH_EMAIL_REPORT: 'Buscar por nombre o correo electrónico',
    SELECT_DEMO_GROUP: 'Seleccionar grupo demográfico',
    N_SIZE_SORT: ['Tamaño N: de mayor a menor', 'Tamaño N: de menor a mayor'],
    OVERALL_SORT: ['General: de mayor a menor', 'General: de menor a mayor'],
    GENERATE_REPORT: 'Generar informe',
    DOWNLOAD_SAMPLE_REPORT: 'Descargar informe de muestra',
    PAST_REPORT: 'Informes anteriores',
    MORE_INFO: 'Más información',
    THESE_OPTIONS_ARE_ACCURATE_PROCED: 'Estas opciones son precisas. proceda.',
    REFRESH_TEXT: 'Actualizar',
    REFRESH_REPORTS: 'Actualizar informes',
    DEMOGRAPHIC_OPTIONS: 'Opciones demográficas',
    DEMOGRAPHIC_FILTERS: 'Filtros demográficos',
    DEMOGRAPHIC_THRESHOLD: 'Umbral demográfico',
    CHANGE_DEMOGRAPHIC_THRESHOLD: '¿Cambiar el umbral demográfico?',
    NOT_ALLOWED: 'N/A',
    X_AXIS: 'Eje X',
    Y_AXIS: 'Eje Y',
    CHANGE: 'Cambiar',
    INSUFF_RES: 'Respuestas insuficientes',
    OUTCOME_OPTIONS: 'Opciones de dimensiones',
    PRACTICE_OPTIONS: 'Opciones de práctica',
    BUBBLE_CHART_SORTING: 'Orden de gráfico de burbujas',
    PRACTICE_SCORE_BY_DEMOGRAPHIC: 'Gráficos de pulso',
    DEMOGRAPHIC_INSIGHTS: 'Análisis demográfico profundo',
    BUBBLE_CHART_CUSTOMISATION: 'Gráficos de burbujas',
    VIEW_DEMOGRAPHIC_SCORES: 'Ver puntajes por demográficos',
    VIEW_SAMPLE_SLIDE: 'Ver diapositiva de muestra',
    SAMPLE_SLIDE: 'Diapositiva de muestra',
    DOWNLOAD_FORMAT: 'Descargar formato',
    VIEW_SAMPLE: 'Ver muestra',
    ALL_DEMOGRAPHICS: NONE_10,
    DEMOGRAPHIC_HEAT_MAP: 'Mapa de calor demográfico',
    SCORE_HEAT_MAP: 'Puntaje de mapas de calor',
    SCORE_MAPS: 'Mapa de calor de puntaje',
    SELECT_ALL: ' Seleccionar todo',
    ADDITIONAL_SETTINGS: 'Configuración adicional',
    CUSTOMISE_EXPORT_REPORT: 'Personalizar exportación de informe',
    CREATE_REPORT: 'Crear informe',
    CHOSE_REPORT: 'Seleccionar informe',
    SELECT_REPORT: 'Elija y selecciona el informe',
    PRACTICE_RANKING: 'Ranking de prácticas',
    CHART_PRACTICE_RANKING: 'Gráfico comparativo con otros benchmarks de referencia y ranking de prácticas',
    CHART_PRACTICE_RANKING_BUBBLES:
        'Gráfico de prácticas comparativo con otros benchmarks de referencia y burbujas de comparación',
    COMPARISON_BUBBLE: 'Burbujas de comparación',
    FLAGPOLE_CHART: 'Resultado de prácticas en un gráfico comparativo de benchmarks',
    GENERAL_SETTINGS: 'Configuración general',
    BULK_REPORT: 'Informe Masivo',
    CHOOSE_BULK_UNITS: 'Seleccionar unidad de negocio',
    COMPARISON_HEATMAP: 'Mapa de calor comparativo',
    MAIN_GROUP_TEXT: 'Grupo principal',
    ENABLE_TEXT:
        'Habilita este interruptor para incluir páginas adicionales que comparen el “Grupo Principal” seleccionado con la “Organización” en general. Ten en cuenta que el tamaño n de la “Organización” es el tamaño total de la muestra y es independiente de cualquier filtro aplicado.',
    DEMO_BU_DESC_TEXT:
        'Seleccione el grupo del cual desea ver un análisis profundo; normalmente es una unidad de negocio o función.',
    COMPARISION_GROUP: 'Grupo de comparación',
    COMPARISION_GROUP_DESC:
        'Seleccione el grupo que será el grupo de referencia - generalmente es toda la organización',
    BULK_REPORT_NOTE:
        'Revise los filtros cuidadosamente en esta página. En el caso de exportaciones masivas, no aplique filtros para la categoría demográfica para la cual está creando descargas masivas.',
    PRACTICE_RANKING_NOTE:
        'Los ranking de prácticas se calculan estandarizando los puntajes de práctica y ordenándolas de mayor a menor',
    ENTER_COMPANY_NAME: 'Escriba el nombre de la organización',
    ENTER_ORG_NAME: 'Ingrese el nombre de la organización',
    LANGUAGE: 'Idioma',
    COMPARISION_BU_TEXT: 'Comparison BU',
    SELECT_BM_VERSION: 'Seleccione la versión del Benchmark',
    BENCHMARK_QUARTILE_COLOR: 'Color del cuartil del Benchmark',
    BENCHMARK_QUARTILE_COLOR_EX: 'Employee Experience Benchmark Quartile Coloring',
    BENCHMARK_YEAR: 'Año del Benchmark',
    BENCHMARK_VERSION: 'Versión de benchmark',
    BENCHMARK_TARGET_SETTING: 'Benchmark aplicado a las páginas para puntos del cuartil superior/subsecuentes',
    APPLIED_BENCHMARK_TEXT: 'Comuníquese con OHI-HelpDesk si necesita cambiar el benchmark aplicado',
    BENCHMARK_TARGET_SETTING_SUB_INFO:
        'Las páginas que muestran puntajes y objetivos para alcanzar el cuartil superior o los cuartiles subsecuentes están comparadas con bechmarks globales/regionales, incluso si el resto del informe está comparado con un benchmark a más granular.',
    BENCHMARK_NA: 'Benchmark no disponible',
    SCORECARD_NOTE:
        'Los puntajes medianos de salud mostrados son propiedad intelectual protegida de la firma y no deben publicarse en publicaciones internas o externas, ni compartirse con los clientes.',
    PERCENT_FAVORABLE: 'Puntajes de OHI reportados en porcentaje favorable',
    PERCENTILES: 'Puntajes de OHI reportados en percentiles',
    TARGET_SETTING: 'Establecimiento de objetivos',
    TARGET_QUARTILE:
        'El establecimiento de objetivos representa los puntos necesarios para alcanzar el siguiente cuartil.',
    ACCESS_EXPIRE: 'El acceso caduca',
    OHI_VALADITY: 'Comuníquese con el servicio de asistencia de OHI para extender su validez',
    EE_PERCENT_FAVORABLE: 'Factores EX reportados en porcentaje favorable',
    EE_PERCENTILE: 'Factores EX reportados en porcentaje favorable',
    EE_OHI_PERCENT_FAVORABLE: 'Puntajes OHI y factores EX reportados en porcentaje favorable',
    EE_OHI_PERCENTILE: 'Puntajes de OHI reportados en percentiles, Factores EX reportados en porcentaje favorable',
    EE_ENPS_FOOTER:
        'El eNPS se calcula restando el porcentaje de detractores del porcentaje de promotores, y puede variar de -100 a 100.',
    ENGLISH: 'Inglés (Estados Unidos)',
    ENGLISH_CBR: 'Inglés',
    GERMAN_CBR: 'Alemán',
    SET_FLAGPOLE_CHART: 'Definir benchmark de referencia',
    SET_EE_FLAGPOLE_CHART: 'Set Employee Experience Flagpole Chart',
    NUMBER_OF_SURVEYS: 'Número de encuestas',
    DISPLAY_TORNADO_CHART: 'Mostrar gráficos de tornado',
    SURVEY_SENT: 'Encuestas enviadas',
    SURVEY_COMPLETED: 'Encuestas completadas',
    COMPLETED: 'Completadas',
    INCLUSION_COMPLETED: 'Tasa de respuesta',
    RESPONSE_RATE_TEXT: 'Tasa de respuesta',
    LAST_UPDATE: 'Última actualización',
    USER_SETTINGS: 'Configuración de usuario',
    OPTION: 'Opciones',
    ADDITIONAL_SURVEY: 'Encuesta adicional',
    PREVIOUS_SURVEY: 'Encuesta anterior',
    CURRENT_SURVEY: 'Encuesta actual',
    CURRENT_REPORT_NAME: 'Nombre del informe actual',
    CURRENT_SURVEY_INFO: 'Información de la encuesta actual',
    DATACUBE_TYPE: 'Tipo de Datacube',
    DATACUBE_LEVEL: 'Nivel demográfico',
    SCORE_DEPTH: 'Detalle de puntaje',
    DATACUBE_VERSION: 'Versión de cubo de datos',
    REPORT_PLACEHOLDER: 'Escriba el nombre que desea que se use en el informe',
    SUB_INFO_GENERAL:
        'Seleccione el Benchmark que desea que se use para el color de los cuartiles en el informe. Elija la opción Global para usar el Benchmark global o Seleccionar otro para ver otras opciones disponibles.',
    SUB_INFO_EX_GENERAL:
        'Select the benchmark you would like to use for quartile coloring the EX factors throughout the report. Wherever possible, we recommend using a consistent benchmark with Outcomes and Practices.',
    EX_BM_NOT_AVL1: 'EX benchmarks are not available for the selected comparison survey',
    EX_BM_NOT_AVL2:
        'As Ex benchmarks were not available for the selected comparison survey. Statistical significance & Custom delta can be calculated on percent favorable scores only',
    EX_BM_NOT_AVL3:
        'As Ex benchmarks were not available for both report as well as comparison survey. Statistical significance & Custom delta can be calculated on percent favorable scores only',
    SUB_INFO_INCLUSION: 'Seleccione el Benchmark que desea que se use para el color de los cuartiles en el informe.',
    SUB_INFO_PRACTICE:
        'Seleccione la casilla de verificación siguiente para mostrar gráficos de tornado para las páginas de ranking de prácticass.',
    SUB_INFO:
        'Seleccione el Benchmark que desea que se use para el color de los cuartiles en el informe. Elija la opción Global para usar el Benchmark global o Seleccionar otro para ver otras opciones disponibles.',
    SUB_INFO_EX_FLAGPOLE:
        "Select the benchmarks you would like to use on the employee experience flagpole pages. The client's employee experience factors will be ﻿shown with the difference to the benchmark's median score.",
    BACK: 'Atrás',
    NEXT: 'Siguiente',
    BUILD_REPORT: 'Construya el informe',
    BUILD_TEXT:
        'Puede personalizar la descarga de su informe eliminando cualquiera de las secciones de la lista. Todas las secciones se seleccionan de forma predeterminada, lo que producirá una salida estándar de plataforma completa.',
    INSUFFICIENT_HEADING: 'Advertencia de informe masivo',
    INSUFFICIENT_INFO: 'Algunas opciones tienen un tamaño n insuficiente',
    SUFFICIENT_INFO: '¿Eliminar estas opciones y continuar?',
    INSUFFICIENT_OK: 'Sí',
    REMOVE: 'Eliminar',
    DOWNLOAD_TEXT: 'Descargar',
    SELECT_DOWNLOAD: 'Seleccione Descargar',
    DOWNLOAD: 'Descargar informe',
    DOWNLOAD_PAST_REPORT: 'Descargar informe',
    PROCESSING: 'Procesando',
    DOWNLOAD_ALL: 'Descargar todo',
    DOWNLOAD_PPT: 'Descargar PPT',
    DOWNLOAD_PDF: 'Descargar PDF',
    DOWNLOAD_PPT_V1: 'Descargar PPT versión 1',
    DOWNLOAD_PPT_V2: 'Descargar PPT versión 2',
    DOWNLOAD_PPT_V3: 'Descargar PPT versión 3',
    VERSION_TEXT: 'Versión',
    SELECT_OUT_VER: 'Seleccione la versión de PPT de dimensión',
    INCLUDE_TEXT: 'Incluir mapas de calor delta para datos demográficos mapeados',
    SELECT_PRTC_VER: 'Seleccione la versión de PPT de práctica',
    DOWNLOAD_EXCEL: 'Descargar Excel',
    DOWNLOAD_POWERPOINT: 'Descargar PowerPoint',
    DEMO_OUTCOME_PARA:
        'Puede personalizar la versión de salida de descarga de la página para mapas de calor de resultados',
    DEMO_PRACTICE_PARA:
        'Puede personalizar la versión de salida de descarga de la página para practicar mapas de calor',
    CUSTOMIZE_PRACTICE_PLACEMENT: 'Puede personalizar la ubicación de la práctica en la mesa del mapa de calor.',
    SELECT_PLACEMENT: 'seleccionar ubicación de práctica',
    OUTCOME_PLACEMENT: 'Puede customizar la posición de las dimensiones en el mapa de calor',
    SELECT_OUTCOME: 'Seleccione la posición de las dimensiones',
    STATUS: 'Estado',
    ACTION: 'Acción',
    DATE: 'Fecha',
    DATE_TIME: 'Fecha y hora',
    RPT_TYPE: 'Tipo de informe',
    RPT_NAME: 'Nombre del informe',
    RETRY: 'Reintentar',
    RESURVEY: 'Comparativo a través del tiempo de Salud',
    COMPARE: 'Comparar',
    TO: 'para',
    NO_REPORTS: 'No se encontraron informes',
    HEAT_MAP_NONE: 'Si corresponde, seleccione los demográficos para el módulo EE',
    HEAT_MAP_SELECT:
        'Seleccione las preguntas demográficas que desea utilizar en los mapas de calor de resultados y prácticas.',
    INCLUSION_HEATMAP: 'Elija los grupos demográficos para los que desea generar los mapas de calor de puntaje.',
    HEAT_MAP_INCLUSION:
        'Elija los grupos demográficos para los que desea generar los mapas de calor de puntaje en "Personalizado".',
    SCORE_NEW_HEATMAP:
        'Elija los grupos demográficos para los que desea generar los mapas de calor de puntaje en "Personalizado".',
    SCORE_NEW_HEATMAP_2:
        'El valor predeterminado es "Ninguno", lo que significa que no habrá mapas de calor de puntaje ni otras páginas enumeradas en su informe.',
    HEAT_MAP_INCLUSION_2:
        'El valor predeterminado es "Ninguno", lo que significa que no habrá mapas de calor de puntaje en su informe.',
    HEAT_MAP_INCLUSION_RES:
        'Elija los grupos demográficos para los que desea generar mapas de calor de puntaje seleccionando "Personalizado".',
    HEAT_MAP_INCLUSION_RES_2:
        'Los mapas de calor de comparación año tras año están disponibles solo para datos demográficos mapeados. Póngase en contacto con el servicio de asistencia técnica para obtener un mapeo demográfico año tras año.',
    INFO_TEXT:
        'Los mapas de calor de comparación año tras año están disponibles solo para datos demográficos mapeados.',
    NAME_COMPARISON: 'Nombre de la comparación',
    GIVE_NAME_PLACEHOLDER: 'Asigne un nombre a la comparación',
    ENTER_REPORT_NAME: 'Escriba el nombre del informe',
    ENTER_NAME: 'Introduzca un nombre de informe',
    SELECT_SURVEY: 'Seleccionar encuesta',
    SELECT_PRIORITY_PRACTICE: 'Seleccione 15 prácticas prioritarias',
    SELECT_COMPARISON_BUBBLES: 'Seleccionar burbujas de comparación  para Resultados y Prácticas',
    SELECT_COMPARISON_BUBBLES_EX: 'Seleccionar burbujas de comparación para factores de Experiencia del Empleado',
    OHI4_HEAD: 'Incluir preguntas beta de OHI 4.0',
    OHI4_META:
        'Incluya preguntas de la versión beta de OHI 4.0 en el informe. Puede seleccionar los demográficos para el mismo en el siguiente paso.',
    OHI4_OPTIONS_HEAD: 'Opciones demográficas de OHI 4.0',
    BU_GROUPS: 'Comparación BU por grupos ',
    BU_BUSINESS_TEXT:
        'Selecciona el grupo para el que deseas ver un análisis detallado (usualmente es una unidad de negocio o función).',
    BU_ORGANIZATION_TEXT: 'Seleccione el grupo que será el grupo de referencia - generalmente es toda la organización',
    OHI4_OPTIONS_META: 'Seleccione los demográficos de eNPS que desea incluir en el informe.',
    SELECT_RESURVEY_DOWNLOAD_TYPE: 'Seleccionar formato de informe',
    ADD_ANOTHER_SURVEY: 'Agregar otra encuesta',
    REPORT_NAME_PLACEHOLDER: 'Escriba el nombre que desea que se use en el informe',
    SELECT_COMPARE_YEAR: 'Seleccione Comparar año',
    OVERVIEW_TEXT: 'Descripción general',
    RESPONDENTS_TEXT: 'Encuestados',
    TOOLBOX: 'Caja de herramientas',
    NEW_THEME: 'Nuevo tema',
    BUILD_PAGE: 'Crear página',
    BU_REPORT_TEXT: 'Grupo de comparación',
    CREATE_TEXT: 'Crear',
    OUT_PRT: 'Dimensiones y prácticas',
    ADD_TEXT: 'Agregar',
    THEME_NAME: 'Escriba el nombre del tema',
    DESC: 'Descripción',
    NOTES: 'Notas',
    FEEDBACK_TEXT: 'Comuníquese con el equipo de OHI-Helpdesk en cualquier momento si tiene alguna pregunta.',
    TYPE_ISSUE: 'Tipo de problema',
    ISSUE_DISCRIPTION: 'Descripción del problema',
    ADDED_IMAGE: 'Imagen agregada',
    THEME_PLACE: 'Escriba un nombre de hasta 100 caracteres',
    NOTES_PLACE: 'Escriba sus notas aquí',
    TEXT_PLACE: 'Escriba texto aquí',
    SAVED_THEMES: 'Patrones guardados',
    NO_PATTERN: 'No se encontraron patrones.',
    CREATE_THEME_TEXT: 'Haga clic aquí para crear un nuevo tema.',
    CONFIRM: 'CONFIRMAR',
    CONFIRM_TEXT: 'Confirmar',
    INCLUDE_TEXT_1: 'Incluir comparación a nivel organizacional',
    MINIMUM_TEXT: 'Se requiere al menos una selección',
    SELECT_DEMOGRAPHIC_TEXT: 'Seleccionar demográfico',
    OUTCOME_PLACEMENT_TEXT: 'Colocación de dimensiones en la tabla de mapa de calor',
    HORIZONTAL_TEXT: 'Por favor, usa el formato horizontal solo para los demográficos con múltiples categorías',
    PRACTICE_TABLE_TEXT: 'Vista de prácticas en la tabla de mapa de calor',
    VERTICAL_AXIS: 'Eje vertical',
    HORIZONTAL_AXIS: 'Eje horizontal',
    REFRESH: 'Actualizar',
    TICKET_JOURNEY: 'Viaje del Ticket:',
    IN_PROGRESS: ' En Progreso',
    QA_READY: 'Listo para QA',
    REVIEW: 'Revisión de Código',
    TO_DO: 'Por Hacer',
    CODE_REVIEW: 'Revisión de Código',
    DONE_TEXT: 'Listo',
    VIEW_DETAILS: 'VER DETALLES',
    RESULT_PER_PAGE: 'Resultados Por Página',
    TITLE: 'Título',
    DELETE: 'ELIMINAR',
    CANCEL: 'CANCELAR',
    CANCEL_TEXT: 'Cancelar',
    SAVE_GROUP: 'Guardar grupo',
    DRAG_DROP_HEAD: 'Arrastre para organizar columnas y filas',
    SHOW_SCORE: 'Mostrar puntuación',
    SHOW_SCORES: 'Mostrar puntuación',
    CUSTOMIZE_COHESION: 'Personalizar distribución',
    COHESION_TAB1: 'Similitudes culturales entre unidades y organizaciones',
    COHESION_TAB2: 'Matriz de similitud',
    CLOSE: 'Cerrar',
    DELETE_PATTERN: '¿Eliminar patrón?',
    AVG_GAP: 'Brecha promedio',
    LARGEST_GAP: 'Brecha mayor',
    OVERALL_INCLUSION: 'Inclusión general',
    OVERALL_SCORE: 'Puntaje general',
    OVERALL_SCORED: 'Puntaje general',
    SORT: 'Ordenar',
    DELETE_MSG:
        'Está a punto de eliminar este patrón guardado. Si elige eliminar este patrón, perderá todos los datos.',
    ARE_YOU_SURE: '¿Está seguro de que desea continuar?',
    SETTINGS: 'Configuración',
    BG_COLOR: 'Color de fondo',
    BD_COLOR: 'Color de borde',
    FONT_COLOR: 'Color de fuente',
    RECIPE_NOTE: 'Alineación de recetas',
    FILTES_CHECK_NOTE: 'Revise los filtros aplicados',
    NOTE: 'Nota',
    QUESTION_NOTE: 'Todos los puntajes indican % de empleados',
    EXCEL_DWNLD_TEXT:
        'Su solicitud de exportación a Excel ha sido enviada. Pronto recibirá un correo electrónico con información para descargar el archivo.',
    APPLY_SETTINGS: 'Aplicar',
    BAR_CHART_NOTE:
        'La similitud de recetas es la correlación entre la receta del cliente y la receta ideal, multiplicada por 10.',
    BENCH_NOTE:
        'Los resultados estadísticamente significativos tuvieron valores p de < 0,05, lo que indica una alta probabilidad de que haya ocurrido un cambio/diferencia real. La significación estadística se basa en el número de encuestados y la magnitud de la diferencia de puntos.',
    FLAGPOLE_NOTE_1:
        'Los resultados estadísticamente significativos tienen valores p < 0.05, lo que indica una alta probabilidad de que las diferencias observadas sean reales y no debidas al azar. La significación estadística se basa en las puntuaciones observadas en la muestra seleccionada y el tamaño de dicha muestra. La misma diferencia absoluta en las puntuaciones es más probable que sea estadísticamente significativa en una muestra grande en comparación con una muestra pequeña.',
    FLAGPOLE_NOTE_2: 'Las clasificaciones de la práctica se basan en percentiles globales.',
    FLAGPOLE_LINK_TEXT: 'Metodología OHI curso e-learning',
    GSOD_OVERVIEW_NOTE:
        'Se requiere un mínimo de 10 encuestas para crear  indicadores de otros benchmarks de referencia y 25 encuestas para crear cuartiles de color para sub-benchmarks en los informes de clientes del índice de salud organizacional.',
    VIEW_ONLINE: 'VER CLASE EN LÍNEA',
    LEARN_MORE:
        'Para obtener más información sobre la metodología del índice de salud organizacional, por favor consulte el curso de e-Learning sobre la metodología del índice de salud organizacional',
    LEARN_MORE_LINK:
        'https://mckinseylearning.csod.com/samldefault.aspx?returnUrl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlodetails%2526lo%253d684aef4c-e57c-4d83-bd2a-d2351584451d',
    OVERVIEW_NUMBER_NOTE: 'No hay análisis detallados disponibles (n<10)',
    GSOD_PRAC_NOTE: [
        'Las clasificaciones de la práctica se basan en percentiles globales',
        'Las clasificaciones de la práctica se basan en valores z',
    ],
    GSOD_PRAC_ANALYSIS: [
        'Las organizaciones del cuartil superior (CS) y del cuartil inferior (CI) reflejan el 25% superior e inferior de las puntuaciones del índice de salud organizacional en la muestra seleccionada',
    ],
    DEMO_NOTE: 'Para proteger la confidencialidad, no se muestran los resultados de grupos de <Umbral participantes.',
    DEMOGRAPHIC_OPTIONS_SUBINFO: 'Seleccione cómo desea que aparezcan los grupos de menos de ',
    DEMOGRAPHIC_OPTIONS_EX:
        'En el Informe de experiencia del empleado, no se mostrarán resultados para grupos con n<10',
    DEMOGRAPHIC_OPTIONS_SUBINFO_2: ' en mapas de calor de dimensiones y prácticas',
    DATACUBE_OPTIONS_SUBINFO: 'No se mostrarán resultados para grupos con n<',
    DATACUBE_OPTIONS_SUBINFO_2: 'de forma predeterminada',
    DATACUBE_NAME_PLACEHOLDER: 'Escriba el valor n',
    DEMO_OPT_NOTE: thresVal =>
        `Para proteger la confidencialidad, los grupos de menos de ${thresVal} no deben compartirse con los clientes. ¿Está seguro de que desea mostrar los grupos < ${thresVal}? Si desea entregar estos resultados a su cliente, comuníquese con su ROM para obtener aprobación.`,
    PREVIOUS: 'Anterior',
    COHESION_HEADING: 'Cohesión',
    TXT_ANA_HEADING: 'Analítica de texto',
    ADD_ONS: 'Complementos',
    COHESION_SCORE: 'Puntaje de cohesión',
    REPORTS_TIP_TEXT: 'Sus informes están listos para descargar.',
    SWITCH_ACCOUNT: 'Cambiar de cuenta',
    COHESION_SUB_HEADING:
        'La cohesión nos ayuda a analizar más allá de los resultados promedio de una organización para determinar si todos están experimentando lo mismo.',
    COHESION_LEGENDS_TEXT: [
        {
            type: 'high',
            title: '> 95 potencialmente muy alta',
            description: 'Potencial de rigidez cultural, falta de diversidad y variedad necesaria en las subculturas.',
        },
        {
            type: 'moderate',
            title: '75 a 84 Moderada',
            description:
                'Cierta cohesión y coherencia cultural, pero no suficiente para fortalecer el desempeño y la salud de la organización debido a formas de trabajo divergentes y silos.',
        },
        {
            type: 'optimal',
            title: '> 84 a 95 Óptima',
            description:
                'En el punto ideal de alta cohesión cultural sin ser demasiado alta; alcanzar el cuartil superior de desempeño y salud es mucho más probable.',
        },
        {
            type: 'low',
            title: '< 75 Baja',
            description:
                'La empresa es, en términos de comportamiento, una serie de culturas y normas laborales diferentes que hacen más difícil mantener la salud en el cuartil superior.',
        },
    ],
    BU_HEAD_TITLE: ['Puntaje de salud de la organización', 'Puntaje de salud de la BU', 'Diferencia'],
    BU_PROJECTS: ['Filtro de informe', 'Filtro de comparación'],
    BAR_CHART_LEGENDS: ['Sin alineación', 'Fuerte', 'Muy fuerte'],
    COHESION_GRAPH_LEGEND_TEXT: [
        'Benchmarks',
        'Puntajes >0,90 (muy alta)',
        'Puntajes entre 0,70 y 0,90 (alta)',
        'Puntajes entre 0,50 y 0,70 (moderada)',
        'Puntajes entre 0,30 y 0,50 (baja)',
        'Puntajes entre -0,30 y 0,30 (insignificante)',
        'Puntaje <-0,30 (negativa)',
    ],
    COHESION_GRAPH_LEGEND_TEXT_OHI4: ['Benchmarks', 'Alta', 'Moderada', 'Baja'],
    SCORECARD_DISCLAIMER: [
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de inclusión, dimensiones y prácticas; las brechas de Inclusión se representan en cuadrados y todos los demás puntajes se representan en círculos.',
        `Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)`,
    ],
    SCORECARD_RES_DISCLAIMER: [
        'Las preguntas subyacentes cambiaron desde la encuesta del año pasado (es decir, se agregaron y/o eliminaron preguntas). Por lo tanto, los puntajes del año pasado y del año actual no se pueden comparar directamente.',
        '** Nuevas prácticas añadidas desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    SCORECARD_DEMOGRAPHIC_DISCLAIMER: [
        ' 1. Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        ' 2. Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de inclusión, dimensiones y prácticas; las brechas de Inclusión se representan en cuadrados y todos los demás puntajes se representan en círculos.',
        ` 3. Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)`,
        ` 4. Los resultados para los grupos demográficos con n < 10 no se informan`,
        ` 5. GEl cálculo de la brecha excluye a aquellos que respondieron 'Prefiero no responder`,
    ],

    SCORECARD_RESURVEY_VALUE_BASED: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de inclusión, dimensiones y prácticas; las brechas de Inclusión se representan en cuadrados y todos los demás puntajes se representan en círculos.',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    SCORECARD_RESURVEY_VALUE_BASED_V2: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
    ],

    SCORECARD_RESURVEY_QUARTILE_BASED_V2: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
    ],

    SCORECARD_RESURVEY_QUARTILE_BASED: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    RESURVEY_DEMO_VALUE_BASED: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'El cálculo de la brecha excluye a quienes respondieron "Prefiero no responder".',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    RESURVEY_DEMO_QUARTILE_BASED: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'El cálculo de la brecha excluye a quienes respondieron "Prefiero no responder".',
        'Las preguntas subyacentes (para  Meaningful work, Allyship, Connection Opportunities, Resource Accessibility y Mentorship) han cambiado desde la encuesta de años anteriores (es decir, se han añadido y/o eliminado preguntas). Por lo tanto, las puntuaciones del año pasado y del año actual no se pueden comparar directamente.',
        'Se han añadido nuevas prácticas (Collective Identity y Sponsorship) desde la encuesta del año pasado. Por lo tanto, no hay comparaciones disponibles.',
    ],

    RESURVEY_DEMO_VALUE_BASED_V2: [
        'Las comparaciones de puntuación se basan únicamente en la magnitud del cambio (±1 o más), no en la significancia estadística',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
    ],

    RESURVEY_DEMO_QUARTILE_BASED_V2: [
        'Score comparisons based on positive/negative changes in quartiles, not statistical significance',
        'Los promedios pueden ocultar diferencias significativas e importantes en la experiencia de inclusión de distintos grupos demográficos.',
        'Gaps highlighted here are highest of the largest demographic level inclusion gaps for each of the Inclusion, Outcome and Practice scores',
        'Los umbrales de las brechas de inclusión se basan en la medida del tamaño del efecto V de Cramer (brecha pequeña: <7, brecha moderada: 7-14, brecha grande: >14)',
        'Los resultados para los grupos demográficos con n < 10 no se informan',
        'Gap calculation excludes those that answered ‘Prefer not to answer',
    ],
    IA_OLD_DISCLAIMER:
        'Note that the Overall Inclusion calculations are based on averages of Personal Experience and Enterprise perception (not an average of the 6 outcomes)',
    DISCLAIMER: 'Aviso',
    RESTRUCTION_TOOLTIP:
        'Los criterios de umbral restringen el análisis a muestras seleccionadas con 10 organizaciones en el cuartil superior y 10 organizaciones en el cuartil inferior de la muestra seleccionada.',
    MEDIAN_RESTRUCTION_TOOLTIP:
        'Los criterios de umbral restringen el análisis a muestras seleccionadas con 10 organizaciones en la mitad superior y 10 organizaciones en la mitad inferior de la muestra seleccionada.',
    QUESTION_DISCLAIMER:
        'El porcentaje de encuestados que indicaron ”No sé o No corresponde” no se considera en los cálculos de los puntajes de dimensiones/prácticas de inclusión.',
    IA_2_QUESTIONS_DISCLAIMER:
        '4. Los textos de las preguntas pueden no coincidir exactamente con lo que se preguntó en la encuesta V2.0',
    IA_RES_HEATMAP_OVAERALL_DISCLAIMER:
        'The overall inclusion score is now calculated as the average of six outcomes, a change since the last survey. This change has little to no impact on the observed score, with most cases showing no difference and a few showing a 1-point decrease',
    LEADERS: 'Líderes',
    PEER_TEXT: 'Par',
    TEAMMATES_TEXT: 'Compañeros(as) de equipo',
    PEERS: 'Pares/compañeros(as) de equipo',
    INCLUSION: 'Inclusión',
    SHOW_OVERALL: 'Mostrar general',
    GAP_SCORE_ARE_LOADING_PLEASE_WAIT: 'Las puntuaciones de brecha se están cargando. Por favor, espere.',
    SYSTEMS: 'Sistemas',
    OUTCOME_SCORE_GAPS: 'Puntajes de dimensiones y brechas',
    PRACTICE_SCORE_GAPS: 'Puntajes de práctica y brechas',
    GAP_CLASSIFICATION_TEXT: 'Clasificación de brecha',
    SCORE_CLASSIFICATION_TEXT: 'Clasificación de puntaje',
    OHI_ANALYTICS_LOGIN: 'Inicio de sesión de analítica de OHI',
    CHANGE_CLASSIFICATION: [
        {
            label: 'Cambio positivo',
            type: 'up',
            color: colors.$green400,
            index: 1,
        },
        {
            label: 'Neutral',
            color: colors.$yellow,
            index: 2,
        },
        {
            label: 'Cambio negativo',
            color: colors.$pink,
            type: 'down',
            index: 0,
        },
    ],
    PRACTICE_CLASSIFICATION_TEXT: 'Tipo de práctica :',
    WORKPLACE_QUESTIONS: 'Preguntas de dimensiones en el lugar de trabajo',
    OUTCOME_QUESTIONS: 'Preguntas por dimensión',
    SURVEY_RES: 'Encuestados',
    PRCT_LARGE_GAP: 'Prácticas con las brechas más grandes',
    OUTCOME_LARGE_GAP: 'Dimensiones con las brechas más grandes',
    DEMOGRAPHICS_LARGE_GAP: 'Demográficos con las brechas más grandes',
    PERSONAL_EXP: 'Experiencia personal',
    ENT_PERCEPTION: 'Percepción de la empresa',
    SIZE_MAIN_TEXT: 'Tamaño',
    WORKPLACE_OUT: 'Dimensiones en el lugar de trabajo',
    WORKPLACE_OUT_SCORE: 'Puntajes de dimensiones en el lugar de trabajo',
    AGGREMENT_LEVEL: 'Nivel de acuerdo',
    AGGREMENT_INCLUSION_LEVEL: 'Agreement Level in %',
    AGREEMENT_SCALE: 'Agreement scale',
    FREQUENCY_LEVEL_PERCENT: 'Nivel de frecuencia en %',
    RESURVEY_YEARS: 'Años de reencuesta',
    AGGREMENT_LEVEL_PERCENT: 'Nivel de acuerdo en %',
    EXPERIENCE_LEVEL_PERCENT: 'Nivel de experiencia en %',
    RESPONSE_PERCENT: 'Response in %',
    SHOW_INCLUSION_GAPS: 'Mostrar brechas de inclusión',
    INCLUSION_SCORECARD: 'Resumen de resultados de inclusión',
    QUESTIONS: 'Preguntas',
    VALUES_TEXT: 'Valores de la empresa',
    TEXT_COMMENTS_TXT: 'Comentarios de texto',
    INC_SCORE_DEMO: 'Puntaje de inclusión para demográficos',
    GAPS_TEXT: 'Brechas',
    BM_DISABLE_TXT:
        'Esta función está deshabilitada ya que el ajuste de comparaciones de inclusión  a través del tiempo está configurado en Cambio de cuartil o Significación estadística.',
    VALUES_MAIN_TEXT: {
        current: {
            heading: 'Valores actuales',
            para: 'Lo que somos hoy',
        },
        intersect: {
            heading: 'Valores actuales y deseados',
            para: 'Lo que nos gustaría continuar',
        },
        desired: {
            heading: 'Valores deseados',
            para: 'Lo que nos gustaría ser',
        },
    },
    VALUES_LEGEND_TEXT: [
        'Detractores de la salud organizacional: valor que tiene una correlación negativa con la salud general',
        'Potenciadores de la salud organizacional: valor que tiene la correlación positiva más sólida con la salud general',
    ],
    NEGATIVE_PRACTICES: ['Intención de irse', 'Agotamiento laboral'],
    INT_TO_LEAVE_TXT: 'Intención de irse',
    END_ORG_TXT: 'Respaldo a la organización ante otros',
    DIFFERENCE: [
        { legendText: 'Score Down', color: '#D51F31' },
        { legendText: 'Score Up', color: '#007F26' },
        { legendText: 'Same score', color: '#FFA800' },
    ],
    SOURCE_TEXT: 'Fuente',
    YEAR: 'Año',
    COMP_TEXT: 'Empresa',
    NUMBER_TEXT: 'n',
    PRACTICE_SCREEN: 'Todas las prácticas caben en una sola pantalla.',
    CATEGORY_BASED: 'Basado en categorías (alineación, renovación de ejecución)',
    NUMBER_CAP_TEXT: 'N',
    NUMBER_SURVEY_TEXT: 'No. encuestas',
    NUMBER_CAP_SURVEY_TEXT: 'Núm. de encuestas',
    RES_PPT_TEXT: 'resultados',
    ACTION_COMP_HEADER: 'Compendio de acciones de OHI',
    ACTION_COMPENDIUM: 'Compendio de acciones',
    ACTION_COMP_TEXT:
        '¡Bienvenido(a) a la herramienta de planificación de acciones de OHI! Hemos recopilado una serie de acciones que puede realizar con los clientes para generar impacto desde el primer día. Búsquelas, compílelas y agréguelas usando las siguientes opciones. Puede buscar por práctica de OHI independiente o puede organizar y explorar por receta, nivel y cuadrante de modelo de influencia.',
    GEN_LIST: 'Generar lista',
    DISCLAIMER_TEXT_PART1: 'Resultados para grupos demográficos con n',
    DISCLAIMER_TEXT_PART2: 'no se informan',
    DISCLAIMER_DEMO_PART1: 'No se muestran resultados para grupos de',
    DISCLAIMER_DEMO_PART2: 'para proteger la confidencialidad',
    DISCLAIMER_DEMO_PART3: '1 La puntuación general es el promedio de las 8 preguntas.',
    DISCLAIMER_GAP_TEXT: 'El cálculo de la brecha excluye a quienes respondieron "Prefiero no responder".',
    DISCLAIMER_PRACTICE_RANK_TEXT:
        'Las brechas destacadas aquí son las más altas de las brechas de inclusión a nivel demográfico más grandes para cada una de los puntajes de práctica de inclusión; las brechas de inclusión se representan en cuadrados y todas los demás puntajes se representan en círculos.',
    INSIGHT_INFO:
        'Seleccione 2 opciones demográficas dentro de un grupo demográfico determinado: los puntajes de la opción demográfica 1 se muestran y se comparan con los puntajes respectivas de la opción demográfica 2 para el cálculo de la brecha.',
    UPDATE_VIEW: 'Cambiar vista de usuario',
    UPDATE_VIEW_BUTTON: 'Actualizar vista',
    EXCLUDE_DEMO_GROUP_HEADING: 'Excluir grupo(s) de demostración de los cálculos de brecha',
    EXCLUDE_DEMO_GROUP_SUB_HEADING: [
        'Seleccione abajo los demográficos que desee excluir de los cálculos de brecha para todos las dimensiones y prácticas.',
        'Nota: Los usuarios de considerados encuestados descuidados, n<grupos de umbral y demostraciones preseleccionadas (si las hay) ya están excluidos. Póngase en contacto con el OHI-HelpDesk para cambiarlo',
    ],
    INCLUSION_DEMOOPTION_SUB_HEADING:
        'En el Informe de evaluación de inclusión no se mostrarán resultados para grupos con n<',
    INCLUSION_DEMOOPTION_SUB_HEADING_RESURVEY:
        'En la comparación a través del tiempo del reporte de inclusión no se mostrarán resultados para grupos con n<',
    INCLUSION_DEMOFILTER_SUB_HEADING: [
        'Seleccione las opciones demográficas necesarias para su informe.',
        'Todas las opciones demográficas se seleccionan de forma predeterminada, lo que genera un informe para toda la organización.',
    ],
    INCLUSION_PRACTICEBY_DEMO_SUB_HEADING:
        'Seleccione los grupos demográficos para los que desea generar gráficos de pulso.',
    DATACUBE_DEMOOPTION_SUB_HEADING:
        'Seleccione un mínimo de 2 y hasta 10 demográficos para generar Datacube L4 o L5. Si selecciona más de 10 demográficos, se generará un Datacube L3.',
    OUTCOMES_OPTION_SUBHEADING:
        'Para profundizar en las dimensiones de Inclusión, seleccione 2 preguntas de cada dimensión y dos de sus prácticas.',
    PRACTICE_OPTION_SUBHEADING:
        'Seleccione 3 prácticas de cada tipo de práctica para ver una comparación lado a lado de las prácticas con sus puntajes, brechas y porcentaje de respuestas favorables de todas sus preguntas.',
    DEMO_SCORE_SUBHEADING: 'Este mapa de calor muestra los puntajes de la organización en general.',
    BUBBLE_CHART_SUBHEADING: [
        'Elija los grupos demográficos y las opciones que necesita en su gráfico de burbujas.',
        'Tenga en cuenta que no es posible seleccionar “demostraciones excluidas del cálculo de brecha”',
    ],
    HEADER: 'Encabezado',
    OHI_4_RPT_TEXT: [
        [
            'Elija los grupos demográficos para generar páginas de desglose de la tasa de respuesta',
            'El valor predeterminado es "Ninguno", lo que significa que el informe no incluirá estadísticas de tasa de respuesta para ningún grupo demográfico.',
        ],
        [
            'Elija el grupo demográfico (y hasta 10 opciones en cada grupo) para generar páginas detalladas de Cohesión organizacional.',
            '"Ninguno" está seleccionado de forma predeterminada, lo que significa que no habrá páginas de análisis demográfico de Cohesion en su informe.',
        ],
        [
            'Elija los grupos demográficos para generar páginas de mapas de calor para el Módulo de experiencia del empleado (mapa de calor de Experiencia del empleado, mapa de calor de eNPS, etc.)',
            'El valor predeterminado es "Ninguno", lo que significa que no habrá mapas de calor demográficos de Experiencia del empleado.',
        ],
    ],
    OHI_4_HEADER: [
        'Tasa de respuesta de los demográficos',
        'Cohesión de demográficos',
        'Experiencia del empleado de demográficos',
    ],
    LOW_THRESHOLD:
        'No se puede crear el informe porque el número de encuestados es inferior al umbral mínimo. Revise los filtros.',
    why_important_text: 'Por qué es importante',
    what_it_entails: 'Qué es',
    ADD_QUES_TEXT: 'Preguntas adicionales',
    EX_JOURNEY_QUES: 'Preguntas Trayectoria del empleado',
    SCORE_TXT: 'Puntuación',
    GAP_TXT: 'Brecha',
    BUTTON_TAG: 'Mostrar puntaje más alto y más bajo',
    GAP_BUTTON_TAG: 'Mostrar la brecha más alta',
    GAP_TYPE: 'Tipo de brecha',
    ON: 'ACTIVADO',
    OFF: 'DESACTIVADO',
    BIG_PPT_ERROR:
        'El archivo es demasiado grande, se enviará a su correo electrónico. Recibirá el archivo en los próximos 5 a 10 minutos.',
    CLOSE_WINDOW_MSG: 'Puede cerrar esta ventana, el proceso continuará en segundo plano.',
    ZIP_FILE_MSG:
        'Sus informes se están descargando y se entregarán como un archivo zip.Los archivos grandes se enviarán por correo electrónico en un plazo de 5 a 10 minutos.',
    INCL_HEAD: 'Preguntas de la encuesta',
    INCL_HEAD_META: '% de respuestas favorables',
    INCL_PRAC_RANK_NOTES: [
        'Porcentaje de encuestados que respondieron "De acuerdo"/"Muy de acuerdo" para preguntas de dimensión y "A menudo"/"Casi siempre" para preguntas de práctica.',
        'El porcentaje de encuestados que indicaron "No sé o No corresponde" no se considera en los cálculos de los puntajes de dimensión/práctica de inclusión.',
    ],
    THRESHOLD_TXT: 'Umbral para el informe',
    UPDATE_TARGET: 'Actualizar conteo objetivo',
    SUBMIT: 'Enviar',
    PPT_RES_TEXT: 'Porcentaje de encuestados que seleccionaron “de acuerdo” o “muy de acuerdo”.',
    PPT_RES_TEXT2: 'Porcentaje de encuestados que seleccionaron “a menudo” o “casi siempre”.',
    PPT_RES_TEXT3: 'Los puntajes representan percentiles.',
    COMPENDIUM_OPTION_KEYS: {
        recipe:
            'Cuando se combinan, las prácticas de OHI crean recetas que nos ayudan a determinar qué tipo de organización es.',
        practice:
            'Son 37 prácticas las que comprenden la salud organizacional, y cada una tiene dimensiones, artículos de encuesta y acciones diferentes.',
        level: 'La complejidad estimada de implementar la acción (p. ej., recursos, personas y tiempo)',
        influence_model: [
            'Modelo a seguir: Comportamiento de líderes y colegas',
            'Convicción: Entender qué y por qué',
            'Capacidades: Habilidades y capacidades',
            'Reforzamiento: Procesos que apoyan el cambio',
        ],
    },
    LEVEL_SCORE: 'Puntajes de nivel',
    OUT_SCORE: 'Puntuaciones de resultados',
    PRC_SCORE: 'Puntuaciones de práctica',
    DEMO_INCLUSION_LEGENDS: [
        {
            background: colors.$red500,
            title: 'Puntaje inferior a 50',
        },
        {
            background: colors.$orange400,
            title: 'Puntaje entre 50-75',
        },
        {
            background: colors.$green700,
            title: 'Puntaje superior a 75',
        },
    ],
    PR_HEATMAP_LEGENDS: [
        {
            background: colors.$blue500,
            title: 'Clasificación más alta',
        },
        {
            background: colors.$red500,
            title: 'Clasificación inferior',
        },
    ],
    DEMOGRAPHICS_GRAPH_CLASSIFICATION: [
        {
            title: 'Muy alto (80 y más))',
            background: colors.$green600,
        },
        {
            title: 'Alto (70-79)',
            background: colors.$green500,
        },
        {
            title: 'Moderado (60-69)',
            background: colors.$orange300,
        },
        {
            title: 'Bajo (50-59)',
            background: colors.$orange200,
        },
        {
            title: 'Muy bajo (menos de 50)',
            background: colors.$orange100,
        },
    ],
    PRACTICE_CLASSIFICATION: [
        {
            level: 'Par',
            title: 'Pares/compañeros(as) de equipo',
        },
        {
            level: 'Líder',
            title: 'Líderes',
        },
        {
            level: 'Sistemas',
            title: 'Sistemas',
        },
    ],
    HEADER_LEGENDS: [
        {
            title: 'En desacuerdo/Muy en desacuerdo',
            background: colors.$black,
        },
        {
            title: 'Ni de acuerdo ni en desacuerdo',
            background: colors.$darkBlue400,
        },
        {
            title: 'De acuerdo/Muy de acuerdo',
            background: colors.$blue700,
        },
        {
            title: 'No sé o N/C',
            background: colors.$white,
        },
    ],

    HEADER_LEGENDS_1: [
        {
            title: 'En desacuerdo/Muy en desacuerdo',
            background: colors.$black,
        },
        {
            title: 'Ni de acuerdo ni en desacuerdo',
            background: colors.$darkBlue400,
        },
        {
            title: 'De acuerdo/Muy de acuerdo',
            background: colors.$blue700,
        },
        {
            title: 'No sé o N/C',
            background: colors.$white,
        },
    ],
    NA_BG: {
        bgcolor: colors.$white,
        text_color: 'black',
        border_color: 'black',
    },
    SUB_HEADER_LEGENDS: [
        {
            title: 'Rara vez/Casi nunca',
            background: colors.$black,
        },
        {
            title: 'Neutral',
            background: colors.$darkBlue400,
        },
        {
            title: '(A menudo/Casi siempre)',
            background: colors.$blue700,
        },
        {
            title: 'No sé o N/C',
            background: colors.$white,
        },
    ],
    SUB_HEADER_LEGENDS_1: [
        {
            title: 'Rara vez/Casi nunca',
            background: colors.$black,
        },
        {
            title: 'Neutral',
            background: colors.$darkBlue400,
        },
        {
            title: '(A menudo/Casi siempre)',
            background: colors.$blue700,
        },
        {
            title: 'No sé o N/C',
            background: colors.$white,
        },
    ],
    practicesByOutcome: [
        'Alianza',
        'Integración de ideas',
        'Respeto mutuo',
        'Apoyo de pares',
        'Defensa',
        'Mentoría',
        'Patrocinio',
        'Toma de decisiones participativa',
        'Formación de equipos',
        'Trato imparcial',
        'Expresión personal',
        'Mecanismos de protección',
        'Oportunidades de conexión',
        'Identidad colectiva',
        'Meritocracia',
        'Accesibilidad de recursos',
        'Balance trabajo-vida',
    ],
    pcLegends: {
        Authenticity: 'Alianza, Idea integration',
        Belonging: 'Mutual respect, Defensa',
        'Trabajo significativo': 'Apoyo de pares, Mentorship, Patrocinio',
        Acceptance: 'Self-expression, Protective mechanisms, Participative decision-making',
        Camaraderie: 'Connection opportunities, Collective identity, Formación de equipos',
        Fairness: 'Meritocracy, Resource accessibility, Balance trabajo-vida, Impartial treatment',
    },
    outLegends: {
        'Pares/compañeros(as) de equipo': 'Alianza, Idea integration, Mutual respect, Apoyo de pares',
        Leader:
            'Defensa, Mentorship, Patrocinio, Participative decision-making, Formación de equipos, Impartial treatment',
        Systems:
            'Self-expression, Protective mechanisms, Connection opportunities, Collective identity, Meritocracy, Resource accessibility, Balance trabajo-vida',
    },
    CHART_TEXT: 'Busque caídas en la organización y los demográficos',
    FEATURE_MODAL_HEADING: '¡Novedades en Inclusión!',
    FEATURE_MODAL_TEXT:
        'Estas son instantáneas de las nuevas características introducidas en Inclusión. Deslice las flechas Siguiente y Anterior para desplazarse por todas las imágenes. Presione el botón de abajo para cerrar esta sección.',
    FEATURE_MODAL_BTN: '¡Entendido!',
    RR_SURVEY_OPTIONS: [
        {
            code: 1,
            label: 'Individual',
        },
        {
            code: 2,
            label: 'Combinado',
        },
        {
            code: 99,
            label: 'Global',
        },
    ],
    HEADER_NAV: [
        {
            text: 'Pantalla de inicio',
            link: '/',
            checkAccess: true,
            checkTabAccess: true,
        },
        {
            text: 'Tasas de respuesta',
            link: '/response-rate',
            checkRR: true,
            checkTabAccess: true,
            checkNonProfit: true,
        },
        {
            text: 'Salud',
            link: '/diagnose',
            checkHealthAccess: true,
        },
        {
            text: 'Inclusión',
            link: '/inclusion',
            checkIncAccess: true,
        },
        // {
        //     text: 'Recomendar',
        //     link: '/recommend',
        //     checkAccess: true,
        //     checkTabAccess: true,
        // },
        // {
        //     text: 'Tomar medidas',
        //     link: '/action',
        //     activeLink: true,
        //     checkAction: true,
        // },
        // {
        //     text: 'Analítica',
        //     link: '/gsod',
        //     checkGsodAccess: true,
        //     checkTabAccess: true,
        // },
        {
            text: 'Usuarios del informe',
            link: '/usersList',
            activeLink: true,
            checkUserPermission: true,
        },
    ],

    HEADER_NAV_2: [
        {
            text: 'Pantalla de inicio',
            link: '/',
        },
        {
            text: 'Tomar medidas',
            link: '/action',
            activeLink: true,
            checkAction: true,
        },
    ],

    HEADER_NAV_3: [
        {
            text: 'Pantalla de inicio',
            link: '/',
            id: 'home',
            activeLink: true,
            showBench: true,
        },
        {
            text: 'Analítica Library',
            link: '/gsod',
            id: 'gsod',
            checkGsodAccess: true,
        },
    ],
    SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$pink,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$green400,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$yellow200,
            text_color: colors.$white,
            borderWidth: 2,
        },
    ],
    SETTINGS_SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$pink,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$green400,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$yellow200,
            text_color: colors.$white,
            borderWidth: 2,
        },
    ],
    SIGNIFICANCES: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$significantlyPink,
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$significantlyGreen,
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Comparable',
            bg: colors.$comparableColor,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Sin significación estadística',
            bg: colors.$black,
            text_color: colors.$black,
            borderWidth: 2,
            checkSig: true,
            sup: '2,3',
        },
    ],
    SIGNIFICANCE_V2: [
        {
            title: 'Small Shift',
            bg: '#ccba9b',
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Large Shift',
            bg: '#948158',
            text_color: colors.$white,
            borderWidth: 2,
            sup: 1,
        },
        {
            title: 'Comparable',
            bg: colors.$comparableColor,
            text_color: colors.$white,
            borderWidth: 2,
        },
        {
            title: 'Sin significación estadística',
            bg: colors.$black,
            text_color: colors.$black,
            borderWidth: 2,
            checkSig: true,
            sup: '2,3',
        },
    ],
    SIGNIFICANCEv2: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
        },
        {
            title: 'Comparable',
            bg: colors.$practiceYellow,
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
        },
    ],
    GSOD_SIG: [
        {
            title: 'Diferenciador negativo',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
            toolTipText: 'Practice(s) that unhealthy organizations uniquely emphasize',
        },
        {
            title: 'Positive Differentiator',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
            toolTipText: 'Practice(s) that healthy organizations uniquely emphasize',
        },
        {
            title: 'Neutral',
            bg: '#b3b3b3',
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
            toolTipText: '',
        },
    ],
    QUESTION_SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            sup: 1,
            imgSrc: DownArrow,
        },
        {
            title: 'Considerablemente más fuerte',
            sup: 1,
            imgSrc: UpArrow,
        },
        {
            title: 'Comparable',
            imgSrc: SquareMinus,
        },
        {
            title: 'No statistical significance',
            checkSig: true,
            sup: 2,
            imgSrc: NoSig,
        },
    ],

    SCORECARD_RESURVEY_SIGNIFICANCE: [
        {
            title: 'Considerablemente más débil',
            bg: colors.$practiceRed,
            text_color: colors.$white,
            practice_color: colors.$practiceRed,
            text: 'weaker',
            borderWidth: 2,
            imgSrc: DownArrow,
            sup: 5,
        },
        {
            title: 'Considerablemente más fuerte',
            bg: colors.$practiceGreen,
            text_color: colors.$white,
            practice_color: colors.$practiceGreen,
            text: 'stronger',
            borderWidth: 2,
            imgSrc: UpArrow,
            sup: 5,
        },
        {
            title: 'Comparable',
            bg: colors.$practiceYellow,
            text_color: colors.$white,
            practice_color: colors.$grey600,
            text: 'comparable',
            borderWidth: 2,
            imgSrc: SquareMinus,
        },
    ],
    QUARTILE_MOVE_LEGEND_TXT: [
        'Shifted down to lower quartile',
        'Shifted up to higher quartile',
        'No quartile movement',
        'Comparison not available',
    ],
    QUARTILE_MOVE_LEGEND_TXT_IA: [
        'Shifted up to higher quartile',
        'Shifted down to lower quartile',
        'No quartile movement',
        'Comparison not available',
    ],
    CUSTOM_DELTA_LEGEND_TXT: ['más débil', 'mas fuerte', 'Comparable', 'Comparación no disponible'],
    customDiffLegends: (n = 'n') => {
        const is0 = parseInt(n, 10) === 0;
        if (is0) {
            return [
                `más débil (less than ${n})`,
                `mas fuerte (more than ${n})`,
                `Comparable (= ${n})`,
                'Comparación no disponible',
            ];
        }
        return [
            `más débil (less than -${n})`,
            `mas fuerte (more than ${n})`,
            `Comparable (between -${n} to ${n})`,
            'Comparación no disponible',
        ];
    },
    generateRanges: n => {
        const ranges = [
            { key: `less than -${n}`, values: [] },
            { key: `more than ${n}`, values: [] },
            { key: `between -${n} to ${n}`, values: [] },
        ];
        if (parseInt(n, 10) === 0) {
            return [
                { key: `less than ${n}`, values: [] },
                { key: `more than ${n}`, values: [] },
                { key: `= ${n}`, values: [] },
            ];
        }
        return ranges;
    },
    CUSTOM_DIFF_COLOR: ['#cd3030', '#10cbbc', '#e6d7bc', '#000000'],
    VALUES_BASED_LEGEND_TEXT: ['Decrease in score value', 'Increase in score value', 'No Change'],
    GAP_LEGEND_TEXT: ['Increase in gap value', 'Decrease in gap value', 'No Change'],
    QUARTILE_MOVE: 'Cambio de cuartil',
    CROSS_TABS: [
        {
            background: colors.$grey800,
            text: '',
        },
        {
            background: colors.$green800,
            text: 'Very high ( > 90% )',
        },
        {
            background: colors.$green900,
            text: 'Alto (70 % - 90 %)',
        },
        {
            background: colors.$orange500,
            text: 'Moderate ( 40% - 70% )',
        },
        {
            background: colors.$orange600,
            text: 'Low ( < 40% )',
        },
    ],
    QUEUE: ['queue', 'raw_ppt_generating'],
    SORTARR: [
        {
            label: 'Predeterminado',
            key: NONE,
        },
        {
            label: 'Tamaño N ascendente',
            key: 'nsize:asc',
        },
        {
            label: 'Tamaño N descendente',
            key: 'nsize:desc',
        },
        {
            label: 'Puntaje de salud ascendente',
            key: 'score:asc',
        },
        {
            label: 'Puntaje de salud descendente',
            key: 'score:desc',
        },
    ],
    SORTARR_EE: [
        {
            label: 'Predeterminado',
            key: NONE,
        },
        {
            label: 'Tamaño N ascendente',
            key: 'nsize:asc',
        },
        {
            label: 'Tamaño N descendente',
            key: 'nsize:desc',
        },
    ],

    CFG_CHOOSE_REPORT_OPTIONS: [
        {
            label: 'Informe de BU',
            key: '1',
        },
        {
            label: 'Tendencias de comparaciones de encuestas a través del tiempo',
            key: '3',
        },
    ],
    INCLUSION_DEFAULT_BENCHMARK: '1001',
    INCLUSION_DEFAULT_BENCHMARK_STR: '1001:10',
    ADDITIONAL_SETTING_OPTIONS: [
        {
            label: 'Descripción general de la encuesta',
            key: 'Descripción general de la encuesta',
        },
        {
            label: 'Sección de puntaje de salud',
            key: 'Sección de puntaje de salud',
        },
        {
            label: 'Priorización de prácticas',
            key: 'Practice priortization',
        },
        {
            label: 'Módulos adicionales',
            key: 'Módulos adicionales',
        },
        {
            label: 'Resultados por demográficos',
            key: 'Result by demographic',
        },
        {
            label: 'Resultados pregunta por pregunta',
            key: 'Question by question result',
        },
    ],
    COMPARISON_BUBBLES: [
        {
            label: 'Significancia estadística',
            key: '1',
            list_key: 'statistical significance',
        },
        {
            label: 'Quartile Movement',
            key: '2',
            list_key: 'Quartile movement',
        },
        {
            label: 'Delta personalizado',
            key: '3',
            list_key: 'Custom delta',
        },
    ],
    RESURVEY_DOWNLOAD_TYPE: [
        {
            label: 'Crear informe en PPT',
            key: 'PPT',
        },
        {
            label: 'Descargar Excel',
            key: 'Excel',
        },
    ],
    demoOptionsValue: thresholdVal => {
        return {
            option: [
                {
                    label: `Ocultar donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: `Reunir < ${thresholdVal} en la categoría "otros"`,
                    key: '1',
                },
            ],
            adminOption: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                // {
                //     label: `Mostrar dónde < ${thresholdVal} pero con -`,
                //     key: '-',
                // },
                // {
                //     label: 'Mostrar todos los puntajes',
                //     key: '0',
                // },
                {
                    label: `Reunir < ${thresholdVal} en la categoría "otros"`,
                    key: '1',
                },
            ],
            exOption: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
            ],
            inclusionDemoOptions: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
            ],
            dataCubeDemoOptions: [
                {
                    label: `Esconderse donde < ${thresholdVal}`,
                    key: `${thresholdVal}`,
                },
                {
                    label: CUSTOM,
                    key: 'custom',
                },
            ],
            filterArr: [
                {
                    label: 'Todos los encuestados',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],

            opModuleArr: [
                {
                    label: 'Todos los encuestados (con CRs del Modelo Operativo excluidos)',
                    key: '1',
                },

                {
                    label: 'Todos los encuestados (con CRs del Modelo Operativo incluidos)',
                    key: '2',
                },

                {
                    label: 'Solo encuestados del módulo de Modelo Operativo (con CRs del Modelo Operativo excluidos)',
                    key: '3',
                },

                {
                    label: 'Custom',
                    key: 'Custom',
                },
            ],
            heatMap: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            bubbleChartSort: [
                {
                    label: 'Ordenar por tamaño N descendente',
                    key: 'nsize',
                },
                {
                    label: 'Ordenar por puntaje descendente',
                    key: 'score',
                },
            ],
            practiceScoreDemographic: [
                {
                    label: 'All Demographics',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            bubbleChartOptions: [
                {
                    label: 'Establecer valor predeterminado',
                    key: 'All',
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            excludeOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            summaryOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            inclusionGapType: [
                {
                    label: 'Mayor brecha',
                    key: false,
                },
                {
                    label: 'Brecha promedio',
                    key: true,
                },
            ],
            rrDemographicOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            practiceRankingOptions: [
                {
                    label: 'Vista de lista: sin puntajes de prácticas',
                    key: false,
                },
                { label: 'con puntajes de práctica', key: true },
            ],
            empExpDemoOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            cohesionDemoOptions: [
                {
                    label: NONE_10,
                    key: NONE,
                },
                {
                    label: CUSTOM,
                    key: CUSTOM,
                },
            ],
            thomsonResFilters: [
                {
                    label: 'All respondents',
                    key: 'All',
                },
                {
                    label: 'Custom',
                    key: 'Custom',
                },
            ],
        };
    },
    ALL_PPT_TEXT: [
        'Lista de posibles prácticas que se deben priorizar según la ciencia detrás de',
        'No faltan prácticas en las 10 principales de la receta',
        'Sin Prácticas poderosas fallidas',
        'No hay prácticas con una brecha >10 para esta receta',
        'salud general y dimensiones vs.',
        'prácticas vs.',
        'Diferencia con',
        'salud general vs.',
        'Puntaje de salud general',
        'perfil de resultados vs.',
        'perfil de resultados',
        '1 - Los resultados estadísticamente significativos tuvieron valores p de < 0,05, lo que indica una alta probabilidad de que haya ocurrido un cambio/diferencia real. La significación estadística se basa en el número de encuestados y la magnitud de la diferencia de puntos',
        'Salud general y resultados a lo largo del tiempo',
        'Perfil de práctica a lo largo del tiempo',
        'Salud general a lo largo del tiempo',
        'Paso 2: Alineación de recetas a lo largo del tiempo',
        'Perfil de dimensiones a lo largo del tiempo',
        'perfil de dimensiones de la encuesta1',
        'Cambio en el perfil de práctica desde la última encuesta con cambio de significación estadística',
        'Mapa de calor de los puntajes de dimensiones de inclusión',
        'Mapa de calor de los puntajes de prácticas de Inclusión - Percepción de la empresa',
        'Mapa de calor de los puntajes de prácticas de Inclusión - Experiencia personal',
        'perfil de práctica',
        'TEXTO y explicación…',
        'Diferencia entre la organización y la mediana del benchmark, pp',
        'Comparaciones clave entre pares',
        'Question',
        'Puntaje de la pregunta1',
        'Para proteger la confidencialidad, no se muestran los resultados de grupos de <10 participantes.',
        'Cambio año tras año',
        'Puntajes y brechas de dimensiones de inclusión',
        'Puntajes y brechas de práctica de Inclusión',
    ],
    PPT_VALUES: ({ rName = '', buName = '', ques_Meta = '', threshold = 0 } = {}) => ({
        GLOBAL_VALS: {
            health: 'health',
            benchmark: 'Benchmark',
            legendTitle: 'Benchmark',
            txttopD: 'Decil superior',
            txttopQ: 'Cuartil superior',
            txt2nd: 'Segundo cuartil',
            txt3rd: 'Tercer cuartil',
            txtBottom: 'Cuartil inferior',
            source_txt: 'Fuente',
            no_of_survey_txt: 'no. surveys',
            legendTitlea: 'Diferencia',
            Strong: 'Considerablemente más fuerte',
            Comparable: 'Comparable',
            Weak: 'Considerablemente más débil',
            Strong_Q: 'Cambio positivo en el cuartil o decil superior',
            Comparable_Q: 'Sin cambio en el cuartil',
            Weak_Q: 'Cambio negativo en el cuartil',
            legendTitleDifference: 'Comparación',
            '4. Nota al pie': '1 Porcentaje de empleados',
            text_and_explanation: 'TEXTO y explicación…',
            strong_txt: 'Fuerte',
            percentle_specific_text:
                'La escala percentil indica el porcentaje de puntajes del Benchmark que están por debajo del puntaje de una dimensión o práctica determinados. Un puntaje porcentual más alto indica un ranking relativo comparado con el Benchmark.',
            favourable_specific_text:
                'La escala indica el porcentaje de encuestados de la organización que seleccionaron “de acuerdo” o “muy de acuerdo” para los resultados y “a menudo” o “casi siempre” para las prácticas de gestión.',
        },
        profile: {
            outcomeTitle: `${rName} outcome profile`,
            practiceTitle: `${rName} perfil de práctica`,
        },
        scorecard: {
            title: `${rName} scorecard`,
        },
        practiceBoard: {
            RANK_LABEL: `${rName} rank`,
            RANK_VS_LABEL: `${rName} rank vs. `,
        },
        benchmark: {
            title: `${rName} results`,
        },
        thresholdTip: `ningún grupo menor a ${threshold} Con el fin de preservar la confidencialidad de los encuestados, no se reflejará.`,
        highlevel: {
            'Título 1': 'Objetivos generales para llevar cada práctica al siguiente cuartil',
            txttop: 'Aumento de puntos necesario para alcanzar el siguiente cuartil',
        },
        practice_profile_bu_checkme: {
            Title_text: `${rName}'s perfil de práctica vs. ${buName}`,
        },
        questionsMeta: {
            outcome_title: `${ques_Meta} outcome questions1`,
            practice_title: `${ques_Meta} preguntas por práctica1`,
            qyq_out_legend: 'Outcomes:',
            qbyq_disagree: 'En desacuerdo',
            qbyq_neutral: 'Neutral',
            qbyq_agree: 'De acuerdo',
            qyq_prac_legend: 'Practices:',
            qbyq_inrequently: 'Con poca frecuencia',
            qbyq_sometimes: 'A veces',
            qbyq_frequently: 'Con frecuencia',
            '4. Nota al pie': '1 Porcentaje de empleados',
        },
        comparisonMeta: {
            legendTitleab: 'Comparación en el tiempo:',
            txttopb: 'Significantly Stronger<sup>1</sup>',
            txt3rdb: 'Significally weaker<sup>1</sup>',
        },
        Recipe_checkme_1: {
            companyshape: `Diferencia de ranking de ${rName} vs. el ranking de la receta`,
            '3. Subtítulo': 'Pasos 3 y 4',
            'Forma automática 250':
                'Diferencia en el orden de ranking de las prácticas entre la organización y la receta.',
            'Rectángulo 2': 'Receta',
        },
        Recipe_checkme_second: {
            BPP_text: 'Prácticas poderosas en el cuartil inferior',
            G_text: 'Practices with a gap of >10 to this recipe',
            M_text: 'Prácticas que faltan dentro de las 10 más enfatizadas de esta receta',
            'Rectángulo 40': 'Las prácticas destacadas son aquellas que se repiten en los cuadros de prioridades.',
            '4. Nota al pie': 'La lista de prácticas no es exhaustiva.',
            'Rectángulo 28a': '1.ra',
            'Rectángulo 28b': '2.da',
            'Rectángulo 28c': '3.ra',
        },
        cdvalues_checkme: {
            Title_text: `${rName} valores`,
            AutoShape250b1: 'Valores actuales',
            AutoShape250c1: 'Lo que somos hoy',
            AutoShape250d1: 'Valores actuales y deseados',
            AutoShape250b2: 'Lo que nos gustaría continuar',
            AutoShape250c2: 'Valores deseados',
            AutoShape250d2: 'Lo que nos gustaría ser',
            txt3rd1: 'Detractores de la salud organizacional',
            txt3rd2: 'valores que tienen una correlación negativa con la salud general',
            txtBottom1: 'Potenciadores de la salud organizacional',
            txtBottom2: 'valores que tienen la correlación positiva más sólida con la salud general',
        },
        resurvey_recipealignment_checkme: {
            '3. Subtítulo': 'Paso 2',
            'Forma automática 250': 'Similitud de recetas según el ranking de las prácticas relativa actual1',
            rcpShp1a: 'Fábrica de líderes',
            rcpShp1b:
                'Impulsar el desempeño mediante el desarrollo y la asignación de líderes sólidos, que cuenten con apoyo de coaching, capacitación formal y oportunidades adecuadas de crecimiento.',
            rcpShp2a: 'Forjador de mercado',
            rcpShp2b:
                'Avanzar a través de la innovación en todos los niveles y de la rápida operacionalización basándose en un profundo conocimiento tanto de los clientes como de la competencia.',
            rcpShp3a: 'Ventaja de ejecución',
            rcpShp3b:
                'Obtener una ventaja competitiva mediante la participación de todos los empleados en la promoción del desempeño y la innovación, en la recopilación de ideas y en el intercambio de conocimientos.',
            rcpShp4a: 'Centro de talento y conocimiento',
            rcpShp4b: 'Crear valor atrayendo e inspirando a los mejores talentos.',
            '4. Nota al pie':
                '1 La similitud de recetas es la correlación entre la receta del cliente y la receta ideal, multiplicada por 10.',
            rcpScale1: 'No alignment',
            rcpScale2: 'Fuerte',
            rcpScale3: 'Muy fuerte',
            Title_text: 'Paso 2: Alineación de recetas a lo largo del tiempo',
        },
    }),
    DYNAMIC_TEXT_INFO: ({ ohi_text = 'OHI' } = {}) => ({
        // OHI_SCORE_TEXT: `${ohi_text} Score`,
        OHI_SCORE_TEXT: `Puntaje de ${ohi_text}`,
        OHI_SCORE_TEXT_PPT: `${ohi_text} Score`,
        REPORT_TYPE: [
            `${ohi_text} Informe empresarial`,
            `${ohi_text} BU Informe`,
            'Informe solo de datos',
            `${ohi_text} Comparativo a través del tiempo de Salud`,
            'Informe empresarial de Inclusión',
            'cubo de datos',
            'Informe comparativo a través del tiempo de Inclusión',
            'Informe empresarial de Experiencia del empleado',
            `${ohi_text} Influyente`,
            'Módulo de modelo operativo excel',
        ],
        CHOOSE_REPORT_OPTIONS: [
            { label: `${ohi_text} Informe empresarial`, key: '0' },
            { label: `${ohi_text} BU Informe`, key: '1' },
            { label: `${ohi_text} Comparativo a través del tiempo de Salud`, key: '3' },
            { label: `${ohi_text} Influyente`, key: '8', checkInfluencer: true },
            { label: 'Informe empresarial de Experiencia del empleado', key: '7', checkExAccess: true },
            { label: 'Informe empresarial de Inclusión', key: '4', checkIncAccess: true },
            {
                label: 'Informe comparativo a través del tiempo de Inclusión',
                key: '6',
                checkIncAccess: true,
                checkIAResurveyAccess: true,
            },
            { label: 'cubo de datos', key: '5', checkCubeAccess: true },
            { label: `Módulo de modelo operativo excel`, key: '9', checkOpAccess: true },
        ],

        THOMSON_REPORT_OPTIONS: [
            { label: `${ohi_text} Informe empresarial`, key: '0' },
            { label: `${ohi_text} BU Informe`, key: '1' },
            { label: `${ohi_text} Comparativo a través del tiempo de Salud`, key: '3' },
            { label: 'cubo de datos', key: '5', checkCubeAccess: true },
        ],
        ING_REPORT_OPTIONS: [
            { label: `${ohi_text} BU Informe`, key: '1', checkYear: true },
            { label: `${ohi_text} Comparativo a través del tiempo de Salud`, key: '3' },
            { label: 'cubo de datos', key: '5', checkCubeAccess: true },
        ],
        AMMEGA_REPORT_OPTIONS: [{ label: `${ohi_text} Comparativo a través del tiempo de Salud`, key: '3' }],
    }),
    ROLES_META: {
        'Roles.user': 'Usuario',
        'Roles.cst': 'CST',
        'Roles.client': 'Cliente',
    },
    COLOR_TEXT: 'Color',
    GREEN_TEXT: 'Verde',
    BLUE_TEXT: 'Azul',
    PASTREPORT_TABLE_HEADING: [
        {
            id: 'export_name',
            numeric: false,
            disablePadding: false,
            label: 'Nombre del informe',
            width: '',
        },
        {
            id: 'export_type',
            numeric: false,
            disablePadding: false,
            label: 'Tipo de informe',
            width: '16%',
        },
        {
            id: 'created_on',
            numeric: false,
            disablePadding: false,
            label: 'Fecha',
            width: '16%',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Estado',
            width: '16%',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Acción',
            width: '16%',
        },
    ],
    DEMOGRAPHIC_EE_THRESHOLD: 'Umbral de demográficos de Experiencia del empleado',
    CHOOSE_DEMOGRAPHICS: 'Elija los demográficos',
    HEALTH_COHESION_BOARD: 'Cohesión de Salud',
    TOP_RANKING: 'Ranking más alto',
    MIDDLE_RANKING: 'Ranking medio',
    BOTTOM_RANKING: 'Ranking inferior',
    RANK: 'Ranking',
    SCORE: 'Puntaje',
    MAIN_GROUP: 'Grupo principal',
    COMPARISON_GROUP: 'Grupo de comparación',
    ORGANIZATION: 'Organización',
    EX_BENCHMARK: 'EX Benchmark',
    COMPARISON_SURVEY: 'Encuesta de comparación',
    RECIPE_TEXT: 'Receta',
    DOWNLOADING_TEXT: {
        '1': 'Descargado',
        '2': 'Descargando',
        '3': 'Su informe se está preparando para la descarga',
        '4':
            'También puede ver el informe listo en la sección de informes anteriores, debajo del llamado a la acción de los informes en la barra de navegación superior.',
        '5': 'Descargar',
    },
    ERROR: 'ERROR',
    INFO_MODAL_TEXT: {
        '1': '¿Cómo se calculan las brechas de Inclusión?',
        '2': 'Calcule los puntajes de inclusión para cada opción en cada grupo demográfico de la encuesta',
        '3':
            'Calcule la brecha “más grande” en los puntajes de Inclusión entre todas las opciones de un grupo demográfico',
        '4': 'Identifique las “más altas” entre todas las brechas “más grandes” entre los grupos demográficos',
        '5': 'Repita los pasos anteriores para cada resultado y práctica.',
        '6': 'Cómo reducir los cálculos de brecha',
        '7': 'Excluya algunos demográficos del cálculo de brecha, o',
        '8': 'Aplique algún filtro demográfico',
    },
    CLEAR_ALL: 'Borrar todo',
    GLOBAL: 'Global',
    HEALTH_BENCHMARK_BOARD: 'Benchmark de Salud',
    ANALYTIC_FLAGPOLE_TEXT: {
        '1': 'Análisis de comparación con otros benchmarks de referencia',
        '2': 'Comparativo con otros benchmarks de referencia',
        '3': 'Los colores de los cuartiles se basan en el Benchmark global',
        '4': 'Median score',
        '5': 'Diferencia media de puntos',
        '6': 'con respecto al Benchmark global',
    },
    ANALYTIC_CUSTOM_BM_TEXT: {
        '1': 'Analytics Custom Benchmark',
        '2': 'Custom Benchmark',
        '3': 'Quartile coloring is based on comparison of median scores to global benchmark',
        '4': 'Median score',
        '5': 'Median Point Difference',
        '6': 'to Global benchmark',
    },
    OVERVIEW_PAGE_TEXT: {
        '1': 'Overview',
        '2': 'Vista rápida de los datos de Analítica de OHI',
        '3': 'Núm. de encuestas',
        '4': 'No. of Surveys',
        '5': 'Núm. de encuestados',
        '6': 'Número de encuestas',
        '7': 'Top Quartile',
        '8': 'Second Quartile',
        '9': 'Third Quartile',
        '10': 'Bottom Quatile',
    },
    GSOD_FLAGPOLE_NAME: 'Specified Sample’s Median Score',
    GSOD_FLAGPOLE_TEXT: 'Median Score',
    GSOD_FLAGPOLE_TEXT_1: 'Median Point Difference',
    GSOD_FLAGPOLE_TEXT_2: 'con respecto al Benchmark global',
    PRACTICE_RANK_PAGE_TEXT: {
        // '1': 'Clasificación de cuartil superior',
        // '2': 'Clasificación de cuartil inferior',
        '1': 'Healthy organizations rank',
        '2': 'Unhealthy organizations rank',
        '3': 'Diferencia entre ranking',
        '4': 'Clasificación general de práctica',
        '5': 'Ranking de prácticass de analítica',
        '6': 'Cuartil superior, n = ',
        '7': 'Bottom quartile, n = ',
        '8': 'Top and Second quartile, n = ',
        '9': 'Third and Bottom quartile, n = ',
    },
    TOOLTIP_PAGE_TEXT: {
        '1': 'Organizations falling in top quartile of selected sample',
        '2': 'Organizations falling in bottom quartile of selected sample',
        '3': 'Organizations falling in top and second quartile of selected sample',
        '4': 'Organizations falling in third and bottom quartile of selected sample',
    },
    SCORECARD_PAGE_TEXT: {
        '1': 'Tabla de GSOD',
        '2': 'Puntuaciones medias del Benchmark',
        '3': 'Colores de cuartiles basados en el Benchmark global',
    },
    ADDONS_PAGE_TEXT: {
        '1': 'Salud',
        '2': 'Comentarios',
        '3': 'Valores',
        '4': 'Tablero',
        '5': 'ING, Índice de diversidad, inclusión y pertenencia',
    },
    EXCEL_TEXT: {
        '1': 'ING, Índice de diversidad, inclusión y pertenencia',
        '2': 'Texto de la pregunta no disponible',
    },
    RESURVEY_BOARD_PAGE: {
        '1': 'Comparison HeatMap Version',
        '2': 'Demográficos de la encuesta anterior',
        '3': 'Mapas de calor',
    },
    EMPLYEE_EXPEIENCE: 'Experiencia del empleado',
    QUESTION_SCORE: 'Puntuación de la pregunta',
    IND_EXP_SCORE_PAGE_TEXT_NOTES: 'Desde 0=Extremadamente improbable hasta 10=Extremadamente probable',
    EMPLOYEE_NET_PROMOTER_SCORE: 'Puntaje neta del promotor del empleado',
    EMPLOYEE_FACTORS_POSITIVE: 'Factores de experiencia (positivos)',
    EMPLOYEE_FACTORS_NEGATIVE: 'Factores de experiencia (negativos)',
    RESPONDENTS_WHO_RESPONDED: 'Los encuestados que respondieron',
    PERCEIVE_THE_ORGANIZATION_AS: 'perciben la organización como',
    HEALTHY: 'Saludable',
    UNHEALTHY: 'No saludable',
    EXRTREMELY_LIKELY: 'Muy probable',
    AGREE_OR_HIGHER: '(De acuerdo) o superior',
    DONT_KNOW_NA: 'No lo sé /NA',
    OF_TOTAL_NUMBER_OF_RESPONDENTS: 'Del número total de encuestados',
    AGREEMENT_LEVEL_IN_PERCENTAGE: 'Nivel de acuerdo en porcentaje',
    HEALTH_PRACTICE_RANKING: 'Ranking de prácticas de salud',
    INCLUSION_PRACTICE_RANKING: 'Ranking de prácticass de Inclusión',
    BU_COMPARISION: 'Comparación de BU',
    SHOW_TOP_12_BU_RANK: 'Mostrar las 12 clasificaciones principales de BU',
    ORGANISATION_RANK_VS: 'Organisation rank vs',
    RANKING: 'Categoría',
    GRAPH_DIFFERENCE: 'Diferencia de Gráfica',
    GSOD_RANKING: 'Ranking general de práctica',
    GSOD_DIFFERENCE: 'Rank Difference',
    HEALTH_PROFILE_PAGE: 'Página de perfil de salud',
    HEALTH_QUESTIONS_BOARD: 'Tablero de preguntas de Salud',
    EMPLOYEE_EXPERIENCE_QUESTIONS: 'Preguntas de Experiencia del Empleado',
    VIEW_EMPLOYEE_EXPERIENCE_QUESTIONS: 'VIEW EMPLOYEE EXPERIENCE QUESTIONS',
    POINT_INCREASE_RREQUIRED_TO_ACHIVE_TOP_QUARTILE: 'Aumento de puntos necesarios para alcanzar el cuartil superior',
    FEEDBACK: 'Retroalimentación',
    REPORT_FEEDBACK: 'Retroalimentación del informe',
    REPORT_ISSUE: 'Reportar un problema',
    REPORT_FEEDBACK_LIST_TEXT:
        'Un problema es cuando la funcionalidad no opera como se espera. Por ejemplo, al ordenar las unidades de negocio de mayor a menor, los tamaños asociados con cada unidad de negocio no se reordenan en consecuencia. Ingresar todos los detalles clave en este formulario ayuda a asegurar que los problemas se gestionen, asignen y resuelvan de manera oportuna.',
    TYPE_BUG: 'Tipo de error',
    FEEDBACK_TEXT_ISSUE: 'Escriba una descripción clara y concisa del problema',
    FEEDBACK_CATEGORY_TEXT:
        'Categorizar el problema permite al equipo de Producto identificar áreas críticas de mejora, extraer ideas y planificar la capacidad. Problema de datos / Error de cálculo / Problema de PPT / Cualquier otro (campo de entrada del usuario).',
    DESCRIBE_ISSUE: 'Describa el problema',
    PROBLEM_TEXT:
        'Describa el problema o inconveniente que ocurrió cuando se encontró el error. Proporcione pasos detallados para replicar el problema. Esto ayudará al equipo de desarrollo a entender y reproducir el problema.',
    ADD_IMAGE: 'Agregar imagen',
    UPLOAD_IMAGE: 'Cargar imagen',
    IMG_INSERT: 'Incluya capturas de pantalla relevantes (jpeg, png), hasta 4 imágenes o 5 mb.',
    CLIENT_TEXT: '¿Habrá un impacto inmediato en la capacidad de atender al cliente?',
    TITLE_ISSUE_TEXT: 'Ingrese el título del problema',
    BUG_TYPE_TEXT: 'Seleccione el tipo de error',
    WORKAROUND_TEXT: '¿Hay una solución temporal que pueda utilizar por ahora?',
    SYSTEM_DETAIL: 'Detalles del sistema',
    DATE_AND_TIME: 'Fecha y hora',
    REPORT_OHI: 'Informe OHI ID',
    SEND_FEEDBACK: 'Enviar retroalimentación',
    FEEDBACK_TEXTS: 'Retroalimentación',
    NO_COMPLETE_TASK: 'No hay tickets completados',
    ENTER_FEEDBACK: 'Ingrese su retroalimentación',
    BUILD_REPORT_OPTION: [
        {
            label: 'Introducción a la salud organizacional',
            key: 'Intro_Contents',
            disabled_ids: [],
        },
        { label: 'Resultados generales de OHI', key: 'Scorecard_Contents', disabled_ids: [] },
        {
            label: 'Análisis detallado de Salud organizacional',
            children: [
                { label: 'Cohesión', key: 'Cohesion_Contents', disabled_ids: [1] },
                {
                    label: 'Ranking de prácticas y sus puntajes',
                    key: 'Practice_rank_score_Contents',
                    disabled_ids: [],
                },
                { label: 'Puntajes de dimensiones', key: 'Outcome_Scores_Contents', disabled_ids: [] },
                { label: 'Puntaje de prácticas', key: 'Practice_Scores_Contents', disabled_ids: [] },
                {
                    label: ' Prácticas de poder y recetas',
                    key: 'Power_practice_recipie_Contents',
                    disabled_ids: [1],
                },
                { label: 'Benchmarks', key: 'Benchmarks_Contents', disabled_ids: [] },
                {
                    label: 'Mapas de calor a nivel demográfico',
                    key: 'Demographic_heatmap_Contents',
                    disabled_ids: [],
                },
            ],
        },
        {
            label: 'Análisis detallado',
            children: [
                { label: 'Experiencia del empleado', key: 'Employee_Experience_Contents', disabled_ids: [] },
                {
                    label: 'Datos demográficos de la flexibilidad del lugar de trabajo',
                    key: 'Workforce_flexibility_Contents',
                    disabled_ids: [],
                },
                { label: 'Valores organizacionales', key: 'Org_values_Contents', disabled_ids: [] },
            ],
        },
        { label: 'Resultados a nivel de pregunta', key: 'QbyQ_Contents', disabled_ids: [] },
        { label: 'Estadísticas de tasa de respuesta', key: 'Resp_rate_Contents', disabled_ids: [] },
        { label: 'Definiciones', key: 'Definitions_Contents', disabled_ids: [] },
    ],
    FEEDBACK_LIST_TABLE_HEADING: [
        {
            id: 'created_on',
            label: 'Creado el',
        },
        {
            id: 'ticket_no',
            label: 'Número de ticket',
        },
        {
            id: 'summary',
            label: 'Resumen',
        },
        {
            id: 'issue_type',
            label: 'Tipo',
        },
        {
            id: 'status',
            label: 'Estado',
        },
        {
            id: 'action',
            label: 'Acción',
        },
    ],
    IMMEDIATE_IMPACT: [
        {
            label: 'Sí, un impacto inmediato',
            key: 'yes',
        },
        {
            label: 'No',
            key: 'no',
        },
    ],
    IS_WORKAROUND_OPT: [
        {
            label: 'Si',
            key: 'yes',
        },
        {
            label: 'No',
            key: 'no',
        },
    ],
    RES_FOOT_ARR: [
        {
            name: 'Footnote_value_2_0',
            is_delete: true,
        },
        {
            name: 'Footnote_quart_2_0',
            is_delete: true,
        },
        {
            name: 'Footnote_value_2_1',
            is_delete: true,
        },
        {
            name: 'Footnote_quart_2_1',
            is_delete: true,
        },
    ],
    COHESION_HEAD: 'What is cultural cohesion?',
    SHOW_SCORE_TOOLTIP: 'Scores will not be available on the page downloaded report',
    BENCHMARK_FILTERS: 'Filtros de benchmarks',
    DEMO_SELECTED_OPTIONS: 'Opción de demográfico seleccionado',
    DWNLD_INFO_TEXT_1: 'Descargando múltiples informes',
    COMPARISON_BU_PLACEHOLDER: 'Escriba el nombre que desea que se use para la BU de comparación',
    IA: 'Evaluación de Inclusión',
    H_TEXT: 'A',
    L_TEXT: 'B',
    REPORT_DOWNLOAD_TXT: rNameToDwnld => `Su informe "${rNameToDwnld}" está listo para la descarga`,
};

export const FILTER_LIST_RANGE = 7;
export const INCLUSION_DEFAULT_BENCHMARK = '1001';
export const INCLUSION_DEFAULT_BENCHMARK_STR = '1001:10';
export const ENTERGY_OHID = 'OHI14740';
export const ENTERGY_OHID_2 = 'OHI15214';

export const BPP_LIST = {
    strategic_clarity: 1,
    role_clarity: 2,
    competitive_insights: 3,
    personal_ownership: 4,
};

export const NON_PROFIT_SURVEY = 'non-profit';
export const TR_OHID_2024 = 'OHI14647';
export const OHI4_IND_EXP = 'individual_experience';
export const OHI4_RESIDUAL = ['new_ohi_practices', 'skip_adding_ohi_4_0_questions'];

export const USER_ROLES = {
    SUPER_ADMIN: 'Roles.sa',
    ADMIN: 'Roles.admin',
    COACH: 'Roles.coach',
    CLIENT: 'Roles.client',
    CST: 'Roles.cst',
    USER: 'Roles.user',
};

export const TEXT_COMMENTS_KEYS = ['open_ThreeWords', 'open_Strengths', 'open_Weaknesses', 'open_Thoughts'];

export const API_DUMMY_PARAMS = {
    ohid: 'OHI011119',
    lang: 1033,
    year: 2020,
    threshold: 10,
    bm_filters: ['1001:1'],
    filters: [],
    page: 1,
    columns: 50,
    type: '',
    demographic: '',
    benchmarks: '',
    report_type: '',
    is_top_quartile: 0,
};

export const INC_DUMMY_PARAMS = {
    ...API_DUMMY_PARAMS,
    with_benchmark: true,
    benchmarks: '1001:10',
};

export const ING_REPORT_ID = '102428';
export const AMMEGA_OHI_ID = 'OHI11817';
export const THOMSON_OHI_ID = 'OHI13191';
export const TALX_REPORT_ID = '100068';
export const HYBRID_QUES_KEY = 'q_hybrid';
export const EXCLUDE_QUES_KEY = 'qvitality';
export const ING_PREV_SURVEY_EXC_QUES = ['q_incl_fair', 'q_incl_bel', 'q_incl_div', 'q_incl_psi'];
export const ING_CURR_OHID = 'OHI14084';
export const ING_RECENT_OHID = 'OHI14401';
export const ENTERGY_REPORT_ID = '100060';

export const INCLUSION_BENCHMARK = [
    {
        code: -1,
        label: 'Clasificación estandarizada (colores de semáforo)',
        options: [{ code: -1, label: 'Clasificación estandarizada (colores de semáforo)' }],
        selectedOption: '',
    },
];

export const CFG_REPORT_ID = '100054';
export const NPS_CODE = 'AddQ1_AddQ1_1';
export const CURRENT_YEAR = '2024';
export const SLICE_VALUE = 30;
export const BLANCO_DEFAULT_DEMO = 'demo_Engageclassify';
export const BANCO_REPORT_ID = 101194;
export const IND_EXP_SCORE_CLASSIFICATION = [
    {
        title: 'Muy Alto (+80)',
        positiveBg: colors.$indExpColor[1][0],
        negativeBg: colors.$indExpColor[1][1],
        color: '#ffffff',
        titleNeg: 'Muy Alto (+40)',
    },
    {
        title: 'Alto (70-79)',
        positiveBg: colors.$indExpColor[2][0],
        negativeBg: colors.$indExpColor[2][1],
        color: '#ffffff',
        titleNeg: 'Alto (30-39)',
    },
    {
        title: 'Moderado (60-69)',
        positiveBg: colors.$indExpColor[3][0],
        negativeBg: colors.$indExpColor[3][1],
        color: '#000000',
        titleNeg: 'Moderado (20-29)',
    },
    {
        title: 'Bajo (50-59)',
        positiveBg: colors.$indExpColor[4][0],
        negativeBg: colors.$indExpColor[4][1],
        color: '#000000',
        titleNeg: 'Bajo (10-19)',
    },
    {
        title: 'Muy Bajo (<50)',
        positiveBg: colors.$indExpColor[5][0],
        negativeBg: colors.$indExpColor[5][1],
        color: '#000000',
        titleNeg: 'Muy Bajo (<10)',
    },
];

export const QUARTILE_COLORS = [
    {
        background: '#061F79',
        border: '#FFA800',
        color: '#ffffff',
        title: 'Top decile',
        top_position: '19000',
    },
    {
        background: '#061F79',
        border: '#061F79',
        color: '#ffffff',
        title: 'Top quartile',
        top_position: '23500',
    },

    {
        background: '#2251FF',
        border: '#2251FF',
        color: '#ffffff',
        title: 'Segundo cuartil',
        top_position: '31000',
    },

    {
        background: '#99C4FF',
        border: '#99C4FF',
        color: '#000000',
        title: 'Tercer cuartil',
        top_position: '41000',
    },

    {
        background: '#CCCCCC',
        border: '#CCCCCC',
        color: '#000000',
        title: 'Bottom quartile',
        top_position: '51000',
    },

    {
        background: '#CCCCCC',
        border: '#757575',
        color: '#ffffff',
        title: 'Bottom decile',
        top_position: '19000',
    },
];
export const NEG_IND_QUES = ['burnout', 'intent_to_leave'];
export const QUES_LEGEND_TYPE = {
    burnout: 3,
    intent_to_leave: 3,
    endorsing_organization_to_others: 2,
};

export const QUES_LEGEND_TYPE_HEATMAP = {
    'Agotamiento laboral': 3,
    'Intención de irse': 3,
    'Recomendar a la organización ante otros': 2,
    eNPS: 2,
};

export const QUES_LEGEND_TYPE_DEEP_DIVE = {
    Burnout: 2,
    'Intent to Leave': 2,
};

export const DEEP_DIVE_TYPE = {
    'I plan to leave the organization in the next 6 months': 3,
    'How likely are you to recommend working at the organization to friends and relatives?': 2,
};

export const DEEP_DIVE_TYPE_EX = {
    'I plan to leave the organization in the next 6 months': 2,
};

export const PRACTICE_RANKING_FILTER = {
    0: [
        'career_opportunities',
        'challenging_leadership',
        'financial_incentives',
        'financial_management',
        'open_and_trusting',
        'performance_goals',
        'personal_ownership',
        'risk_management',
        'shared_vision',
        'supportive_leadership',
    ],
    1: [
        'business_partnerships',
        'capturing_external_ideas',
        'competitive_insights',
        'customer_orientation',
        'employee_innovation',
        'government_industry_relations',
        'operational_management',
        'operationally_disciplined',
        'role_clarity',
        'top_down_innovation',
    ],
    2: [
        'capturing_external_ideas',
        'common_purpose',
        'consequence_management',
        'employee_innovation',
        'financial_incentives',
        'knowledge_sharing',
        'performance_transparency',
        'rewards_recognition',
        'role_clarity',
        'top_down_innovation',
    ],
    3: [
        'career_opportunities',
        'challenging_leadership',
        'inspirational_leaders',
        'knowledge_sharing',
        'meaningful_values',
        'people_performance_review',
        'personal_ownership',
        'rewards_recognition',
        'talent_acquisition',
        'talent_development',
    ],
};

export const SINGLE_PAGE_EXPORT_PARAMS = ({ BENCHMARK_NAME = 'Global', EX_BENCHMARK_NAME = 'Global' }) => ({
    others: 0,
    level: 1,
    export_type: 'single_page',
    report_type: 'favorable',
    need_tornado_chart: false,
    resource: 'ohi',
    resurveyDownloadType: 'ppt',
    n_size: 10,
    outcomePptVersion: '1',
    fill_threshold_with: '',
    report_name: 'Single_Page',
    comparison_heatmap: false,
    sort_by: '',
    practicePptVersion: '1',
    ingDemographicFilters: [],
    score_depth: 3,
    summaryOpt: NONE,
    summaryValue: NONE,
    bubbleChartValue: NONE,
    deepDiveValue: NONE,
    rrDemographics: NONE,
    empExpDemographics: NONE,
    cohesionDemographics: NONE,
    summary_demographic: {},
    includeDelta: false,
    unmapped_demos: {},
    bu_comparison_names: '',
    bu_reportname: '',
    options: {
        TopDecileBorder: '#FFD700',
        BU_FilterSummary: '',
        IsPercentile: false,
        TopDecileToggle: true,
        TopQuartileColor: '#002960',
        reportLanguage: '1033',
        SecondQuartileColor: '#128FA7',
        BENCHMARK_NAME,
        EX_BENCHMARK_NAME,
        SURVEYSENT_RR: '3321||98%',
        FilterSummary: '',
        TopDecileColor: '#002960',
        ReportType: '0',
        BottomQuartileColor: '#D9D9D9',
        flagpole_footer: [
            { bmcode: '', bmtext: '' },
            { bmcode: '', bmtext: '' },
        ],
        ThirdQuartileColor: '#9FB9BD',
        leftBenchmarks: 'Global',
        rightBenchmarks: 'Global',
        pss_module: 0,
        engagement: null,
        non_profit_report_full: '1',
        Resurvey_SigVsQuartile: '1',
        survey_names: '',
        resurvey_custom_delta: '',
        org_level_filter: '0',
    },
    heatmap_demographic: {},
    response_rate_demographic: {},
    employee_experience_demographic: {},
    cohesion_demographic: [],
    ohi4_incModules: [],
    ohi4_incDemographics: {},
});

export const RESURVEY_EXPORT_PARAMS = {
    report_name: 'Resurvey_Scorecard',
    export_type: 'single_page',
    options: {
        Resurvey_footer_BM: '',
        Resurvey_SigVsQuartile: '1',
        Resurvey_IsResurvey: 'TRUE',
        reportLanguage: "'1033'",
        ReportType: '3',
        Resurvey_Stats: '2023$3321$3269',
        SURVEYSENT_RR: '3321||98%',
        BU_FilterSummary: '',
        IngBuFilterSummary: '',
        survey_names: 'Prev $#$ Current',
        pss_module: 0,
        engagement: null,
        FilterSummary: '',
        includeDelta: false,
        unmapped_demos: {},
        resurvey_custom_delta: '',
    },
    heatmap_demographic: {},
    comparison_names: 'm',
    PRIORITY_PRAC_ORDER: 'DELETE',
    BENCHMARK_NAME: 'Global',
    practicePptVersion: '1',
    outcomePptVersion: '1',
    comparison_heatmap: true,
    bu_comparison_names: '',
    bu_reportname: '',
    bu_Filters: [],
    sort_by: '',
    others: 0,
};

export const PRACTICE_RANKING_DESCRIPTION = {
    'Alineación con la Receta A (Fábrica de líderes)':
        'Impulsar el desempeño mediante el desarrollo y la asignación de líderes sólidos, apoyados por coaching, capacitación y oportunidades de crecimiento.',
    'Alineación con la Receta B (Forjador de mercado)':
        'Avanzar a través de la innovación en todos los niveles y lograr un entendimiento profundo de los clientes y los competidores.',
    'Alineación con la Receta C (Motor de ejecución)':
        'Desarrollar una ventaja competitiva mediante la participación de todos los empleados en la promoción del desempeño y la innovación, en la recopilación de ideas y en el intercambio de conocimientos.',
    'Recipe D (Núcleo de talento y conocimiento) alignment':
        'Crear valor atrayendo e inspirando a los mejores talentos.',
};

export const QUESTION_REPLACEMENTS = {
    QbyQ_coordination_control_4_0: ['pss_1_QbyQ_coordination_control_4_0', 'pss_2_QbyQ_coordination_control_4_0'],
    QbyQ_capabilities_4_0: ['pss_QbyQ_capabilities_4_0'],
    QbyQ_accountability_4_0: ['pss_QbyQ_accountability_4_0'],
    QbyQ_work_environment_4_0: ['pss_QbyQ_work_environment_4_0'],
};

export const PROFILE_REPLACEMENTS = {
    practice_profile_checkme_4_0: ['pss_practice_profile_checkme_4_0'],
    Practice_rank_alignment_4_0: ['pss_Practice_rank_alignment_4_0'],
    Practice_rank_execution_4_0: ['pss_Practice_rank_execution_4_0'],
    Practice_rank_renewal_4_0: ['pss_Practice_rank_renewal_4_0'],
};

export const SCORECARD_REPLACEMENTS = {
    Scorecard_4_0: ['pss_Scorecard_4_0'],
};

export const ING_OVERALL_HEALTH = [
    {
        name: `OVERALL_HEALTHGRP04`,
        is_delete: true,
    },
    {
        name: 'OVERALL_HEALTHOVAL03',
        is_delete: true,
    },
];

// new
export const DEMOGRAPHIC_OUTCOME_CARD = [
    {
        title: 'Download Ppt',
        imgSrc: Pdf,
    },
    {
        title: 'Excel Download',
        imgSrc: Pdf,
    },
];

export const RESURVEY_DEMO_CONTENT = [
    {
        title: 'Solo puntuaciones y delta del año actual (Versión-1)',
        key: 'Descargar PPT versión 1',
    },
    {
        title: 'Puntajes y deltas de ambos años: tablas separadas (Versión-2)',
        key: 'Descargar PPT versión 2',
    },
    {
        title: 'Puntajes y deltas de ambos años: misma tabla (Versión 3)',
        key: 'Descargar PPT versión 3',
    },
];
