import React from 'react';
import PropTypes from 'prop-types';
// import c from 'classnames';
import { Helmet } from 'react-helmet';
import { cloneDeep } from 'lodash';
// import { ArrowForward } from '@material-ui/icons';
import AuthStore from '../../../common/AuthStore';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
// import Differentiator from './DifferentiatorAnalysis';
import PracticeRanking from './PracticeRanking';
import Down from '../../Diagnose/assets/Down.svg';
import { getFilterName, getFilterArrayName } from '../assets/functions';
// import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import WarningModal from '../Intro/WarningModal';
// new

function PracticeRankingBoard({
    toggleFilterView,
    defaultSettings,
    getRanking,
    rankingFetched,
    rankingData,
    // getDifferential,
    // differentialDataFetched,
    differentialData,
    renderfilterApplied,
    filterParams,
    // setDifferentialData,
    tab,
    // setTab,
    rankingError,
    // differentialError,
    getPptData,
    filtersApplied,
    filters,
    defaultYear,
    dataAtGlance,
    // new
    warningModal,
    setWarningModal,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        // ANALYTICS_RANKING_TABS,
        PRACTICE_RANK_PAGE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        PRACTICE_HEAD,
        DOWNLOAD,
        GSOD_SIG,
    } = SITE_TEXT || {};
    // const { n_survey = 0 } = rankingData || {};
    // const { n_survey: differentialDataSize = 0, n_respondents = '' } = differentialData || {};
    const { n_survey: practiceSize = 0, n_respondents = '' } = rankingData || {};
    const { total_survey = '' } = dataAtGlance;

    const getPpt = (slideArr = [], pptName = 'Practice_ranking') => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name: 'Template_OHIBMX_4_0.pptx',
            slides: slideArr,
        };
        const currentDate = new Date().toLocaleDateString();
        const currentTimestamp = new Date().toLocaleTimeString();
        const pptPracticeName = `${pptName}_${currentDate}_${currentTimestamp}.pptx`;
        getPptData(output, pptAccessToken, pptPracticeName);
    };

    const dataObject = (row_index, value, style = 0, is_bold = 'false', alignment = 'center', rowspan) => {
        const obj = {
            row_index,
            column_index: 1,
            value,
            style,
            alignment,
            is_bold,
        };
        if (rowspan) {
            obj.rowspan = rowspan;
        }
        return obj;
    };

    const getDataForPpt = () => {
        const { records: mainData = [], n_size_tq = '', n_size_bq = '' } = rankingData || {};
        const slidesArr = [];
        // const selectedSample = getFilterArrayName(filters);
        const selectedSample = getFilterName(filters);
        const sampleExclYear = getFilterName(
            filters.filter(({ filter_id: id = '' }) => !(id === 'year' || id === 'survey_year'))
        );

        const dataHealthy = [
            {
                name: 'n_size',
                value: `1. Healthy organizations reflect the top 25% of OHI scores in the selected sample (n=${n_size_tq})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            {
                name: 'Footnote',
                is_delete: true,
            },
            {
                name: 'Client_practice_rank_with_score_title',
                value: 'Practice Ranking',
            },
            {
                name: 'Source',
                value: `Source: Selected sample=${selectedSample} (n=${n_respondents} no. of surveys=${practiceSize})`,
            },
        ];

        const dataUnhealthy = [
            {
                name: 'n_size',
                value: `1. Healthy organizations reflect the top 25% of OHI scores in the selected sample (n=${n_size_bq})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            {
                name: 'Footnote',
                is_delete: true,
            },
            {
                name: 'Client_practice_rank_with_score_title',
                value: 'Practice Ranking',
            },
            {
                name: 'Source',
                value: `Source: Selected sample=${selectedSample} (n=${n_respondents} no. of surveys=${practiceSize})`,
            },
        ];

        let rank_top = [];
        let rank_middle = [];
        let rank_bottom = [];
        let col = 0;

        mainData.forEach(({ display_name }, i) => {
            if (i === 12 || i === 31) {
                col = 0;
            }
            if (i < 12) {
                rank_top.push(dataObject(col, display_name, 0, 'true'));
            } else if (i >= 12 && i < 31) {
                rank_middle.push(dataObject(col, display_name, 0));
            } else if (i >= 31) {
                rank_bottom.push(dataObject(col, display_name, 0));
            }
            col += 1;
        });

        dataHealthy.push(
            {
                name: 'ClientTopPratice_1',
                table_data: rank_top,
            },
            {
                name: 'ClientTopPratice_2',
                table_data: rank_middle,
            },
            {
                name: 'ClientTopPratice_3',
                table_data: rank_bottom,
            }
        );

        const dataCopy = cloneDeep(mainData);
        const newItem = dataCopy.sort((a, b) => a.rank_bq - b.rank_bq);
        col = 0;
        rank_top = [];
        rank_middle = [];
        rank_bottom = [];
        newItem.forEach(({ display_name }, i) => {
            if (i === 12 || i === 31) {
                col = 0;
            }
            if (i < 12) {
                rank_top.push(dataObject(col, display_name, 0, 'true'));
            } else if (i >= 12 && i < 31) {
                rank_middle.push(dataObject(col, display_name, 0));
            } else if (i >= 31) {
                rank_bottom.push(dataObject(col, display_name, 0));
            }
            col += 1;
        });
        dataUnhealthy.push(
            {
                name: 'ClientTopPratice_1',
                table_data: rank_top,
            },
            {
                name: 'ClientTopPratice_2',
                table_data: rank_middle,
            },
            {
                name: 'ClientTopPratice_3',
                table_data: rank_bottom,
            }
        );

        slidesArr.push(
            {
                identify_shape_name: 'checkme',
                identify_shape_value: 'Client_practice_rank_score',
                data: dataHealthy,
                style: [
                    {
                        bgcolor: '#ffffff',
                        text_color: '#000000',
                    },
                ],
            },
            {
                identify_shape_name: 'checkme',
                identify_shape_value: 'Client_practice_rank_score_2',
                data: dataUnhealthy,
                style: [
                    {
                        bgcolor: '#ffffff',
                        text_color: '#16537e',
                    },
                ],
            }
        );
        getPpt(slidesArr, `practice_ranking`);
    };

    return (
        <>
            <div className="practiceBoard clearfix">
                <div className="practiceContentFix">
                    <Helmet>
                        <title>{PRACTICE_RANK_PAGE_TEXT['5']}</title>
                    </Helmet>
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">{PRACTICE_HEAD}</div>
                            <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${
                                // tab === 0 ? n_survey : differentialDataSize
                                total_survey
                            }`}</div>
                        </div>
                        <ul className="navScoreList">
                            {/* {tab === 1 && ( */}
                            <li data-testid="getPptBtn" onClick={getDataForPpt}>
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                            {/* )} */}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    {renderfilterApplied}
                    <div className="optionWrapper">
                        {/* <div>
                        <ul className="leftNav">
                            {ANALYTICS_RANKING_TABS.map((tabName, index) => (
                                <li className={c({ activeLink: tab === index })} onClick={() => setTab(index)}>
                                    {tabName}
                                </li>
                            ))}
                        </ul>
                    </div> */}
                        <div className="rightOptions">
                            {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                        </div>
                    </div>
                </div>
                <PracticeRanking
                    defaultSettings={defaultSettings}
                    getRanking={getRanking}
                    rankingFetched={rankingFetched}
                    rankingData={rankingData}
                    filterParams={filterParams}
                    rankingError={rankingError}
                />
            </div>

            {warningModal ? <WarningModal setWarningModal={setWarningModal} /> : null}
        </>
    );
}

PracticeRankingBoard.propTypes = {
    rankingData: PropTypes.object.isRequired,
    rankingFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    getRanking: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    differentialData: PropTypes.object.isRequired,
    // differentialDataFetched: PropTypes.bool.isRequired,
    // getDifferential: PropTypes.func.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filterParams: PropTypes.string.isRequired,
    // setDifferentialData: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    // setTab: PropTypes.func.isRequired,
    rankingError: PropTypes.array.isRequired,
    // differentialError: PropTypes.array.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number.isRequired,
    // new
    warningModal: PropTypes.bool.isRequired,
    setWarningModal: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
};

export default PracticeRankingBoard;
