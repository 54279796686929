import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import c from 'classnames';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import Info from '../../assets/C-info.svg';
import '../index.scss';
import { getEEBoxStyle } from '../../../../utils/functions';
import MetaChartModal from '../../MetaChartModal';
import SelectModal from '../../../../components/SelectModal';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import BulletList from '../../assets/BulletList.svg';
import Down from '../../assets/Down.svg';
import colors from '../../../../sass/colors';
import DonutChart from '../../../../components/DonutChart/DonutChart';

const showProgress = (value, background, isNegative, applyEx, key, isTopQuartile, isPercentile) => {
    const { positiveBg = '#000000', negativeBg = '#ffffff' } = getEEBoxStyle(value, isNegative) || {};
    const noBmBg = isNegative ? negativeBg : positiveBg;
    const bgToUse = applyEx ? background : noBmBg;
    const isEnps = key === 'endorsing_organization_to_others' && applyEx && !isPercentile;
    const percentage = isEnps ? ((value + 100) * 100) / 200 : value;
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar">
                <DonutChart background={bgToUse} value={percentage} displayValue={isEnps ? value : `${value}%`} />
                {isTopQuartile && applyEx ? <div className="triangle" /> : null}
            </div>
        </Box>
    );
};

function EmpExpScorecard({
    qbyqData,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    projects,
    projectsFetched,
    resurveyModal,
    setReSurveyModal,
    callReSurveyApi,
    setReport,
    selectedIds,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    appliedBenchmark,
    footnoteList,
    getComparisonYearSettings,
    compYrSettings,
}) {
    const {
        lang = '1033',
        report_type: surveyType = '',
        quartileColors = [],
        apply_employee_exp_benchmark = false,
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUESTIONS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { NEG_IND_QUES, QUES_LEGEND_TYPE, SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const [showMetaChart, setShowMetaChart] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [isPracticeMeta, setPraticeMeta] = useState(false);

    const toggleMetaDataPopup = (modalState = false, scores = {}, isPractice = false) => {
        setShowMetaChart(modalState);
        setMetaData(scores);
        setPraticeMeta(isPractice);
    };

    const { records: qbyq = {}, size = '' } = qbyqData || {};
    const {
        fe_ee_factor_pc_fv,
        com_ee_enps_calc_detail_pc_fv,
        com_ee_neg_enps_pc_fv,
        fe_ee_factor_percentile,
        com_ee_enps_calc_detail_percentile,
        com_ee_neg_enps_percentile,
    } = footnoteList || {};
    const checkLength = !!Object.keys(qbyq).length;
    const {
        SINGLE_QUESTIONS_LEGEND,
        QUESTION_SCORE,
        IND_EXP_SCORE_PAGE_TEXT_NOTES,
        // EMPLOYEE_NET_PROMOTER_SCORE,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        RESURVEY_TEXT,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
        EE_ENPS_FOOTER,
        BENCHMARK_NA,
        BOTTOM_DECILE,
        BENCHMARK_HEAD,
        NO_BENCHMARK,
    } = SITE_TEXT || {};

    const selectProject = (ohId, reportType) => {
        setReSurveyModal(false);
        callReSurveyApi([ohId]);
        setReport(reportType);
    };

    // const renderRightOptions = () => {
    //     const { demographics = [] } = filters[0] || {};
    //     // if (addOnTab === 1) {
    //     //     return (
    //     //         <div className="outcomeContent">
    //     //             <ul className="questionLegends">
    //     //                 <li>{FREQUENCY_LEVEL_PERCENT}</li>
    //     //                 {IND_QUES_LEGEND.map(legendText => (
    //     //                     <li>
    //     //                         <span />
    //     //                         {legendText}
    //     //                     </li>
    //     //                 ))}
    //     //             </ul>
    //     //         </div>
    //     //     );
    //     // }
    //     // if (addOnTab < 2 || (addOnTab === 3 && crossTabDemo !== DEFAULT_STATE)) return null;
    //     return (
    //         <div className="rightOptions">
    //             <span>
    //                 <Select
    //                     IconComponent={KeyboardArrowDownSharpIcon}
    //                     value={selectedDemographics}
    //                     MenuProps={{
    //                         anchorOrigin: {
    //                             vertical: 'bottom',
    //                             horizontal: 'left',
    //                         },
    //                         transformOrigin: {
    //                             vertical: 'top',
    //                             horizontal: 'left',
    //                         },
    //                         getContentAnchorEl: null,
    //                     }}
    //                     onChange={({ target: { value: code } }) => {
    //                         setSelectedDemographic(code);
    //                         callHeatMap(code);
    //                     }}
    //                 >
    //                     {demographics.map(({ label, code }) => {
    //                         return (
    //                             <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
    //                                 {label}
    //                             </MenuItem>
    //                         );
    //                     })}
    //                 </Select>
    //             </span>
    //         </div>
    //     );
    // };

    const oldSelectedId = selectedIds.length ? selectedIds[0] : '';
    let sup = 1;
    const FOOTNOTES =
        surveyType === 'percent favorable'
            ? [fe_ee_factor_pc_fv, com_ee_enps_calc_detail_pc_fv, com_ee_neg_enps_pc_fv]
            : [fe_ee_factor_percentile, com_ee_enps_calc_detail_percentile, com_ee_neg_enps_percentile];

    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">
                                {EMPLYEE_EXPEIENCE} {resurveyModal ? 'Resurvey' : ''}
                                <sup>{sup}</sup>
                            </div>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                            </div>
                        </div>
                        <ul className="navScoreList">
                            <li
                                data-testid="setReSurveyModalBtn"
                                onClick={() => setReSurveyModal(true)}
                                className="fImage resurveyImg"
                            >
                                <img src={BulletList} alt="" />
                                <span className="upperHead">{RESURVEY_TEXT}</span>
                            </li>
                            {!errorInfo && (
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            )}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    {appliedFiltersInfo()}
                    {appliedBenchmark()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    if (
                                        (!apply_employee_exp_benchmark && index === 4) ||
                                        (surveyType === 'percentile' && index === 4)
                                    )
                                        return null;
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {/* {renderRightOptions()} */}
                    </div>
                    <Fragment>
                        <div className="indExpScorecard">
                            {checkLength &&
                                QUESTIONS.map(({ title, children, imgSrc }) => {
                                    return (
                                        <div className="cardWrapper">
                                            <div className="headerContent">
                                                <h2>
                                                    <span>
                                                        <img src={imgSrc} alt={title} />
                                                    </span>
                                                    {title}
                                                </h2>
                                            </div>
                                            {children.map(key => {
                                                const {
                                                    quartile,
                                                    range_start,
                                                    range_end,
                                                    display_name: childTitle = '',
                                                    score,
                                                    meta_scores = [],
                                                } = qbyq[key] || {};
                                                const negativeTrait = NEG_IND_QUES.includes(key);
                                                sup = QUES_LEGEND_TYPE[key] || '';
                                                const {
                                                    qtext = '',
                                                    bottom2_score = 0,
                                                    nuetral_score = 0,
                                                    score: metaScore = 0,
                                                } = meta_scores[0] || {};
                                                // const enps = metaScore - bottom2_score;
                                                // const positiveNps = enps > 0;
                                                const childSet = {
                                                    meta_scores,
                                                    display_name: childTitle,
                                                    score,
                                                    quartile,
                                                    range_start,
                                                    range_end,
                                                };
                                                const TITLE = 'Recomendar la organización ante otros';
                                                const titleToUse =
                                                    lang === '10' && key === 'endorsing_organization_to_others'
                                                        ? TITLE
                                                        : childTitle;
                                                const { background = '#ffffff' } = quartileColors[quartile] || {};
                                                if (isNaN(parseInt(score, 10))) return null;
                                                return (
                                                    <div className={c({ hoverElement: sup === 2 }, 'cardData')}>
                                                        <div onClick={() => toggleMetaDataPopup(true, childSet, true)}>
                                                            {titleToUse}
                                                            <sup>{sup}</sup>
                                                            {sup === 2 ? (
                                                                <span>
                                                                    <img src={Info} alt="" />
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                        <div className="percentProgress">
                                                            {showProgress(
                                                                score,
                                                                background,
                                                                negativeTrait,
                                                                apply_employee_exp_benchmark,
                                                                key,
                                                                parseInt(quartile, 10) === 0,
                                                                surveyType === 'percentile'
                                                            )}
                                                        </div>
                                                        {sup === 2 ? (
                                                            <div className="detailedInfo">
                                                                <div className="questionSection">
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <b>{QUESTION_SCORE}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">{qtext}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="metaInfo">
                                                                        {IND_EXP_SCORE_PAGE_TEXT_NOTES}
                                                                    </div>
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <div className="metaScore chart">
                                                                                    <div className="chartLegends">
                                                                                        {SINGLE_QUESTIONS_LEGEND.map(
                                                                                            legendText => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        <span />{' '}
                                                                                                        {legendText}
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="metaScore">
                                                                                <div className="chart">
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${bottom2_score}%`,
                                                                                        }}
                                                                                    >
                                                                                        {bottom2_score}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${nuetral_score}%`,
                                                                                        }}
                                                                                    >
                                                                                        {nuetral_score}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${metaScore}%`,
                                                                                        }}
                                                                                    >
                                                                                        {metaScore}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="questionSection">
                                                                    <div className="practiceSet bottom clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <span>
                                                                                    <strong>
                                                                                        eNPS<sup>4</sup>
                                                                                    </strong>
                                                                                    <br />
                                                                                    {EMPLOYEE_NET_PROMOTER_SCORE}
                                                                                </span>
                                                                                <span>{`= ${metaScore}% - ${bottom2_score}%`}</span>
                                                                            </div>
                                                                            <div className="metaScore">
                                                                                <div className="chart bottomChart">
                                                                                    <div className="axis horizontal" />
                                                                                    <div className="axis vertical" />
                                                                                    <span />
                                                                                    <span className="section2">
                                                                                        <span
                                                                                            className={c(
                                                                                                {
                                                                                                    negative: !positiveNps,
                                                                                                },
                                                                                                'capsule'
                                                                                            )}
                                                                                            style={{
                                                                                                width: `${
                                                                                                    positiveNps
                                                                                                        ? enps / 2
                                                                                                        : -enps / 2
                                                                                                }%`,
                                                                                            }}
                                                                                        >
                                                                                            {enps + '%'}
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            {showMetaChart && (
                                <MetaChartModal
                                    metaData={metaData}
                                    closeModal={toggleMetaDataPopup}
                                    isPracticeMeta={isPracticeMeta}
                                    totalSize={size.toLocaleString('en-US')}
                                    defaultSettings={defaultSettings}
                                    isEE={!apply_employee_exp_benchmark}
                                />
                            )}
                            {resurveyModal && (
                                <SelectModal
                                    defaultSettings={defaultSettings}
                                    oldSelectedId={oldSelectedId}
                                    projects={projects}
                                    projectsFetched={projectsFetched}
                                    closeModal={setReSurveyModal}
                                    selectProject={selectProject}
                                    practiceRankModal
                                    getComparisonYearSettings={getComparisonYearSettings}
                                    compYrSettings={compYrSettings}
                                    apiLoadingCount={apiLoadingCount}
                                    isExModule={apply_employee_exp_benchmark}
                                />
                            )}
                        </div>

                        {!apiLoadingCount && !errorInfo && (
                            <Fragment>
                                {apply_employee_exp_benchmark ? (
                                    <div className="note">
                                        <b>{NOTE}:</b>
                                        {FOOTNOTES.map((txt, i) => {
                                            return <div>{`${i + 1}. ${txt}`}</div>;
                                        })}
                                    </div>
                                ) : (
                                    <div className="note">
                                        <b>{NOTE}:</b>
                                        {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                        {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                        {IND_EXP_QUESTION_NOTE[0]}
                                        <br />
                                        {IND_EXP_QUESTION_NOTE[1]}
                                        <div>4. {EE_ENPS_FOOTER}</div>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                {apply_employee_exp_benchmark ? (
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === BOTTOM_DECILE;
                                const BenchmarkTitle = title === NO_BENCHMARK;
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                ) : (
                    <Fragment>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                            {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                            {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                )}
            </ul>
        </Fragment>
    );
}

EmpExpScorecard.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    qbyqData: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    projects: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    resurveyModal: PropTypes.bool.isRequired,
    setReSurveyModal: PropTypes.func.isRequired,
    callReSurveyApi: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    appliedBenchmark: PropTypes.func.isRequired,
    footnoteList: PropTypes.array.isRequired,
    getComparisonYearSettings: PropTypes.func.isRequired,
    compYrSettings: PropTypes.object.isRequired,
};

export default EmpExpScorecard;
