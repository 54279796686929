import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ExpandLess, ExpandMore, ArrowForward } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { Tooltip, Checkbox } from '@material-ui/core';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import BlankPage from '../BlankPage/BlankPage';
import { getStaticTextAnalytics } from '../constants';
import { getTabName } from '../assets/functions';
import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import WarningModal from '../Intro/WarningModal';
import { matchFilterRegex } from '../../../utils/functions';
import XLSX from 'xlsx-color';
import Down from '../../Diagnose/assets/Down.svg';
import Download from '../assets/gsodDown.svg';
import AuthStore from '../../../common/AuthStore';
import { getFilterArrayName, getFilterName } from '../assets/functions';

function Overview({
    toggleFilterView,
    dataAtGlance,
    getAnalyticsPerCategory,
    defaultSettings,
    filterParams,
    filters,
    setAnalyticsPerCategory,
    analyticsPerCategory,
    tabs,
    setTabs,
    renderfilterApplied,
    filtersApplied,
    setWarningModal,
    warningModal,
    getPptData,
    getAnalyticsPerSector,
    analyticsPerSectorData,
    setPendingPptData,
    pendingPptData,
}) {
    const { lang } = defaultSettings;
    const staticTextAnalytics = getStaticTextAnalytics(lang);
    const { OVERVIEW_HEADER } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        OVERVIEW_PAGE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        NOTE,
        GSOD_OVERVIEW_NOTE,
        OVERVIEW_NUMBER_NOTE,
        SCORECARD_NOTE,
        DISCLAIMER,
        LEARN_MORE,
        LEARN_MORE_LINK,
        VIEW_ONLINE,
        DOWNLOAD,
    } = SITE_TEXT;
    const { [tabs]: chartData = [] } = analyticsPerCategory || [];
    const { total_survey: nSize = '', survey_completion: surveySize = '' } = dataAtGlance;
    const [showQuartileDistribution, setShowQuartileDistribution] = useState(() => {
        const saved = localStorage.getItem('showQuartileDistribution');
        return saved ? JSON.parse(saved) : false;
    });

    const keys = matchFilterRegex(filterParams);
    const keyCount = keys ? keys.length : 0;

    useEffect(() => {
        if (!(tabs in analyticsPerCategory)) {
            getAnalyticsPerCategory({ filters: filterParams, filter_id: tabs });
        }
        // eslint-disable-next-line
    }, [tabs, filterParams]);

    useEffect(() => {
        if (tabs === 4 && !analyticsPerSectorData) {
            getAnalyticsPerSector({ filters: filterParams, filter_id: 5 });
        }
    }, [tabs, filterParams]);

    const getPpt = (slideArr = [], pptName = 'Overview', newItem) => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name: 'Template_OHIBMX_4_0.pptx',
            slides: slideArr,
        };
        const currentDate = new Date().toLocaleDateString();
        const currentTimestamp = new Date().toLocaleTimeString();
        const pptPracticeName = `${newItem}_statehealth_${currentDate}_${currentTimestamp}.pptx`;
        getPptData(output, pptAccessToken, pptPracticeName);
    };
    const chartWidth = showQuartileDistribution ? '50' : '80';
    const numberWidth = showQuartileDistribution ? '15' : '50';
    const quartileWidth = showQuartileDistribution ? '15' : '0';
    const tableContainerWidth = showQuartileDistribution ? '50' : '20';
    const chartFooterWidth = showQuartileDistribution ? '17.5' : '25';

    const sortData = (sortObject, sort_elem, sort_by) => {
        const sortObj = cloneDeep(sortObject);
        sortObj.sort(
            (
                { total_survey: a = '', survey_completion: x = '' },
                { total_survey: b = '', survey_completion: y = '' }
            ) => {
                if (sort_by === 'asc') {
                    return sort_elem === 'survey' ? a - b : x - y;
                }
                return sort_elem === 'survey' ? b - a : y - x;
            }
        );
        setAnalyticsPerCategory({ ...analyticsPerCategory, [tabs]: sortObj });
    };

    const handleWarningModal = () => {
        setWarningModal(true);
    };

    const chartDivision = 8;
    // const chartDivision = 2;

    const maxValue = useMemo(() => {
        const chartDiv = 100 / chartDivision;
        if (chartData.length) {
            const allData = chartData.map(({ total_survey }) => total_survey);
            const maxDataPoint = Math.max(...allData);
            const maxDataPointRounded = Math.ceil(maxDataPoint / chartDiv) * chartDiv;
            return maxDataPointRounded;
        }
        return 0;
    }, [chartData]);

    const sectorApiData = (newItem, total_survey, topQ, secondQ, threeQ, bottomQ, code, survey_completion) => {
        const dataToSet = { newItem, total_survey, topQ, secondQ, threeQ, bottomQ, survey_completion };
        const sectorFilterParams = `${filterParams};4:${code}`;
        setPendingPptData(dataToSet);
        getAnalyticsPerSector({ filters: sectorFilterParams, filter_id: 5 });
    };

    useEffect(() => {
        if (Object.keys(analyticsPerSectorData).length > 0 && Object.keys(pendingPptData).length > 0) {
            const { newItem, total_survey, topQ, secondQ, threeQ, bottomQ, survey_completion } = pendingPptData;
            downloadPptData(newItem, total_survey, topQ, secondQ, threeQ, bottomQ, survey_completion);
            setPendingPptData({});
        }
    }, [analyticsPerSectorData, pendingPptData]);

    const downloadPptData = (newItem, total_survey, topQ, secondQ, threeQ, bottomQ, survey_completion) => {
        const topQuartile = topQ.replace('%', '');
        const secondQuartile = secondQ.replace('%', '');
        const threeQuartile = threeQ.replace('%', '');
        const bottomQuartile = bottomQ.replace('%', '');

        const selectedSample = getFilterArrayName(filters);
        const slidesArr = [];

        let selectedFilterSample = getFilterName(filters);

        if (typeof selectedFilterSample === 'string') {
            selectedFilterSample = selectedFilterSample.split(', ');
        }
        const result = selectedFilterSample.slice(1);

        const filteredSubsectors = analyticsPerSectorData.filter(item => item.total_survey >= 10);

        const dataHealthy = [
            {
                name: 'Title_text',
                value: `State of health in ${newItem} sector`,
            },
            {
                name: 'Subtitle',
                value: `${newItem} sector and subsector health breakdown`,
            },
            {
                name: 'Sub_Subtitle',
                value: `Overall health of ${newItem} organizations`,
            },
            {
                name: 'Sector_title',
                value: `${newItem}`,
            },
            {
                name: 'Sector_n_size',
                value: `n=${total_survey}`,
            },
            {
                name: 'Source',
                value: `Source: Selected sample (${newItem})=${selectedSample} (n=${survey_completion} no. of surveys=${total_survey}), Filters: ${result}`,
            },
            {
                name: 'Sector_chart',
                chart_data: [
                    { row_index: 1, column_index: 0, value: 'Category 1', index: 0 },
                    { row_index: 1, column_index: 1, value: bottomQuartile, index: 1, Series: 0 },
                    { row_index: 1, column_index: 2, value: threeQuartile, index: 2, Series: 1 },
                    { row_index: 1, column_index: 3, value: secondQuartile, index: 3, Series: 2 },
                    { row_index: 1, column_index: 4, value: topQuartile, index: 4, Series: 3 },
                ],
            },
            {
                name: 'Checkme',
                is_delete: true,
            },
            {
                name: 'Footnote',
                is_delete: true,
            },
            {
                name: 'Sector_state_health',
                value: 'Overview',
            },
        ];
        filteredSubsectors.forEach((item, index) => {
            const chartName = `Subsector${index + 1}_chart`;
            const nSizeName = `Subsector${index + 1}_n_size`;
            const titleName = `Subsector${index + 1}_title`;

            if (item.topQ !== '-' && item.secondQ !== '-' && item.threeQ !== '-' && item.bottomQ !== '-') {
                dataHealthy.push({
                    name: chartName,
                    chart_data: [
                        { row_index: 1, column_index: 0, value: 'Category 1', index: 0 },
                        { row_index: 1, column_index: 1, value: item.bottomQ.replace('%', ''), index: 1, Series: 0 },
                        { row_index: 1, column_index: 2, value: item.threeQ.replace('%', ''), index: 2, Series: 1 },
                        { row_index: 1, column_index: 3, value: item.secondQ.replace('%', ''), index: 3, Series: 2 },
                        { row_index: 1, column_index: 4, value: item.topQ.replace('%', ''), index: 4, Series: 3 },
                    ],
                });
            }

            dataHealthy.push(
                { name: titleName, value: item.name },
                { name: nSizeName, value: `n=${item.total_survey}` }
            );
        });
        for (let i = filteredSubsectors.length + 1; i <= 5; i++) {
            dataHealthy.push(
                { name: `Subsector${i}_chart`, is_delete: true },
                { name: `Subsector${i}_title`, is_delete: true },
                { name: `Subsector${i}_n_size`, is_delete: true }
            );
        }

        slidesArr.push({
            identify_shape_name: 'Checkme',
            identify_shape_value: 'Sector_state_health',
            data: dataHealthy,
        });

        // getPpt(slidesArr, `overview`);
        // new item here
        getPpt(slidesArr, `overview`, newItem);
    };
    const { filter_name: currentTabName = '' } = filters.filter(({ filter_id }) => filter_id === tabs)[0] || {};

    const handleCheckboxChange = checked => {
        setShowQuartileDistribution(checked);
        localStorage.setItem('showQuartileDistribution', JSON.stringify(checked));
    };

    const downloadExcel = data => {
        let filteredData = data.map(({ code, ...rest }) => rest);
        if (!showQuartileDistribution) {
            filteredData = filteredData.map(({ topQ, secondQ, threeQ, bottomQ, ...rest }) => rest);
        }
        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(filteredData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Overview');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'overview.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="overviewWrapper">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{OVERVIEW_PAGE_TEXT['1']}</div>
                        <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${nSize}`}</div>
                    </div>
                    <ul className="navScoreList">
                        {/* <li onClick={() => downloadExcel(chartData)}>
                            <img src={Down} alt="" />
                            <span className="lineHead upperHead">Download excel File</span>
                        </li> */}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                <div className="benchmarkInfo">{OVERVIEW_PAGE_TEXT['2']}</div>
                <div className="disclaimerContent">
                    <img src={DisclaimerWarning} alt="" />
                    <div className="note scoreCardNote">
                        <span>{DISCLAIMER} : </span>
                        <div>{SCORECARD_NOTE}</div>
                        <div style={{ cursor: 'pointer' }} className="warningContent" onClick={handleWarningModal}>
                            know more
                        </div>
                    </div>
                </div>
                {renderfilterApplied}
                {chartData.length ? (
                    <Fragment>
                        <ul className="headContent">
                            {Object.keys(dataAtGlance).map((dataPoints, index) => {
                                const score = dataAtGlance[dataPoints].toLocaleString('en-US');
                                const { label = '' } = OVERVIEW_HEADER[dataPoints];
                                return (
                                    <li data-testid={`headContent${index}`} key={index}>
                                        <div className="description">
                                            <span>{score}</span>
                                            <div className="ellipsisPro">{label}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <section className="sectionWrapper">
                            <div className="tabContainer">
                                <div className="overviewTabContent">
                                    <ul className="leftNav">
                                        {filters
                                            .filter(({ filter_id = '' }) => typeof filter_id === 'number')
                                            .map(({ filter_name, filter_id }) => {
                                                const tabName = getTabName(filter_name);
                                                return (
                                                    <li
                                                        key={tabName}
                                                        data-testid={tabName}
                                                        className={c({ activeLink: tabs === filter_id })}
                                                        onClick={() => {
                                                            setTabs(filter_id);
                                                        }}
                                                    >
                                                        {tabName}
                                                    </li>
                                                );
                                            })}
                                    </ul>

                                    {/* <div className="checkboxContainer">
                                        <label className="checkboxLabel">
                                            <Checkbox
                                                type="checkbox"
                                                checked={showQuartileDistribution}
                                                onChange={e => handleCheckboxChange(e.target.checked)}
                                            />
                                            Show Global Quartile Distribution for Overall Health
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        <section className="sectionWrapper">
                            <div className="cardsContainer">
                                <div className="middleSection">
                                    <div className="graphCard">
                                        {/* <div className="cardHeading">{OVERVIEW_PAGE_TEXT['2']}</div> */}
                                        <div className="cardBody">
                                            <div className="header">
                                                <div style={{ width: `${chartWidth}%` }}>
                                                    <div style={{ width: '25%' }}>{currentTabName}</div>
                                                    <div>{OVERVIEW_PAGE_TEXT['3']}</div>
                                                </div>
                                                <div
                                                    style={{ width: `${tableContainerWidth}%` }}
                                                    className="headerRowContent"
                                                >
                                                    <div style={{ width: `${numberWidth}%` }} className="headerRow">
                                                        <div>{OVERVIEW_PAGE_TEXT['4']}</div>
                                                        <div>
                                                            <span
                                                                className="expandLess"
                                                                onClick={() => sortData(chartData, 'survey', 'asc')}
                                                            >
                                                                <ExpandLess />
                                                            </span>
                                                            <span
                                                                className="expandMore"
                                                                onClick={() => sortData(chartData, 'survey', 'desc')}
                                                            >
                                                                <ExpandMore />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: `${numberWidth}%` }} className="headerRow">
                                                        <div>{OVERVIEW_PAGE_TEXT['5']}</div>
                                                        <div>
                                                            <span
                                                                className="expandLess"
                                                                onClick={() => sortData(chartData, 'respondent', 'asc')}
                                                            >
                                                                <ExpandLess />
                                                            </span>
                                                            <span
                                                                className="expandMore"
                                                                onClick={() =>
                                                                    sortData(chartData, 'respondent', 'desc')
                                                                }
                                                            >
                                                                <ExpandMore />
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {showQuartileDistribution && (
                                                        <>
                                                            <div style={{ width: `${quartileWidth}%` }}>
                                                                {OVERVIEW_PAGE_TEXT['7']}
                                                            </div>
                                                            <div style={{ width: `${quartileWidth}%` }}>
                                                                {OVERVIEW_PAGE_TEXT['8']}
                                                            </div>
                                                            <div style={{ width: `${quartileWidth}%` }}>
                                                                {OVERVIEW_PAGE_TEXT['9']}
                                                            </div>
                                                            <div style={{ width: `${quartileWidth}%` }}>
                                                                {OVERVIEW_PAGE_TEXT['10']}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="barChartContainer" style={{ width: `${chartWidth}%` }}>
                                                    {chartData.map(({ name, total_survey }, i) => {
                                                        const width = (total_survey / maxValue) * 100;
                                                        const newItem = name;
                                                        return (
                                                            <div className="tableRow" key={name}>
                                                                {/* <div className="ellipsisPro">{name}</div> */}
                                                                <div className="ellipsisPro" style={{ width: '25%' }}>
                                                                    {name}
                                                                </div>
                                                                <div className={c({ adjustPadding: !i })}>
                                                                    <div className="chart">
                                                                        {Array(8)
                                                                            .fill(0)
                                                                            .map((e, i) => (
                                                                                <div key={e + i} className="grid" />
                                                                            ))}
                                                                        {total_survey < 10 ? (
                                                                            <Tooltip
                                                                                title={OVERVIEW_NUMBER_NOTE}
                                                                                placement="right"
                                                                                arrow
                                                                            >
                                                                                <div
                                                                                    className={c('bars', {
                                                                                        negative: total_survey < 10,
                                                                                        adjustPadding: !i,
                                                                                    })}
                                                                                    style={{ width: `${width}%` }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <div
                                                                                className={c('bars', {
                                                                                    adjustPadding: !i,
                                                                                })}
                                                                                style={{ width: `${width}%` }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div
                                                    className="tableContainer"
                                                    style={{ width: `${tableContainerWidth}%` }}
                                                >
                                                    {chartData.map(
                                                        ({
                                                            survey_completion,
                                                            survey_completion_perc,
                                                            total_survey,
                                                            total_survey_perc,
                                                            topQ,
                                                            secondQ,
                                                            threeQ,
                                                            bottomQ,
                                                            name,
                                                            code,
                                                        }) => {
                                                            return (
                                                                <div className="tableRow">
                                                                    <div
                                                                        className="percentageColumn"
                                                                        style={{ width: `${numberWidth}%` }}
                                                                        // style={{width: '50%'}}
                                                                    >
                                                                        <span className="surveyString">
                                                                            {total_survey.toLocaleString('en-US')}{' '}
                                                                        </span>
                                                                        <span> ({total_survey_perc}%) </span>
                                                                    </div>

                                                                    <div
                                                                        className="percentageColumn"
                                                                        style={{ width: `${numberWidth}%` }}
                                                                        // style={{width: '50%'}}
                                                                    >
                                                                        <span className="surveyString">
                                                                            {survey_completion.toLocaleString('en-US')}
                                                                        </span>
                                                                        <span>({survey_completion_perc}%)</span>
                                                                    </div>

                                                                    {showQuartileDistribution && (
                                                                        <>
                                                                            <div
                                                                                className="percentageColumn"
                                                                                style={{ width: `${quartileWidth}%` }}
                                                                            >
                                                                                <span className="surveyString">
                                                                                    {topQ}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="percentageColumn"
                                                                                style={{ width: `${quartileWidth}%` }}
                                                                            >
                                                                                <span className="surveyString">
                                                                                    {secondQ}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="percentageColumn"
                                                                                style={{ width: `${quartileWidth}%` }}
                                                                            >
                                                                                <span className="surveyString">
                                                                                    {threeQ}
                                                                                </span>
                                                                            </div>
                                                                            <div
                                                                                className="percentageColumn"
                                                                                style={{ width: `${quartileWidth}%` }}
                                                                            >
                                                                                <span className="surveyString">
                                                                                    {bottomQ}
                                                                                </span>
                                                                            </div>
                                                                            {topQ !== '-' && tabs === 4 && (
                                                                                <div
                                                                                    style={{
                                                                                        width: '10%',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        sectorApiData(
                                                                                            name,
                                                                                            total_survey,
                                                                                            topQ,
                                                                                            secondQ,
                                                                                            threeQ,
                                                                                            bottomQ,
                                                                                            code,
                                                                                            survey_completion
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <img src={Download} alt="" />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="chartFooter">
                                                <div>
                                                    <div style={{ width: `${chartFooterWidth}%` }} />
                                                    <div>
                                                        <div>
                                                            <div className="chart">
                                                                {Array(8)
                                                                    .fill(0)
                                                                    .map((_, i) => {
                                                                        const unit = maxValue / chartDivision;
                                                                        return (
                                                                            <div
                                                                                key={i}
                                                                                style={{ left: `${unit * i}%` }}
                                                                            >
                                                                                {Math.round(unit * i)}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                <div className="lastRangeItem">
                                                                    {Math.round(maxValue)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="note">
                            <b>{NOTE}:</b>
                            {GSOD_OVERVIEW_NOTE}
                        </div>
                    </Fragment>
                ) : (
                    <section className="sectionWrapper">
                        <BlankPage count={keyCount} />
                    </section>
                )}
            </div>

            <div className="bottomCard bottomDisclaimer">
                <span>
                    {LEARN_MORE}
                    <a className="arrowIcon" href={LEARN_MORE_LINK}>
                        {VIEW_ONLINE}
                        <ArrowForward />
                    </a>
                </span>
            </div>

            {warningModal ? <WarningModal setWarningModal={setWarningModal} /> : null}
        </>
    );
}

Overview.propTypes = {
    toggleFilterView: PropTypes.bool.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    getAnalyticsPerCategory: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filterParams: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    setAnalyticsPerCategory: PropTypes.func.isRequired,
    analyticsPerCategory: PropTypes.object.isRequired,
    tabs: PropTypes.number.isRequired,
    setTabs: PropTypes.func.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    setWarningModal: PropTypes.func.isRequired,
    warningModal: PropTypes.bool.isRequired,
    getPptData: PropTypes.func.isRequired,
    getAnalyticsPerSector: PropTypes.func.isRequired,
    setPendingPptData: PropTypes.func.isRequired,
    pendingPptData: PropTypes.object.isRequired,
    analyticsPerSectorData: PropTypes.object.isRequired,
};

export default Overview;
