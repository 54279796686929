import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FlagpoleBoard from './FlagpoleBoard';

// import {
//     updateLeftBenchmarks,
//     updateRightBenchmarks,
//     getScoreData,
//     updateApiParams,
//     getPptData,
//     getSinglePastReport,
// } from '../actions';

// import {
//     selectScoreData,
//     selectScoreDataFetched,
//     selectFilters,
//     selectLeftBenchmarks,
//     selectRightBenchmarks,
//     selectApiParams,
//     selectApiCount,
//     selectFiltersApplied,
//     getErrorInfo,
//     selectPastReport,
//     selectLocalPractice,
// } from '../selector';
import { getDefaultSettings } from '../../../Login/selector';
import {
    getErrorInfo,
    selectApiCount,
    selectExLeftBenchmarks,
    selectExRightBenchmarks,
    selectFilters,
    selectFootnoteList,
    selectSingleQuestion,
} from '../../selector';
import { getSingleQuestion, updateExApiParams, updateExLeftBenchmarks, updateExRightBenchmarks } from '../../actions';

const stateToProps = createStructuredSelector({
    scoreData: selectSingleQuestion(),
    filters: selectFilters(),
    leftBenchmarks: selectExLeftBenchmarks(),
    rightBenchmarks: selectExRightBenchmarks(),
    // apiParams: selectApiParams(),
    // apiLoadingCount: selectApiCount(),
    // activeFilter: selectFiltersApplied(),
    // errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    // singlePastReport: selectPastReport(),
    // localPractice: selectLocalPractice(),
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    footnoteList: selectFootnoteList(),
});

const dispatchToProps = {
    getSingleQuestion,
    updateExLeftBenchmarks,
    updateExRightBenchmarks,
    updateExApiParams,
};

export default connect(stateToProps, dispatchToProps)(FlagpoleBoard);
