import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Lottie from 'react-lottie';
import { getStaticText } from '../../../../utils/constants';
import { getColor, getNegColor } from '../../../../utils/functions';
import animationData from '../../assets/lottie.json';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import BulletList from '../../assets/BulletList.svg';
import Down from '../../assets/Down.svg';
import SelectModal from '../../../../components/SelectModal';
import colors from '../../../../sass/colors';

function Questions({
    singleQuestion,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    resurveyModal,
    setReSurveyModal,
    callReSurveyApi,
    setReport,
    selectedIds,
    projects,
    projectsFetched,
    appliedBenchmark,
    footnoteList,
    getComparisonYearSettings,
    compYrSettings,
}) {
    const { lang, report_type: surveyType = '', quartileColors = [], apply_employee_exp_benchmark = false } =
        defaultSettings || {};
    const staticText = getStaticText(lang);
    const { IND_EXP_SCORE_CLASSIFICATION, QUES_LEGEND_TYPE, SITE_TEXT } = staticText;
    const {
        SINGLE_QUESTIONS_LEGEND,
        IND_QUESTIONS_QUES,
        NEGATIVE_PRACTICES,
        RESPONSE_PERCENT,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        IND_QUES_LEGEND,
        FREQUENCY_LEVEL_PERCENT,
        RESURVEY_TEXT,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
        BENCHMARK_NA,
        BOTTOM_DECILE,
        BENCHMARK_HEAD,
        NO_BENCHMARK,
    } = SITE_TEXT || {};
    // const [selectedTab, setSelectedTab] = useState(0);
    // const { SINGLE_QUESTIONS_LEGEND, IND_QUESTIONS_QUES, NEGATIVE_PRACTICES, RESPONSE_PERCENT } = SITE_TEXT[lang] || {};
    const [expandedList, updateExpandedList] = useState([0]);
    const [isExpanded, setIsExpanded] = useState(false);
    const { children: selectedChild = [] } = IND_QUESTIONS_QUES[0];
    const { qbyq: singleQbyq = {} } = singleQuestion || {};
    const { records: data = {}, size = '' } = singleQbyq || {};
    const {
        fe_ee_factor_pc_fv,
        fe_ee_enps_calc_short_pc_fv,
        com_ee_neg_enps_pc_fv,
        fe_ee_factor_percentile,
        fe_ee_enps_calc_short_percentile,
        com_ee_neg_enps_percentile,
    } = footnoteList || {};
    const FOOTNOTES =
        surveyType === 'percent favorable'
            ? [fe_ee_factor_pc_fv, fe_ee_enps_calc_short_pc_fv, com_ee_neg_enps_pc_fv]
            : [fe_ee_factor_percentile, fe_ee_enps_calc_short_percentile, com_ee_neg_enps_percentile];

    const selectProject = (ohId, reportType) => {
        setReSurveyModal(false);
        callReSurveyApi([ohId]);
        setReport(reportType);
    };

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        setIsExpanded(true);
        updateExpandedList(newList);
    };

    const ExpandIcon = () => {
        const defaultOptions = {
            loop: false,
            autoplay: false,
            animationData,
        };

        return (
            <div className="expand-icon">
                <Lottie options={defaultOptions} />
            </div>
        );
    };

    const ExpandIconAnimated = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
        };

        const onClickOptions = {
            ...defaultOptions,
            loop: false,
            autoplay: false,
        };

        const handleClick = () => {
            setIsExpanded(true);
        };

        return (
            <div className="expand-icon" onClick={handleClick}>
                <Lottie options={isExpanded ? onClickOptions : defaultOptions} />
            </div>
        );
    };
    const oldSelectedId = selectedIds.length ? selectedIds[0] : '';

    const renderCategoryData = child => {
        return child.map((dataSet, index) => {
            const {
                display_name: title = '',
                meta_scores = [],
                title: childTitle,
                score: prScore = '-',
                quartile = '',
            } = data[dataSet] || {};
            const metas = meta_scores.map(({ meta }) => meta);
            const sup = QUES_LEGEND_TYPE[dataSet] || '';
            const singleQues = sup === 2;
            const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
            const colorIndex = getColor(prScore, [], false, true);
            const colorIndexNeg = getNegColor(prScore, [], false, true);
            const { positiveBg = '#ffffff', negativeBg = '#ffffff', color: oldColor = '#ffffff' } =
                IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? colorIndexNeg : colorIndex] || {};
            const bg = isNegativeTrait ? negativeBg : positiveBg;
            const { background: qBg = '#ffffff', color: qColor = '#000000', border: qBorder } =
                quartileColors[quartile] || {};
            const background = apply_employee_exp_benchmark ? qBg : bg;
            const color = apply_employee_exp_benchmark ? qColor : oldColor;
            const border = apply_employee_exp_benchmark ? qBorder : bg;
            const SINGLE_QUESTIONS_LEGEND_TITLE = metas.includes('IENPS1');
            if (isNaN(parseInt(prScore, 10))) return null;
            return (
                <Accordion classes={{ root: 'accordionRoot' }} key={index}>
                    <AccordionSummary
                        expandIcon={index === 0 ? <ExpandIconAnimated /> : <ExpandIcon />}
                        onClick={() => updateExpanded(index)}
                        data-testid={title}
                        classes={{ content: 'accordionHead' }}
                    >
                        {!SINGLE_QUESTIONS_LEGEND_TITLE ? (
                            <div className="outcomeContent">
                                {prScore ? (
                                    <div className="metaTotalScore">
                                        <div
                                            className="circle"
                                            style={{
                                                background: `linear-gradient(135deg, ${border} 25%, ${background} 25%)`,
                                                color,
                                                borderColor: border,
                                                borderWidth: quartile === '' ? 1 : 2,
                                            }}
                                        >
                                            {prScore}
                                        </div>
                                    </div>
                                ) : null}
                                {title} <sup>{sup}</sup>
                            </div>
                        ) : (
                            <Fragment>
                                {!singleQues ? (
                                    <div className="outcomeContent">{title}</div>
                                ) : (
                                    <div className="outcomeContent">
                                        {prScore ? (
                                            <div className="metaTotalScore">
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background,
                                                        color,
                                                    }}
                                                >
                                                    {prScore}
                                                </div>
                                            </div>
                                        ) : null}
                                        {title} <sup>{sup}</sup>
                                        <span className="scoreHeading" />
                                        <ul className="questionLegends singleQuestions">
                                            <li className="listTitle">{RESPONSE_PERCENT}</li>
                                            {SINGLE_QUESTIONS_LEGEND.map(legendText => (
                                                <li key={legendText}>
                                                    <span />
                                                    {legendText}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <section className="leftSection">
                            {meta_scores.map(item => {
                                const {
                                    qtext: metaPracticeText = '',
                                    bottom2_score = 0,
                                    nuetral_score = 0,
                                    na_score = 0,
                                    score = '-',
                                } = item || {};
                                const topScore = 100 - bottom2_score - nuetral_score - na_score;
                                const totalScore = bottom2_score + nuetral_score + topScore;
                                return (
                                    <div key={childTitle} className="clearfix">
                                        <section key={metaPracticeText} className="metaSection clearfix">
                                            <div className="metaText">{metaPracticeText}</div>
                                            {totalScore ? (
                                                <Fragment>
                                                    <div className={c({ singleLegends: singleQues }, 'metaScore')}>
                                                        <div>
                                                            <span
                                                                style={{
                                                                    width: `${bottom2_score}%`,
                                                                }}
                                                            >
                                                                {bottom2_score}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    width: `${nuetral_score}%`,
                                                                }}
                                                            >
                                                                {nuetral_score}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    width: `${score}%`,
                                                                }}
                                                            >
                                                                {score}
                                                            </span>
                                                            <span
                                                                className={c({ singleQues }, 'naScore')}
                                                            >{`${na_score}%`}</span>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ) : null}
                                        </section>
                                    </div>
                                );
                            })}
                        </section>
                    </AccordionDetails>
                </Accordion>
            );
        });
    };

    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">
                                {EMPLYEE_EXPEIENCE} <sup>1</sup>
                            </div>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                            </div>
                        </div>
                        <ul className="navScoreList">
                            <li
                                data-testid="setReSurveyModalBtn"
                                onClick={() => setReSurveyModal(true)}
                                className="fImage resurveyImg"
                            >
                                <img src={BulletList} alt="" />
                                <span className="upperHead">{RESURVEY_TEXT}</span>
                            </li>
                            {!errorInfo && (
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            )}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    {appliedFiltersInfo()}
                    {appliedBenchmark()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    if (
                                        (!apply_employee_exp_benchmark && index === 4) ||
                                        (surveyType === 'percentile' && index === 4)
                                    )
                                        return null;
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="outcomeContent">
                            <ul className="questionLegends">
                                <li>{FREQUENCY_LEVEL_PERCENT}</li>
                                {IND_QUES_LEGEND.map(legendText => (
                                    <li>
                                        <span />
                                        {legendText}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <Fragment>
                        {Object.keys(data).length ? (
                            <div className="questionBoard clearfix">{renderCategoryData(selectedChild)}</div>
                        ) : null}

                        {!apiLoadingCount && !errorInfo && (
                            <Fragment>
                                {apply_employee_exp_benchmark ? (
                                    <div className="note">
                                        <b>{NOTE}:</b>
                                        {FOOTNOTES.map((txt, i) => {
                                            return <div>{`${i + 1}. ${txt}`}</div>;
                                        })}
                                    </div>
                                ) : (
                                    <div className="note">
                                        <b>{NOTE}:</b>
                                        {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                        {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                        {IND_EXP_QUESTION_NOTE[0]}
                                        <br />
                                        {IND_EXP_QUESTION_NOTE[1]}
                                    </div>
                                )}
                            </Fragment>
                        )}

                        {resurveyModal && (
                            <SelectModal
                                defaultSettings={defaultSettings}
                                oldSelectedId={oldSelectedId}
                                projects={projects}
                                projectsFetched={projectsFetched}
                                closeModal={setReSurveyModal}
                                selectProject={selectProject}
                                practiceRankModal
                                getComparisonYearSettings={getComparisonYearSettings}
                                compYrSettings={compYrSettings}
                                apiLoadingCount={apiLoadingCount}
                                isExModule={apply_employee_exp_benchmark}
                            />
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                {apply_employee_exp_benchmark ? (
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === BOTTOM_DECILE;
                                const BenchmarkTitle = title === NO_BENCHMARK;
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                ) : (
                    <Fragment>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                            {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                            {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                )}
            </ul>
        </Fragment>
    );
}

Questions.propTypes = {
    singleQuestion: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    resurveyModal: PropTypes.bool.isRequired,
    setReSurveyModal: PropTypes.func.isRequired,
    callReSurveyApi: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    appliedBenchmark: PropTypes.func.isRequired,
    footnoteList: PropTypes.array.isRequired,
    getComparisonYearSettings: PropTypes.func.isRequired,
    compYrSettings: PropTypes.object.isRequired,
};

export default Questions;
