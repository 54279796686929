import { call, put, takeLatest } from 'redux-saga/effects';
import { getReportsFailed, getReportsSuccess, getReportUsersSuccess, getReportUsersFailed } from './actions';

import { GET_REPORTS, GET_REPORT_USERS } from './constants';

import { getUserReportsUrl, getUserListUrl } from './apis';

export function* getUserReportsSaga() {
    try {
        const { data = {} } = yield call(getUserReportsUrl);
        const { data: reportsData = {} } = data;
        const dataToIterate = {};
        reportsData.forEach(report => {
            const { report_id } = report;
            if (dataToIterate[report_id]) {
                dataToIterate[report_id].push(report);
            } else {
                dataToIterate[report_id] = [report];
            }
        });
        yield put(getReportsSuccess(dataToIterate));
    } catch ({ response = {} }) {
        const { data: errorData = {} } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getReportsFailed({ message, error, status: 401 }));
    }
}

export function* getUserListSaga({ payload }) {
    try {
        const { data = {} } = yield call(getUserListUrl, payload);
        const { data: usersList = [] } = data;
        yield put(getReportUsersSuccess(usersList));
    } catch ({ response = {} }) {
        const { data: errorData = {} } = response;
        const { message = {}, error = '', status = '' } = errorData;
        yield put(getReportUsersFailed({ message, error, status }));
    }
}

const userReportsSagas = [
    takeLatest(`${GET_REPORTS}_PENDING`, getUserReportsSaga),
    takeLatest(`${GET_REPORT_USERS}_PENDING`, getUserListSaga),
];

export default userReportsSagas;
