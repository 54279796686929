import React, { useState, useEffect, useMemo, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Select, MenuItem, FormControl, InputLabel, Tooltip } from '@material-ui/core';
import { SwapVert } from '@material-ui/icons';
import axios from 'axios';
import moment from 'moment';

import { getStaticText } from '../../utils/constants';
import { DEMO_MAX_VALUE, RESET_TAB_REDUX } from './constants';
import endpoints from '../../Endpoints';
import AuthStore from '../../common/AuthStore';
import { getDemographicName, sortArray } from '../../utils/functions';
import DownloadIcon from '../Diagnose/assets/Download.svg';
import FilterSvg from '../Diagnose/assets/Filter.svg';
import ActiveFilter from '../Diagnose/assets/ActiveFilter.svg';
import GraphMenu from '../Inclusion/assets/GraphMenu.svg';
import GraphMenuWhite from '../Inclusion/assets/GraphMenuWhite.svg';
import Menu from '../Inclusion/assets/Menu.svg';
import MenuWhite from '../Inclusion/assets/MenuWhite.svg';

function DemoResponses({
    errorInfo,
    activeFilter,
    toggleFilterView,
    apiParams,
    surveyLoadCount,
    demoResFetched,
    demoResponses,
    getExport,
    demographics,
    getDemoResponses,
    dropDownValue,
    setDropDownValue,
    renderDemographicFilter,
    originalInd,
    setIndGlobal,
    addCustomInput,
    reloadTabData,
    defaultSettings,
    showUpdateTarget,
    setIsLoading,
}) {
    const { lang, rr_threshold } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SURVEY_LINKS,
        DOWNLOAD,
        DOWNLOAD_ALL,
        FILTERS,
        SWITCH_VIEW,
        COMPLETE_RES_TEXT,
        INCOMPLETE_RES_TEXT,
        DEMO_SURVEY_TOOLTIP,
        RESPONDENTS_TEXT,
        COMPLETE,
        INCOMPLETE,
        RES_HEAD,
        INCLUSION_COMPLETED,
        PEOPLE_RES_TEXT,
        DEMO_RES_TEXT,
        FILTERS_MAIN,
        DEMOGRAPHICS,
        DEMOGRAPHIC_FILTERS,
        INSUFF_RES,
        TOTAL_RES_TEXT,
        SURVEY_TYPE,
        UPDATE_TARGET,
        RR_SURVEY_OPTIONS,
        SURVEY_NAV_LINKS,
    } = SITE_TEXT || {};
    const [showGraphContent, toggleGraphContent] = useState(false);
    const [sortInfo, setSortKey] = useState(null);
    const { result = [], n_size = 0 } = demoResponses[dropDownValue] || {};

    const sortedResults = useMemo(() => {
        if (!sortInfo) return result;
        return sortArray(result, sortInfo.key, sortInfo.asc, sortInfo.key === 'incompleted');
        // eslint-disable-next-line
    }, [result, sortInfo]);

    const setNewSortKey = newVal => {
        const { key: oldKey = '' } = sortInfo || {};
        if (oldKey === newVal) {
            setSortKey({ ...sortInfo, asc: -1 * sortInfo.asc });
        } else {
            setSortKey({ key: newVal, asc: 1 });
        }
    };

    const graphResult = result.filter(({ response_rate }) => response_rate !== -2);
    const { ind_global } = apiParams;

    const callDemographicsData = demographic => {
        if (isEmpty(demoResponses[demographic])) {
            getDemoResponses({ ...apiParams, demographic, threshold: rr_threshold });
        }
    };

    const getDataForDemo = () => {
        const { apiBasePath, demoResPath } = endpoints;
        const demoUrl = `${apiBasePath}${demoResPath}`;
        const { accessToken = '' } = AuthStore;
        setIsLoading(true);
        const payload = {
            ...apiParams,
            demographic: dropDownValue,
            download: 1,
        };
        axios
            .post(demoUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: accessToken,
                },
                responseType: 'blob',
            })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${SURVEY_NAV_LINKS[1]} ${moment().format('MM_DD_YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(!err);
            });
    };

    useEffect(() => {
        return () => reloadTabData(RESET_TAB_REDUX[1]);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!demoResFetched && demographics.length && !surveyLoadCount) {
            const { code = '' } = demographics[0] || {};
            const valueToSet = dropDownValue || code;
            setDropDownValue(valueToSet);
            callDemographicsData(valueToSet);
        }
        // eslint-disable-next-line
    }, [demoResFetched, apiParams, demographics]);

    const headerText = useMemo(() => getDemographicName(demographics, dropDownValue), [demographics, dropDownValue]);

    return (
        <div className="tabContent demoResContent clearfix" data-testid="demoResContent">
            <h2 className="clearfix">
                {SURVEY_LINKS[1]}
                <ul className="rightSideNav clearfix">
                    <li>
                        {TOTAL_RES_TEXT} {n_size}
                    </li>
                    {originalInd === RR_SURVEY_OPTIONS[1].code && (
                        <li>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">{SURVEY_TYPE}</InputLabel>
                                <Select
                                    value={ind_global}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        classes: { paper: 'demoDropdown' },
                                    }}
                                    onChange={({ target: { value } }) => setIndGlobal(value)}
                                >
                                    {RR_SURVEY_OPTIONS.map(({ code = '', label = '' }) => (
                                        <MenuItem key={code} value={code}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </li>
                    )}
                    {showUpdateTarget && (
                        <li onClick={() => addCustomInput(true)}>
                            <span className="updateTargetBtn">{UPDATE_TARGET}</span>
                        </li>
                    )}
                    {!errorInfo[1] && (
                        <Fragment>
                            <li data-testid="getExportBtn" onClick={getExport}>
                                <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                            </li>
                            <li data-testid="getExportBtn2" onClick={getDataForDemo}>
                                <img src={DownloadIcon} alt="" /> {DOWNLOAD_ALL}
                            </li>
                        </Fragment>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                        {FILTERS}
                    </li>
                </ul>
            </h2>
            {errorInfo[1] && <div className="error">{errorInfo[1]} </div>}
            <section className="mainContent clearfix">
                <section className="dataContent clearfix">
                    <div className="upperHeader clearfix">
                        <div className="leftHeader">
                            <h3>
                                {DEMO_RES_TEXT} {headerText}
                            </h3>
                            <p>{PEOPLE_RES_TEXT}</p>
                        </div>

                        <div className="rightHeader">
                            <div className="switchGraph marginTop">
                                <div
                                    data-testid="hideChartsBtn"
                                    className={c({ isActive: !showGraphContent })}
                                    onClick={() => toggleGraphContent(false)}
                                >
                                    <img src={showGraphContent ? Menu : MenuWhite} alt="" />
                                </div>
                                <div
                                    data-testid="showChartsBtn"
                                    className={c({ isActive: showGraphContent })}
                                    onClick={() => toggleGraphContent(true)}
                                >
                                    <img src={showGraphContent ? GraphMenuWhite : GraphMenu} alt="" />
                                </div>
                            </div>
                            <div className="switchView marginTop">{SWITCH_VIEW}</div>

                            <FormControl>
                                <InputLabel id="demo-simple-select-label">{DEMOGRAPHICS}</InputLabel>
                                <Select
                                    value={dropDownValue}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        classes: { paper: 'demoDropdown' },
                                    }}
                                    onChange={({ target: { value: code } }) => {
                                        setDropDownValue(code);
                                        callDemographicsData(code);
                                    }}
                                >
                                    {demographics.map(({ code = '', label = '' }) => (
                                        <MenuItem key={code} value={code}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {result.length ? (
                        <Fragment>
                            {showGraphContent ? (
                                <Fragment>
                                    {graphResult.length ? (
                                        <Fragment>
                                            <div
                                                className={c('barChart clearfix', {
                                                    addScroll: result.length > DEMO_MAX_VALUE,
                                                })}
                                            >
                                                <div className="backPoints">
                                                    {Array.from({ length: 21 }, (_, i) => i * 5).map(point => (
                                                        <div
                                                            style={{ left: `${point}%` }}
                                                            key={point}
                                                            className="point"
                                                        >
                                                            <span>{point}</span>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="barPoints clearfix">
                                                    {graphResult.map(
                                                        ({ display_name, response_rate, total, completed }) => (
                                                            <section key={display_name} className="bars clearfix">
                                                                <Tooltip
                                                                    title={
                                                                        <span style={{ fontSize: '1.2vh' }}>
                                                                            {display_name}
                                                                        </span>
                                                                    }
                                                                >
                                                                    <div className="ellipsisPro">{display_name}</div>
                                                                </Tooltip>
                                                                <div>
                                                                    <Tooltip
                                                                        title={`${response_rate}% (${completed}/${total})`}
                                                                        arrow
                                                                    >
                                                                        <div
                                                                            className={c({
                                                                                lowWidth: response_rate <= 1,
                                                                            })}
                                                                            style={{
                                                                                width: `${response_rate}%`,
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            {response_rate}% {`(${completed}/${total})`}
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </section>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="metaChart">{INCLUSION_COMPLETED}</div>
                                            <ul className="classification clearfix">
                                                <li>{RES_HEAD} </li>
                                                <li>
                                                    <span /> {COMPLETE}
                                                </li>
                                                <li>
                                                    <span /> {INCOMPLETE}
                                                </li>
                                            </ul>
                                        </Fragment>
                                    ) : (
                                        <div className="error">{INSUFF_RES}</div>
                                    )}
                                </Fragment>
                            ) : null}
                            <table
                                id="tableData"
                                className={c('tableData demoTable', { showTable: !showGraphContent })}
                            >
                                <tbody style={{ display: 'none' }}>
                                    <tr>
                                        <th>{FILTERS_MAIN}</th>
                                    </tr>
                                    <tr>
                                        <td>{DEMOGRAPHIC_FILTERS}</td>
                                        {renderDemographicFilter()}
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th className="ellipsisPro" onClick={() => setNewSortKey('display_name')}>
                                            {headerText} <SwapVert />
                                        </th>
                                        <th onClick={() => setNewSortKey('total')}>
                                            {RESPONDENTS_TEXT} <SwapVert />
                                        </th>
                                        <th onClick={() => setNewSortKey('completed')}>
                                            {COMPLETE_RES_TEXT} <SwapVert />
                                        </th>
                                        <th onClick={() => setNewSortKey('incompleted')}>
                                            {INCOMPLETE_RES_TEXT} <SwapVert />
                                        </th>
                                        <th onClick={() => setNewSortKey('response_rate')}>
                                            {INCLUSION_COMPLETED} <SwapVert />
                                        </th>
                                    </tr>
                                    {sortedResults.map(({ display_name, total, completed, response_rate }) => (
                                        <tr key={display_name}>
                                            <td className="ellipsisPro">
                                                <Tooltip
                                                    title={<span style={{ fontSize: '1.2vh' }}>{display_name}</span>}
                                                >
                                                    <span>{display_name}</span>
                                                </Tooltip>
                                            </td>
                                            <td>{total === -2 ? '-' : total}</td>
                                            <td>{completed === -2 ? '-' : completed}</td>
                                            <td>{completed === -2 ? '-' : total - completed}</td>
                                            <td>
                                                {response_rate === -2 ? (
                                                    <Tooltip
                                                        title={
                                                            <span style={{ fontSize: '1.2vh' }}>
                                                                {DEMO_SURVEY_TOOLTIP}
                                                            </span>
                                                        }
                                                    >
                                                        <span>{INSUFF_RES}</span>
                                                    </Tooltip>
                                                ) : (
                                                    `${response_rate}%`
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Fragment>
                    ) : null}
                </section>
            </section>
        </div>
    );
}

DemoResponses.propTypes = {
    errorInfo: PropTypes.array.isRequired,
    demographics: PropTypes.array.isRequired,
    showUpdateTarget: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    demoResFetched: PropTypes.bool.isRequired,
    surveyLoadCount: PropTypes.number.isRequired,
    originalInd: PropTypes.number.isRequired,
    apiParams: PropTypes.object.isRequired,
    demoResponses: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    setDropDownValue: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getExport: PropTypes.func.isRequired,
    getDemoResponses: PropTypes.func.isRequired,
    renderDemographicFilter: PropTypes.func.isRequired,
    setIndGlobal: PropTypes.func.isRequired,
    reloadTabData: PropTypes.func.isRequired,
    addCustomInput: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
};

export default DemoResponses;
