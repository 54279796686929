import { fromJS } from 'immutable';
import { getStaticTextAnalytics } from './constants';
import getFilterString, {
    getSelectedFilterIdsById,
    removeQuartileFromFilterParams,
    removeYearFromFilterParams,
} from './assets/functions';
import { filter } from 'lodash';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    UPDATE_ANALYTICS_FILTERS,
    GET_DIFFERENTIAL,
    GET_RANKING,
    GET_ANALYTICS_CATEGORY,
    GET_FILTERS_DATA,
    GET_ANALYTICS_FLAGPOLE,
    GET_ANALYTICS_SCORECARD,
    UPDATE_SCORECARD_DROPDOWN,
    UPDATE_ANALYTICS_CATEGORY,
    UPDATE_DIFFERENTIAL_DATA,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
    SET_ORG_SIZE_DATA,
    GET_MEDIAN,
    UPDATE_MEDIAN_DATA,
    GET_DIFFERENTIAL_QUARTILE,
    SET_QUARTILE_TOP_BTN,
    SET_MEDIAN_TOP_BTN,
    GET_DIFFERENTIAL_MEDIAN,
    GET_CUSTOM_BM_LIST,
    GET_CUSTOM_BM_SURVEY_LIST,
    SAVE_CUSTOM_BM,
    GET_CUSTOM_BM_DETAIL,
    SET_CREATE_CUSTOM_BM_PAYLOAD,
    SET_CUSTOM_BM_FILTER,
    DELETE_CUSTOM_BM,
    UPDATE_CUSTOM_BM,
    CHECK_NAME_EXIST,
    SET_CUSTOM_BM_MSG,
    SEND_CUSTOM_BM_REQUEST,
    SET_ACTION_CODE,
    GET_APPROVAL_REQUESTS,
    GET_REJECTED_REQUESTS,
    APPROVE_REQUEST,
    REJECT_REQUEST,
    GET_APPROVED_REQUESTS,
    SAVE_AND_PUBLISH,
    CREATE_CUSTOM_BM,
    DUPLICATE_BM,
    SET_EDIT_CUSTOM_BM_PAYLOAD,
    RESET_FORM,
    PRE_VALIDATION_CHECK,
    RESET_PRE_VALIDATION_CHECK,
    GET_REALTIME_YEAR,
    GET_REALTIME_ANALYTICS,
    GET_REALTIME_SURVEY_LIST,
    GET_REALTIME_APPROVAL,
    GET_REALTIME_REJECT,
    SHOW_SECOND_POPUP,
    HIDE_SECOND_POPUP,
    GET_REALTIME_FILTERS_DATA,
    UPDATE_ANALYTICS_REALTIME_FILTERS,
    GET_REALTIME_FILTERS,
    REFRESH_META_REQ,
    GET_ANALYTICS_SECTOR,
    SET_PENDING_PPT_DATA,
} = staticTextAnalytics;

export const INITIAL_STATE = fromJS({
    count: 0,
    dataAtGlance: fromJS({}),
    dataAtGlanceFetched: false,
    differentialData: fromJS({}),
    differentialDataFetched: false,
    differentialError: fromJS([]),
    rankingData: fromJS({}),
    rankingFetched: false,
    rankingError: fromJS([]),
    analyticsPerCategoryData: fromJS({}),
    flagpoleData: fromJS({}),
    flagpoleFetched: false,
    flagpoleError: fromJS([]),
    scorecardData: fromJS({}),
    scorecardDataFetched: false,
    scorecardError: fromJS([]),
    filters: [],
    defaultFilters: [],
    filterParam: '',
    scorecardDropdown: '',
    filtersFetched: false,
    filtersApplied: false,
    filtersMapping: fromJS([]),
    filtersMappingFetched: false,
    defaultYear: '',
    range: fromJS([0, 100]),
    medianData: fromJS({}),
    medianDataFetched: false,
    medianError: fromJS([]),
    quartileData: fromJS({}),
    quartileDataFetched: false,
    quartileError: fromJS([]),
    showQuartileBtn: false,
    showMedianBtn: false,
    customBmList: fromJS([]),
    customBmListFetched: false,
    customBmSurveyList: fromJS([]),
    saveCustomBmMsg: '',
    saveCustomBmError: '',
    customBmDetail: fromJS([]),
    createCustomBmForm: fromJS(CREATE_CUSTOM_BM),
    editCustomBmForm: fromJS(CREATE_CUSTOM_BM),
    createCustomBmFilter: fromJS([]),
    checkNameMsg: '',
    isNameAvailable: false,
    surveyListLoading: false,
    customBmDefaultFilter: fromJS([]),
    sendCustomBmReqMsg: '',
    actionCode: 0,
    approvalRequestList: fromJS([]),
    approvalRequestListFetched: false,
    approvalRejectList: fromJS([]),
    approvalRejectListFetched: false,
    approvedBmList: fromJS([]),
    approvedBmListFetched: false,
    bmExist: false,
    existingBm: fromJS({}),
    defaultRealTimeYear: '',
    realtimeTotalData: fromJS([]),
    realtimeSurveyList: fromJS([]),
    realtimeTotalDataFetched: false,
    realtimeSurveyListFetched: false,
    realtimeError: '',
    realtimeApproveList: fromJS([]),
    realTimeAppproveListFeiched: false,
    realTimeRejectList: fromJS([]),
    realTimeRejectListFetched: false,
    secondPopupVisible: false,
    // new
    realtimeFilters: [],
    realtimeFiltersFetched: false,
    realtimeFilterParam: '',
    defaultRealtimeFilters: [],
    realtimeFiltersApplied: false,
    // new
    realtimeFiltersData: [],
    realtimeDefaultFiltersData: [],
    realtimeFiltersParams: '',
    realtimeFilterDataFetched: false,
    refreshMetaData: [],
    // role: false,
    analyticsPerSectorData: fromJS({}),
    pendingPptData: fromJS({}),
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_FILTERS_DATA}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_FILTERS_DATA}_SUCCESS`: {
            const { data, year } = payload || {};
            const filterYear = `year:${year}`;
            const customBmFilter = data.filter(({ filter_id }) => filter_id !== 'year');
            const existingFilters = state.get('filters') || [];
            const filtersToUpdate = !existingFilters.length ? data : [existingFilters[0], ...data.slice(1)];
            return state
                .update('count', count => count - 1)
                .set('filters', filtersToUpdate)
                .set('defaultFilters', data)
                .set('createCustomBmFilter', customBmFilter)
                .set('customBmDefaultFilter', customBmFilter)
                .set('filterParam', filterYear)
                .set('filtersFetched', true);
        }
        case `${GET_FILTERS_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('filters', fromJS([]))
                .set('filtersFetched', true);
        }

        case `${GET_REALTIME_FILTERS_DATA}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }

        case `${GET_REALTIME_FILTERS_DATA}_SUCCESS`: {
            const { data, year } = payload || {};
            const filterYear = `year:${year}`;
            // const customBmFilter = data.filter(({ filter_id }) => filter_id !== 'year');
            return (
                state
                    .update('count', count => count - 1)
                    .set('realtimeFilters', data)
                    .set('defaultRealtimeFilters', data)
                    // .set('createCustomBmFilter', customBmFilter)
                    // .set('customBmDefaultFilter', customBmFilter)
                    .set('realtimeFilterParam', filterYear)
                    .set('realtimeFiltersFetched', true)
            );
        }
        case `${GET_REALTIME_FILTERS_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('realtimeFilters', fromJS([]))
                .set('realtimeFiltersFetched', true);
        }

        case `${GET_REALTIME_FILTERS}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_REALTIME_FILTERS}_SUCCESS`: {
            const { data, year } = payload || {};
            const filterYear = `year:${year}`;
            // const customBmFilter = data.filter(({ filter_id }) => filter_id !== 'year');
            return state
                .update('count', count => count - 1)
                .set('realtimeFiltersData', data)
                .set('realtimeDefaultFiltersData', data)
                .set('realtimeFiltersParams', filterYear)
                .set('realtimeFilterDataFetched', true);
        }
        case `${GET_REALTIME_FILTERS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('realtimeFiltersData', fromJS([]))
                .set('realtimeFilterDataFetched', true);
        }

        case `${GET_FILTERS_MAPPING}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_FILTERS_MAPPING}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('filtersMapping', payload)
                .set('filtersMappingFetched', true);
        }
        case `${GET_FILTERS_MAPPING}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('filtersMapping', fromJS([]))
                .set('filtersMappingFetched', true);
        }
        case `${GET_DATA_AT_GLANCE}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DATA_AT_GLANCE}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('dataAtGlance', payload)
                .set('dataAtGlanceFetched', true);
        }
        case `${GET_DATA_AT_GLANCE}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('dataAtGlance', fromJS({}))
                .set('dataAtGlanceFetched', true);
        }
        case `${GET_DIFFERENTIAL}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DIFFERENTIAL}_SUCCESS`: {
            return (
                state
                    .update('count', count => count - 1)
                    .set('differentialData', payload)
                    .set('differentialDataFetched', true)
                    // .set('quartileShowBtn', false);
                    .set('showQuartileBtn', false)
            );
        }
        case `${GET_DIFFERENTIAL}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return (
                state
                    .update('count', count => count - 1)
                    .set('differentialData', fromJS({}))
                    .set('differentialDataFetched', true)
                    .set('differentialError', fromJS(errorMsg))
                    // .set('quartileShowBtn', false)
                    .set('showQuartileBtn', false)
            );
        }

        // new
        case `${GET_DIFFERENTIAL_QUARTILE}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DIFFERENTIAL_QUARTILE}_SUCCESS`: {
            return (
                state
                    .update('count', count => count - 1)
                    // .set('quartileData', payload)
                    // .set('quartileDataFetched', true)
                    // .set('quartileShowBtn', true);
                    .set('differentialData', payload)
                    .set('differentialDataFetched', true)
                    .set('showQuartileBtn', true)
            );
        }
        case `${GET_DIFFERENTIAL_QUARTILE}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return (
                state
                    .update('count', count => count - 1)
                    // .set('quartileData', fromJS({}))
                    // .set('quartileDataFetched', true)
                    // .set('quartileError', fromJS(errorMsg))
                    .set('differentialData', fromJS({}))
                    .set('differentialDataFetched', true)
                    .set('differentialError', fromJS(errorMsg))
                    .set('showQuartileBtn', true)
            );
        }
        case `${GET_MEDIAN}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }

        case `${GET_MEDIAN}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('medianData', payload)
                .set('medianDataFetched', true)
                .set('showMedianBtn', false);
        }
        case `${GET_MEDIAN}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('medianData', fromJS({}))
                .set('medianDataFetched', true)
                .set('medianError', fromJS(errorMsg))
                .set('showMedianBtn', false);
        }

        case `${GET_DIFFERENTIAL_MEDIAN}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }

        case `${GET_DIFFERENTIAL_MEDIAN}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('medianData', payload)
                .set('medianDataFetched', true)
                .set('showMedianBtn', true);
        }
        case `${GET_DIFFERENTIAL_MEDIAN}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('medianData', fromJS({}))
                .set('medianDataFetched', true)
                .set('medianError', fromJS(errorMsg))
                .set('showMedianBtn', true);
        }
        case `${GET_RANKING}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_RANKING}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('rankingData', payload)
                .set('rankingFetched', true);
        }
        case `${GET_RANKING}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('rankingError', fromJS(errorMsg))
                .set('rankingData', fromJS({}))
                .set('rankingFetched', true);
        }
        case `${GET_ANALYTICS_CATEGORY}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_CATEGORY}_SUCCESS`: {
            return state.update('count', count => count - 1).set('analyticsPerCategoryData', payload);
        }
        case `${GET_ANALYTICS_CATEGORY}_FAILED`: {
            return state.update('count', count => count - 1).set('analyticsPerCategoryData', payload.data);
        }
        case `${GET_ANALYTICS_SECTOR}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_SECTOR}_SUCCESS`: {
            return state.update('count', count => count - 1).set('analyticsPerSectorData', payload);
        }
        case `${GET_ANALYTICS_SECTOR}_FAILED`: {
            return state.update('count', count => count - 1).set('analyticsPerSectorData', payload.data);
        }
        case `${SET_PENDING_PPT_DATA}`: {
            return state.set('pendingPptData', payload);
        }
        case `${GET_ANALYTICS_FLAGPOLE}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_FLAGPOLE}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('flagpoleData', payload)
                .set('flagpoleFetched', true);
        }
        case `${GET_ANALYTICS_FLAGPOLE}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('flagpoleError', fromJS(errorMsg))
                .set('flagpoleData', fromJS({}))
                .set('flagpoleFetched', true);
        }
        case `${GET_ANALYTICS_SCORECARD}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_SCORECARD}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('scorecardData', payload)
                .set('scorecardDataFetched', true);
        }
        case `${GET_ANALYTICS_SCORECARD}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('scorecardError', fromJS(errorMsg))
                .set('scorecardData', fromJS({}))
                .set('scorecardDataFetched', true);
        }
        case UPDATE_ANALYTICS_FILTERS: {
            const { filters } = payload || {};
            const range = state.get('range');
            const filterParamItem = `${getFilterString(filters)}`;
            const filterParamsString = `${getFilterString(filters)};totalcomplete:${range[0]},${range[1]}`;
            const filterParam = range[1] === '' ? filterParamItem : filterParamsString;

            const [filterYear] = getSelectedFilterIdsById(filters, 'year');
            const defaultYear = state.get('defaultYear');

            let differentialDataFetchedState = false;
            let medianDataFetchedState = false;
            const filterQuartile = getSelectedFilterIdsById(filters, 'quartile');
            if (filterQuartile.length) {
                const filterParamExcQuartile = removeQuartileFromFilterParams(filterParam);
                const removeYear = removeYearFromFilterParams(filterParamExcQuartile);
                differentialDataFetchedState = !removeYear;
                medianDataFetchedState = !removeYear;
            }
            if (defaultYear !== filterYear) {
                const defaultParam = `year:${filterYear}`;
                return state
                    .set('filters', payload.filters)
                    .set('filtersApplied', payload.filtersApplied)
                    .set('filterParam', defaultParam)
                    .set('differentialDataFetched', false)
                    .set('dataAtGlanceFetched', false)
                    .set('analyticsPerCategoryData', fromJS({}))
                    .set('scorecardDataFetched', false)
                    .set('rankingFetched', false)
                    .set('flagpoleFetched', false)
                    .set('filtersFetched', false)
                    .set('filtersMappingFetched', false)
                    .set('defaultYear', filterYear)
                    .set('medianDataFetched', false)
                    .set('quartileDataFetched', false)
                    .set('showQuartileBtn', false)
                    .set('showMedianBtn', false);
            }
            return state
                .set('filters', payload.filters)
                .set('filtersApplied', payload.filtersApplied)
                .set('filterParam', filterParam)
                .set('differentialDataFetched', differentialDataFetchedState)
                .set('dataAtGlanceFetched', false)
                .set('analyticsPerCategoryData', fromJS({}))
                .set('scorecardDataFetched', false)
                .set('rankingFetched', false)
                .set('flagpoleFetched', false)
                .set('medianDataFetched', medianDataFetchedState)
                .set('quartileDataFetched', false)
                .set('showQuartileBtn', false)
                .set('showMedianBtn', false);
        }

        case UPDATE_ANALYTICS_REALTIME_FILTERS: {
            const { filters } = payload || {};
            const range = state.get('range');
            const filterParamItem = `${getFilterString(filters)}`;
            const filterParamsString = `${getFilterString(filters)};totalcomplete:${range[0]},${range[1]}`;
            const filterParam = range[1] === '' ? filterParamItem : filterParamsString;

            const [filterYear] = getSelectedFilterIdsById(filters, 'year');
            const defaultRealTimeYear = state.get('defaultRealTimeYear');

            if (defaultRealTimeYear !== filterYear) {
                const defaultParam = `year:${filterYear}`;
                // const defaultParam = `year:2025`;
                return state
                    .set('realtimeFiltersApplied', payload.realtimeFiltersApplied)
                    .set('realtimeFiltersData', payload.filters)
                    .set('realtimeFilterDataFetched', false)
                    .set('realtimeTotalDataFetched', false)
                    .set('realtimeSurveyListFetched', false)
                    .set('realtimeFiltersParams', defaultParam);
            }
            return (
                state
                    .set('realtimeFiltersApplied', payload.realtimeFiltersApplied)
                    .set('realtimeFiltersData', payload.filters)
                    //  .set('realtimeFilterDataFetched', false)
                    .set('realtimeTotalDataFetched', false)
                    .set('realtimeSurveyListFetched', false)
                    .set('realtimeFiltersParams', filterParam)
            );
        }
        case UPDATE_SCORECARD_DROPDOWN: {
            return state.set('scorecardDropdown', payload);
        }
        case UPDATE_ANALYTICS_CATEGORY: {
            return state.set('analyticsPerCategoryData', payload);
        }
        case UPDATE_DIFFERENTIAL_DATA: {
            return state.set('differentialData', payload);
        }
        case UPDATE_MEDIAN_DATA: {
            return state.set('medianData', payload);
        }
        case SET_ORG_SIZE_DATA: {
            return state.set('range', payload);
        }

        case SET_QUARTILE_TOP_BTN: {
            return state.set('showQuartileBtn', true);
        }
        case SET_MEDIAN_TOP_BTN: {
            return state.set('showMedianBtn', true);
        }
        case `${GET_DEFAULT_YEAR}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DEFAULT_YEAR}_SUCCESS`: {
            const filterYear = `year:${payload}`;
            return state
                .update('count', count => count - 1)
                .set('defaultYear', payload)
                .set('filterParam', filterYear)
                .updateIn(['createCustomBmForm'], oldSettings => oldSettings.merge({ year: payload }))
                .updateIn(['editCustomBmForm'], oldSettings => oldSettings.merge({ year: payload }));
        }
        case `${GET_DEFAULT_YEAR}_FAILED`: {
            return state.set('defaultYear', '').update('count', count => count - 1);
        }

        case `${GET_REALTIME_YEAR}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_REALTIME_YEAR}_SUCCESS`: {
            // const filterYear = `year:${payload}`;
            return state.update('count', count => count - 1).set('defaultRealTimeYear', payload);
        }
        case `${GET_REALTIME_YEAR}_FAILED`: {
            return state.set('defaultRealTimeYear', '').update('count', count => count - 1);
        }
        case `${GET_REALTIME_ANALYTICS}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_REALTIME_ANALYTICS}_SUCCESS`: {
            // const filterYear = `year:${payload}`;
            return state
                .update('count', count => count - 1)
                .set('realtimeTotalDataFetched', true)
                .set('realtimeTotalData', payload);
            // .set('filterParam', filterYear);
        }
        case `${GET_REALTIME_ANALYTICS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('realtimeTotalData', '')
                .set('realtimeTotalDataFetched', true);
        }

        case `${GET_REALTIME_SURVEY_LIST}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_REALTIME_SURVEY_LIST}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('realtimeSurveyList', payload)
                .set('realtimeSurveyListFetched', true);
        }
        case `${GET_REALTIME_SURVEY_LIST}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('realtimeSurveyList', '')
                .set('realtimeSurveyListFetched', true);
        }

        case `${GET_REALTIME_APPROVAL}_PENDING`: {
            return state
                .set('realtimeError', '')
                .update('count', count => count + 1)
                .set('secondPopupVisible', false);
        }
        case `${GET_REALTIME_APPROVAL}_SUCCESS`: {
            return state.update('count', count => count - 1).set('secondPopupVisible', true);
        }
        case `${GET_REALTIME_APPROVAL}_FAILED`: {
            return state.update('count', count => count - 1);
        }

        case `${GET_REALTIME_REJECT}_PENDING`: {
            return state.set('realtimeError', '').update('count', count => count + 1);
        }
        case `${GET_REALTIME_REJECT}_SUCCESS`: {
            return state.update('count', count => count - 1).set('secondPopupVisible', true);
        }
        case `${GET_REALTIME_REJECT}_FAILED`: {
            return state.update('count', count => count - 1);
        }
        case `${GET_CUSTOM_BM_LIST}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${GET_CUSTOM_BM_LIST}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('customBmList', payload)
                .set('customBmListFetched', true);
        }
        case `${GET_CUSTOM_BM_LIST}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('customBmList', fromJS([]))
                .set('customBmListFetched', true);
        }
        case `${GET_CUSTOM_BM_SURVEY_LIST}_PENDING`: {
            return state.set('customBenchmarkError', '').set('surveyListLoading', true);
        }
        case `${GET_CUSTOM_BM_SURVEY_LIST}_SUCCESS`: {
            return state.set('customBmSurveyList', payload).set('surveyListLoading', false);
        }
        case `${GET_CUSTOM_BM_SURVEY_LIST}_FAILED`: {
            return state.set('customBmSurveyList', fromJS([])).set('surveyListLoading', false);
        }
        case `${SAVE_CUSTOM_BM}_PENDING`: {
            return state.set('saveCustomBmError', '').update('count', count => count + 1);
        }
        case `${SAVE_CUSTOM_BM}_SUCCESS`: {
            const defaultYear = state.get('defaultYear');
            return state
                .update('count', count => count - 1)
                .set('saveCustomBmMsg', payload)
                .set('customBmSurveyList', fromJS([]))
                .set('customBmListFetched', false)
                .set('checkNameMsg', '')
                .set(
                    'createCustomBmForm',
                    fromJS({
                        ...CREATE_CUSTOM_BM,
                        year: defaultYear,
                    })
                );
        }
        case `${SAVE_CUSTOM_BM}_FAILED`: {
            const { errors = '' } = payload || {};
            return state.update('count', count => count - 1).set('saveCustomBmError', errors);
        }
        case `${GET_CUSTOM_BM_DETAIL}_PENDING`: {
            return state.update('count', count => count + 1);
        }
        case `${GET_CUSTOM_BM_DETAIL}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('customBmDetail', payload)
                .set('checkNameMsg', '');
        }
        case `${GET_CUSTOM_BM_DETAIL}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('customBmDetail', fromJS([]))
                .set('checkNameMsg', '');
        }
        case SET_CREATE_CUSTOM_BM_PAYLOAD: {
            return state.set('createCustomBmForm', payload);
        }
        case SET_EDIT_CUSTOM_BM_PAYLOAD: {
            return state.set('editCustomBmForm', payload);
        }
        case SET_CUSTOM_BM_FILTER: {
            return state.set('createCustomBmFilter', payload);
        }
        case `${DELETE_CUSTOM_BM}_PENDING`: {
            return state.update('count', count => count + 1);
        }
        case `${DELETE_CUSTOM_BM}_SUCCESS`: {
            const { message = '' } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('customBmListFetched', false)
                .set('saveCustomBmMsg', message);
        }
        case `${DELETE_CUSTOM_BM}_FAILED`: {
            return state.update('count', count => count - 1).set('customBmListFetched', false);
        }
        case `${UPDATE_CUSTOM_BM}_PENDING`: {
            return state.update('count', count => count + 1);
        }
        case `${UPDATE_CUSTOM_BM}_SUCCESS`: {
            const defaultYear = state.get('defaultYear');
            return state
                .update('count', count => count - 1)
                .set('customBmListFetched', false)
                .set(
                    'editCustomBmForm',
                    fromJS({
                        ...CREATE_CUSTOM_BM,
                        year: defaultYear,
                    })
                );
        }
        case `${UPDATE_CUSTOM_BM}_FAILED`: {
            return state.update('count', count => count - 1).set('customBmListFetched', false);
        }
        case `${CHECK_NAME_EXIST}_SUCCESS`: {
            const { message = '', success = false } = payload || {};
            return state.set('checkNameMsg', message).set('isNameAvailable', !success);
        }
        case `${CHECK_NAME_EXIST}_FAILED`: {
            return state.set('checkNameMsg', '').set('isNameAvailable', false);
        }
        case SET_CUSTOM_BM_MSG: {
            return state.set('saveCustomBmMsg', payload);
        }
        case `${SEND_CUSTOM_BM_REQUEST}_PENDING`: {
            return state.update('count', count => count + 1);
        }
        case `${SEND_CUSTOM_BM_REQUEST}_SUCCESS`: {
            const { message } = payload || {};
            const defaultYear = state.get('defaultYear');
            return state
                .update('count', count => count - 1)
                .set('saveCustomBmMsg', message)
                .set('customBmDetail', fromJS([]))
                .set('customBmListFetched', false)
                .set('approvalRequestListFetched', false)
                .set('checkNameMsg', '')
                .set(
                    'createCustomBmForm',
                    fromJS({
                        ...CREATE_CUSTOM_BM,
                        year: defaultYear,
                    })
                )
                .set(
                    'editCustomBmForm',
                    fromJS({
                        ...CREATE_CUSTOM_BM,
                        year: defaultYear,
                    })
                );
        }
        case `${SEND_CUSTOM_BM_REQUEST}_FAILED`: {
            return state.update('count', count => count - 1).set('saveCustomBmMsg', '');
        }
        case SET_ACTION_CODE:
            return state.set('actionCode', payload);
        case `${GET_APPROVAL_REQUESTS}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${GET_APPROVAL_REQUESTS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('approvalRequestList', payload)
                .set('approvalRequestListFetched', true);
        }
        case `${GET_APPROVAL_REQUESTS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('approvalRequestList', fromJS([]))
                .set('approvalRequestListFetched', true);
        }
        case `${GET_REJECTED_REQUESTS}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${GET_REJECTED_REQUESTS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('approvalRejectList', payload)
                .set('approvalRejectListFetched', true);
        }
        case `${GET_REJECTED_REQUESTS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('approvalRejectList', fromJS([]))
                .set('approvalRejectListFetched', true);
        }
        case `${GET_APPROVED_REQUESTS}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${GET_APPROVED_REQUESTS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('approvedBmList', payload)
                .set('approvedBmListFetched', true);
        }
        case `${GET_APPROVED_REQUESTS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('approvedBmList', fromJS([]))
                .set('approvedBmListFetched', true);
        }
        case `${APPROVE_REQUEST}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${APPROVE_REQUEST}_SUCCESS`: {
            const { message } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('saveCustomBmMsg', message)
                .set('approvalRequestListFetched', false)
                .set('approvalRejectListFetched', false);
        }
        case `${APPROVE_REQUEST}_FAILED`: {
            return state.update('count', count => count - 1);
        }
        case `${REJECT_REQUEST}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${REJECT_REQUEST}_SUCCESS`: {
            const { message } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('saveCustomBmMsg', message)
                .set('approvalRequestListFetched', false)
                .set('approvalRejectListFetched', false);
        }
        case `${REJECT_REQUEST}_FAILED`: {
            return state.update('count', count => count - 1);
        }
        case SAVE_AND_PUBLISH: {
            return state
                .set('customBmDetail', fromJS([]))
                .set('customBmListFetched', false)
                .set('approvalRequestListFetched', false);
        }
        case `${DUPLICATE_BM}_PENDING`: {
            return state.set('customBenchmarkError', '').update('count', count => count + 1);
        }
        case `${DUPLICATE_BM}_SUCCESS`: {
            const { message } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('saveCustomBmMsg', message)
                .set('customBmDetail', fromJS([]))
                .set('customBmListFetched', false);
        }
        case `${DUPLICATE_BM}_FAILED`: {
            return state.update('count', count => count - 1);
        }
        case RESET_FORM: {
            const defaultFilters = state.get('customBmDefaultFilter');
            return state
                .set('checkNameMsg', '')
                .set('customBmSurveyList', fromJS([]))
                .set('createCustomBmFilter', defaultFilters);
        }
        case PRE_VALIDATION_CHECK: {
            return state
                .set('bmExist', true)
                .set('existingBm', payload)
                .update('count', count => count - 1);
        }
        case RESET_PRE_VALIDATION_CHECK: {
            return state.set('bmExist', false);
        }
        case SHOW_SECOND_POPUP: {
            return state.set('secondPopupVisible', true);
        }
        case HIDE_SECOND_POPUP: {
            return state.set('secondPopupVisible', false);
        }
        case `${REFRESH_META_REQ}_PENDING`:
            return state
                .set('error', '')
                .set('realtimeSurveyListFetched', false)
                .set('refreshMetaData', [])
                .update('count', count => count + 1);
        case `${REFRESH_META_REQ}_SUCCESS`: {
            return state.update('count', count => count - 1).set('refreshMetaData', payload);
        }
        case `${REFRESH_META_REQ}_FAILED`: {
            return state.update('count', count => count - 1);
        }
        default:
            return state;
    }
};

export default reducer;
