import React, { useEffect, useState, useMemo, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { MenuItem, Select, Tooltip } from '@material-ui/core';

import { cloneDeep } from 'lodash';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import {
    getBenchmarkString,
    getColor,
    getNegColor,
    getRandomValues,
    manipulateHeatmapTitle,
} from '../../../../utils/functions';
import colors from '../../../../sass/colors';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import ArrowRight from '../../assets/ArrowRight.svg';
import ArrowUp from '../../assets/ArrowUp.svg';
import ArrowDown from '../../assets/ArrowDown.svg';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';

function DeepDive({
    filters,
    selectedDemographic,
    ieHeatmap,
    ieHeatmapFetched,
    sortIeHeatmap,
    defaultSettings,
    apiParams,
    getHeatMapPractice,
    heatMapPracticeFetched,
    heatMapPractice,
    callHeatMap,
    selectedTab,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    setSelectedDemographic,
    selectedDemographics,
    crossTabDemo,
    setSelectedTab,
    setCrossTabDemo,
    appliedBenchmark,
}) {
    const SLICE_MAX_VALUE = 8;
    const SLICE_MIN_VALUE = 0;
    const {
        quartileColors = [],
        demographic = '',
        survey_version = '',
        lang: langTxt = 1033,
        engagement = '',
        apply_employee_exp_benchmark = false,
        report_type: surveyType = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const {
        MAX_LENGTH_SUBSTRING,
        PRACTICE_VAR,
        SORT_ASCENDING,
        SORT_DESCENDING,
        IE_CROSSTAB_OPTIONS,
        DEFAULT_STATE,
    } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const {
        N_SIZE,
        OHI_SCORE_TEXT,
        NEGATIVE_PRACTICES,
        PRACTICE_CATEGORY_MAP,
        PRACTICE_CATEGORY_MAP_3_2,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        FREQUENCY_LEVEL_PERCENT,
        IND_QUES_LEGEND,
        NON_PROFIT_SURVEY,
    } = SITE_TEXT;
    const { demographics = [], ex_benchmarks = [] } = filters[0] || {};
    const { header = [], items = [] } = ieHeatmap || {};
    const { items: items0 = [] } = items[0] || {};
    const { score: size = '' } = items0[0] || {};
    const { items: ohiItems = [], header: ohiHeader = [] } = heatMapPractice || {};
    const [minSliceValue, setMinSliceValue] = useState(SLICE_MIN_VALUE);
    const [maxSliceValue, setMaxSliceValue] = useState(SLICE_MAX_VALUE);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [sortOrder, setSortOrder] = useState({});
    const is3_2survey = survey_version === '3_2';
    const mapToUse = is3_2survey ? PRACTICE_CATEGORY_MAP_3_2 : PRACTICE_CATEGORY_MAP;
    const isNonProfit = engagement === NON_PROFIT_SURVEY;

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
        columns: 10000,
    };

    const headerIE = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [ieHeatmap]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(_elem => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [ieHeatmap, maxSliceValue, minSliceValue, headerIE]);

    // Condition to set table data when no of columns are different in ohi heatmap and ee heatmap due to different threshold
    const dataToUseOhi = useMemo(() => {
        if (!ohiItems.length) return [];
        const newItems = [];
        if (headerIE.length === ohiHeader.length) return ohiItems;
        if (ohiHeader.length > headerIE.length) {
            const headerDiff = [];
            ohiHeader.forEach((item, index) => {
                if (!headerIE.includes(item)) {
                    headerDiff.push(index);
                }
            });
            ohiItems.forEach(({ items: sub_items = [], ...rest }) => {
                const subItem = cloneDeep(sub_items);
                newItems.push({
                    ...rest,
                    items: subItem.filter((_, i) => !headerDiff.includes(i)),
                });
            });
        } else {
            ohiItems.forEach(({ items: sub_items = [], ...rest }) => {
                let subItem = cloneDeep(sub_items);
                const headerDiff = [];
                headerIE.forEach((item, index) => {
                    if (!ohiHeader.includes(item)) {
                        headerDiff.push(index);
                    }
                });
                headerDiff.forEach(index => {
                    subItem = [...subItem.slice(0, index), { score: '-', quartile: '999' }, ...subItem.slice(index)];
                });
                newItems.push({
                    ...rest,
                    items: subItem,
                });
            });
        }
        return newItems;
        // eslint-disable-next-line
    }, [heatMapPractice, headerIE, ieHeatmap]);

    const headerToUse = headerIE;
    const tabOptionsToUse = crossTabDemo === DEFAULT_STATE ? mapToUse.slice(0, 3) : [];

    useEffect(() => {
        if (!heatMapPracticeFetched) {
            getHeatMapPractice({
                ...apiParams,
                ...addOns,
                type: PRACTICE_VAR,
                demographic: selectedDemographic || demographics[0].code,
                ex_benchmarks: apply_employee_exp_benchmark ? getBenchmarkString(ex_benchmarks) || '1001:1' : '',
            });
        }
        // eslint-disable-next-line
    }, [heatMapPracticeFetched]);

    useEffect(() => {
        if (items.length - 1 >= maxSliceValue) {
            setShowRightArrow(true);
        } else {
            setShowRightArrow(false);
        }
        // eslint-disable-next-line
    }, [maxSliceValue, items]);

    const sideHeaderVal = () => {
        const { title: practiceCategory, children = [], children_ohi = [] } = mapToUse[selectedTab];
        return ['Management Practices', practiceCategory].map((categoryN, parentI) => {
            // const len = childArr.includes('N') ? 1 : childArr.length;
            const len = parentI === 0 ? children_ohi.length : children.length;
            const height = parentI === 0 ? (len - 1) * 7 - 1 : len * 7 + 2;
            return (
                <div style={{ height: `${height}vh` }} className="deepDiveHeading">
                    <div style={{ transform: 'rotate(-90deg)', textWrap: 'wrap', width: '100%' }}>{categoryN}</div>
                </div>
            );
        });
    };

    const getHeapMapValues = itemsToIterate => {
        const { children } = mapToUse[selectedTab];
        return itemsToIterate
            .filter((_elem, parentI) => children.includes(header[parentI]) || !header[parentI] === 'N')
            .map((subItems, parentI) => {
                const title = header.filter(headerN => children.includes(headerN) || !header[parentI] === 'N')[parentI];
                const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                return (
                    <div className="sectionDiv" key={title}>
                        {/* {!parentI ? (
                            <ul>
                                <li className="sectionContent headerContent">{practiceCategory}</li>
                            </ul>
                        ) : null} */}
                        <ul>
                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                <li className="sectionContent">
                                    {title} {isNegativeTrait ? <sup>3</sup> : null}
                                </li>
                            </Tooltip>
                            {subItems
                                .slice(minSliceValue, maxSliceValue)
                                .map(({ score: innerScore, quartile = '' }, index) => {
                                    const i = getColor(innerScore, [], false, true);
                                    const iNeg = getNegColor(innerScore, [], false, true);
                                    const {
                                        positiveBg = '#ffffff',
                                        negativeBg = '#ffffff',
                                        color: oldColor = '#ffffff',
                                    } = IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                    const oldBg = isNegativeTrait ? negativeBg : positiveBg;
                                    const { background: qBg = '', color: qColor = '', border: grad = '' } =
                                        quartileColors[quartile] || {};
                                    const background = apply_employee_exp_benchmark ? qBg : oldBg;
                                    const color = apply_employee_exp_benchmark ? qColor : oldColor;
                                    const gradient = apply_employee_exp_benchmark ? grad : oldBg;
                                    return (
                                        <li
                                            className="contentList"
                                            key={`${innerScore}${index}${getRandomValues()}`}
                                            style={{
                                                border: `1px solid white`,
                                                background: `linear-gradient(135deg, ${gradient} 15%, ${background} 15%)`,
                                                color,
                                            }}
                                        >
                                            {!isNaN(parseInt(innerScore, 10)) ? innerScore : '-'}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                );
            });
    };

    const getOhiHeapMapValues = arrItems => {
        if (!arrItems.length) return null;
        const { children_ohi_slug = [] } = mapToUse[selectedTab];
        const itemsToIterate = [];
        children_ohi_slug.forEach(elem => {
            itemsToIterate.push(arrItems.find(({ slug = '' }) => slug === elem || elem === 'n'));
        });
        return itemsToIterate.map(({ items: subItems = [], title: rowTitle = '' }, parentI) => {
            const title = manipulateHeatmapTitle(rowTitle);
            return (
                <div className="sectionDiv" key={title}>
                    {/* {parentI === 1 ? (
                        <ul>
                            <li className="sectionContent headerContent">{OHI_PRACTICE}</li>
                        </ul>
                    ) : null} */}
                    <ul>
                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                            <li className="sectionContent">{title}</li>
                        </Tooltip>
                        {subItems.slice(minSliceValue, maxSliceValue).map(({ score: innerScore, quartile }, index) => {
                            const {
                                background = colors.$white,
                                color = colors.$darkBlue100,
                                border: borderColor = '',
                            } = quartileColors[quartile] || {};
                            return (
                                <li
                                    className={c({ nSize: !parentI }, 'contentList')}
                                    key={`${innerScore}${index}${getRandomValues()}`}
                                    style={{
                                        border: `1px solid white`,
                                        background: borderColor
                                            ? `linear-gradient(135deg, ${borderColor} 15%, ${background} 15%)`
                                            : background,
                                        color,
                                    }}
                                >
                                    {!isNaN(parseInt(innerScore, 10)) ? innerScore.toLocaleString('en-US') : '-'}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    };

    const renderRightOptions = () => {
        // const { demographics = [] } = filters[0] || {};
        if (addOnTab === 1) {
            return (
                <div className="outcomeContent">
                    <ul className="questionLegends">
                        <li>{FREQUENCY_LEVEL_PERCENT}</li>
                        {IND_QUES_LEGEND.map(legendText => (
                            <li>
                                <span />
                                {legendText}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return (
            <div className="rightOptions">
                <span>
                    <Select
                        IconComponent={KeyboardArrowDownSharpIcon}
                        value={selectedDemographics}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            setSelectedDemographic(code);
                            callHeatMap(code);
                        }}
                    >
                        {demographics.map(({ label, code }) => {
                            return (
                                <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </span>
            </div>
        );
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    useEffect(() => {
        if (!ieHeatmapFetched) {
            callHeatMap(demographics[0].code);
        }
        // eslint-disable-next-line
    }, [ieHeatmapFetched, maxSliceValue]);

    return (
        <div className="demographicBoard addOns clearfix">
            <div className="indExpBoard">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">
                            {EMPLYEE_EXPEIENCE} <sup>1</sup>
                        </div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                {appliedFiltersInfo()}
                {appliedBenchmark()}

                <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                    <div>
                        <ul className="leftNav">
                            {IND_EXP_LINKS.map((tabName, index) => {
                                if (
                                    (!apply_employee_exp_benchmark && index === 4) ||
                                    (surveyType === 'percentile' && index === 4)
                                )
                                    return null;
                                return (
                                    <li
                                        data-testid={tabName}
                                        key={tabName}
                                        className={c({ activeLink: addOnTab === index })}
                                        onClick={() => setAddOnTabs(index)}
                                    >
                                        {tabName}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {renderRightOptions()}
                </div>
                <Fragment>
                    <div className="deepDiveHeading">
                        {IE_CROSSTAB_OPTIONS.filter(({ code }) => code === crossTabDemo)[0].label} <sup>1</sup>
                    </div>
                    <div className="buttonWrapper">
                        <div className="buttonContent">
                            {tabOptionsToUse.map(({ title: tabVal }, tabIndex) => {
                                return (
                                    <button
                                        key={tabVal}
                                        type="button"
                                        className={c('separateButton', { isActive: tabIndex === selectedTab })}
                                        onClick={() => setSelectedTab(tabIndex)}
                                    >
                                        {tabVal}
                                    </button>
                                );
                            })}
                        </div>
                        <div className="rightOptions">
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharpIcon}
                                    value={crossTabDemo}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target: { value: code } }) => setCrossTabDemo(code)}
                                >
                                    {IE_CROSSTAB_OPTIONS.map(({ label, code, nonProfitAccess }) => {
                                        if (nonProfitAccess && isNonProfit) {
                                            return null;
                                        }

                                        return (
                                            <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                                {label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </div>
                    </div>
                    <div className="heatmapBoard clearfix">
                        <section className="sectionWrapper">
                            <div className="deepDiveOutcome" style={{ top: '14vh' }}>
                                {dataToUse.length ? sideHeaderVal() : null}
                            </div>
                            <div id="myHeader" className={c('sectionDiv headerWrapper')}>
                                {showLeftArrow ? (
                                    <div
                                        data-testid="arrowLeft"
                                        className="arrow"
                                        onClick={() => {
                                            setMaxSliceValue(minSliceValue);
                                            setMinSliceValue(minSliceValue - (SLICE_MAX_VALUE - SLICE_MIN_VALUE));
                                            setShowRightArrow(maxSliceValue >= SLICE_MAX_VALUE);
                                            setShowLeftArrow(minSliceValue > SLICE_MAX_VALUE);
                                        }}
                                    >
                                        <img className="imgClass" src={ArrowLeft} alt="" />
                                    </div>
                                ) : null}

                                <div>
                                    {headerToUse.length ? (
                                        <ul className={c({ tabLeft: true })}>
                                            <li className="headerList sectionContent" />
                                            {headerToUse &&
                                                headerToUse.slice(minSliceValue, maxSliceValue).map((val, i) => {
                                                    const cellVal = manipulateHeatmapTitle(val, langTxt);
                                                    return (
                                                        <Tooltip
                                                            key={`${val}${i}`}
                                                            placement="top"
                                                            arrow
                                                            title={val}
                                                            aria-label={val}
                                                        >
                                                            <li
                                                                className="headerList contentList"
                                                                onClick={() => sortData(val)}
                                                            >
                                                                {cellVal.length > MAX_LENGTH_SUBSTRING
                                                                    ? cellVal.substring(0, MAX_LENGTH_SUBSTRING) + '...'
                                                                    : cellVal}
                                                                {cellVal === OHI_SCORE_TEXT && (
                                                                    <img
                                                                        className="arrowSort"
                                                                        src={sortOrder[val] === 1 ? ArrowUp : ArrowDown}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </li>
                                                        </Tooltip>
                                                    );
                                                })}
                                        </ul>
                                    ) : null}
                                </div>
                                {showRightArrow ? (
                                    <div
                                        data-testid="arrowRight"
                                        className="arrow arrowRight"
                                        onClick={() => {
                                            setMinSliceValue(maxSliceValue);
                                            setMaxSliceValue(maxSliceValue + SLICE_MAX_VALUE);
                                            setShowRightArrow(maxSliceValue + SLICE_MAX_VALUE <= header.length);
                                            setShowLeftArrow(maxSliceValue + SLICE_MAX_VALUE > SLICE_MAX_VALUE);
                                        }}
                                    >
                                        <img className="imgClass" src={ArrowRight} alt="" />
                                    </div>
                                ) : null}
                            </div>
                            <div className="contentWrapper clearfix">
                                <div className="mainContent">{getOhiHeapMapValues(dataToUseOhi)}</div>
                            </div>
                            <div className="contentWrapper clearfix">
                                <div className="mainContent">{getHeapMapValues(dataToUse)}</div>
                            </div>
                        </section>
                    </div>
                </Fragment>
            </div>
        </div>
    );
}

DeepDive.propTypes = {
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    errorInfo: PropTypes.string.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setCrossTabDemo: PropTypes.func.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    selectedDemographics: PropTypes.string.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    appliedBenchmark: PropTypes.func.isRequired,
};

export default DeepDive;
