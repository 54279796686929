import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import {
    TableContainer,
    TextField,
    IconButton,
    InputAdornment,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    MenuItem,
    Select,
    ListItemText,
    Checkbox,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { getStaticText } from '../../utils/constants';
import './index.scss';

function ListView({
    apiLoadingCount,
    defaultSettings,
    usersList,
    usersListFetched,
    errorInfo,
    settingsErrorInfo,
    changeUser,
    getReportUsers,
}) {
    const { lang, report_id } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        USER_MNGT,
        SEARCH_EMAIL_REPORT,
        USER_ID,
        NAME,
        EMAIL,
        ROLE,
        LOGIN_DIFF_USER,
        LOGIN_BTN,
        ROLES_META,
        REPORTS_USER_PAGE,
    } = SITE_TEXT;
    const [search, setSearch] = useState('');
    const [userArr, setUserArr] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);

    useEffect(() => {
        getReportUsers({ report_id });
        // eslint-disable-next-line
    }, [report_id]);

    useEffect(() => {
        if (report_id) {
            setUserArr(usersList);
        }
        // eslint-disable-next-line
    }, [report_id, usersListFetched]);

    const handleInputChange = ({ target: { value } }) => {
        setSearch(value);
        if (value.length) {
            const filterList = usersList.filter(
                ({ name, email }) =>
                    email.toLowerCase().includes(value.toLowerCase()) ||
                    name.toLowerCase().includes(value.toLowerCase())
            );
            setUserArr(filterList);
        } else {
            setUserArr(usersList);
        }
    };

    const handleSelectedRole = ({ target: { value } }) => {
        setSelectedRole(value);
        if (value.length) {
            setUserArr(usersList.filter(item => value.indexOf(item.role) !== -1));
        } else {
            setUserArr(usersList);
        }
    };

    const errorToShow = errorInfo || settingsErrorInfo;

    return (
        <Layout showHeader>
            <Helmet>
                <title>{REPORTS_USER_PAGE}</title>
            </Helmet>
            <div className="userManagementContent clearfix">
                {apiLoadingCount ? <Loader position="absolute" showBackground background="transparent" /> : null}

                <h2>{USER_MNGT}</h2>
                {errorToShow && <div className="error">{errorToShow} </div>}

                <div className="search clearfix">
                    <TextField
                        fullWidth
                        classes={{ root: 'inputLabel' }}
                        size="small"
                        name="search"
                        value={search}
                        onChange={e => handleInputChange(e)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment>
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        // eslint-disable-next-line
                        inputProps={{
                            'data-testid': 'searchInput',
                        }}
                        placeholder={SEARCH_EMAIL_REPORT}
                    />
                </div>

                <div className="tableContentWrap">
                    <TableContainer className="fixedContainer" component={Paper} sx={{ maxHeight: '70%' }}>
                        <Table className="fixedTable" stickyHeader>
                            <TableHead className="fixedTableHeader">
                                <TableRow className="fixedRow">
                                    <TableCell style={{ width: '10%' }}>{USER_ID}</TableCell>
                                    <TableCell style={{ width: '20%' }}>{NAME}</TableCell>
                                    <TableCell style={{ width: '25%' }}>{EMAIL}</TableCell>
                                    <TableCell style={{ width: '15%' }}>
                                        {ROLE}
                                        <Select
                                            id="role"
                                            value={selectedRole}
                                            multiple
                                            style={{ width: 30 }}
                                            onChange={handleSelectedRole}
                                            renderValue={selected => selected.displayEmpty}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                PaperProps: {
                                                    style: {
                                                        marginTop: 20,
                                                    },
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            disableUnderline
                                        >
                                            {Object.keys(ROLES_META).map(role => (
                                                <MenuItem
                                                    classes={{ root: 'selectOptions' }}
                                                    key={role}
                                                    value={role}
                                                    style={{ width: 150 }}
                                                >
                                                    <Checkbox checked={selectedRole.indexOf(role) > -1} />
                                                    <ListItemText primary={ROLES_META[role]} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell style={{ width: '30%' }}>{LOGIN_DIFF_USER}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {userArr.map(({ name, role, email, id: user_id }) => {
                                    return (
                                        <TableRow className="fixedTableRow" key={user_id}>
                                            <TableCell>{user_id}</TableCell>
                                            <TableCell>{name}</TableCell>
                                            <TableCell>{email}</TableCell>
                                            <TableCell>{ROLES_META[role]}</TableCell>
                                            <TableCell>
                                                <span
                                                    onClick={() => changeUser({ user_id })}
                                                    data-testid={`login${user_id}`}
                                                >
                                                    {LOGIN_BTN}
                                                    <ArrowForwardIos />
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Layout>
    );
}

ListView.propTypes = {
    apiLoadingCount: PropTypes.number.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    usersList: PropTypes.array.isRequired,
    usersListFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    settingsErrorInfo: PropTypes.string.isRequired,
    changeUser: PropTypes.func.isRequired,
    getReportUsers: PropTypes.func.isRequired,
};

export default ListView;
