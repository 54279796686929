import { fetchWebApi, postWebApi, deleteWebApi, postFormWebApi, putWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getScoreDataUrl = options => {
    const { apiBasePath, scoreDataPath } = endpoints;
    const url = `${apiBasePath}${scoreDataPath}`;
    return postWebApi(url, options).request;
};

export const getValuesDataUrl = options => {
    const { apiBasePath, valuesPath } = endpoints;
    const url = `${apiBasePath}${valuesPath}`;
    return postWebApi(url, options).request;
};

export const getTextCommentsUrl = options => {
    const { apiBasePath, textCommentsPath } = endpoints;
    const url = `${apiBasePath}${textCommentsPath}`;
    return postWebApi(url, options).request;
};

export const getFiltersUrl = (ohid, lang) => {
    const { apiBasePath, filtersPath } = endpoints;
    const url = `${apiBasePath}${filtersPath}?ohid=${ohid}&lang=${lang}`;
    return fetchWebApi(url).request;
};

export const getBenchmarksUrl = ({ ohid, year, type = 'benchmark', resource = '', lang }) => {
    const { apiBasePath, benchmarksPath } = endpoints;
    const url = `${apiBasePath}${benchmarksPath}?ohid=${ohid}&year=${year}&type=${type}&resource=${resource}&lang=${lang}`;
    return fetchWebApi(url).request;
};

export const getIaBenchmarksUrl = (ohid, year, type = 'benchmark', resource = '', version = 2, getVersion = 1) => {
    const { apiBasePath, benchmarksPath } = endpoints;
    const url = `${apiBasePath}${benchmarksPath}?ohid=${ohid}&year=${year}&type=${type}&resource=${resource}&benchmark_sub_version_id=${version}&get_benchmark_sub_versions=${getVersion}`;
    return fetchWebApi(url).request;
};

export const getProjectsUrl = options => {
    const { apiBasePath, projectsPath } = endpoints;
    const url = `${apiBasePath}${projectsPath}`;
    return postWebApi(url, options).request;
};

export const getHeatMapUrl = options => {
    const { apiBasePath, heatMapPath } = endpoints;
    const url = `${apiBasePath}${heatMapPath}`;
    return postWebApi(url, options).request;
};

export const getHeatMapResurveyUrl = options => {
    const { apiBasePath, resurveyHeatMapPath } = endpoints;
    const url = `${apiBasePath}${resurveyHeatMapPath}`;
    return postWebApi(url, options).request;
};

export const getHeatMapInlcusionUrl = options => {
    const { apiBasePath, heatMapInclusionPath } = endpoints;
    const url = `${apiBasePath}${heatMapInclusionPath}`;
    return postWebApi(url, options).request;
};

export const getPracticeRankingUrl = options => {
    const { apiBasePath, rankingPath } = endpoints;
    const url = `${apiBasePath}${rankingPath}`;
    return postWebApi(url, options).request;
};

export const getPracticeRankingBuUrl = options => {
    const { apiBasePath, rankingBuPath } = endpoints;
    const url = `${apiBasePath}${rankingBuPath}`;
    return postWebApi(url, options).request;
};

export const reSurveyUrl = options => {
    const { apiBasePath, reSurveyPath } = endpoints;
    const url = `${apiBasePath}${reSurveyPath}`;
    return postWebApi(url, options).request;
};

export const buPathUrl = options => {
    const { apiBasePath, buPath } = endpoints;
    const url = `${apiBasePath}${buPath}`;
    return postWebApi(url, options).request;
};

export const savePptUrl = (options, token, taskId, downloadType) => {
    const { pptBasePath, pptSavePath, pdfSavePath } = endpoints;
    const url = !taskId
        ? `${pptBasePath}${downloadType === 'pdf' ? pdfSavePath : pptSavePath}`
        : `${pptBasePath}${downloadType === 'pdf' ? pdfSavePath : pptSavePath}?task_id=${taskId}`;
    return postWebApi(url, options, token).request;
};

export const responsePptUrl = (options, token) => {
    const { pptBasePath, pptResponsePath } = endpoints;
    const url = `${pptBasePath}${pptResponsePath}`;
    return postWebApi(url, options, token).request;
};

export const getPptStatusUrl = (token, taskId) => {
    const { pptBasePath, pptStatusPath } = endpoints;
    const url = `${pptBasePath}${pptStatusPath}?task_id=${taskId}`;
    return postWebApi(url, {}, token).request;
};

export const getToolThemeUrl = () => {
    const { apiBasePath, toolBox } = endpoints;
    const url = `${apiBasePath}${toolBox}`;
    return fetchWebApi(url).request;
};

export const createToolThemeUrl = options => {
    const { apiBasePath, toolBox } = endpoints;
    const url = `${apiBasePath}${toolBox}`;
    return postWebApi(url, options).request;
};

export const deleteToolThemeUrl = options => {
    const { apiBasePath, toolBox } = endpoints;
    const url = `${apiBasePath}${toolBox}`;
    return deleteWebApi(url, options).request;
};
export const cohesionUrl = options => {
    const { apiBasePath, cohesionPath } = endpoints;
    const url = `${apiBasePath}${cohesionPath}`;
    return postWebApi(url, options).request;
};
export const cohesionUserUrl = options => {
    const { apiBasePath, cohesionUserSettingsPath } = endpoints;
    const url = `${apiBasePath}${cohesionUserSettingsPath}`;
    return postWebApi(url, options).request;
};

export const getMappedDemographicsUrl = options => {
    const { apiBasePath, mappedDemographicsPath } = endpoints;
    const url = `${apiBasePath}${mappedDemographicsPath}`;
    return postWebApi(url, options).request;
};

export const getNpsUrl = options => {
    const { apiBasePath, ingNpsPath } = endpoints;
    const url = `${apiBasePath}${ingNpsPath}`;
    return postWebApi(url, options).request;
};

export const getEngagementUrl = options => {
    const { apiBasePath, ingEngagementPath } = endpoints;
    const url = `${apiBasePath}${ingEngagementPath}`;
    return postWebApi(url, options).request;
};

export const getSingleScoreUrl = options => {
    const { apiBasePath, singleQuestionScore } = endpoints;
    const url = `${apiBasePath}${singleQuestionScore}`;
    return postWebApi(url, options).request;
};

export const getTlxEngagementUrl = options => {
    const { apiBasePath, tlxengagementstatsPath } = endpoints;
    const url = `${apiBasePath}${tlxengagementstatsPath}`;
    return postWebApi(url, options).request;
};

export const getIeHeatmapUrl = options => {
    const { apiBasePath, ieHeatmapPath } = endpoints;
    const url = `${apiBasePath}${ieHeatmapPath}`;
    return postWebApi(url, options).request;
};

export const getOrgHealthUrl = options => {
    const { ohid = '', lang = '', threshold = '', filters = '' } = options || {};
    const { apiBasePath, orgHealthPath } = endpoints;
    const url = `${apiBasePath}${orgHealthPath}?ohid=${ohid}&lang=${lang}&threshold=${threshold}&filters=${JSON.stringify(
        filters
    )}`;
    return fetchWebApi(url).request;
};

export const getRankingHeatMapUrl = options => {
    const { apiBasePath, rankHeatmapPath } = endpoints;
    const url = `${apiBasePath}${rankHeatmapPath}`;
    return postWebApi(url, options).request;
};

export const getFeedbackUrl = options => {
    const { apiBasePath, feedbackPath } = endpoints;
    const url = `${apiBasePath}${feedbackPath}`;
    return postFormWebApi(url, options).request;
};

export const getFeedbackListUrl = options => {
    const { user_id, feedback_id = '' } = options || {};
    const { apiBasePath, feedbackPath } = endpoints;
    const url = `${apiBasePath}${feedbackPath}?created_by=${user_id}&${
        !feedback_id ? `is_completed=0` : `feedback_id=${feedback_id}`
    }`;
    return fetchWebApi(url).request;
};

export const putFeedbackListUrl = options => {
    const { user_id, feedback_id = '' } = options || {};
    const { apiBasePath, feedbackPath } = endpoints;
    const url = `${apiBasePath}${feedbackPath}?created_by=${user_id}&${
        !feedback_id ? `is_completed=0` : `feedback_id=${feedback_id}`
    }`;
    return putWebApi(url, { feedback_id }).request;
};

export const getTargetScoreDataUrl = options => {
    const { apiBasePath, scoreDataPath } = endpoints;
    const url = `${apiBasePath}${scoreDataPath}`;
    return postWebApi(url, options).request;
};

export const getEmpExpResurvey = options => {
    const { apiBasePath, empExpResurveyPath } = endpoints;
    const url = `${apiBasePath}${empExpResurveyPath}`;
    return postFormWebApi(url, options).request;
};

export const getEmpExpResurveyHeatmap = options => {
    const { apiBasePath, empExpResurveyHeatmapPath } = endpoints;
    const url = `${apiBasePath}${empExpResurveyHeatmapPath}`;
    return postFormWebApi(url, options).request;
};

export const getIeCrossTab = options => {
    const { apiBasePath, ieCrossTabPath } = endpoints;
    const url = `${apiBasePath}${ieCrossTabPath}`;
    return postFormWebApi(url, options).request;
};

export const getResIeCrossTab = options => {
    const { apiBasePath, ieCrossTabresurveyPath } = endpoints;
    const url = `${apiBasePath}${ieCrossTabresurveyPath}`;
    return postFormWebApi(url, options).request;
};

export const getExBenchmarksUrl = ({ ohid, year, type = 'benchmark', resource = '', lang }) => {
    const { apiBasePath, benchmarksPath } = endpoints;
    const url = `${apiBasePath}${benchmarksPath}?ohid=${ohid}&year=${year}&type=${type}&resource=${resource}&lang=${lang}&module=employee_exp&ignore=0`;
    return fetchWebApi(url).request;
};

export const getExFootnoteUrl = options => {
    const { ohid, lang } = options;
    const { apiBasePath, footnotePath } = endpoints;
    const url = `${apiBasePath}${footnotePath}?ohid=${ohid}&lang=${lang}`;
    return fetchWebApi(url).request;
};
