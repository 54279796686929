import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import c from 'classnames';
import { ArrowBack } from '@material-ui/icons';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import Info from '../../assets/C-info.svg';
import UpArrow from '../../assets/arrow-up.svg';
import DownArrow from '../../assets/arrow-down.svg';
import SquareMinus from '../../assets/minus-square.svg';
import NoSig from '../../assets/no_significance.svg';
import '../index.scss';
import { getEEBoxStyle } from '../../../../utils/functions';
import MetaChartModal from '../../MetaChartModal';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';
import colors from '../../../../sass/colors';
import DonutChart from '../../../../components/DonutChart/DonutChart';

const renderIcon = (sig, diff) => {
    const diffToUse = sig;
    if (isNaN(parseInt(sig, 10)) && !isNaN(parseInt(diff, 10))) return NoSig;
    if (!diff) return null;
    if (diffToUse === 0) {
        return DownArrow;
    }
    if (diffToUse === 1) {
        return UpArrow;
    }
    if (diffToUse === 2) {
        return SquareMinus;
    }
    return null;
};

const showProgress = (values, significance, diff, isNegative, quartile, quartileColors, applyEx, key, isPercentile) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {values.map((val, i) => {
                const { background = '#ffffff' } = quartileColors[quartile[i]] || {};
                const { positiveBg = '#ffffff', negativeBg = '#ffffff' } = getEEBoxStyle(val, isNegative) || {};
                const noBmBg = isNegative ? negativeBg : positiveBg;
                const bgToUse = applyEx ? background : noBmBg;
                const isEnps = key === 'endorsing_organization_to_others' && applyEx && !isPercentile;
                const isTopQuartile = parseInt(quartile[i], 10) === 0;
                const percentage = isEnps ? ((val + 100) * 100) / 200 : val;
                return (
                    <div className="progress-bar">
                        <DonutChart background={bgToUse} value={percentage} displayValue={isEnps ? val : `${val}%`} />
                        {isTopQuartile && applyEx ? <div className="triangle" /> : null}
                    </div>
                );
            })}
            <div className="diffContainer">
                <div className="diff">
                    {diff}
                    <span
                        className={c('difference', {
                            negative: diff < 0 && significance[0] === '',
                        })}
                    >
                        <img src={renderIcon(significance[0], diff)} alt="" />
                    </span>
                </div>
            </div>
        </Box>
    );
};

function EmpExpResurvey({
    // qbyqData,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    // getEmpExpResurveyData,
    empExpResurveyData,
    // empExpResurveyDataFetched,
    // projects,
    // selectedIds,
    // apiParams,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    setReport,
    years,
    // filters,
    ohids,
    footnoteList,
}) {
    const {
        lang = '1033',
        report_type: surveyType = '',
        year,
        emp_exp_resurvey_settings = '',
        emp_exp_resurvey_custom_delta = '',
        apply_employee_exp_benchmark = false,
        quartileColors = [],
    } = defaultSettings;
    // const { filters = [] } = apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUESTIONS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { NEG_IND_QUES, QUES_LEGEND_TYPE, SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION, RESURVEY_YEARS } = staticText;
    const [showMetaChart, setShowMetaChart] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [isPracticeMeta, setPraticeMeta] = useState(false);
    const {
        fe_ee_factor_pc_fv,
        com_ee_enps_calc_detail_pc_fv,
        com_ee_neg_enps_pc_fv_over_time,
        fe_ee_factor_percentile,
        com_ee_flagpole_footnote,
        fe_ee_statistical_sig_percentile,
        com_ee_statiscal_sig_neg_enps_percentile,
    } = footnoteList || {};

    const toggleMetaDataPopup = (modalState = false, scores = {}, isPractice = false) => {
        setShowMetaChart(modalState);
        setMetaData(scores);
        setPraticeMeta(isPractice);
    };

    const { qbyq: empData = {} } = empExpResurveyData || {};
    const { records: qbyq = {}, size = '' } = empData || {};
    const checkLength = !!Object.keys(qbyq).length;
    const {
        SINGLE_QUESTIONS_LEGEND,
        QUESTION_SCORE,
        IND_EXP_SCORE_PAGE_TEXT_NOTES,
        // EMPLOYEE_NET_PROMOTER_SCORE,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        // NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        // RESURVEY_TEXT,
        SCORECARD,
        STATISTICAL_DIFFERENCE,
        SCORECARD_RESURVEY_SIGNIFICANCE,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
        CUSTOM_DIFFERENCE,
        // CUSTOM_DIFF_COLOR,
        customDiffLegends,
        EE_ENPS_FOOTER,
        EE_SS_FOOTNOTE,
        BENCHMARK_NA,
        BOTTOM_DECILE,
        BENCHMARK_HEAD,
        NO_BENCHMARK,
        QUARTILE_DIFFERENCE,
    } = SITE_TEXT || {};

    const renderNewFootnotes = () => {
        const setting = emp_exp_resurvey_settings.toLowerCase() || '';
        const percentileArr = [
            fe_ee_factor_percentile,
            fe_ee_statistical_sig_percentile,
            com_ee_statiscal_sig_neg_enps_percentile,
            com_ee_flagpole_footnote,
        ];
        const percentArr = [
            fe_ee_factor_pc_fv,
            com_ee_enps_calc_detail_pc_fv,
            com_ee_neg_enps_pc_fv_over_time,
            com_ee_flagpole_footnote,
        ];
        const arr = surveyType === 'percentile' ? percentileArr : percentArr;
        return setting === 'statistical significance' ? arr : arr.slice(0, 3);
    };

    const FOOTNOTES = renderNewFootnotes();

    // useEffect(() => {
    //     if (!empExpResurveyDataFetched) {
    //         getEmpExpResurveyData(getResurveyParams(filters[1]));
    //     }
    // }, [empExpResurveyDataFetched]);

    const sigValToUse = SCORECARD_RESURVEY_SIGNIFICANCE;

    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="breadCrumb">
                        <span data-testid="setScoreFromBuBtn" onClick={() => setReport(0)}>
                            {SCORECARD}
                        </span>{' '}
                        {' > '}
                        {'Resurvey'}
                    </div>
                    <h2 className="clearfix">
                        <div className="rightScorecardHeader">
                            <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                                <ArrowBack />
                                Resurvey {EMPLYEE_EXPEIENCE} <sup>1</sup>
                            </span>
                            <ul className="navScoreList">
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                                <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                    <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                    <span className="filter">{FILTERS}</span>
                                </li>
                            </ul>
                        </div>
                    </h2>
                    {/* <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">Resurvey {EMPLYEE_EXPEIENCE}</div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        <li
                            data-testid="setReSurveyModalBtn"
                            // onClick={() => setReSurveyModal(true)}
                            className="fImage resurveyImg"
                        >
                            <img src={BulletList} alt="" />
                            <span className="upperHead">{RESURVEY_TEXT}</span>
                        </li>
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div> */}
                    {appliedFiltersInfo()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    if (
                                        (!apply_employee_exp_benchmark && index === 4) ||
                                        (surveyType === 'percentile' && index === 4)
                                    )
                                        return null;
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {/* {renderRightOptions()} */}
                    </div>
                    <div className="legendCard">
                        <div className="title">{RESURVEY_YEARS}</div>
                        <div className="compYears">
                            <div>
                                <div>{ohids[0]}</div>
                                <div>{year}</div>
                            </div>
                            <div>
                                <div>{ohids[1]}</div>
                                <div>{years[1]}</div>
                            </div>
                        </div>
                    </div>
                    <Fragment>
                        <div className="indExpScorecard empExpResurvey">
                            {checkLength &&
                                QUESTIONS.map(({ title, children, imgSrc }) => {
                                    return (
                                        <div className="cardWrapper">
                                            <div className="headerContent">
                                                <h2>
                                                    <span>
                                                        <img src={imgSrc} alt={title} />
                                                    </span>
                                                    {title}
                                                </h2>
                                            </div>
                                            {children.map(key => {
                                                const {
                                                    quartile,
                                                    range_start,
                                                    range_end,
                                                    display_name: childTitle = '',
                                                    score = ['', ''],
                                                    meta_scores = [],
                                                    significance = [],
                                                    differences = [],
                                                } = qbyq[key] || {};
                                                const negativeTrait = NEG_IND_QUES.includes(key);
                                                const sup = QUES_LEGEND_TYPE[key] || '';
                                                const {
                                                    qtext = '',
                                                    bottom2_score = [0, 0],
                                                    nuetral_score = [0, 0],
                                                    score: qScore = ['', ''],
                                                } = meta_scores[0] || {};
                                                // const enps = score[0] - bottom2_score[0];
                                                // const positiveNps = enps > 0;
                                                // const enps1 = score[1] - bottom2_score[1];
                                                // const positiveNps1 = enps1 > 0;
                                                // const score = childTitle === 'eNPS' ? qScore : childScore;
                                                const childSet = {
                                                    meta_scores,
                                                    display_name: childTitle,
                                                    score,
                                                    quartile,
                                                    range_start,
                                                    range_end,
                                                };
                                                const TITLE = 'Recomendar la organización ante otros';
                                                const titleToUse =
                                                    lang === '10' && key === 'endorsing_organization_to_others'
                                                        ? TITLE
                                                        : childTitle;
                                                if (!score.length) return null;
                                                return (
                                                    <div className={c({ hoverElement: sup === 2 }, 'cardData')}>
                                                        <div
                                                            onClick={() => toggleMetaDataPopup(true, childSet, true)}
                                                            className="cardResurveyHeading"
                                                        >
                                                            {titleToUse}
                                                            <sup>{sup}</sup>
                                                            {sup === 2 ? (
                                                                <span>
                                                                    <img src={Info} alt="" />
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                        <div className="percentProgress">
                                                            {showProgress(
                                                                score,
                                                                significance,
                                                                differences,
                                                                negativeTrait,
                                                                quartile,
                                                                quartileColors,
                                                                apply_employee_exp_benchmark,
                                                                key,
                                                                surveyType === 'percentile'
                                                            )}
                                                        </div>
                                                        {sup === 2 ? (
                                                            <div className="detailedInfo">
                                                                <div className="questionSection">
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <b>{QUESTION_SCORE}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="metaText">{qtext}</div>
                                                                        </div>
                                                                        <div>{IND_EXP_SCORE_PAGE_TEXT_NOTES}</div>
                                                                    </div>
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <div className="metaScore chart">
                                                                                    <div>
                                                                                        {SINGLE_QUESTIONS_LEGEND.map(
                                                                                            (legendText, i) => {
                                                                                                const sectionWidth =
                                                                                                    i === 1
                                                                                                        ? nuetral_score
                                                                                                        : score;
                                                                                                const width = !i
                                                                                                    ? bottom2_score
                                                                                                    : sectionWidth;
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: `${width -
                                                                                                                0.5}%`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <span />{' '}
                                                                                                        {legendText}
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="metaScore">
                                                                                <div className="chart">
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${bottom2_score[0]}%`,
                                                                                        }}
                                                                                    >
                                                                                        {bottom2_score[0]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${nuetral_score[0]}%`,
                                                                                        }}
                                                                                    >
                                                                                        {nuetral_score[0]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${qScore[0]}%`,
                                                                                        }}
                                                                                    >
                                                                                        {qScore[0]}
                                                                                    </span>
                                                                                    <div className="year">{year}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="metaSection">
                                                                            <div className="metaText" />
                                                                            <div className="metaScore">
                                                                                <div className="chart">
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${bottom2_score[1]}%`,
                                                                                        }}
                                                                                    >
                                                                                        {bottom2_score[1]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${nuetral_score[1]}%`,
                                                                                        }}
                                                                                    >
                                                                                        {nuetral_score[1]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${qScore[1]}%`,
                                                                                        }}
                                                                                    >
                                                                                        {qScore[1]}
                                                                                    </span>
                                                                                    <div className="year">
                                                                                        {years[1]}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metaInfo" />
                                                                {/* <div className="questionSection">
                                                                    <div className="practiceSet bottom clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <div>
                                                                                    <div>
                                                                                        <strong>
                                                                                            eNPS<sup>4</sup>
                                                                                        </strong>
                                                                                    </div>
                                                                                    <div>
                                                                                        {EMPLOYEE_NET_PROMOTER_SCORE}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="metaScore">
                                                                                <div className="chart bottomChart">
                                                                                    <div className="axis horizontal" />
                                                                                    <div className="axis vertical" />
                                                                                    <span />
                                                                                    <span className="section2">
                                                                                        <span
                                                                                            className={c(
                                                                                                {
                                                                                                    negative: !positiveNps,
                                                                                                },
                                                                                                'capsule'
                                                                                            )}
                                                                                            style={{
                                                                                                width: `${
                                                                                                    positiveNps
                                                                                                        ? enps / 2
                                                                                                        : -enps / 2
                                                                                                }%`,
                                                                                            }}
                                                                                        >
                                                                                            {enps}
                                                                                        </span>
                                                                                    </span>
                                                                                    <div className="year">{year}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="metaSection">
                                                                            <div className="metaText" />
                                                                            <div className="metaScore">
                                                                                <div className="chart bottomChart">
                                                                                    <div className="axis horizontal" />
                                                                                    <div className="axis vertical" />
                                                                                    <span />
                                                                                    <span className="section2">
                                                                                        <span
                                                                                            className={c(
                                                                                                {
                                                                                                    negative: !positiveNps1,
                                                                                                },
                                                                                                'capsule'
                                                                                            )}
                                                                                            style={{
                                                                                                width: `${
                                                                                                    positiveNps1
                                                                                                        ? enps1 / 2
                                                                                                        : -enps1 / 2
                                                                                                }%`,
                                                                                            }}
                                                                                        >
                                                                                            {enps1}
                                                                                        </span>
                                                                                    </span>
                                                                                    <div className="year">
                                                                                        {years[1]}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            {showMetaChart && (
                                <MetaChartModal
                                    metaData={metaData}
                                    closeModal={toggleMetaDataPopup}
                                    isPracticeMeta={isPracticeMeta}
                                    totalSize={size.toLocaleString('en-US')}
                                    defaultSettings={defaultSettings}
                                    isEE
                                />
                            )}
                        </div>

                        {/* {!apiLoadingCount && !errorInfo && (
                        <div className="note">
                            <b>{NOTE}:</b>
                            <br />
                            {IND_EXP_QUESTION_NOTE[0]}
                            <br />
                            {IND_EXP_QUESTION_NOTE[1]}
                            <br />
                            {surveyType === 'percent favorable' ? <div>3. {EE_PERCENT_FAVORABLE}</div> : ''}
                            {surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                        </div>
                    )} */}
                        {!apiLoadingCount && !errorInfo && (
                            <Fragment>
                                {apply_employee_exp_benchmark ? (
                                    <div className="note">
                                        <b>{NOTE}:</b>
                                        {FOOTNOTES.map((txt, i) => {
                                            return <div>{`${i + 1}. ${txt}`}</div>;
                                        })}
                                    </div>
                                ) : (
                                    <div className="note">
                                        <b>{NOTE}:</b>
                                        {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                        {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                        {IND_EXP_QUESTION_NOTE[0]}
                                        <br />
                                        {IND_EXP_QUESTION_NOTE[1]}
                                        <br />
                                        <div>4. {EE_ENPS_FOOTER}</div>
                                        {emp_exp_resurvey_settings.toLowerCase() === 'statistical significance' ? (
                                            <div>5. {EE_SS_FOOTNOTE} </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                {apply_employee_exp_benchmark ? (
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === BOTTOM_DECILE;
                                const BenchmarkTitle = title === NO_BENCHMARK;
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                ) : (
                    <Fragment>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                            {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                        <ul className="benchmark clearfix">
                            <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                            {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                                <li key={title}>
                                    <span style={{ background, border: 'none' }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                )}
                {emp_exp_resurvey_settings.toLowerCase() === 'statistical significance' ? (
                    <ul className="benchmark clearfix change">
                        <li>{STATISTICAL_DIFFERENCE} : </li>
                        {sigValToUse.map(({ title, imgSrc, sup = '' }) => (
                            <li key={title}>
                                <span>
                                    <img src={imgSrc} alt="" />
                                </span>
                                {title} <sup>{sup && apply_employee_exp_benchmark ? 4 : sup}</sup>
                            </li>
                        ))}
                    </ul>
                ) : null}
                {emp_exp_resurvey_settings.toLowerCase() === 'quartile movement' ? (
                    <ul className="benchmark clearfix change">
                        <li>{QUARTILE_DIFFERENCE} : </li>
                        {sigValToUse.map(({ title, imgSrc, sup = '' }) => (
                            <li key={title}>
                                <span>
                                    <img src={imgSrc} alt="" />
                                </span>
                                {title} <sup>{sup && apply_employee_exp_benchmark ? 4 : sup}</sup>
                            </li>
                        ))}
                    </ul>
                ) : null}
                {emp_exp_resurvey_settings.toLowerCase() === 'custom delta' ? (
                    <ul className="benchmark clearfix change">
                        <li>{CUSTOM_DIFFERENCE} : </li>
                        {sigValToUse.map(({ imgSrc }, i) => {
                            const title = customDiffLegends(emp_exp_resurvey_custom_delta)[i] || '';
                            return (
                                <li key={title}>
                                    <span>
                                        <img src={imgSrc} alt="" />
                                    </span>
                                    {title}
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </ul>
        </Fragment>
    );
}

EmpExpResurvey.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    // projects: PropTypes.array.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    // getEmpExpResurveyData: PropTypes.func.isRequired,
    empExpResurveyData: PropTypes.object.isRequired,
    // empExpResurveyDataFetched: PropTypes.bool.isRequired,
    // selectedIds: PropTypes.array.isRequired,
    // apiParams: PropTypes.object.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setReport: PropTypes.func.isRequired,
    years: PropTypes.array.isRequired,
    ohids: PropTypes.array.isRequired,
    footnoteList: PropTypes.array.isRequired,
};

export default EmpExpResurvey;
