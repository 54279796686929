import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    getIeHeatmap,
    sortIeHeatmap,
    getEmpExpResurveyHeatmapData,
    getDemographics,
    getBenchmarks,
    updateHeatMapInfo,
    getMappedDemographics,
    setSelectedDemographic,
    updateFilters,
    resetEmpExpResDataFetched,
    getIeCrosstabData,
    getResIesCrosstabData,
    getHeatMapOutcome,
    getHeatMapResurvey,
    getExBenchmarks,
    getComparisonYearSettings,
} from '../../actions';

import {
    selectApiCount,
    getErrorInfo,
    selectFilters,
    selectSelectedDemographic,
    selectIeHeatmap,
    selectIeHeatmapFetched,
    selectEmpExpResurveyHeatmapData,
    selectEmpExpResurveyHeatmapDataFetched,
    selectApiParams,
    selectProjects,
    selectHeatMapResurveyInfo,
    selectIeCrossTabData,
    selectIeResCrossTabData,
    selectHeatMapData,
    selectHeatMapDataFetched,
    selectResurveyOutcomeHeatMapFetched,
    selectResurveyOutcomeHeatMap,
    selectFootnoteList,
    selectCompYrSettings,
} from '../../selector';

import HeatmapBoard from './HeatmapBoard';
import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    selectedDemographics: selectSelectedDemographic(),
    ieHeatmap: selectIeHeatmap(),
    ieHeatmapFetched: selectIeHeatmapFetched(),
    defaultSettings: getDefaultSettings(),
    empExpResurveyHeatmapData: selectEmpExpResurveyHeatmapData(),
    empExpResurveyHeatmapDataFetched: selectEmpExpResurveyHeatmapDataFetched(),
    apiParams: selectApiParams(),
    projects: selectProjects(),
    heatMapResurveyInfo: selectHeatMapResurveyInfo(),
    ieCrossTabData: selectIeCrossTabData(),
    ieResCrossTabData: selectIeResCrossTabData(),
    heatMapDataFetched: selectHeatMapDataFetched(),
    heatMapData: selectHeatMapData(),
    resurveyOutcomeHeatMapFetched: selectResurveyOutcomeHeatMapFetched(),
    resurveyOutcomeHeatMap: selectResurveyOutcomeHeatMap(),
    footnoteList: selectFootnoteList(),
    compYrSettings: selectCompYrSettings(),
});

const dispatchToProps = {
    getIeHeatmap,
    sortIeHeatmap,
    getEmpExpResurveyHeatmapData,
    // resetReSurveyDataFetched,
    getDemographics,
    getBenchmarks,
    updateHeatMapInfo,
    getMappedDemographics,
    setSelectedDemographic,
    updateFilters,
    resetEmpExpResDataFetched,
    getIeCrosstabData,
    getResIesCrosstabData,
    getHeatMapOutcome,
    getHeatMapResurvey,
    getExBenchmarks,
    getComparisonYearSettings,
};

export default connect(stateToProps, dispatchToProps)(HeatmapBoard);
