import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ReportContent from './ReportContent';
import {
    updateRightBenchmarks,
    updateLeftBenchmarks,
    updateFilters,
    getDemographics,
    getBenchmarks,
    updateApiParams,
    resetFilterIndex,
    getIaBenchmarks,
    getBenchmarkVersion,
    getScoreData,
    getMappedTargetSettingBm,
    getExBenchmarks,
    updateExRightBenchmarks,
    updateExLeftBenchmarks,
    getComparisonYearSettings,
} from '../../Diagnose/actions';

import { setBmVersionInUse, updateInclusionFilters, getIaProjects } from '../../Inclusion/actions';

import {
    getReport,
    getResurveyExcel,
    getDataCube,
    updateReportApiParams,
    updateBuFilters,
    updateBenchmarkDataFetched,
    updateDemographicDataFetched,
    updateFilterIndex,
    updateBulkFilters,
    setBulkToggle,
    getResurveyMapping,
    setAvgGapToggle,
    updateResurveyMapping,
    getBulkScoreData,
    resetBulkAssign,
    setOrgLevelToggle,
    updateExBenchmarkDataFetched,
} from '../actions';
import {
    selectScoreData,
    selectFilters,
    selectApiParams,
    selectLeftBenchmarks,
    selectRightBenchmarks,
    selectApiCount,
    selectProjects,
    selectDefaultDemographics,
    selectTargetBenchmarks,
    selectTargetBm,
    selectExLeftBenchmarks,
    selectExRightBenchmarks,
    selectCompYrSettings,
} from '../../Diagnose/selector';
import {
    selectInclusionDataFetched,
    selectInclusionBenchmark,
    selectFilters as selectInclusionFilters,
    selectIaApiParams,
    selectDemographicMappedData,
    selectBenchmarkVersions,
    selectBmVersionInUse,
    selectResurveyProjectsFetched,
} from '../../Inclusion/selector';

import { getDefaultSettings } from '../../Login/selector';

import {
    selectReportError,
    selectReportApiParams,
    selectReportApiCount,
    selectBuFilters,
    selectBenchDataFetched,
    selectDemograhicDataFetched,
    selectFilterIndex,
    selectInclusionData,
    selectBulkDemographics,
    selectShowBulk,
    selectResurveyDemographics,
    selectResurveyDemographicDataFetched,
    selectMappedDemos,
    selectShowAvgGap,
    selectScoreBulkData,
    selectScoreBulkDataFetched,
    selectOrgLevelBu,
    selectExBenchDataFetched,
} from '../selector';

const stateToProps = createStructuredSelector({
    reportError: selectReportError(),
    scoreData: selectScoreData(),
    filters: selectFilters(),
    apiParams: selectApiParams(),
    leftBenchmarks: selectLeftBenchmarks(),
    rightBenchmarks: selectRightBenchmarks(),
    reportApiParams: selectReportApiParams(),
    reportApiCount: selectReportApiCount(),
    apiLoadingCount: selectApiCount(),
    buDemographics: selectBuFilters(),
    projects: selectProjects(),
    benchDataFetched: selectBenchDataFetched(),
    demographicDataFetched: selectDemograhicDataFetched(),
    filterIndex: selectFilterIndex(),
    defaultSettings: getDefaultSettings(),
    inclusionData: selectInclusionData(),
    inclusionDataFetched: selectInclusionDataFetched(),
    inclusionBenchmarkStatus: selectInclusionBenchmark(),
    inclusionFilters: selectInclusionFilters(),
    iaApiParams: selectIaApiParams(),
    bulkDemographics: selectBulkDemographics(),
    showBulk: selectShowBulk(),
    mappedDemos: selectMappedDemos(),
    resurveyDemographics: selectResurveyDemographics(),
    resurveyDemographicDataFetched: selectResurveyDemographicDataFetched(),
    resurveyMapping: selectDemographicMappedData(),
    defaultDemographics: selectDefaultDemographics(),
    iaBenchmarkVersions: selectBenchmarkVersions(),
    iaBmVersionInUse: selectBmVersionInUse(),
    iaProjects: selectResurveyProjectsFetched(),
    showAvgGap: selectShowAvgGap(),
    scoreBulkData: selectScoreBulkData(),
    scoreBulkDataFetched: selectScoreBulkDataFetched(),
    orgLevelBu: selectOrgLevelBu(),
    targetBenchmarks: selectTargetBenchmarks(),
    targetBm: selectTargetBm(),
    exLeftBenchmarks: selectExLeftBenchmarks(),
    exRightBenchmarks: selectExRightBenchmarks(),
    exBenchDataFetched: selectExBenchDataFetched(),
    compYrSettings: selectCompYrSettings(),
});

const dispatchToProps = {
    updateReportApiParams,
    updateRightBenchmarks,
    updateLeftBenchmarks,
    updateFilters,
    getDemographics,
    getBenchmarks,
    updateApiParams,
    getReport,
    getResurveyExcel,
    getDataCube,
    updateBuFilters,
    updateBenchmarkDataFetched,
    updateDemographicDataFetched,
    resetFilterIndex,
    updateFilterIndex,
    updateInclusionFilters,
    getIaBenchmarks,
    updateBulkFilters,
    setBulkToggle,
    getResurveyMapping,
    updateResurveyMapping,
    getBenchmarkVersion,
    setBmVersionInUse,
    getIaProjects,
    setAvgGapToggle,
    getBulkScoreData,
    resetBulkAssign,
    setOrgLevelToggle,
    getScoreData,
    getMappedTargetSettingBm,
    getExBenchmarks,
    updateExRightBenchmarks,
    updateExLeftBenchmarks,
    updateExBenchmarkDataFetched,
    getComparisonYearSettings,
};

export default connect(stateToProps, dispatchToProps)(ReportContent);
