import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import AuthStore from '../../../common/AuthStore';
import FilterBar from '../../../components/FilterBar';
import {
    updateFilterParams,
    getBenchmarkString,
    getFilterArray,
    getBenchmarkName,
    getFiltersAppliedText,
    getOutputFilter,
} from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import './index.scss';
import QuestionBoard from './QuestionBoard';
import ResurveyReport from './ResurveyReport';
import FocusArea from '../assets/FocusArea.svg';
import viewIcon from '../../Reports/assets/viewIcon.svg';
import { getStaticText } from '../../../utils/constants';

function MainBoard(props) {
    const {
        apiParams,
        apiLoadingCount,
        filters,
        defaultSettings,
        getProjectsData,
        projects,
        projectsFetched,
        getReSurveyData,
        reSurveyDataFetched,
        reSurveyData,
        resetReSurveyDataFetched,
        updateFilters,
        getDemographics,
        getBenchmarks,
        activeFilter,
        localPractice,
    } = props;
    const [currentView, setReport] = useState(0);
    const [selectedIds, updateIds] = useState([]);
    const [reSurveyFilter, updateFilterView] = useState(false);
    const [sizeToUse, updateSize] = useState([0, 0]);
    const [surveyRespondents, updateSurveyRes] = useState([0, 0]);
    const [surveys, updateSurveys] = useState([0, 0]);
    const { benchmark = [], resurvey_settings = '', lang, ohid: currentOhid } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUARTILE_RESURVEY } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { VIEW_LESS, VIEW_MORE, BENCHMARK, NUMBER_OF_SURVEYS, YEAR } = SITE_TEXT || {};
    const { ohid: oldId, year: oldYear, report_type = '' } = apiParams;
    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);
    const projectInFilter = [`${oldId} - ${oldYear}`];

    const projectsInUse = useMemo(() => {
        return projects.filter(({ ohid }) => ohid !== currentOhid);
        // eslint-disable-next-line
    }, [currentOhid, projects]);

    selectedIds.forEach(addedId => {
        const { ohid, year } = projectsInUse[addedId];
        projectInFilter.push(`${ohid} - ${year}`);
    });

    const { year: prevYear } = projectsInUse[selectedIds[0]] || {};
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };
    const practiceResurveyName1 = AuthStore.resurveyFilter1 || 'Organization';
    const practiceResurveyName2 = AuthStore.resurveyFilter2 || 'Comparison survey';

    const getResurveyParams = newFilters => {
        const selectedOhiId = selectedIds.map(indexId => projectsInUse[indexId].ohid);
        const ohid = `${oldId},${selectedOhiId.join(',')}`;
        const compare_ids = selectedOhiId.map(selectId => `${oldId}-${selectId}`);

        const filtersArray = [];
        const benchmarksArray = [];
        newFilters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
            let benchMarkYear = oldYear;
            let preString = '';
            let endString = '';

            if (indexToUse) {
                const projectIndex = selectedIds[indexToUse - 1];
                if (isNaN(Number(projectIndex))) return;
                const { year } = projectsInUse[projectIndex];
                benchMarkYear = year;
                preString = indexToUse === 1 ? '||' : '||||';
                endString = indexToUse === 1 && selectedIds.length === 2 ? '||' : '';
            } else {
                endString = selectedIds.length === 2 ? '||||' : '||';
            }
            const filtersObtained = getFilterArray(newD, preString, endString);
            filtersObtained.forEach(result => {
                filtersArray.push(result);
            });
            if (newB.length) {
                benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
            }
        });
        return {
            ohid,
            filters: filtersArray,
            benchmark_str: benchmarksArray.join(','),
            compare_ids,
            report_type,
            comparison_type: resurvey_settings.toLowerCase() === QUARTILE_RESURVEY ? 2 : 1,
            lang: parseInt(lang, 10),
        };
    };

    const getData = newFilters => {
        const RESURVEY_PARAMS = getResurveyParams(newFilters);
        getReSurveyData(RESURVEY_PARAMS);
    };

    useEffect(() => {
        if (!projectsFetched) {
            const { report_id = '' } = AuthStore;
            getProjectsData({ report_id: parseInt(report_id, 10) });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (currentView < 1 && reSurveyDataFetched && Object.keys(reSurveyData).length) {
            const { sizes = {} } = reSurveyData;
            const { nSize = [], nRespondents = [], nSurvey = [] } = sizes;
            updateSize(nSize);
            updateSurveyRes(nSurvey);
            updateSurveys(nRespondents);
        }
        // eslint-disable-next-line
    }, [reSurveyDataFetched, reSurveyData]);

    useEffect(() => {
        if (!reSurveyDataFetched && !apiLoadingCount) {
            const allDataReceived = filters.filter(({ benchmarks: cBen, demographics: cDen }) => cBen && cDen).length;
            if (allDataReceived === filters.length) {
                getData(filters);
            }
        }
        // eslint-disable-next-line
    }, [reSurveyDataFetched, filters, apiLoadingCount]);

    const setFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        const filtersToSend = currentView < 1 ? newFilters : [newFilters[0]];
        updateFilters({ filters: filtersToSend, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
        if (currentView < 1) {
            resetReSurveyDataFetched(false);
        }
    };

    const callReSurveyApi = (currentIds, indexToUse = 0) => {
        if (JSON.stringify(selectedIds) !== JSON.stringify(currentIds)) {
            updateIds(currentIds);
            const { ohid: newId, year: newYear } = projectsInUse[currentIds[indexToUse]];
            getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, indexToUse + 1);
            getBenchmarks({ ohid: newId, year: newYear, lang }, indexToUse + 1, benchmark);
            AuthStore[`resurveyName${indexToUse + 1}`] = `${newId} (${newYear})`;
            resetReSurveyDataFetched(false);
        }
    };

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const handleAllFilterData = (parKey, childKey, demographic, filtersHere, updateRedux = true) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: filBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: filBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true);
        }
    };

    const handleAllCompFilter = (parKey, childKey, demographic, filtersHere, updateRedux = true) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = filtersHere[0];
        const allList = { benchmarks: compBenchmark, demographics: newDemographic };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true);
        }
    };

    const appliedResurveyFiltersInfo = (years = [oldYear, prevYear]) => {
        const maxItemsToShow = 6;
        const { benchmarks: bm = [], demographics = [] } = filters[0] || {};
        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(bm);
        const { benchmarks: compBenchmarks = [], demographics: compDemographic = [] } = filters[1] || {};
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};

        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);
        const sizeResurvey = sizeToUse || [];

        return (
            <div className="filterAppliedContent">
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && (
                            <div className="heading">{practiceResurveyName1 + ` (${years[0]}) : `}</div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{sizeResurvey[0]}</div>
                        </div>
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterData(parentCode, childCode, demographics, filters);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {arrItemComp.length > 0 && newItemComp !== null && (
                    <div className="filterDemo">
                        {newItemComp !== null && (
                            <div className="heading">{practiceResurveyName2 + ` (${years[1]}) : `}</div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{sizeResurvey[1]}</div>
                        </div>
                        {newItemComp !== null &&
                            newItemComp
                                .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllCompFilter(
                                                        parentCode,
                                                        childCode,
                                                        compDemographic,
                                                        filters
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                            <div onClick={toggleCompShowAll}>
                                {showCompAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div className="filterDemo">
                    <div className="heading">{BENCHMARK + `:`}</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{years[0]}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{surveyRespondents[0].toLocaleString('en-US')}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{surveys[0].toLocaleString('en-US')}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>

                    <div className="heading">{BENCHMARK + `:`}</div>
                    <div className="nsizeContent">
                        <div>{YEAR} : </div>
                        <div>{years[1]}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{surveyRespondents[1].toLocaleString('en-US')}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>{NUMBER_OF_SURVEYS} :</div>
                        <div>{surveys[1].toLocaleString('en-US')}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{getBenchmarkName(compBenchmarks)}</div>
                    </div>
                </div>
            </div>
        );
    };

    const getPage = () => {
        let page;
        switch (currentView) {
            case 0:
            default:
                page = (
                    <QuestionBoard
                        {...props}
                        projects={projectsInUse}
                        callReSurveyApi={callReSurveyApi}
                        setReport={setReport}
                        selectedIds={selectedIds}
                        localPractice={localPractice}
                    />
                );
                break;
            case 1:
                page = (
                    <ResurveyReport
                        {...props}
                        callReSurveyApi={callReSurveyApi}
                        setReport={setReport}
                        selectedIds={selectedIds}
                        years={[oldYear, prevYear]}
                        getResurveyParams={getResurveyParams}
                        currentView={currentView}
                        appliedFiltersInfo={appliedResurveyFiltersInfo}
                        toggleFilterView={updateFilterView}
                    />
                );
                break;
        }
        return page;
    };

    return (
        <Fragment>
            {getPage()}
            {reSurveyFilter && (
                <FilterBar
                    activeResetFilter={activeFilter}
                    size={sizeToUse}
                    surveyRespondents={surveyRespondents}
                    surveys={surveys}
                    filters={filters}
                    projectInFilter={projectInFilter}
                    currentView={currentView}
                    showBenchmarks
                    closeFilters={updateFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    years={[oldYear, prevYear]}
                />
            )}
        </Fragment>
    );
}

MainBoard.defaultProps = {
    cohesionScore: 0,
};

MainBoard.propTypes = {
    cohesionScore: PropTypes.number,
    apiParams: PropTypes.object.isRequired,
    reSurveyData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    buData: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    pulseSurvey: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    reSurveyDataFetched: PropTypes.bool.isRequired,
    buDataFetched: PropTypes.bool.isRequired,
    isDragDisabled: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    buFilters: PropTypes.array.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    currentTheme: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    getScoreData: PropTypes.func.isRequired,
    getBuData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getProjectsData: PropTypes.func.isRequired,
    getReSurveyData: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    resetReSurveyDataFetched: PropTypes.func.isRequired,
    resetBuDataFetched: PropTypes.func.isRequired,
    updateBuFilters: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    toggleCreateTheme: PropTypes.func.isRequired,
    changeCurrentPage: PropTypes.func.isRequired,
    setPracticeDisplay: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getClientEngagementData: PropTypes.func.isRequired,
    clientEngagement: PropTypes.object.isRequired,
    clientEngagementFetched: PropTypes.bool.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    cohesionDemographic: PropTypes.string.isRequired,
    localPractice: PropTypes.string.isRequired,
    activeFilter: PropTypes.bool.isRequired,
};

export default MainBoard;
