import React, { useState, useMemo, Fragment, useEffect } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { MenuItem, Select, Tooltip } from '@material-ui/core';

import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import {
    getBenchmarkString,
    getColor,
    getNegColor,
    getRandomValues,
    manipulateHeatmapTitle,
} from '../../../../utils/functions';
import ArrowUp from '../../assets/ArrowUp.svg';
import ArrowDown from '../../assets/ArrowDown.svg';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';

function EnpsDemo({
    sortIeHeatmap,
    defaultSettings,
    empExpHeatmaps,
    crossTabDemo,
    empExpOhiHeatmaps,
    getEmpExpFactor,
    getOhiScoreForEmp,
    addOns,
    apiParams,
    enpsFetched,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    setCrossTabDemo,
    appliedBenchmark,
    filters,
}) {
    const {
        quartileColors = [],
        lang: langTxt = '1033',
        threshold = '',
        engagement = '',
        apply_employee_exp_benchmark = false,
        report_type,
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const {
        MAX_LENGTH_SUBSTRING,
        SORT_ASCENDING,
        SORT_DESCENDING,
        QUESTIONS_HEATMAP,
        IE_CROSSTAB_OPTIONS,
        WORKPLACE_DEMO,
        INT_TO_LEAVE_DEMO,
        ENPS_DEMO,
        OUTCOME_VAR,
        PRACTICE_VAR,
        QUESTIONS_HEATMAP_BM,
    } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const {
        SITE_TEXT,
        IND_EXP_SCORE_CLASSIFICATION,
        QUES_LEGEND_TYPE_HEATMAP,
        QUES_LEGEND_TYPE_DEEP_DIVE,
    } = staticText;
    const {
        N_SIZE,
        NEGATIVE_PRACTICES,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        NON_PROFIT_SURVEY,
        DYNAMIC_TEXT_INFO,
        END_ORG_TXT,
        INT_TO_LEAVE_TXT,
    } = SITE_TEXT;
    const { filters: filterParams, ohid, lang, year, benchmarks = '' } = apiParams || {};
    const { ex_benchmarks = [] } = filters[0] || {};
    const { [crossTabDemo]: data = {} } = empExpHeatmaps || {};
    const { header = [], items = [] } = data || {};
    const { items: items0 = [] } = items[0] || {};
    const { score: size = '' } = items0[0] || {};
    const { [crossTabDemo]: ohiScoreData = {} } = empExpOhiHeatmaps || {};
    const { items: ohiItems = [] } = ohiScoreData || {};
    const [sortOrder, setSortOrder] = useState({});
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const { OHI_SCORE_TEXT } = DYNAMIC_TEXT_INFO();
    const practiceCategories = apply_employee_exp_benchmark ? QUESTIONS_HEATMAP_BM : QUESTIONS_HEATMAP;
    const SUPERSCRIPT = apply_employee_exp_benchmark ? QUES_LEGEND_TYPE_DEEP_DIVE : QUES_LEGEND_TYPE_HEATMAP;

    const headerIE = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [empExpHeatmaps, crossTabDemo]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(_elem => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [empExpHeatmaps, crossTabDemo]);

    const ohiScoreToUse = useMemo(() => {
        if (!ohiItems.length) return [];
        const newArray = [];
        const innerArray = [];
        const innerArrayOhi = {};
        ohiItems.forEach(({ items: subItem = [], sub_demo = '' }) => {
            innerArrayOhi[sub_demo] = subItem;
        });
        const innerArrayEx = {};
        items.forEach(({ items: subItem = [], sub_demo = '' }) => {
            innerArrayEx[sub_demo] = subItem;
        });
        Object.keys(innerArrayEx).forEach(elem => {
            if (innerArrayOhi[elem]) {
                innerArray.push(innerArrayOhi[elem]);
            } else {
                innerArray.push(Array(header.length).fill({ score: '-', quartile: '' }));
            }
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(_elem => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [empExpOhiHeatmaps, crossTabDemo]);

    const headerToUse = headerIE;
    const { name = '' } = IE_CROSSTAB_OPTIONS.find(({ code }) => code === crossTabDemo);

    const sideHeaderVal = () => {
        return practiceCategories.map(({ children: child = [], title: categoryN = '' }) => {
            const itemsToIterate = [];
            const childArr = child.filter(
                prName =>
                    !(
                        (prName === INT_TO_LEAVE_TXT && crossTabDemo === INT_TO_LEAVE_DEMO) ||
                        (prName === END_ORG_TXT && crossTabDemo === ENPS_DEMO) ||
                        (prName === 'eNPS' && crossTabDemo === ENPS_DEMO)
                    )
            );
            childArr.forEach(elem => {
                const index = header.indexOf(elem);
                if (header[index] !== N_SIZE) {
                    itemsToIterate.push(0);
                    // titleArr.push(header[index]);
                }
            });
            return itemsToIterate.map((subItems, parentI) => {
                const len = childArr.includes('N') ? 1 : childArr.length;
                return (
                    <>
                        {!parentI ? (
                            <div style={{ height: `${len * 7}vh` }} className="deepDiveHeading">
                                <div style={{ transform: 'rotate(-90deg)', textWrap: 'wrap', width: '100%' }}>
                                    {categoryN}
                                </div>
                            </div>
                        ) : null}
                    </>
                );
            });
        });
    };

    const getHeatMapValues = arrItems => {
        return practiceCategories.map(({ children: child = [] }) => {
            const itemsToIterate = [];
            const titleArr = [];
            child
                .filter(
                    prName =>
                        !(
                            (prName === INT_TO_LEAVE_TXT && crossTabDemo === INT_TO_LEAVE_DEMO) ||
                            (prName === END_ORG_TXT && crossTabDemo === ENPS_DEMO) ||
                            (prName === 'eNPS' && crossTabDemo === ENPS_DEMO)
                        )
                )
                .forEach(elem => {
                    const index = header.indexOf(elem);
                    if (header[index] !== N_SIZE) {
                        itemsToIterate.push(arrItems[index]);
                        titleArr.push(header[index]);
                    }
                });
            return itemsToIterate.map((subItems, parentI) => {
                const title = manipulateHeatmapTitle(titleArr[parentI], langTxt);
                const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                const sup = SUPERSCRIPT[title] || '';
                // const len = child.includes('N') ? 1 : child.length;
                return (
                    <>
                        <div className="sectionDiv" key={title}>
                            <div>
                                <ul style={{ marginLeft: '30px' }}>
                                    <Tooltip placement="top" arrow title={title} aria-label={title}>
                                        <li className="sectionContent">
                                            {title} <sup>{sup}</sup>
                                        </li>
                                    </Tooltip>
                                    {subItems.map(({ score: innerScore, quartile = '' }, index) => {
                                        const i = getColor(innerScore, [], false, true);
                                        const iNeg = getNegColor(innerScore, [], false, true);
                                        const {
                                            positiveBg = '#ffffff',
                                            negativeBg = '#ffffff',
                                            color: oldColor = '#000000',
                                        } = IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                        const oldBg = isNegativeTrait ? negativeBg : positiveBg;
                                        const { background: qBg = '', color: qColor = '', border: grad = '' } =
                                            quartileColors[quartile] || {};
                                        const background = apply_employee_exp_benchmark ? qBg : oldBg;
                                        const color = apply_employee_exp_benchmark ? qColor : oldColor;
                                        const gradient = apply_employee_exp_benchmark ? grad : oldBg;
                                        const scoreToDisplay =
                                            !isNaN(parseInt(innerScore, 10)) &&
                                            !(crossTabDemo === INT_TO_LEAVE_DEMO && parseInt(innerScore, 10) === 0) &&
                                            !(crossTabDemo === ENPS_DEMO && parseInt(innerScore, 10) === 0)
                                                ? innerScore.toLocaleString('en-US')
                                                : '-';
                                        return (
                                            <li
                                                className={c({ nSize: !i }, 'contentList')}
                                                key={`${innerScore}${index}${getRandomValues()}`}
                                                style={{
                                                    border: `1px solid white`,
                                                    background: `linear-gradient(135deg, ${gradient} 15%, ${background} 15%)`,
                                                    color,
                                                }}
                                            >
                                                {scoreToDisplay}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </>
                );
            });
        });
    };

    const getOhiHeapMapValues = itemsToIterate => {
        return itemsToIterate.slice(0, 2).map((list, i) => {
            return (
                <div className="sectionDiv" key={'ohiScore' + i}>
                    {crossTabDemo !== WORKPLACE_DEMO ? (
                        <ul>
                            <li
                                className="sectionContent"
                                // style={{paddingLeft: '7vh'}}
                            >
                                {!i ? 'n' : OHI_SCORE_TEXT}
                            </li>
                            {list.map(({ score: innerScore, quartile }, index) => {
                                const { background = '', color = '', border: borderColor = '' } =
                                    quartileColors[quartile] || {};
                                return (
                                    <li
                                        className={c({ nSize: !i }, 'contentList')}
                                        key={`${innerScore}${index}${getRandomValues()}`}
                                        style={{
                                            color,
                                            background: borderColor
                                                ? `linear-gradient(135deg, ${borderColor} 15%, ${background} 15%)`
                                                : background,
                                            border: '1px solid white',
                                        }}
                                    >
                                        {!isNaN(parseInt(innerScore, 10))
                                            ? parseInt(innerScore, 10).toLocaleString('en-US')
                                            : '-'}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                </div>
            );
        });
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    const callData = code => {
        getEmpExpFactor({
            demographic: code,
            threshold,
            lang,
            page: 1,
            ohid,
            benchmarks,
            type: PRACTICE_VAR,
            filters: filterParams,
            year,
            ...addOns,
            report_type,
            ex_benchmarks: apply_employee_exp_benchmark ? getBenchmarkString(ex_benchmarks) || '1001:1' : '',
        });
        getOhiScoreForEmp({
            demographic: code,
            threshold,
            lang,
            page: 1,
            ohid,
            benchmarks,
            type: OUTCOME_VAR,
            filters: filterParams,
            year,
            ...addOns,
            report_type,
        });
    };

    useEffect(() => {
        if (!(crossTabDemo in empExpHeatmaps) || !enpsFetched) {
            callData(crossTabDemo);
        }
        // eslint-disable-next-line
    }, [crossTabDemo, enpsFetched]);

    return (
        <div className="demographicBoard addOns clearfix">
            <div className="indExpBoard">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">
                            {EMPLYEE_EXPEIENCE} <sup>1</sup>
                        </div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                {appliedFiltersInfo()}
                {appliedBenchmark()}

                <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                    <div>
                        <ul className="leftNav">
                            {IND_EXP_LINKS.map((tabName, index) => {
                                if (
                                    (!apply_employee_exp_benchmark && index === 4) ||
                                    (report_type === 'percentile' && index === 4)
                                )
                                    return null;
                                return (
                                    <li
                                        data-testid={tabName}
                                        key={tabName}
                                        className={c({ activeLink: addOnTab === index })}
                                        onClick={() => setAddOnTabs(index)}
                                    >
                                        {tabName}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <Fragment>
                    <div className="deepDiveHeading">
                        {IE_CROSSTAB_OPTIONS.filter(({ code }) => code === crossTabDemo)[0].label} <sup>1</sup>
                    </div>
                    <div className="buttonWrapper">
                        <div className="buttonContent" />
                        <div className="rightOptions">
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharpIcon}
                                    value={crossTabDemo}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target: { value: code } }) => setCrossTabDemo(code)}
                                >
                                    {IE_CROSSTAB_OPTIONS.map(({ label, code, nonProfitAccess }) => {
                                        if (nonProfitAccess && isNonProfit) {
                                            return null;
                                        }

                                        return (
                                            <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                                {label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </div>
                    </div>
                    <div className="demographicHeatmapBoard">
                        <div className="heatmapBoard clearfix clearPadding enpsTable">
                            <section className="sectionWrapper">
                                {crossTabDemo !== WORKPLACE_DEMO ? (
                                    <div className="deepDiveOutcome">{dataToUse.length ? sideHeaderVal() : null}</div>
                                ) : (
                                    <div className="deepDiveOutcome" style={{ top: '5vh' }}>
                                        {dataToUse.length ? sideHeaderVal() : null}
                                    </div>
                                )}
                                <div id="myHeader" className={c('headerWrapper sectionDiv clearfix')}>
                                    <div>
                                        {headerToUse.length ? (
                                            <ul className={c({ tabLeft: true })}>
                                                <li className="headerList leftHeader contentList">{name}</li>
                                                {headerToUse &&
                                                    headerToUse.map((val, i) => {
                                                        const cellVal = manipulateHeatmapTitle(val, langTxt);
                                                        return (
                                                            <Tooltip
                                                                key={`${val}${i}`}
                                                                placement="top"
                                                                arrow
                                                                title={val}
                                                                aria-label={val}
                                                            >
                                                                <li
                                                                    className="headerList contentList"
                                                                    onClick={() => sortData(val)}
                                                                >
                                                                    {cellVal.length > MAX_LENGTH_SUBSTRING
                                                                        ? cellVal.substring(0, MAX_LENGTH_SUBSTRING) +
                                                                          '...'
                                                                        : cellVal}
                                                                    {cellVal === OHI_SCORE_TEXT && (
                                                                        <img
                                                                            className="arrowSort"
                                                                            src={
                                                                                sortOrder[val] === 1
                                                                                    ? ArrowUp
                                                                                    : ArrowDown
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                </li>
                                                            </Tooltip>
                                                        );
                                                    })}
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="contentWrapper clearfix">
                                    <div className="mainContent deepDiveOhi">
                                        {ohiScoreToUse.length ? getOhiHeapMapValues(ohiScoreToUse) : null}
                                    </div>
                                </div>
                                <div className="contentWrapper clearfix">
                                    <div className="mainContent">
                                        {dataToUse.length ? getHeatMapValues(dataToUse) : null}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </Fragment>
            </div>
        </div>
    );
}

EnpsDemo.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    empExpHeatmaps: PropTypes.object.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
    empExpOhiHeatmaps: PropTypes.object.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    getEmpExpFactor: PropTypes.func.isRequired,
    getOhiScoreForEmp: PropTypes.func.isRequired,
    addOns: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    enpsFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setCrossTabDemo: PropTypes.func.isRequired,
    appliedBenchmark: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
};

export default EnpsDemo;
