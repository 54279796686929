import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Overview from './Overview';

import {
    selectAnalyticsApiCount,
    selectDataAtGlance,
    selectDataAtGlanceFetched,
    selectFilterParam,
    selectFilters,
    selectAnalyticsPerCategory,
    selectAnalyticsPerSectorData,
    selectPendingPptData,
} from '../selector';
import {
    getDataAtGlance,
    getAnalyticsPerCategory,
    getAnalyticsPerSector,
    setAnalyticsPerCategory,
    updateFilters,
    setPendingPptData,
} from '../actions';
import { getDefaultSettings } from '../../Login/selector';
import { getPptData } from '../../Diagnose/actions';

const stateToProps = createStructuredSelector({
    analyticsApiCount: selectAnalyticsApiCount(),
    dataAtGlance: selectDataAtGlance(),
    dataAtGlanceFetched: selectDataAtGlanceFetched(),
    analyticsPerCategory: selectAnalyticsPerCategory(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    filters: selectFilters(),
    analyticsPerSectorData: selectAnalyticsPerSectorData(),
    pendingPptData: selectPendingPptData(),
});

const dispatchToProps = {
    getDataAtGlance,
    getAnalyticsPerCategory,
    setAnalyticsPerCategory,
    updateFilters,
    getPptData,
    getAnalyticsPerSector,
    setPendingPptData,
};

export default connect(stateToProps, dispatchToProps)(Overview);
