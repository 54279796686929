import React, { useState, useEffect, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';
import { ArrowForward } from '@material-ui/icons';
import AuthStore from '../../../common/AuthStore';

import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import Down from '../../Diagnose/assets/Down.svg';
import { getStaticText } from '../../../utils/constants';
import { getStaticTextAnalytics } from '../constants';
import colors from '../../../sass/colors';
import './index.scss';
import BlankPage from '../BlankPage/BlankPage';
import QuickHelp from '../QuickHelp/QuickHelp';
import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import { getFilterName, getFilterArrayName } from '../assets/functions';
import WarningModal from '../Intro/WarningModal';
import { matchFilterRegex } from '../../../utils/functions';
// new

function Flagpole({
    toggleFilterView,
    getFlagpole,
    flagpoleFetched,
    flagpoleData,
    defaultSettings,
    filterParams,
    renderfilterApplied,
    flagpoleError,
    dataAtGlance,
    filtersApplied,
    filters,
    getPptData,
    defaultYear,
    // new
    warningModal,
    setWarningModal,
}) {
    useEffect(() => {
        if (!flagpoleFetched) {
            getFlagpole(filterParams);
        }
        // eslint-disable-next-line
    }, [flagpoleFetched]);
    const { lang } = defaultSettings;
    const staticTextAnalytics = getStaticTextAnalytics(lang);
    const { ANALYTICS_QUARTILE_COLORS, FLAGPOLE_LINK } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, QUARTILE_COLORS } = staticText;

    const { $white, $grey200 } = colors;
    const [expandedList, updateExpandedList] = useState([0]);
    const [helpView, helpMode] = useState(false);
    const {
        COMPARISON,
        FILTERS,
        NOTE,
        SIGNIFICANCE,
        BENCHMARK_HEAD,
        BENCHMARK_NA,
        ANALYTIC_FLAGPOLE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        GSOD_FLAGPOLE_TEXT,
        GSOD_FLAGPOLE_TEXT_1,
        GSOD_FLAGPOLE_TEXT_2,
        SCORECARD_NOTE,
        DISCLAIMER,
        FLAGPOLE_NOTE_1,
        FLAGPOLE_NOTE_2,
        FLAGPOLE_LINK_TEXT,
        DOWNLOAD,
        LEARN_MORE,
        LEARN_MORE_LINK,
        VIEW_ONLINE,
        BOTTOM_DECILE,
        NO_BENCHMARK,
    } = SITE_TEXT || {};

    const { LEARN_MORE_FLAGPOLE_LINK } = FLAGPOLE_LINK;
    const { records = [], n_survey: flagpoleDataDataSize = 0, n_respondents = '' } = flagpoleData || {};
    const { total_survey: n_survey = '' } = dataAtGlance;
    const dataToIterate = records.slice(1);
    const {
        display_name: ohiName,
        quartile: ohiQuartile = '',
        score: ohiScore = '',
        diff: ohiScoreDiff = '',
        sig: ohiScoreSig = '',
    } = records[0] || {};
    const { background: ohiBg, border: ohiBorder = $grey200, color: ohiColor } = QUARTILE_COLORS[ohiQuartile] || {};
    const ohiStyleBlack = ohiQuartile === ('' || 999) ? { border: '2px solid #ddd' } : {};
    const { bg: ohiOneBg } = SIGNIFICANCE[ohiScoreSig] || {};
    const keys = matchFilterRegex(filterParams);
    const keyCount = keys ? keys.length : 0;
    const is24 = defaultYear === 2024;

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    const selectedItems = filters
        .filter(item => item.filter_id === 'year')
        .flatMap(item => item.filter_items)
        .filter(item => item.isSelected)
        .map(item => item.filter_item_name);

    const flagpoleCardSelected = () => {
        if (selectedItems[0] === '2024') {
            return <div>(n = 682 orgs, n = 2,619,975 respondents)</div>;
        }
        if (selectedItems[0] === '2023') {
            return <div>(n = 768 orgs, n = 2,965,672 respondents)</div>;
        }
    };

    const handleWarningModal = () => {
        setWarningModal(true);
    };

    const getPpt = (slideArr = [], pptName = 'flagpole') => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name: 'Template_OHIBMX_4_0.pptx',
            slides: slideArr,
        };
        const currentDate = new Date().toLocaleDateString();
        const currentTimestamp = new Date().toLocaleTimeString();
        const pptFlagpoleName = `${pptName}_${currentDate}_${currentTimestamp}.pptx`;
        getPptData(output, pptAccessToken, pptFlagpoleName);
    };

    const getDataForPpt = () => {
        const selectedSample = getFilterName(filters);
        const commonEle = [
            {
                name: 'Weak_Square',
                bgcolor: SIGNIFICANCE[0].bg,
            },
            {
                name: 'Comparable_Square',
                bgcolor: SIGNIFICANCE[2].bg,
            },
            {
                name: 'Strong_Square',
                bgcolor: SIGNIFICANCE[1].bg,
            },
            {
                name: 'Checkme',
                is_delete: true,
            },
            {
                name: '2. Slide Title',
                value: `Flagpole for ${selectedSample}`,
            },
            {
                name: 'benchmark1_text',
                value: `Median point difference between ${selectedSample} and global benchmark`,
            },
            {
                name: '4. Footnote',
                value: `1. ${FLAGPOLE_NOTE_1} See ${FLAGPOLE_LINK_TEXT} for additional detail.`,
            },
            {
                name: '5. Source',
                value: `Source: Selected sample=${selectedSample} (n=${n_respondents} no. of surveys=${flagpoleDataDataSize})`,
            },
        ];
        const outcomeArray = [...commonEle];
        const flagpolePractices = [...commonEle];
        const diffParentChart1 = [
            {
                row_index: 1,
                column_index: 0,
                index: 0,
                value: ohiScoreDiff,
                bgcolor: ohiOneBg,
                border_color: ohiOneBg,
            },
        ];
        const diffChildChart1 = [];
        let indexInArr = 0;
        Object.keys(dataToIterate).forEach((innerContent, innerContentIndex) => {
            // const { display_name, quartile = '', score: value = '', children = {} } = records[innerContent];
            // debugger;
            const { diff: recordOneDiff, sig: recordOneSign, children = [] } = dataToIterate[innerContent] || {};
            const { bg: recordOneBg } = SIGNIFICANCE[recordOneSign] || {};
            // debugger;
            const index = innerContentIndex + 1;
            diffParentChart1.push({
                row_index: index + 1,
                column_index: 0,
                index,
                value: recordOneDiff,
                bgcolor: recordOneBg,
                border_color: recordOneBg,
            });
            Object.keys(children).forEach(childVal => {
                const {
                    // score: childScore,
                    // quartile: childQT,
                    // display_name: cDisplayName,
                    diff: recordOneChildDiff,
                    sig: recordOneChildSign,
                } = children[childVal];
                // const { background = $white, border = $white } = quartileColors[childQT] || {};
                // const { diff: recordOneChildDiff, sig: recordOneChildSign } = recordOneChild[childVal];
                const { bg: recordOneChildBg } = SIGNIFICANCE[recordOneChildSign] || {};
                // childChart.push(
                //     {
                //         row_index: indexInArr + 1,
                //         column_index: 1,
                //         index: indexInArr,
                //         value: childScore,
                //         bgcolor: background,
                //         border_color: border,
                //     },
                //     {
                //         row_index: indexInArr + 1,
                //         column_index: 0,
                //         index: indexInArr,
                //         value: cDisplayName,
                //         cat: cDisplayName,
                //     }
                // );
                diffChildChart1.push({
                    row_index: indexInArr + 1,
                    column_index: 0,
                    index: indexInArr,
                    value: recordOneChildDiff,
                    bgcolor: recordOneChildBg,
                    border_color: recordOneChildBg,
                });
                indexInArr += 1;
            });
        });

        outcomeArray.push({ name: 'benchmark1_chart', chart_data: diffParentChart1 });
        flagpolePractices.push({ name: 'Chart 2', chart_data: diffChildChart1 });
        const slides = [
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: 'Flagpole_Outcomes',
                data: outcomeArray,
            },
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: 'Flagpole_Practices',
                data: flagpolePractices,
            },
        ];

        getPpt(slides);
    };

    return (
        <Fragment>
            <div className="flagpole clearfix">
                <Helmet>
                    <title>{ANALYTIC_FLAGPOLE_TEXT['1']} </title>
                </Helmet>
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{ANALYTIC_FLAGPOLE_TEXT['2']}</div>
                        <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div>
                    </div>
                    <ul className="navScoreList">
                        {is24 ? (
                            <li data-testid="getPptBtn" onClick={getDataForPpt}>
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        ) : null}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                <div className="benchmarkInfo">
                    {ANALYTIC_FLAGPOLE_TEXT['3']}
                    <div className="selectOrgsRes">{flagpoleCardSelected()}</div>
                </div>
                <div className="disclaimerContent">
                    <img src={DisclaimerWarning} alt="" />
                    <div className="note scoreCardNote">
                        <span>{DISCLAIMER} : </span>
                        <div>{SCORECARD_NOTE}</div>
                        <div className="warningContent" onClick={handleWarningModal}>
                            know more
                        </div>
                    </div>
                </div>
                {renderfilterApplied}
                {/* {errorInfo && <ErrorToast message={errorInfo} />} */}
                {dataToIterate.length ? (
                    <Fragment>
                        <div className="ChartsContainer">
                            <section className="outcomeContent selectContent clearfix flagPoleSection">
                                <div className="headerNav">
                                    <div className="headingContent headingBuComparison" />
                                    <div className="headingContent">
                                        <div>{GSOD_FLAGPOLE_TEXT}</div>
                                    </div>
                                    <div className="headingContent">
                                        <div className="medianHeading">{GSOD_FLAGPOLE_TEXT_1}</div>
                                        <div>({GSOD_FLAGPOLE_TEXT_2})</div>
                                    </div>
                                </div>

                                <div className="headerNav2">
                                    <div className="headingContent headingBuComparison">{ohiName}</div>
                                    <div className="headingContent">
                                        <div
                                            style={{
                                                position: 'relative',
                                                display: 'flex',
                                                width: '45px',
                                                fontWeight: '500',
                                                padding: '4px 18px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: !ohiStyleBlack
                                                    ? `linear-gradient(135deg, ${ohiBorder} 25%, ${ohiBg} 25%)`
                                                    : ohiBg,
                                                borderColor: !ohiStyleBlack ? 'transparent' : ohiBorder,
                                                borderWidth: ohiQuartile === ('' || 999) || ohiStyleBlack ? 1 : 2,
                                                borderStyle: !ohiStyleBlack ? 'none' : 'solid',
                                                borderRadius: '4px',
                                                fontSize: '1.8vh',
                                                color: ohiColor,
                                            }}
                                        >
                                            <div>{ohiScore} </div>
                                        </div>
                                    </div>
                                    <div className="flagPoleChart">
                                        <div className={c('rank', { negative: ohiScoreDiff < 0 })}>
                                            <div
                                                className="textBorder"
                                                style={{
                                                    background: ohiOneBg,
                                                    width: `${2 * Math.abs(ohiScoreDiff)}%`,
                                                }}
                                            >
                                                <div className="textRank">
                                                    {ohiScoreDiff > 0 ? `+${ohiScoreDiff}` : ohiScoreDiff}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {dataToIterate.map((dataSet, index) => {
                                const {
                                    display_name = '',
                                    quartile = '',
                                    score = '',
                                    children = {},
                                    diff: recordDiff = '',
                                    sig: pSig = '',
                                } = dataSet;
                                const { background = $white, border: borderColor = $grey200, color: parColor } =
                                    QUARTILE_COLORS[quartile] || {};
                                const styleBlack = quartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                                const { bg: recordOneBg } = SIGNIFICANCE[pSig] || {};
                                return (
                                    <Accordion
                                        classes={{ root: 'accordionRoot' }}
                                        key={index}
                                        expanded={expandedList.includes(index)}
                                    >
                                        <AccordionSummary
                                            data-testid={dataSet}
                                            expandIcon={<ExpandMoreIcon />}
                                            onClick={() => updateExpanded(index)}
                                            classes={{ content: 'accordionHead' }}
                                        >
                                            <section className="outcomeContent clearfix flagPoleSection1">
                                                <div className="headerNav2">
                                                    <div className="headingContent headingBuComparison">
                                                        {display_name}
                                                    </div>
                                                    <div className="headingContent">
                                                        <div
                                                            style={{
                                                                position: 'relative',
                                                                display: 'flex',
                                                                width: '45px',
                                                                fontWeight: '500',
                                                                padding: '4px 18px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                background: !styleBlack
                                                                    ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                                                                    : background,
                                                                borderColor: !styleBlack ? 'transparent' : borderColor,
                                                                borderWidth:
                                                                    ohiQuartile === ('' || 999) || styleBlack ? 1 : 2,
                                                                borderStyle: !styleBlack ? 'none' : 'solid',
                                                                borderRadius: '4px',
                                                                fontSize: '1.8vh',
                                                                color: parColor,
                                                            }}
                                                        >
                                                            <div>{score} </div>
                                                        </div>
                                                    </div>
                                                    <div className="flagPoleChart">
                                                        <div className={c('rank', { negative: recordDiff < 0 })}>
                                                            <div
                                                                className="textBorder"
                                                                style={{
                                                                    background: recordOneBg,
                                                                    width: `${2 * Math.abs(recordDiff)}%`,
                                                                }}
                                                            >
                                                                <div className="textRank">
                                                                    {recordDiff > 0 ? `+${recordDiff}` : recordDiff}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: 'clearfix' }}>
                                            {children.map(childSet => {
                                                const {
                                                    display_name: childName = '',
                                                    score: childScore = '',
                                                    quartile: childQuartile,
                                                    diff: recordOneDiff,
                                                    sig: recordOneSign,
                                                } = childSet;
                                                const {
                                                    background: childBg = $white,
                                                    border: childBd = $grey200,
                                                    color: childColor,
                                                } = QUARTILE_COLORS[childQuartile] || {};
                                                const styleBlackC =
                                                    childQuartile === ('' || 999)
                                                        ? { border: '2px solid #000000' }
                                                        : {};
                                                const { bg: recordOneChildBg } = SIGNIFICANCE[recordOneSign] || {};
                                                return (
                                                    <section className="outcomeContent clearfix flagPoleSection2">
                                                        <div className="headerNav2">
                                                            <div className="headingContent headingBuComparison">
                                                                {childName}
                                                            </div>
                                                            <div className="headingContent">
                                                                <div
                                                                    style={{
                                                                        position: 'relative',
                                                                        display: 'flex',
                                                                        width: '30px',
                                                                        padding: '4px 8px',
                                                                        fontWeight: '500',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        background: !styleBlackC
                                                                            ? `linear-gradient(135deg, ${childBd} 25%, ${childBg} 25%)`
                                                                            : childBg,
                                                                        borderColor: !styleBlackC
                                                                            ? 'transparent'
                                                                            : childBd,
                                                                        borderWidth:
                                                                            ohiQuartile === ('' || 999) || styleBlackC
                                                                                ? 1
                                                                                : 2,
                                                                        borderStyle: !styleBlackC ? 'none' : 'solid',
                                                                        borderRadius: '4px',
                                                                        fontSize: '1.3vh',
                                                                        color: childColor,
                                                                    }}
                                                                >
                                                                    <div>{childScore} </div>
                                                                </div>
                                                            </div>
                                                            <div className="flagPoleChart">
                                                                <div
                                                                    className={c('rank', {
                                                                        negative: recordOneDiff < 0,
                                                                    })}
                                                                >
                                                                    <div
                                                                        className="textBorder"
                                                                        style={{
                                                                            background: recordOneChildBg,
                                                                            width: `${2 * Math.abs(recordOneDiff)}%`,
                                                                        }}
                                                                    >
                                                                        <div className="textRank">
                                                                            {recordOneDiff > 0
                                                                                ? `+${recordOneDiff}`
                                                                                : recordOneDiff}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                            <div className="note flagpoleNote">
                                <b>{NOTE}:</b>
                                <div>
                                    <b>1.</b>
                                    {FLAGPOLE_NOTE_1} See{' '}
                                    <a
                                        className="flagpoleLink"
                                        href={LEARN_MORE_FLAGPOLE_LINK}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {FLAGPOLE_LINK_TEXT}
                                    </a>{' '}
                                    for additional detail.
                                </div>
                                <div>
                                    <b>2.</b>
                                    {FLAGPOLE_NOTE_2}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <section className="sectionWrapper">
                        <BlankPage error={flagpoleError} count={keyCount} />
                    </section>
                )}
                <QuickHelp helpMode={helpMode} helpView={helpView} activePage="3" />
            </div>

            <div className="bottomCard bottomDisclaimer">
                <span>
                    {LEARN_MORE}
                    <a className="arrowIcon" href={LEARN_MORE_LINK}>
                        {VIEW_ONLINE}
                        <ArrowForward />
                    </a>
                </span>
            </div>

            <div className="flagPoleFootnote">
                <ul className="bottomList clearfix flagPoleList">
                    <li>{COMPARISON} : </li>
                    {SIGNIFICANCE.map(({ title, bg: background }) => (
                        <li key={title}>
                            <span style={{ background }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{BENCHMARK_HEAD} : </li>
                    {ANALYTICS_QUARTILE_COLORS.slice()
                        .reverse()
                        .map(({ title, background, border: borderColor }) => {
                            const bottomDecileVar = title === BOTTOM_DECILE;
                            const BenchmarkTitle = title === NO_BENCHMARK;
                            return (
                                <li
                                    key={title}
                                    style={{ color: background }}
                                    className={c({
                                        hide: bottomDecileVar || BenchmarkTitle,
                                    })}
                                >
                                    <span
                                        className="benchmarkTriangle"
                                        style={{
                                            background,
                                            borderWidth: '13px 13px 0 0',
                                            borderColor: bottomDecileVar
                                                ? `transparent ${borderColor} transparent transparent`
                                                : `${borderColor} transparent transparent transparent`,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <div style={{ color: 'black' }}>{title}</div>
                                </li>
                            );
                        })}
                    <li>
                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                        {BENCHMARK_NA}
                    </li>
                </ul>
            </div>

            {/* new  */}
            {warningModal ? (
                <WarningModal
                    // defaultSettings={defaultSettings}
                    setWarningModal={setWarningModal}
                    // setGsodLoginCountNum={setGsodLoginCountNum}
                />
            ) : null}
        </Fragment>
    );
}

Flagpole.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    flagpoleData: PropTypes.object.isRequired,
    flagpoleFetched: PropTypes.bool.isRequired,
    getFlagpole: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    filterParams: PropTypes.string.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    flagpoleError: PropTypes.array.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    getPptData: PropTypes.func.isRequired,
    defaultYear: PropTypes.number.isRequired,
    // new
    warningModal: PropTypes.bool.isRequired,
    setWarningModal: PropTypes.func.isRequired,
};

export default Flagpole;
