import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    setSelectedDemographic,
    getEmpExpFactor,
    sortIeHeatmap,
    getHeatMapPractice,
    getOhiScoreForEmp,
    getOrgHealthData,
} from '../../actions';

import {
    selectApiCount,
    getErrorInfo,
    selectFilters,
    selectApiParams,
    selectSelectedDemographic,
    selectIeHeatmap,
    selectIeHeatmapFetched,
    selectEmpExpHeatmaps,
    selectEmpExpOhiHeatmaps,
    selectHeatMapPractiseDataFetched,
    selectHeatMapPractiseData,
    selectOrgHealth,
    selectSingleQuestion,
    selectOrgHealthFetched,
    selectEnpsFetched,
    selectFootnoteList,
} from '../../selector';

import { getDefaultSettings } from '../../../Login/selector';
import HeatmapBoard from './DeepDive';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    filters: selectFilters(),
    selectedDemographic: selectSelectedDemographic(),
    ieHeatmap: selectIeHeatmap(),
    ieHeatmapFetched: selectIeHeatmapFetched(),
    apiParams: selectApiParams(),
    heatMapPracticeFetched: selectHeatMapPractiseDataFetched(),
    heatMapPractice: selectHeatMapPractiseData(),
    empExpHeatmaps: selectEmpExpHeatmaps(),
    empExpOhiHeatmaps: selectEmpExpOhiHeatmaps(),
    orgHealth: selectOrgHealth(),
    singleQuestion: selectSingleQuestion(),
    orgHealthFetched: selectOrgHealthFetched(),
    enpsFetched: selectEnpsFetched(),
    footnoteList: selectFootnoteList(),
});

const dispatchToProps = {
    setSelectedDemographic,
    getEmpExpFactor,
    sortIeHeatmap,
    getHeatMapPractice,
    getOhiScoreForEmp,
    getOrgHealthData,
};

export default connect(stateToProps, dispatchToProps)(HeatmapBoard);
