import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { Dialog, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import c from 'classnames';
import Loader from 'react-spinners/FadeLoader';

import { getStaticText } from '../../../utils/constants';
import { getGapColor, getIncBenchmarkStyles, getInclusionStyle, quartileTitle } from '../../../utils/functions';
import { getInclusionGapUrl } from '../../Reports/apis';
import './index.scss';

const ClickThrusModal = ({
    closeModal,
    clickThrusMeta,
    demographics,
    apiParams,
    parentGap,
    inclusion_threshold,
    scoreColor,
    withBenchmark,
    defaultSettings,
}) => {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        QUESTIONS,
        DEMOGRAPHICS,
        OUTCOME_QUESTIONS,
        INCL_HEAD_META,
        AGGREMENT_LEVEL,
        DISCLAIMER,
        DISCLAIMER_TEXT_PART1,
        DISCLAIMER_TEXT_PART2,
        DISCLAIMER_GAP_TEXT,
        QUESTION_DISCLAIMER,
        FILTES_CHECK_NOTE,
        PERSONAL_EXP,
        ENT_PERCEPTION,
        HEADER_LEGENDS,
        GAPS_TEXT,
        H_TEXT,
        L_TEXT,
    } = SITE_TEXT;
    const { quartileColors = [], inclusion_old_overall_calc = false } = defaultSettings || {};
    const [isQuestions, setView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gapData, setGapData] = useState({});

    async function getDataForDemo(apiValues) {
        setLoading(true);
        try {
            const { data: valueData = {} } = await getInclusionGapUrl(apiValues);
            const { data = {} } = valueData;
            setGapData(data);
            setLoading(false);
        } catch (error) {
            setLoading(!error);
        }
    }

    useEffect(() => {
        if (parentGap) {
            getDataForDemo({
                ...apiParams,
                gap_on: parentGap,
                with_benchmark: withBenchmark,
            });
        }
        // eslint-disable-next-line
    }, []);

    const showData = !!Object.keys(gapData).length;
    const { isLevel, meta_scores = [], score, gap: gapMain, parentKey, childKey = '', data = {} } = clickThrusMeta;
    const { score: scoreStyles = [], gap: gapStyles = [], styles: styleColors = [] } = scoreColor;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const { inclusion_quartile = -1, quartile = -1 } = data;

    const infoToDisplay = useMemo(() => {
        const { qbyq = {}, gap = {} } = gapData;
        const scores = [];
        const sizes = [];
        let gapValue = '';
        let mainTitle = '';
        const displays = [];
        let highestVal = 100;
        let lowestVal = 0;

        Object.keys(qbyq).forEach(key => {
            const { display_name = '', size = '', level_scores: levels_score = {}, records = {} } = qbyq[key];
            const { records: gapRecords = {}, levels_gap = {} } = gap;
            const {
                score: parentScore = '',
                children = {},
                display_name: parentTtile = '',
                quartile: parentQuart = -1,
                inclusion_quartile: parentIncQuart = -1,
            } = records[parentKey] || {};
            const { gap: gapV = '', children: gapChildren = {} } = gapRecords[parentKey] || {};
            if (isLevel) {
                const {
                    score: levelScore = '',
                    quartile: quart = -1,
                    inclusion_quartile: incQuart = -1,
                } = levels_score[parentKey];
                scores.push({ score: levelScore, quartile: quart, inclusion_quartile: incQuart });
                const { gap: gapL = '' } = levels_gap[parentKey] || {};
                gapValue = gapL;
                mainTitle = parentKey === 'individual' ? PERSONAL_EXP : ENT_PERCEPTION;
            } else if (childKey) {
                const {
                    score: childScore = '',
                    display_name: childTitle = '',
                    quartile: quart = -1,
                    inclusion_quartile: incQuart = -1,
                } = children[childKey] || {};
                mainTitle = childTitle;
                const { gap: gapC = '' } = gapChildren[childKey] || {};
                gapValue = gapC;
                scores.push({ score: childScore, quartile: quart, inclusion_quartile: incQuart });
            } else {
                mainTitle = parentTtile;
                scores.push({ score: parentScore, quartile: parentQuart, inclusion_quartile: parentIncQuart });
                gapValue = gapV;
            }
            sizes.push(size);
            displays.push(display_name);
        });
        const scoreArr = scores.slice(0, scores.length - 1).map(({ score: scoreVal }) => scoreVal);
        highestVal = Math.max(...scoreArr);
        lowestVal = Math.min(...scoreArr);
        return { displays, sizes, scores, gapValue, mainTitle, highestVal, lowestVal };
        // eslint-disable-next-line
    }, [gapData]);

    const { label: selectedLevel = '' } = demographics.filter(({ code }) => code === parentGap)[0] || {};

    const { displays, sizes, scores, gapValue, mainTitle, highestVal, lowestVal } = infoToDisplay || {};

    const { bgcolor: bgColor = '', text_color: textColor = '', border_color: borderColor = '' } = getInclusionStyle(
        inclusion_quartile,
        quartile,
        benchmarkStyles,
        styleColors
    );
    const { bgcolor: gapBgColor = '', text_color: textBgColor = '', border_color: gapBorderColor = '' } = getGapColor(
        gapMain,
        gapStyles
    );
    const { bgcolor: gapBg = '', text_color: gapColor = '' } = getGapColor(gapValue, gapStyles);

    return (
        <Dialog
            open
            onClose={() => closeModal(null)}
            maxWidth="xl"
            classes={{
                root: 'clickThruDialogRoot',
            }}
        >
            {loading ? (
                <Loader
                    color="#000000"
                    cssOverride={{
                        margin: '30vh auto',
                    }}
                />
            ) : null}
            <div className="mainContent inclusionContent">
                <section className="headContent">
                    <IconButton
                        data-testid="closeButton"
                        aria-label="close"
                        className="closeButton"
                        onClick={() => closeModal(null)}
                    >
                        <CloseIcon />
                    </IconButton>
                    {showData && (
                        <Fragment>
                            <span className="header">{mainTitle}</span>
                            <span
                                className="scoreSpan"
                                style={{ background: bgColor, color: textColor, border: `0.5px solid ${borderColor}` }}
                            >
                                <span>{score}</span>
                            </span>
                            {gapMain !== '' && (
                                <span
                                    style={{
                                        color: textBgColor,
                                        background: gapBgColor,
                                        border: `0.5px solid ${gapBorderColor}`,
                                    }}
                                    className="gapSpan"
                                >
                                    <span>{gapMain}</span>
                                </span>
                            )}
                            <div className="tabBarContainer">
                                {!isLevel && (
                                    <section className="tabBar clearfix">
                                        <span
                                            data-testid="hideQuestions"
                                            className={c({ active: !isQuestions })}
                                            onClick={() => setView(false)}
                                        >
                                            {DEMOGRAPHICS}
                                        </span>
                                        <span
                                            data-testid="showQuestions"
                                            className={c({ active: isQuestions })}
                                            onClick={() => setView(true)}
                                        >
                                            {QUESTIONS}
                                        </span>
                                    </section>
                                )}
                            </div>
                        </Fragment>
                    )}
                </section>
                {!parentGap && <div className="error">{FILTES_CHECK_NOTE}</div>}
                {showData && (
                    <Fragment>
                        <div className="legendContent tile">
                            <div>
                                <ul className="classification clearfix">
                                    <li>{SCORE_CLASSIFICATION_TEXT} </li>
                                    {styleToUse
                                        .filter(
                                            ({ quartile: quart = '', style_for = '' }) =>
                                                quart !== '0' && quart !== '999' && style_for !== 'blank_data'
                                        )
                                        .map(({ title, bgcolor: background = '' }) => (
                                            <li key={title}>
                                                <div className="circle" style={{ background }} />
                                                <div>{title}</div>
                                            </li>
                                        ))}
                                </ul>
                                <ul className="classification clearfix">
                                    <li>{GAP_CLASSIFICATION_TEXT} </li>
                                    {gapStyles.map(({ title, bgcolor: background }) => (
                                        <li key={title}>
                                            <div className="sq" style={{ background }} />
                                            <div>{title}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="dataContent">
                            {isQuestions ? (
                                <div className="box">
                                    <div>
                                        <div className="columnBox">
                                            <div className="leftSection">
                                                <h3>{OUTCOME_QUESTIONS}</h3>
                                            </div>
                                            <div className="middleSection">
                                                <div className="heading">{INCL_HEAD_META}</div>
                                            </div>
                                            <div className="rightSection">
                                                <div className="heading">{AGGREMENT_LEVEL}</div>
                                                <div className="legends">
                                                    {HEADER_LEGENDS.map(({ title = '', background = '' }, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div
                                                                    style={{
                                                                        border:
                                                                            index === HEADER_LEGENDS.length - 1
                                                                                ? '1px solid'
                                                                                : '',
                                                                        backgroundColor: background,
                                                                    }}
                                                                />
                                                                <div>{title}</div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rowBox">
                                            <div>
                                                <div className="leftSection">
                                                    <div className="secBody clearfix">
                                                        <div>
                                                            <div className="categoryHeading">{mainTitle}</div>
                                                            <div
                                                                style={{
                                                                    background: gapBgColor,
                                                                    border: `0.5px solid ${gapBorderColor}`,
                                                                }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>{gapMain}</div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    background: bgColor,
                                                                    border: `0.5px solid ${borderColor}`,
                                                                }}
                                                                className="circle"
                                                            >
                                                                <div style={{ color: textColor }}>{score}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="middleSection" />
                                                <div className="rightSection" />
                                            </div>
                                            {meta_scores.map((val, meta_index) => {
                                                const {
                                                    score: meta_score = '',
                                                    qtext = '',
                                                    neutral_score = '',
                                                    bottom_2_score = '',
                                                    na_score = '',
                                                } = val;
                                                return (
                                                    <div key={meta_index}>
                                                        <div className="leftSection">
                                                            <div className="margin10">{qtext}</div>
                                                        </div>
                                                        <div className="middleSection">
                                                            <div className="margin10">{meta_score}</div>
                                                        </div>
                                                        <div className="rightSection">
                                                            <div
                                                                className={c('column', {
                                                                    marginBottom3:
                                                                        meta_scores.length - 1 === meta_index,
                                                                })}
                                                            >
                                                                <div className="lineGraph margin10">
                                                                    <div style={{ width: `${bottom_2_score}%` }}>
                                                                        <div>{bottom_2_score}</div>
                                                                    </div>
                                                                    <div style={{ width: `${neutral_score}%` }}>
                                                                        <div>{neutral_score}</div>
                                                                    </div>
                                                                    <div style={{ width: `${meta_score}%` }}>
                                                                        <div>{meta_score}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="naValue margin10">
                                                                    <div>{na_score}%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="demoContainer">
                                    <div className="innerBody">
                                        <div className="leftHeading">
                                            <Tooltip placement="top" arrow title={selectedLevel}>
                                                <div>{selectedLevel}</div>
                                            </Tooltip>
                                            <div>N-size</div>
                                            <div>{mainTitle}</div>
                                        </div>
                                        <div className="rightContent">
                                            <div className="rightContainer">
                                                <div>
                                                    <div>{GAPS_TEXT}</div>
                                                    <div style={{ color: '#ffffff' }}>.</div>
                                                    <div style={{ background: gapBg, color: gapColor }}>{gapValue}</div>
                                                </div>
                                                {displays.map((info, index) => {
                                                    const {
                                                        score: scoreInfo,
                                                        quartile: quart = -1,
                                                        inclusion_quartile: incQuart = -1,
                                                    } = scores[index];
                                                    const {
                                                        bgcolor: disBg = '',
                                                        text_color: disColor = '',
                                                    } = getInclusionStyle(
                                                        incQuart,
                                                        quart,
                                                        benchmarkStyles,
                                                        styleColors
                                                    );
                                                    return (
                                                        <div key={info}>
                                                            <Tooltip key={info} placement="top" arrow title={info}>
                                                                <div>{info}</div>
                                                            </Tooltip>

                                                            <div>{sizes[index]}</div>
                                                            <div
                                                                className="scoreBox"
                                                                style={{
                                                                    background: disBg,
                                                                    color: disColor,
                                                                }}
                                                            >
                                                                {scoreInfo}
                                                                {highestVal === scoreInfo ? (
                                                                    <Tooltip
                                                                        title="Highest score"
                                                                        placement="right-end"
                                                                    >
                                                                        <div className="highestVal">
                                                                            <div>{H_TEXT}</div>
                                                                        </div>
                                                                    </Tooltip>
                                                                ) : null}
                                                                {lowestVal === scoreInfo ? (
                                                                    <Tooltip title="Lowest score">
                                                                        <div className="lowestVal">
                                                                            <div>{L_TEXT}</div>
                                                                        </div>
                                                                    </Tooltip>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={c('disclaimer', { isQuestions })}>
                            <div>{DISCLAIMER}</div>
                            {isQuestions ? (
                                <div>{QUESTION_DISCLAIMER}</div>
                            ) : (
                                <ul className="clearfix">
                                    <li>
                                        {`${DISCLAIMER_TEXT_PART1} < ${inclusion_threshold} ${DISCLAIMER_TEXT_PART2} `}
                                    </li>
                                    <li>{DISCLAIMER_GAP_TEXT}</li>
                                </ul>
                            )}
                        </div>
                    </Fragment>
                )}
            </div>
        </Dialog>
    );
};

ClickThrusModal.defaultProps = {
    withBenchmark: false,
};

ClickThrusModal.propTypes = {
    inclusion_threshold: PropTypes.number.isRequired,
    clickThrusMeta: PropTypes.object.isRequired,
    demographics: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    parentGap: PropTypes.string.isRequired,
    scoreColor: PropTypes.object.isRequired,
    withBenchmark: PropTypes.bool,
    defaultSettings: PropTypes.object.isRequired,
};

export default ClickThrusModal;
